<template>
  <p class="cr1fot">
    By
    <router-link :to="'/Dprofile/' + id">
      <a
        ><i 
          >{{ firstName + " " + lastName.substring(0, 30)
          }}<span v-if="firstName + ' ' + lastName.toString().length > 30"
            >...</span
          ></i
        >
      </a>
    </router-link>
  </p>
</template>
<script>
export default {
  name: "Owner",
  data() {
    return {
      uni: null,
      processing: true,
    };
  },
  props: {
    id: String,
    firstName: String,
    lastName: String,
  }
};
</script>
<style >
</style>
<template>
    <div class="row">
        <ul class="wide">
            
            <li class="pageInActive" v-bind:class="{pageActive: page=='Deditcourse'}">
                <router-link :to="'/Deditcourse/' + courseId">
                    <a>
                        <span class="number"></span>
                        <span class="step-name">Course Information</span>
                    </a>
                </router-link>
            </li>
        
        
            <li class="pageInActive" v-bind:class="{pageActive: page=='Dnewcoursecontent'}">
                <router-link :to="'/Dnewcoursecontent/' + courseId">
                    <a>
                    <span class="number"></span>
                    <span class="step-name">Course Sections</span>
                    </a>
                </router-link>
            </li>
        
        
            <li class="pageInActive" v-bind:class="{pageActive: page=='DnewcourseAffiliation'}">
            <router-link
                :to="'/DnewcourseAffiliation/' + courseId"
            >
                <a>
                <span class="number"></span>
                <span class="step-name">Course Affiliations</span>
                </a>
            </router-link>
            </li>
        
        
            <li class="pageInActive" v-bind:class="{pageActive: page=='DnewcourseTest'}">
            <router-link :to="'/DnewcourseTest/' + courseId">
                <a>
                <span class="number"></span>
                <span class="step-name">Course Test</span>
                </a>
            </router-link>
            </li>
        
        
            <li class="pageInActive" v-bind:class="{pageActive: page=='DnewcourseCoupons'}">
            <router-link :to="'/DnewcourseCoupons/' + courseId">
                <a>
                <span class="number"></span>
                <span class="step-name">Course Coupons</span>
                </a>
            </router-link>
            </li>
            
        </ul>
    </div>
</template>

<script>
export default {
    data(){
		return{
			page: ""
        }
    },
    methods: {
    },
    watch: {
		$route() {
            this.page = window.location.pathname.split('/')[1]
		},
    },
    props: {
        courseId: String,
    },
    mounted(){
       this.page = window.location.pathname.split('/')[1]
    }
};
</script>

<style>
    .pageInActive{
        padding: 5px;
        /* width: 30%; */
    }

    .pageActive{
        padding: 5px;
        color: white;
        /* width: 33%; */
        border-radius: 10px;
        display: inline-block;
    }

    .pageInActive{
        display: inline-block;
    }

	.pageInActive a{
		text-decoration: none;
		color: white;
		/* height: 100%; */
		padding: 5px; 
		border-radius: 20px;
		/* width: 100%; */
		background-color: grey;
        display: inline-block;
	}

	.pageActive a{
		text-decoration: none;
		color: white;
		padding: 8px; 
		border-radius: 35px;
		background-color: red;
        display: inline-block;
	}

    .wide{
        width: 100%;
    }

</style>
<template>
  <div>
    <!-- Header Start -->
    <Dheader />
    <!-- Header End -->
    <Dnav1 />
    <!-- Body Start -->
    <SkeletonLoader v-show="!status.message"/>

    <div class="wrapper" v-show="status.message=='ok'">
      <div class="_215cd2">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="course_tabs">
                <nav>
                  <div class="nav nav-tabs tab_crse justify-content-center">
                    <a
                      class="nav-item nav-link"
                      v-bind:class="{ active: tap=='books' }"
                      @click.prevent="switchtap('books')"
                      >E-books</a
                    >
                    <a
                      class="nav-item nav-link"
                      v-bind:class="{ active: tap=='courses' }"
                      @click.prevent="switchtap('courses')"
                      >Courses</a
                    >
                  </div>
                </nav>
              </div>
              <div class="title129 mt-35 mb-35">
                <h2>Outlet Address</h2>
                <center><p>{{outletUserId}}</p></center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="_215zd5" v-if="tap=='books'" id="course">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="title589 text-center">
                <h2>Our books</h2>
                <img class="line-title" src="./assets/images/line.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="row">
            <EbookEntity
              v-for="b in books"
              :key="b"
              :ISBN="b.ISBN"
              :createdAt="b.createdAt"
              :discountPercent="b.discountPercent"
              :language="b.language"
              :outlet="b.outlet"
              :pages="b.pages"
              :publisher="b.publisher"
              :secondTitle="b.secondTitle"
              :thumbnailFile="b.thumbnailFile"
              :title="b.title"
              :year="b.year"
              :id="b._id"
              :category="b.category[0].name"
              :price="0"
              :firstName="b.user[0].firstName"
              :lastName="b.user[0].lastName"
              :userId="b.user[0]._id"
              :file="b.thumbnailFile"
              :discount="100"
              :outletCredentials="outletCredentials"
            />
          </div>

          <br /><br /><br />
          <center>
            <button
              class="live_link"
              type="button"
              v-if="!Loading"
              @click="fetchMore('books')"
            >
              Load More
            </button>
            <button
              class="live_link"
              type="button"
              v-if="Loading"
            >
              Please wait ...
            </button>
          </center>
          <br /><br /><br />
        </div>
      </div>
      <div class="_215zd5" v-if="tap=='courses'" id="course">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="title589 text-center">
                <h2>Our Courses</h2>
                <img class="line-title" src="./assets/images/line.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="row" >
            <CourseEntityTiny
              v-for="c in courses"
              :key="c"
              :id="c._id"
              :title="c.title"
              :category="c.category[0].name"
              :price="0"
              :firstName="c.user[0].firstName"
              :lastName="c.user[0].lastName"
              :userId="c.user[0]._id"
              :file="c.thumbnailFile"
              :discount="100"
              :outletCredentials="outletCredentials"
            />
          </div>
          <br /><br /><br />
          <center>
            <button
              class="live_link"
              type="button"
              v-if="!Loading"
              @click="fetchMore('courses')"
            >
              Load More
            </button>
            <button
              class="live_link"
              type="button"
              v-if="Loading"
            >
              Please wait ...
            </button>
          </center>
          <br /><br /><br />
        </div>
      </div>
      <Dfooter />
    </div>

    <div class="wrapper" v-show="status.message && status.message!='ok'">
      <div class="_215cd2">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              
              <div class="title129 mt-35 mb-35">
                <center><h3>{{status.message}}</h3></center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dfooter />
    </div>
    <!-- Body End -->
  </div>
</template>
<script>
import SkeletonLoader from "@/components/SkeletonLoader.vue";
import CourseEntityTiny from "@/components/dashboard/CourseEntityTiny";
import EbookEntity from "@/components/dashboard/EbookEntity";
import { mapActions, mapGetters } from "vuex";
import Dheader from "@/components/dashboard/Header.vue";
import Dnav1 from "@/components/dashboard/Nav1.vue";
import Dfooter from "@/components/dashboard/Footer.vue";
// import Loading from "@/components/Loading.vue";
// import Success from "@/components/Success.vue";
// import ProfileImage from "@/components/ProfileImage";
// import Ratings from "@/components/site/Ratings.vue";
// import Errors from "@/components/Errors.vue";
// import RatingsDetails from "@/components/dashboard/RatingsDetails.vue";
export default {
  name: "Dhome",
  data() {
    return {
      tap: 'courses',
      key: '',
			user_id: '',
			error: '',
			skip: 0,
      outlet: null,
      Loading: false,
      courses: [],
      status: {
        message: ""
      },
      books: [],
      outletCredentials: ""
    };
  },
  components: {
    SkeletonLoader,
    Dheader,
    Dnav1,
    Dfooter,
    // Loading,
    // Errors,
    // ProfileImage,
    // Ratings,
    CourseEntityTiny,
    EbookEntity,
    // Success,
    // RatingsDetails,
  },
  computed: {
    ...mapGetters(["backEnd", "publicFiles"]),
    ...mapGetters(["isInstructor"]),
  },
  methods: {
    ...mapActions([
      "verifyOutlet","fetchOutletAllBooks","fetchOutletCourses"
    ]),
    switchFocus(to) {
      this.skip = 0;
      this.focus = to;
    },
    switchtap(tap){
      this.skip = 0;
      this.tap = tap
    },
    fetchMore(type){
      this.Loading = true
			this.skip = this.skip + 10
			switch (type) {
				case 'courses':
          this.fetchOutletCourses({userId: this.outletUserId, skip: this.skip})
          .then(res=>{
						for (let i = 0; i < res.data.length; i++) {
							const element = res.data[i];
							this.courses.push(element)
						}
						this.Loading = false
					})		
					break;
				case 'books':
          this.fetchOutletAllBooks({userId: this.outletUserId, skip: this.skip})
          .then(res=>{
						for (let i = 0; i < res.data.length; i++) {
							const element = res.data[i];
							this.books.push(element)
						}
						this.Loading = false
					})
								
					break;
				default:
					break;
			}
		},
  },
  mounted(){
    this.outletUserId = this.$route.params.userId;
    this.key = this.$route.query.key;
    this.outletCredentials = "?outletUserId="+this.outletUserId+"&key="+this.key
    this.verifyOutlet({userId: this.outletUserId, key: this.key})
    .then(res=>{
      this.outlet = res.data
      if(!this.outlet){
        this.status = {
          message : "Outlet not found" 
        }
      } else if (new Date(this.outlet.expire).getTime() < new Date().getTime()) {
        this.status = {
          message : "This Outlet is temporally closed" 
        }
      } else {
        this.status = {
          message : "ok" 
        }
        this.fetchOutletCourses({userId: this.outletUserId, skip: this.skip})
        .then(res=>{
          this.courses = res.data
        })
        this.fetchOutletAllBooks({userId: this.outletUserId, skip: this.skip})
        .then(res=>{
          this.books = res.data
        })
      }

      this.Loading = false
    })
  }
};
</script>
<style scoped>
@import url("./assets/css/style.css");
/* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
@import url("./assets/vendor/unicons-2.0.1/css/unicons.css");
@import url("./assets/css/instructor-dashboard.css");
@import url("./assets/css/vertical-responsive-menu.min.css");
@import url("./assets/css/instructor-dashboard.css");
@import url("./assets/css/instructor-responsive.css");
@import url("./assets/css/responsive.css");
@import url("./assets/css/night-mode.css");
@import url("./assets/vendor/fontawesome-free/css/all.min.css");
@import url("./assets/vendor/OwlCarousel/assets/owl.carousel.css");
@import url("./assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
@import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
@import url("./assets/vendor/semantic/semantic.min.css");

.nav-link {
  cursor: pointer;
  border-radius: 50%;
}

.liked {
  background-color: yellowgreen;
  border: 2px solid yellowgreen;
}

.likeButton {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  padding: 10px;
}

.liking {
  width: 60px;
  height: 60px;
  display: inline-block;
  margin: 5px;
  padding-top: 10px;
}
</style>
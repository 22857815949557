<template>
    <div>
        <Header />

        <div class="content-wrapper">
            <div class="container-fluid">
                <!-- Breadcrumbs-->
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                    <a href="#">Dashboard</a>
                    </li>
                    <li class="breadcrumb-item active">Tables</li>
                </ol>
                    <!-- Example DataTables Card-->
                <div class="card mb-3">
                    <div class="card-header">
                    <i class="fa fa-table"></i> Purchased Courses</div>
                    <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                            <th>Course</th>
                            <th>Amount</th>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>Purchased Date</th>
                            <th>Reference</th>
                            <th>Membership</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                            <th>Course</th>
                            <th>Amount</th>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>Purchased Date</th>
                            <th>Reference</th>
                            <th>Membership</th>
                            </tr>
                        </tfoot>
                        <tbody>
                            <tr v-for="p in purchases" :key="p">
                                <td>{{p.course[0].title}}</td>
                                <td>₦{{p.price.$numberDecimal}}</td>
                                <td>{{p.student[0].firstName}} {{p.student[0].lastName}}</td>
                                <td>{{p.student[0].email}}</td>
                                <td>{{p.createdAt.split('T')[0]}}</td>
                                <td>{{p.reference}}</td>
                                <td v-if="p.membership&&p.membership.length!=0">{{p.membership[0].title}}</td>
                                <td v-else>NULL</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                    <!-- <div class="card-footer small text-muted">Updated yesterday at 11:59 PM</div> -->
                </div>
                <!-- /tables-->
                <p><a href="#0" class="btn_1 medium" @click.prevent="fetchMore()" v-if="!Loading">Fetch More Payouts</a></p>
                <p><a href="#0" class="btn_1 medium" v-if="Loading">Please Wait ...</a></p>
                </div>
                <!-- /container-fluid-->
            </div>
            <!-- /.container-wrapper-->
            <Footer />
            <!-- Scroll to Top Button-->
            <a class="scroll-to-top rounded" href="#page-top">
            <i class="fa fa-angle-up"></i>
            </a>
            <!-- Logout Modal-->
            <Modal />
        
    </div>
</template>
<script>
import Header from '@/components/superAdmin/Header.vue'
import Modal from '@/components/superAdmin/Modal.vue'
import Footer from '@/components/superAdmin/Footer.vue'
import { mapActions } from "vuex";
export default {
    data(){
        return{
            skip: 0,
            purchases: '',
            Loading: false
        }
    },
    components: {
        Header,
        Modal,
        Footer
    },
    methods: {
        ...mapActions(['fetctPurchasedCourses__Admin']),
        fetchMore(){
            this.skip = this.skip + 10
            this.Loading = true
            this.fetctPurchasedCourses__Admin(this.skip)
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    this.purchases.push(element)
                }
                this.Loading = false
            })
        }
        
    },
    mounted(){
        this.fetctPurchasedCourses__Admin(this.skip)
        .then(res => {
            this.purchases = res.data
        })
        
    }
}
</script>
<style scoped>
	@import url("./assets/css/admin.css");
    @import url("./assets/css/custom.css");
    @import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
    @import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    .payed{
        color: white;
        background-color: green;
        padding: 5px;
        border-radius: 10px;
        border: none;
    }
    .notpayed{
        color: white;
        background-color: red;
        padding: 8px;
        border-radius: 10px;
        border: none;
    }
</style>
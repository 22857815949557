<template>
    <div>
    <!-- Header Start -->
        <Dheader />
	<!-- Header End -->
	<!-- Left Sidebar Start -->
        <Dnav1 />
	<!-- Left Sidebar End -->
	<!-- Body Start -->
        <div class="wrapper _bg4586">
            <div class="sa4d25">
                <div class="container-fluid">			
                    <div class="row">
                        <br><br>
                        <div class="col-lg-3 col-md-4 ">
                            <div class="section3125 hstry142">
                                <!-- <div class="grp_titles pt-0">
                                    <div class="ht_title">Saved Courses</div>
                                    <a href="#" class="ht_clr">Remove All</a>
                                </div> -->
                                <div class="tb_145">
                                    <!-- <div class="wtch125">
                                        <span class="vdt14">4 Courses</span>
                                    </div> -->
                                    <ul class="allcate15">
                                        <li><a class="ct_item" ><h4>Categories</h4></a></li>
                                        <router-link :to="'/Dallcourses/e'">
                                        <li><p class="ct_item" style="font-size: 12px; padding: 5px 5px" v-bind:class="{ actively: category=='e'}"><i class='uil uil-arrow'></i>All</p></li>
                                        </router-link>
                                        <router-link :to="'/Dallcourses/free'">
                                        <li><p class="ct_item" style="font-size: 12px; padding: 5px 5px" v-bind:class="{ actively: category=='free'}"><i class='uil uil-arrow'></i>Free</p></li>
                                        </router-link>
                                        <router-link :to="'/Dallcourses/'+c._id" v-for="c in categories" :key="c">
                                        <li><p class="ct_item" style="font-size: 12px; padding: 5px 5px" v-bind:class="{ actively: category==c._id}"><i class='uil uil-arrow'></i>{{c.name}}</p></li>
                                        </router-link>
                                    </ul>
                                </div>						
                            </div>							
                        </div>			
                        	
                        <div class="col-md-9">
                            <div class="_14d25 mb-20">
                                <Loading v-if="Loading"/>
                                <div class="row" >
                                    <div class="col-md-12" v-if="courses.length>0">
                                        <h4 class="mhs_title">Courses</h4>
                                        <CourseEntitySpread v-for="c in courses" :key="c" 
                                            :id="c._id" 
                                            :title="c.title"
                                            :file="c.thumbnailFile"
                                            :category="c.category[0].name"
                                            :price="c.price.$numberDecimal"
                                            :firstName="c.user[0].firstName"
                                            :lastName="c.user[0].lastName"
                                            :userId="c.user[0]._id"
                                            :discount="c.discountPercent"
                                        />	
                                    </div>
                                    <div class="col-md-12" v-if="!Loading&&courses.length<=0">
                                        <br>
                                        <br>
                                        <br>
                                        <br>
                                        <br>
                                        <br>
                                        <center>
                                            <h3>No Course for this category yet</h3>
                                        </center>
                                    </div>								
                                </div>																		
                            </div>	
                            							
                        </div>	
                        <div class="col-md-9">
                            <center>
                                <nav aria-label="...">
                                    <ul class="pagination pagination-sm add_bottom_30">
                                        <li class="page-item" v-if="page>1" @click="fetchNew(page-1)">
                                            <a class="page-link"  >Previous</a>
                                        </li>
                                        <li class="page-item" v-for="p in pages" :key="p">
                                            <a class="page-link" :class="{activePage: p==page}" @click="fetchNew(p)" >{{p}}</a></li>
                                            <li class="page-item" v-if="page<numberOfPages" @click="fetchNew(page+1)">
                                            <a class="page-link">Next </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" > total record : {{total}}</a>
                                        </li>
                                    </ul>
                                </nav>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
            <Dfooter />
        </div>
	<!-- Body End -->
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import CourseEntitySpread from "@/components/dashboard/CourseEntitySpread";
import Loading from '@/components/Loading.vue'
import Dheader from '@/components/dashboard/Header.vue'
import Dnav1 from '@/components/dashboard/Nav1.vue'
import Dfooter from '@/components/dashboard/Footer.vue'
export default {
    name: 'Dhome',
    data(){
        return{
            category: '',
            courses: [],
            categories: '',
            page: 1,
            pages: [],
            numberOfPages: 0,
            total: 0,
            limit: 10,
            Loading: false
        }
    },
    watch: {
        $route() {
            this.category = this.$route.params.cat
            this.page = 1
            this.fetchAllCategoryCoursesPagination__public({
                cat: this.category,
                page: this.page,
            }).then(res => {
                this.courses = res.data.courses
                this.total = res.data.total
                this.getPages();
                this.Loading = false;
            })
        },

        circle: function () {
            if (this.circle == 'loading') {
                this.Loading = true
            }else{
                this.Loading = false
            }
        }
        
    },
    computed: {
        ...mapGetters(['circle']),
        ...mapGetters(['backEnd'])
    },
    methods: {
        ...mapActions(['fetch_courses_by_category','fetchAllCategoryCoursesPagination__public']),
        ...mapActions(['fetch_categories']),
        // fetchMore(){
        //     this.skip = this.skip + 10
        //     this.fetch_courses_by_category({cat: this.category,skip: this.skip})  
        //     .then(res => {
        //         for (let i = 0; i < res.data.length; i++) {
        //             const element = res.data[i];
        //             this.courses.push(element)
        //         }
        //     })
        // }
        getPages() {
            this.pages = []
            this.numberOfPages = ~~(this.total/this.limit)
            if((this.total/this.limit)>this.numberOfPages){
                this.numberOfPages = this.numberOfPages + 1
            }
            for (let i = 1; i <= this.numberOfPages; i++) {
                this.pages.push(i)
            }
        },
        fetchNew(page) {
            this.page = page;
            this.Loading = true;
            this.fetchAllCategoryCoursesPagination__public({
                cat: this.category,
                page: this.page,
            }).then(res => {
                this.courses = res.data.courses
                this.total = res.data.total
                this.getPages();
                this.Loading = false;
                window.scrollTo(0,0)
            })
        }
    },
    components: {
        Dheader,
        Dnav1,
        Loading,
        CourseEntitySpread,
        Dfooter
    },   
    mounted(){
        this.Loading = true
        this.category =  this.$route.params.cat
        this.fetch_categories()
        .then(res => {
            this.categories = res.data
        })
        // this.fetch_courses_by_category({cat: this.category,skip: this.skip})  
        // .then(res => {
        //     this.courses = res.data
        //     this.Loading = false
        // })
        this.fetchAllCategoryCoursesPagination__public({
            cat: this.category,
            page: this.page,
        }).then(res => {
            this.courses = res.data.courses
            this.total = res.data.total
            this.getPages();
            this.Loading = false;
        })
        
        var currentUrl = window.location.pathname+'';
		var page = currentUrl.split('/')[1]
        console.log(page +" this is it")
    }
}
</script>
<style scoped>
    @import url("./assets/css/style.css");
    /* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
    @import url("./assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/vertical-responsive-menu.min.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/instructor-responsive.css");
    @import url("./assets/css/responsive.css");
    @import url("./assets/css/night-mode.css");
    @import url("./assets/vendor/fontawesome-free/css/all.min.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("./assets/vendor/semantic/semantic.min.css");

    .actively{
        background-color: red;
        width: 100%;
        border-radius: 20px;
        color: white;
    }

    .activePage{
        color: white;
        background-color: red;  
    }
   
</style>
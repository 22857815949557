<template>
    <div></div>
    <!-- <footer class="sticky-footer">
        <div class="container">
            <div class="text-center">
            <small>Copyright © Faslearn 2020</small>
            </div>
        </div>
    </footer> -->
</template>
<script>
export default {
    
}
</script>
<style >

</style>
<template>
    <div>
        <Dheader />

        <Dnav2 />
        
        <div class="wrapper">
            <div class="sa4d25">
                <div class="container-fluid">			
                    <div class="row">
                        <div class="col-lg-12">	
                            <h2 class="st_title"><i class="uil uil-book-alt"></i>Books</h2>
                        </div>			
                        <div class="col-md-12" v-if="isInstructor||isAdmin">
                            <div class="card_dash1">
                                <div class="card_dash_left1"> 
                                    <i class="uil uil-book-alt"></i>
                                    <h1>Jump Into Book Creation</h1>
                                </div>
                                <router-link to="/Dnewbook">
                                <div class="card_dash_right1">
                                    <button class="create_btn_dash" type="button">Create Your Book </button>
                                </div>
                                </router-link>
                            </div>
                        </div>					
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="my_courses_tabs">
                                <ul class="nav nav-pills my_crse_nav" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="pills-my-courses-tab" data-toggle="pill" href="#pills-my-courses" role="tab" aria-controls="pills-my-courses" aria-selected="true"><i class="uil uil-download-alt"></i>My Purchases</a>
                                    </li>
                                    <li class="nav-item" v-if="isAdmin||isInstructor">
                                        <a class="nav-link" id="pills-my-purchases-tab" data-toggle="pill" href="#pills-my-purchases" role="tab" aria-controls="pills-my-purchases" aria-selected="false"><i class="uil uil-book-alt"></i>My Books</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-upcoming-courses-tab" data-toggle="pill" href="#pills-upcoming-courses" role="tab" aria-controls="pills-upcoming-courses" aria-selected="false"><i class="uil uil-upload-alt"></i>Pseudo Uploads</a>
                                    </li>
                                    <!-- <li class="nav-item" v-if="isAdmin">
                                        <a class="nav-link" id="pills-discount-tab" data-toggle="pill" href="#pills-discount" role="tab" aria-controls="pills-discount" aria-selected="false"><i class="uil uil-tag-alt"></i>Affiliations Request</a>
                                    </li>
                                    <li class="nav-item" v-if="isAdmin||isInstructor">
                                        <a class="nav-link" id="pills-promotions-tab" data-toggle="pill" href="#pills-promotions" role="tab" aria-controls="pills-promotions" aria-selected="false"><i class="uil uil-package"></i>Bundles</a>
                                    </li> -->
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" style="background-color: transparent" id="pills-my-courses" role="tabpanel">
                                        <div class="table-responsive mt-30" style="background-color: transparent">
                                            <table class="table ucp-table">
                                                <thead class="thead-s">
                                                    <tr>
                                                        <th class="text-center" scope="col">Item No.</th>
                                                        <th>Title</th>
                                                        <th class="text-center" scope="col">2nd Title</th>
                                                        <!-- <th class="text-center" scope="col">Sales</th>
                                                        <th class="text-center" scope="col">Parts</th> -->
                                                        <th class="text-center" scope="col">Category</th>
                                                        <th class="text-center" scope="col">Status</th>
                                                        <th class="text-center" scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="p in purchasedBooks" :key="p">
                                                        <td class="text-center">{{1+(purchasedBooks.indexOf(p))}}</td>
                                                        <td>{{p.title}}</td>
                                                        <td class="text-center">{{p.secondTitle}}</td>
                                                        <!-- <td class="text-center">15</td>
                                                        <td class="text-center">5</td> -->
                                                        <td class="text-center"><a href="#">{{p.category[0].name}}</a></td>
                                                        <td class="text-center"><b class="course_active">Active</b></td>
                                                        <router-link :to="'/Dbook/'+p._id">
                                                        <td class="text-center">
                                                            <a href="#" title="Edit" class="gray-s"><i class="uil uil-eye"></i></a>
                                                        </td>
                                                        </router-link>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br><br><br>
                                        <center>
                                            <button class="live_link" type="button" v-if="!Loading" @click="fetchMore('myPurchaseSkip')">Load More</button>	
                                        </center>
                                        <br><br><br>
                                    </div>
                                    <div class="tab-pane fade" style="background-color: transparent" id="pills-my-purchases" role="tabpanel">
                                        <div class="table-responsive mt-30" style="background-color: transparent">
                                            <table class="table ucp-table">
                                                <thead class="thead-s">
                                                    <tr>
                                                        <th class="text-center" scope="col">Item No.</th>
                                                        <th class="cell-ta" scope="col">Title</th>
                                                        <th class="text-center" scope="col">2nd Title</th>
                                                        <!-- <th class="cell-ta" scope="col">Vendor</th> -->
                                                        <th class="cell-ta" scope="col">Category</th>
                                                        <th class="text-center" scope="col">Status</th>
                                                        <!-- <th class="text-center" scope="col">Price</th>
                                                        <th class="text-center" scope="col">Purchase Date</th> -->
                                                        <th class="text-center" scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="p in myBooks" :key="p">
                                                        <td class="text-center">{{1+(myBooks.indexOf(p))}}</td>
                                                        <td class="cell-ta">{{p.title}}</td>
                                                        <td class="text-center">{{p.secondTitle}}</td>
                                                        <!-- <td class="cell-ta"><a href="#">Zoena Singh</a></td> -->
                                                        <td class="cell-ta">{{p.category[0].name}}</td>
                                                        <td class="text-center">
                                                            <b class="course_active" style="color: green" v-if="p.isApproved">Approved</b>
                                                            <b  class="course_active" v-else>
                                                                <b v-if="p.adminSeen" style="color: red">Denied</b>
                                                                <b v-else style="color: orange">Pending</b>
                                                            </b>
                                                        </td>
                                                        <!-- <td class="text-center">$15</td>
                                                        <td class="text-center">25 March 2020</td> -->
                                                        <td class="text-center">
                                                            <router-link :to="'/Deditbook/'+p._id">
                                                            <a title="Edit" class="gray-s"><i class="uil uil-edit-alt"></i></a>
                                                            </router-link>
                                                            <!-- <a title="Delete" class="gray-s" @click="deleteBook(p._id,p.title)"><i class="uil uil-trash-alt"></i></a> -->
                                                            <router-link :to="'/Dbook/'+p._id">
                                                            <a title="View" class="gray-s"><i class="uil uil-eye"></i></a>
                                                            </router-link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>	
                                        <br><br><br>
                                        <center>
                                            <button class="live_link" type="button" v-if="!Loading" @click="fetchMore('instructorSkip')">Load More</button>	
                                        </center>
                                        <br><br><br>							
                                    </div>
                                    <div class="tab-pane fade" style="background-color: transparent" id="pills-upcoming-courses" role="tabpanel">
                                        <div class="table-responsive mt-30" style="background-color: transparent">
                                            <table class="table ucp-table">
                                                <thead class="thead-s">
                                                    <tr>
                                                        <th class="text-center" scope="col">Item No.</th>
                                                        <th class="cell-ta" scope="col">Title</th>
                                                        <th class="text-center" scope="col">2nd Title</th>
                                                        <th class="cell-ta" scope="col">Category</th>
                                                        <th class="cell-ta" scope="col">Status</th>
                                                        <th class="text-center" scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="p in psuedoBooks" :key="p" :class="{ off : p.user[0]._id == p.psuedoUser[0] }">
                                                        <td class="text-center">{{1+(psuedoBooks.indexOf(p))}}</td>
                                                        <td class="cell-ta">{{p.title}}</td>
                                                        <td class="text-center">{{p.secondTitle}}</td>
                                                        <!-- <td class="cell-ta"><a href="#">Zoena Singh</a></td> -->
                                                        <td class="cell-ta">{{p.category[0].name}}</td>
                                                        <td class="text-center">
                                                            <b class="course_active" style="color: green" v-if="p.isApproved">Approved</b>
                                                            <b class="course_active" v-else>
                                                                <b v-if="p.adminSeen" style="color: red">Denied</b>
                                                                <b v-else style="color: orange">Pending</b>
                                                            </b>
                                                        </td>
                                                        <td class="text-center">
                                                            <router-link :to="'/Deditbook/'+p._id">
                                                            <a title="Edit" class="gray-s"><i class="uil uil-edit-alt"></i></a>
                                                            </router-link>
                                                            <router-link :to="'/Dbook/'+p._id">
                                                            <a title="View" class="gray-s"><i class="uil uil-eye"></i></a>
                                                            </router-link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br><br><br>
                                        <center>
                                            <button class="live_link" type="button" v-if="!Loading" @click="fetchMore('psuedoSkip')">Load More</button>	
                                        </center>
                                        <br><br><br>
                                    </div>
                                    <div class="tab-pane fade" style="background-color: transparent" id="pills-discount" role="tabpanel">
                                        <div class="table-responsive mt-30" style="background-color: transparent">
                                            <table class="table ucp-table">
                                                <thead class="thead-s">
                                                    <tr>
                                                        <th class="text-center" scope="col">Item No.</th>
                                                        <th class="cell-ta">Book</th>
                                                        <th class="text-center" scope="col">Category</th>
                                                        <th class="text-center" scope="col">Price</th>
                                                        <th class="text-center" scope="col">Percentage</th>
                                                        <th class="text-center" scope="col">Status</th>
                                                        <th class="text-center" scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="a in myAffiliations" :key="a">
                                                        <td class="text-center">{{1+(myAffiliations.indexOf(a))}}</td>
                                                        <td class="cell-ta">{{a.title}}</td>
                                                        <td class="text-center">{{a.category[0].name}}</td>
                                                        <td class="text-center">{{a.price.$numberDecimal}}</td>
                                                        <td class="text-center">{{(1/(a.affiliatedInstitutions.length+1))*100}}%</td>
                                                        <td class="text-center">
                                                            <b class="course_active" v-for="i in a.affiliatedInstitutions" :key="i">
                                                                <i v-if="i.institutionId[0].user[0]==userId && i.accepted" style="color: green">
                                                                    Active
                                                                </i>
                                                                <i v-if="i.institutionId[0].user[0]==userId && !i.accepted">
                                                                    Pending
                                                                </i>
                                                            </b>
                                                        </td>
                                                        <td class="text-center">
                                                            <a title="View Details" class="gray-s" @click="activateModal(a._id, 'affilliation')"><i class="uil uil-eye"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" style="background-color: transparent" id="pills-promotions" role="tabpanel" aria-labelledby="pills-promotions-tab">
                                        <div class="table-responsive mt-30" style="background-color: transparent">
                                            <br>
                                            <center>
                                                <button class="live_link" type="button" v-if="!Loading" @click="activateModal(null, 'addPackage')"><i class="uil uil-plus"></i> Add new Bundle</button>	
                                            </center>
                                            <br>
                                            <div v-for="p in packages" :key="p">
                                                <h4 style="margin-top: 5px; margin-left: 5px">{{p.title}}</h4>
                                                <div class="controls">
                                                    <a title="Edit" class="gray-s"  @click="activateModal(p._id, 'editPackage')"><i class="uil uil-edit"></i></a>
                                                    <a title="Upload" class="gray-s" @click="activateModal(p._id, 'addBook')"><i class="uil uil-upload"></i></a>
                                                    <!-- <a title="Delete" class="gray-s" ><i class="uil uil-trash-alt"></i></a> -->
                                                </div>
                                                <table class="table ucp-table">
                                                    <thead class="thead-s">
                                                        <tr>
                                                            <th class="text-center" scope="col">Item No.</th>
                                                            <th class="cell-ta">Book</th>
                                                            <th class="text-center" scope="col">Price</th>
                                                            <th class="text-center" scope="col">Percentage</th>
                                                            <th class="text-center" scope="col">Status</th>
                                                            <th class="text-center" scope="col">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="a in p.courses" :key="a">
                                                            <td class="text-center">{{1+(p.courses.indexOf(a))}}</td>
                                                            <td class="cell-ta">{{a.title}}</td>
                                                            <td class="text-center">{{a.price.$numberDecimal}}</td>
                                                            <td class="text-center">{{(1/(a.affiliatedInstitutions.length+1))*100}}%</td>
                                                            <td class="text-center">
                                                                <b class="course_active" style="color: green" v-if="a.isApproved">Approved</b>
                                                                <b class="course_active" v-else>
                                                                    <b v-if="a.adminSeen" style="color: red">Denied</b>
                                                                    <b v-else style="color: orange">Pending</b>
                                                                </b>
                                                            </td>
                                                            <td class="text-center">
                                                                <router-link :to="'/Dbook/'+a._id">
                                                                    <a title="View Details" class="gray-s"><i class="uil uil-eye"></i></a>
                                                                </router-link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <br>
                                                <br>
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dfooter />
        </div>
    </div>

</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Dheader from '@/components/dashboard/Header.vue'
import Dnav2 from '@/components/dashboard/Nav2.vue'
import Dfooter from '@/components/dashboard/Footer.vue'
export default {
    name: 'DBook',
    data(){
        return{
            userId: '',
            purchasedBooks: '',
            myBooks: '',
            psuedoBooks: '',
            myAffiliations: '',
            Loading: false,
            success: false,
            courseId: '',
            recentBooks: '',
            affiliationObject: { user: [{firstName: '', lastName: '', email: ''}] },
            psuedoSkip: 0,
            myPurchaseSkip: 0,
            instructorSkip: 0,
            affiliateSkip : 0,
            packages: [],
            modalType: "affilliation",
            packageId: '',
            packageSkip: 0,
            packageTitle: '',
            packagePercent: '',
            packageDate: '',
            error: [],
            selectedBooks: []
        }
    },
    components: {
        Dheader,
        Dnav2,
        Dfooter,
    }, 
    computed: {
        ...mapGetters(['isInstructor']),
        ...mapGetters(['isAdmin'])
    }, 
    methods: {
        ...mapActions(['fetchInstructorBooksForInstructorView', 'fetchPurchasedBooks', 'fetchPseudoInstructorBooksForInstructorView']),
        
        deleteBook(id,name){
            if (confirm("Do you REALLY want to delete "+name+" ?")) {
                this.delete_course(id)
				.then(res => {
					this.myBooks = res.data.courses
				})
            }else{
                alert('ok, we will ignore your delete request')
            }
        },

        fetchMore(type){
            this.Loading = true
            switch (type) {
                case 'psuedoSkip':
                   this.psuedoSkip = this.psuedoSkip + 10
                   this.fetchPseudoInstructorBooks({id: this.userId, skip: this.myPurchaseSkip})
                    .then(res => {
                        for (let i = 0; i < res.data.length; i++) {
                            const element = res.data[i];
                            this.psuedoBooks.push(element)
                        }
                        this.Loading = false
                    })
                    break;
                case 'myPurchaseSkip':
                    this.myPurchaseSkip = this.myPurchaseSkip + 10
                    this.fetchPurchasedBooksForInstructorView({user: this.userId, skip: this.myPurchaseSkip})
                    .then(res => {
                        for (let i = 0; i < res.data.length; i++) {
                            const element = res.data[i];
                            this.purchasedBooks.push(element)
                        }
                        this.Loading = false
                    })
                    break;
                case 'instructorSkip':
                    this.instructorSkip = this.instructorSkip + 10
                    this.fetchInstructorBooksForInstructorView({id: this.userId, skip: this.psuedoSkip})
                    .then(res => {
                        for (let i = 0; i < res.data.length; i++) {
                            const element = res.data[i];
                            this.myBooks.push(element)
                        }
                        this.Loading = false
                    })
                    break;
            
                default:
                    break;
            }
        }
    }, 
    mounted(){
        this.userId = localStorage.getItem('fmruser');
        this.fetchPurchasedBooks({user: this.userId, skip: this.myPurchaseSkip})
        .then(res => {
            this.purchasedBooks = res.data
        })
        // this.fetch_recent_courses(this.psuedoSkip)
        // .then(res => {
        //     this.recentBooks = res.data
        // })

        if (this.isInstructor||this.isAdmin) {
            this.fetchInstructorBooksForInstructorView({id: this.userId, skip: this.psuedoSkip})
            .then(res => {
                this.myBooks = res.data
            })

            this.fetchPseudoInstructorBooksForInstructorView({id: this.userId, skip: this.myPurchaseSkip})
            .then(res => {
                this.psuedoBooks = res.data
            })
        }
    }
}
</script>
<style>
    @import url("../assets/css/style.css");
    /* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
    @import url("../assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/vertical-responsive-menu.min.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/instructor-responsive.css");
    @import url("../assets/css/responsive.css");
    @import url("../assets/css/night-mode.css");
    @import url("../assets/vendor/fontawesome-free/css/all.min.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("../assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("../assets/vendor/semantic/semantic.min.css");

    .modal-body{
        max-height: 600px;
        overflow-y: scroll;
    }
    .off{
        display: none;
    }

</style>
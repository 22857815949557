<template>
  <div class="item col-lg-4 col-md-4">
    <div class="fcrse_1 mb-20">
      <router-link :to="'/Dcoursedetail/' + id">
        <a class="fcrse_img">
          <!-- <img v-bind:src="backEnd+'public/courseImage/'+id" alt="" style="height: 200px"> -->
          <CourseImage :name="file" style="height: 200px" />
          <div class="course-overlay">
            <div class="badge_seller">Bestseller</div>
            <Ratings :id="id" :type="'course'" />
            <span class="play_btn1"><i class="uil uil-play"></i></span>
            <CourseTime :id="id" :type="'dashboard'" />
          </div>
        </a>
      </router-link>
      <div class="fcrse_content">
        <div class="eps_dots more_dropdown">
          <a><i class="uil uil-ellipsis-v"></i></a>
          <div class="dropdown-content">
            <span
              @click="
                copyURL(
                  'https://faslearn.com/Dcoursedetail/' +
                    id +
                    '?affiliateUserRef=' +
                    fmruser
                )
              "
              ><i class="uil uil-share-alt"></i>Copy Link</span
            >
            <!-- <span><i class="uil uil-clock-three"></i>Save</span>
                        <span><i class='uil uil-ban'></i>Not Interested</span>
                        <span><i class="uil uil-windsock"></i>Report</span> -->
          </div>
        </div>
        <router-link :to="'/Dcoursedetail/' + id">
          <TotalSeenCourse :id="id" :type="'dashboard'" />
          <a class="crse14s" v-if="title"
            >{{ title.substring(0, 30)
            }}<span v-if="title.toString().length > 30">...</span></a
          >
          <a href="#" class="crse-cate">{{ category }}</a>
        </router-link>
        <div class="auth1lnkprce">
          <CourseOwner
            :id="userId"
            :firstName="firstName"
            :lastName="lastName"
          />
          <br />
          <div class="prce142">
            <span v-if="rate">
              <span v-if="price != 0">
                <span v-if="selectedCurrency==='NGN'">
                 ₦{{
                  (price*parseFloat(rate)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}   
                </span>
                <span v-if="selectedCurrency==='USD'">
                 ${{
                (price*parseFloat(rate)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}   
                </span>
                <span v-if="selectedCurrency==='GBP'">
                 £{{
                  (price*parseFloat(rate)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}   
                </span>
                </span
              >
              <span v-else>FREE</span> <br />
              <small v-if="price != 0">
                <Discount
                  :id="id"
                  :rate="rate"
                  :selectedCurrency="selectedCurrency"
                  :price="price"
                  :discount="discount"
              />
              </small>
            </span>
            <span v-else>
              <span v-if="price != 0">
                ₦{{
                  price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}</span
              >
              <span v-else>FREE</span> <br />
              <small v-if="price != 0">
                <Discount :id="id" :price="price" :discount="discount"/>
              </small>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Discount from "@/components/Discount";
import CourseTime from "@/components/CourseTime";
import CourseOwner from "@/components/CourseOwner";
import TotalSeenCourse from "@/components/TotalSeenCourse";
import CourseImage from "@/components/CourseImage";
import Ratings from "@/components/dashboard/Ratings.vue";
export default {
  data() {
    return {
      total: 0,
      fmruser: localStorage.getItem("fmruser"),
    };
  },
  props: {
    id: String,
    title: String,
    category: String,
    price: String,
    firstName: String,
    lastName: String,
    userId: String,
    file: String,
    discount: Number,
    selectedCurrency: String,
    rate: Number,
  },
  components: {
    CourseTime,
    TotalSeenCourse,
    CourseOwner,
    CourseImage,
    Discount,
    Ratings,
  },
  computed: {
    ...mapGetters(["backEnd"]),
  },
  methods: {
    async copyURL(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        alert("Copied");
      } catch ($e) {
        alert("Cannot copy");
      }
    },
  },
};
</script>
<style>
</style>
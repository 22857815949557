<template>
    <div id="login">
		<div class="back">
			<aside>
				<img src="../../assets/flogo.png" width="200" height="200" style="margin-top: -100px; margin-bottom: -50px" alt="">	
				<form autocomplete="off">
					<div class="form-group">

						<span class="input">
						<input class="input_field" type="text" v-model="firstName">
							<label class="input_label">
								Your Name
							</label>
						</span>

						<span class="input">
						<input class="input_field" type="text" v-model="lastName">
							<label class="input_label">
								Your Last Name
							</label>
						</span>

						<span class="input">
						<input class="input_field" type="email" v-model="email">
							<label class="input_label">
								Your Email
							</label>
						</span>

						<span class="input">
						<input class="input_field" type="text" v-model="address">
							<label class="input_label">
								Your State
							</label>
						</span>

						<span class="input">
						<input class="input_field" type="number" v-model="phone">
							<label class="input_label">
								Mobile number
							</label>
						</span>

						<span class="input">
						<input class="input_field" type="password" id="password1" v-model="password">
							<label class="input_label">
								Your password
							</label>
						</span> 
						<br>
						<div :class="{emphasis : !agree && unchecked}">
							<input type="checkbox" id="terms" v-model="agree"><span> Agree terms & conditions </span>
							<label>
								<span style="font-size: 11px">This means you have read our <a href="/terms"> terms and conditions </a> and you agree with them</span>
							</label>
						</div>
						<small style="color: red" v-if="!agree && unchecked">please click the little box beside <b>"Agree terms & conditions"</b> </small >
						
						<!-- <div id="pass-info" class="clearfix"></div> -->
					</div>
					
					<Loading v-if="Loading"/>
					<Errors v-if="error">
						<ul>
							<li v-for="error in error" :key="error">{{error}}</li>	
						</ul>
					</Errors>
					<Success v-if="Success">
						<ul>
							<li>One last step remaining!</li>
							<li>Please go to your mail and verify your account.</li>
							<li>Check your spam folder if you cannot find our mail.</li>
						</ul>
					</Success>

					<center>
					<div @click.prevent="registerUser()" v-if="!Loading"><button  class="myButtoonn" >Register to Faslearn</button></div>
					</center>
					<center>
						<router-link :to="'/login'">
						<div style="color: #EF6421; margin-top: 10px">Already have an acccount? <strong><a >Sign In</a></strong></div>
						</router-link>
					</center>
				</form>
				<div class="copy">© 2021 Faslearn</div>
			</aside>
		</div>
	</div>
</template>

<script>
	//import Authservices from '../../services/auth.js'
	import Loading from "@/components/Loading";
	import Errors from '@/components/Errors.vue';
	import Success from '@/components/Success.vue';
	import { mapActions, mapGetters } from 'vuex';
    export default {
		name: 'Register',
		data(){
			return{
				firstName: '',
				lastName: '', 
				email: '', 
				password: '',
				userType: 'student', 
				address: '', 
				phone: '',
				affiliateRef: localStorage.getItem('fmrAffiliateUserRef'),
				Loading: false,
				Success: false,
				agree: false,
				unchecked: false
			}
		},
		computed: {
			...mapGetters(['error','publicToken'])
		},
        components: {
			Errors,
			Success,
			Loading
        },
        mounted(){
			this.reset_error()
		},
		methods:{
			...mapActions(['register','reset_error']),
			registerUser(){
				if (this.agree) {
					this.Loading = true
					let user = { 
						firstName : this.firstName, 
						lastName : this.lastName, 
						email : this.email, 
						password : this.password, 
						userType : this.userType, 
						address : this.address, 
						phone : this.phone,
						affiliateRef: this.affiliateRef,
						publicToken: this.publicToken,
					};
					this.register(user)
					.then(res => {
						this.Loading = false
						this.firstName = '' 
						this.lastName = '' 
						this.password = '' 
						this.userType = '' 
						this.address = '' 
						this.phone = '' 
						localStorage.removeItem('fmrAffiliateUserRef')
						if(res.status == 201){
							this.Success = true
						}
					})
				}else{
					this.unchecked = true
				}
				
			}
			// async adduser(){
			// 	let user = { 
			// 		firstName : this.firstName, 
			// 		lastName : this.lastName, 
			// 		email : this.email, 
			// 		password : this.password, 
			// 		userType : this.userType, 
			// 		address : this.address, 
			// 		phone : this.phone
			// 		}

			// 		//console.log(user);
			// 	await Authservices.addUser(user)
			// 	.then(console.log('success'))
			// 	.catch((error) => {
			// 		this.error = error;
			// 	});
			// }
		}
    }
</script>

<style scoped>
	h4{
		display: none;
	}
	.back{
		width: 100%;
		height: 750px;
		background: url(../../assets/12.jpg) center center no-repeat fixed;
	}

	.myButtoonn{
		background-color: #EF6421;
		width: 100%;
		margin: 5px; 
		color: white;
		padding: 10px;
		padding-left: 30px;
		padding-right: 30px;
		border-radius: 20px;
		border: none;
	}
	.emphasis{
		border: 2px solid red;
		padding: 10px;
	}
	h4{
		display: none;
	}
</style>

<template>
  <div>
    <Header />

    <div class="content-wrapper">
      <div class="container-fluid">
        <!-- Breadcrumbs-->
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Dashboard</a>
          </li>
          <li class="breadcrumb-item active">Tables</li>
        </ol>
        <!-- Example DataTables Card-->
        <br>
        <div class="card mb-3">
          <div class="card-header">
            <i class="fa fa-table"></i> Data Table Example
            <p class="verifiedButton" :class="{ active: type=='all' }" @click="switchVerified('all')" >
              All
            </p>
            <p class="verifiedButton" :class="{ active: type=='Verified' }" @click="switchVerified('Verified')" >
              Verified
            </p>
            <p class="verifiedButton" :class="{ active: type=='Unverified' }" @click="switchVerified('Unverified')">
              Unverified
            </p>
          </div>
          <div class="card-header">
            <div class="row">
            <div class="col">
              <p>
                <a href="#0" class="btn_1 medium" @click.prevent="downloadXLSX()">Download ({{users.length}}) Users as Excel</a>
                <a href="#0" class="btn_1 medium" @click.prevent="exportPdf()">Download ({{users.length}}) Users as PDF</a>
              </p>
              <p>
                <label for="">Item Per Page</label>
                <select style="width:100px" class="form-control" id="exampleFormControlSelect1" v-model="limit">
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                  <option value="250">250</option>
                  <option value="300">300</option>
                  <option value="350">350</option>
                  <option value="400">400</option>
                  <option value="450">450</option>
                  <option value="500">500</option>
                </select>
                <label for="">Start Date</label>
                <input @change="newTime()" class="datefield" type="date" v-model="startDate">
                <label for="">End Date</label>
                <input @change="newTime()" class="datefield" type="date" v-model="endDate">
              </p>
            </div>
          </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <Loading v-if="Loading" />
              <table
                class="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Type</th>
                    <th>Start date</th>
                    <th>Categories</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Type</th>
                    <th>Start date</th>
                    <th>Categories</th>
                  </tr>
                </tfoot>
                <tbody v-if="users != ''">
                  <tr v-for="u in users" :key="u">
                    <td>{{ u.firstName }} {{ u.lastName }}</td>
                    <td>{{ u.email }}</td>
                    <td>{{ u.phone }}</td>
                    <td>{{ u.userType }}</td>
                    <td>{{ u.createdAt }}</td>
                    <td><ul class="catScroll" v-if="u.categories">
                      <li v-for="category in u.categories" :key="category">{{ category.name }}</li>
                      <li v-for="category in u.categories" :key="category">{{ category.name }}</li>
                      <li v-for="category in u.categories" :key="category">{{ category.name }}</li>
                      <li v-for="category in u.categories" :key="category">{{ category.name }}</li></ul></td>
                  </tr>
                </tbody>
              </table>
              <!-- pagination -->

            </div>
          </div>
          <!-- <div class="card-footer small text-muted">Updated yesterday at 11:59 PM</div> -->
        </div>
        <!-- /tables-->
        <!-- <p>
          <a href="#0" class="btn_1 medium" @click.prevent="fetchMore()" v-if="!Loading&&!reachedFinalUserLimit" >Fetch More Users</a>
        </p> -->
        <nav aria-label="...">
          <ul class="pagination pagination-sm add_bottom_30">
            <li class="page-item" v-if="page>1" @click="fetchNew(page-1)">
              <a class="page-link" tabindex="-1" >Previous</a>
            </li>
            <li class="page-item" v-for="p in pages" :key="p"><a class="page-link" :class="{activePage: p==page}" @click="fetchNew(p)" >{{p}}</a></li>
            <li class="page-item" v-if="page<numberOfPages" @click="fetchNew(page+1)">
              <a class="page-link">Next </a>
            </li>
            <li class="page-item">
              <a class="page-link" > total record : {{total}}</a>
            </li>
          </ul>
        </nav>
        <p>
          <a href="#0" class="btn_1 medium" v-if="Loading">Please Wait ...</a>
        </p>
        <p>
          <a href="#0" class="btn_1 medium" @click.prevent="downloadXLSX()">Download ({{users.length}}) Users as Excel</a>
          <a href="#0" class="btn_1 medium" @click.prevent="exportPdf()">Download ({{users.length}}) Users as PDF</a>
        </p>
      </div>
      <!-- /container-fluid-->
    </div>
    <!-- /.container-wrapper-->
    <Footer />
    <!-- Scroll to Top Button-->
    <a class="scroll-to-top rounded" href="#page-top">
      <i class="fa fa-angle-up"></i>
    </a>
    <!-- Logout Modal-->
    <Modal />
  </div>
</template>
<script>
import Header from "@/components/superAdmin/Header.vue";
import Modal from "@/components/superAdmin/Modal.vue";
import Footer from "@/components/superAdmin/Footer.vue";
import Loading from "@/components/Loading.vue";
import { mapActions } from "vuex";
import jsPDF from "jspdf";
import { utils, writeFile } from "xlsx";
import "jspdf-autotable";
export default {
  data() {
    return {
      users: [],
      isVerified: true,
      startDate: new Date,
      endDate: new Date,
      dateSlug: "",
      type: 'Verified',
      limit: 100,
      Loading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      reachedFinalUserLimit: false,
      pages : [],
      numberOfPages: 0,
      total: 0
    };
  },
  components: {
    Header,
    Modal,
    Loading,
    Footer,
  },
  watch: {
		limit: function() {
      this.Loading = true;
      this.page = 1
      this.fetchVerifiedUsers__Admin({
        isVerified: this.isVerified,
        page: this.page,
        limit: this.limit
      }).then((res) => {
        this.users = res.data.allUsers;
        this.total = res.data.total;
        this.Loading = false
      });
		}
  },
  methods: {
    ...mapActions(["fetchVerifiedUsers__Admin"]),
    exportPdf() {
      var vm = this;
      var DownloadUsers = []
      for (let i = 0; i < vm.users.length; i++) {
        const element = vm.users[i];
        var categories = ""
        for (let j = 0; j < element.categories.length; j++) {
          const category = element.categories[j];
          if (categories == "") {
            categories = category.name
          }else{
           categories = categories +" , "+category.name
          }
        }
        element.categories = categories
        DownloadUsers.push(element)
      }
      var columns = [
        { title: "Name", dataKey: "firstName" },
        { title: "Email", dataKey: "email" },
        { title: "Phone", dataKey: "phone" },
        { title: "Categories", dataKey: "categories" },
      ];
      const doc = new jsPDF("p", "pt");
      doc.text("Report", 30, 20);
      doc.autoTable(columns, DownloadUsers);
      doc.save("table.pdf");
    },
    
    downloadXLSX(){
      this.processing = true;
      const exportData = this.users.map(user => {
        return {
          "Name" : user.firstName+" "+user.lastName,
          "Email": user.email,
          "Phone": user.phone,
          "Start Date": user.createdAt,
          "Categories": this.fixCategories(user.categories)
        }
      });

      const sheet = utils.json_to_sheet(exportData);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, sheet, 'agents');
      writeFile(workbook, `faslearn_users.xlsx`);
      this.processing = false;
    },

    fixCategories(categories){
      var catString = ""
      if (categories) {
        for (let j = 0; j < categories.length; j++) {
          const category = categories[j];
          if (catString == "") {
            catString = category.name
          }else{
            catString = catString +" , "+category.name
          }
        } 
      }
      return catString
    },
    switchVerified(type) {
      switch (type) {
        case 'Verified':
          this.isVerified = true
          break;
        case 'Unverified':
          this.isVerified = false
          break;
        case 'all':
          this.isVerified = 'all'
          break;
        default:
          this.isVerified = 'all'
          break;
      }
      this.type = type
      this.page = 1;
      this.Loading = true;
      this.getDateSlug()
      this.fetchVerifiedUsers__Admin({
        isVerified: this.isVerified,
        page: this.page,
        limit: this.limit,
        slug: this.dateSlug
      }).then((res) => {
        this.users = res.data.allUsers;
        this.total = res.data.total;
        this.getPages()
        this.Loading = false
      });
    },
    fetchNew(page) {
      this.page = page;
      this.Loading = true;
      this.getDateSlug()
      this.fetchVerifiedUsers__Admin({
        isVerified: this.isVerified,
        page: this.page,
        limit: this.limit,
        slug: this.dateSlug
      }).then((res) => {
        this.users = res.data.allUsers;
        this.total = res.data.total;
        this.getPages();
        this.Loading = false;
      });
    },
    newTime() {
      this.page = 1;
      this.Loading = true;
      this.getDateSlug()
      this.fetchVerifiedUsers__Admin({
        isVerified: this.isVerified,
        page: this.page,
        limit: this.limit,
        slug: this.dateSlug
      }).then((res) => {
        this.users = res.data.allUsers;
        this.total = res.data.total;
        this.getPages()
        this.Loading = false
      });
    },
    getPages() {
      this.pages = []
      this.numberOfPages = ~~(this.total/this.limit)
      if((this.total/this.limit)>this.numberOfPages){
        this.numberOfPages = this.numberOfPages + 1
      }
      for (let i = 1; i <= this.numberOfPages; i++) {
        this.pages.push(i)
      }
    },
    getDateSlug() {
      this.dateSlug = "?startDate="+this.startDate+"&endDate="+this.endDate
      console.log(this.dateSlug)
    },
  },
  mounted() {
    this.endDate = new Date(); // Get current date
    this.startDate = new Date(this.endDate.getFullYear() - 5, this.endDate.getMonth(), this.endDate.getDate()); // Subtract 5 years from current date
    this.getDateSlug()
    this.fetchVerifiedUsers__Admin({
      isVerified: this.isVerified,
      page: this.page,
      limit: this.limit,
      slug: this.dateSlug
    }).then((res) => {
      this.users = res.data.allUsers;
      this.total = res.data.total;
      this.getPages();
      this.Loading = false;
    });
  },
};
</script>
<style scoped>
@import url("./assets/css/admin.css");
@import url("./assets/css/custom.css");
@import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
@import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
@import url("./assets/vendor/bootstrap/css/bootstrap.min.css");

.verifiedButton {
  color: white;
  background-color: grey;
  border-radius: 10px;
  padding: 5px;
  display: inline;
  margin-left: 10px;
  cursor: pointer;
}

.active {
  background-color: red;
}

.activePage{
  background-color: red;  
}

.catScroll{
  height: 50px;
  overflow-y: scroll;
}

.datefield{
  widows: 100px;
  height: 20px;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
</style>
<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-dark bg-default fixed-top" id="mainNav" style="background-color: #EF6421">
            <a class="navbar-brand" href="/SAhome" style="z-index: 100000"><img src="../../assets/faslearnWhite.png" data-retina="true" alt="" width="163" height="56"></a>
            <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarResponsive" style="background-color: #EF6421">
             <a class="nav-link">Admin: {{Admin.name}}</a>
            <ul class="navbar-nav navbar-sidenav" id="exampleAccordion" v-if="isSuperLoggedIn" style="background-color: #EF6421">
                <a class="nav-link">{{Admin.type}}</a>
                <li class="nav-item" :class="{activee : page=='SAhome'}" data-toggle="tooltip" data-placement="right" title="Dashboard">
                <a class="nav-link board">
                    <router-link :to="'/SAhome'">
                    <i class="fa fa-fw fa-dashboard" style="color: white"></i>
                    <span class="nav-link-text" style="color: white"> Dashboard</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='/SAblogPosts'}" data-toggle="tooltip" data-placement="right" title="Messages">
                <a class="nav-link board" v-if="Admin.type=='Manager'||Admin.type=='General'||Admin.type=='Customer'">
                    <router-link :to="'/SAblogPosts'">
                    <i class="fa fa-bookmark" style="color: white"></i>
                    <span class="nav-link-text" style="color: white"> Blog </span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='/SAaddBlogPost'}" data-toggle="tooltip" data-placement="right" title="Messages">
                <a class="nav-link board" v-if="Admin.type=='Manager'||Admin.type=='General'||Admin.type=='Customer'">
                    <router-link :to="'/SAaddBlogPost'">
                    <i class="fa fa-fw fa-plus-circle" style="color: white"></i>
                    <span class="nav-link-text" style="color: white"> Add Blog Post</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAinfluencers'}" data-toggle="tooltip" data-placement="right" title="Messages">
                <a class="nav-link board" v-if="Admin.type=='Manager'||Admin.type=='General'">
                    <router-link :to="'/SAinfluencers'">
                    <i class="fa fa-fw fa-user" style="color: white"></i>
                    <span class="nav-link-text" style="color: white"> Influencers</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAinstructors'}" data-toggle="tooltip" data-placement="right" title="Messages">
                <a class="nav-link board" v-if="Admin.type=='Manager'||Admin.type=='General'">
                    <router-link :to="'/SAinstructors'">
                    <i class="fa fa-fw fa-user" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Instructors</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAnewinstructors'}" data-toggle="tooltip" data-placement="right" title="Messages">
                <a class="nav-link board" v-if="Admin.type=='Manager'||Admin.type=='General'">
                    <router-link :to="'/SAnewinstructors'">
                    <i class="fa fa-fw fa-user" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">New Instructors</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAupdateInstructors'}" data-toggle="tooltip" data-placement="right" title="Messages">
                <a class="nav-link board" v-if="Admin.type=='Manager'||Admin.type=='General'">
                    <router-link :to="'/SAupdateInstructors'">
                    <i class="fa fa-fw fa-user" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Updated Instructors</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAinstitutions'}" data-toggle="tooltip" data-placement="right" title="Bookings">
                <a class="nav-link board" v-if="Admin.type=='Manager'||Admin.type=='General'">
                    <router-link :to="'/SAinstitutions'">
                    <i class="fa fa-fw fa-university" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Institutions <span class="badge badge-pill badge-primary"></span></span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAnewinstitution'}" data-toggle="tooltip" data-placement="right" title="Bookings">
                <a class="nav-link board" v-if="Admin.type=='Manager'||Admin.type=='General'">
                    <router-link :to="'/SAnewinstitution'">
                    <i class="fa fa-fw fa-university" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">New Institutions <span class="badge badge-pill badge-primary"></span></span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAupdateInstitutions'}" data-toggle="tooltip" data-placement="right" title="Bookings">
                <a class="nav-link board" v-if="Admin.type=='Manager'||Admin.type=='General'">
                    <router-link :to="'/SAupdateInstitutions'">
                    <i class="fa fa-fw fa-university" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Updated Institutions <span class="badge badge-pill badge-primary"></span></span>
                    </router-link>
                </a>
                </li>
                <!-- <li class="nav-item" :class="{activee : page=='SAnewinstitutions'}" data-toggle="tooltip" data-placement="right" title="Reviews">
                    <a class="nav-link board" >
                        <router-link :to="'/SAnewinstitutions'">
                        <i class="fa fa-fw fa-star" style="color: white"></i>
                        <span class="nav-link-text" style="color: white">New institutions</span>
                        </router-link>
                    </a>
                </li> -->
                <li class="nav-item" :class="{activee : page=='SAcourses'}" data-toggle="tooltip" data-placement="right" title="Bookmarks">
                <a class="nav-link board" v-if="Admin.type=='Manager'||Admin.type=='General'||Admin.type=='Analyst'||Admin.type=='Editor'">
                    <router-link :to="'/SAcourses'">
                    <i class="fa fa-fw fa-book" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Courses</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAnewcourses'}" data-toggle="tooltip" data-placement="right" title="Bookmarks">
                <a class="nav-link board" >
                    <router-link :to="'/SAnewcourses'" v-if="Admin.type=='Manager'||Admin.type=='General'||Admin.type=='Analyst'||Admin.type=='Editor'">
                    <i class="fa fa-fw fa-book" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">New Courses</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAbooks'}" data-toggle="tooltip" data-placement="right" title="Bookmarks">
                <a class="nav-link board" v-if="Admin.type=='Manager'||Admin.type=='General'||Admin.type=='Analyst'||Admin.type=='Editor'">
                    <router-link :to="'/SAbooks'">
                    <i class="fa fa-file-pdf-o" style="color: white"></i>
                    <span class="nav-link-text" style="color: white"> Books</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAnewBooks'}" data-toggle="tooltip" data-placement="right" title="Bookmarks">
                <a class="nav-link board" >
                    <router-link :to="'/SAnewBooks'" v-if="Admin.type=='Manager'||Admin.type=='General'||Admin.type=='Analyst'||Admin.type=='Editor'">
                    <i class="fa fa-file-pdf-o" style="color: white"></i>
                    <span class="nav-link-text" style="color: white"> New Books</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAfeedbacks'}" data-toggle="tooltip" data-placement="right" >
                <a class="nav-link board" v-if="Admin.type=='Manager'||Admin.type=='General'||Admin.type=='Customer'||Admin.type=='Analyst'">
                    <router-link :to="'/SAfeedbacks'">
                    <i class="fa fa-fw fa-feed" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Feedbacks</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SApurchasedCourses'}" data-toggle="tooltip" data-placement="right" >
                <a class="nav-link board" v-if="Admin.type=='Manager'||Admin.type=='General'||Admin.type=='Customer'||Admin.type=='Analyst'">
                    <router-link :to="'/SApurchasedCourses'">
                    <i class="fa fa-fw fa-feed" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Purchased Courses</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAMessages'}" data-toggle="tooltip" data-placement="right" >
                <a class="nav-link board" v-if="Admin.type=='Manager'||Admin.type=='General'||Admin.type=='Customer'||Admin.type=='Analyst'">
                    <router-link :to="'/SAMessages'">
                    <i class="fa fa-fw fa-envelope-o " style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Messages <b style="color: black">{{totalUnseenMessages}}</b></span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAUsers'}" data-toggle="tooltip" data-placement="right" >
                <a class="nav-link board" v-if="Admin.type=='Manager'||Admin.type=='General'||Admin.type=='Customer'||Admin.type=='Analyst'">
                    <router-link :to="'/SAUsers'">
                    <i class="fa fa-fw fa-address-book-o" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Users</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAAdmins'}" data-toggle="tooltip" data-placement="right" >
                <a class="nav-link board" v-if="Admin.type=='General'">
                    <router-link :to="'/SAAdmins'">
                    <i class="fa fa-fw fa-address-book-o" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Admins</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAaddNewSuperAdmin'}" data-toggle="tooltip" data-placement="right" title="Add listing">
                <a class="nav-link board" v-if="Admin.type=='General'">
                    <router-link :to="'/SAaddNewSuperAdmin'">
                    <i class="fa fa-fw fa-plus-circle" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Add New SuperAdmin</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SANewCoupon'}" data-toggle="tooltip" data-placement="right" title="Add listing">
                <a class="nav-link board" v-if="Admin.type=='General'">
                    <router-link :to="'/SANewCoupon'">
                    <i class="fa fa-fw fa-gift" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Add New Coupon</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SACoupons'}" data-toggle="tooltip" data-placement="right" title="Add listing">
                <a class="nav-link board" v-if="Admin.type=='General'">
                    <router-link :to="'/SACoupons'">
                    <i class="fa fa-fw fa-gift" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Coupons</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SAdonations'}" data-toggle="tooltip" data-placement="right" title="Add listing">
                <a class="nav-link board" v-if="Admin.type=='General'">
                    <router-link :to="'/SAdonations'">
                    <i class="fa fa-fw fa-money" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Donations</span>
                    </router-link>
                </a>
                </li>
                <li class="nav-item" :class="{activee : page=='SApayRequests'||page=='SApaymentHistory'||page=='SAsearchPayments'}" data-toggle="tooltip" data-placement="right" title="My profile" v-if="Admin.type=='Manager'||Admin.type=='General'">
                <a class="nav-link board nav-link-collapse collapsed" data-toggle="collapse" href="#collapseProfile" data-parent="#exampleAccordion">
                    <i class="fa fa-fw fa-credit-card" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Payments</span>
                </a>
                <ul class="sidenav-second-level collapse" id="collapseProfile">
                    <li>
                        <router-link :to="'/SAnewPayouts'">
                            <a >Pay Requests</a>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="'/SAhistoryPayouts'">
                            <a >Payment History</a>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="'/SAsearchPayouts'">
                            <a >Search payment</a>
                        </router-link>
                    </li>
                </ul>
                </li>
                <li class="nav-item" :class="{activee : page=='SARaffleItemCreate'||page=='SARaffleItems'||page=='SARaffleItemAttempts'}" data-toggle="tooltip" data-placement="right" title="My profile" v-if="Admin.type=='Manager'||Admin.type=='General'">
                    <a class="nav-link board nav-link-collapse collapsed" data-toggle="collapse" href="#collapseRaffleDraw" data-parent="#exampleAccordion">
                        <i class="fa fa-fw fa-credit-card" style="color: white"></i>
                        <span class="nav-link-text" style="color: white">Raffle Draw</span>
                    </a>
                    <ul class="sidenav-second-level collapse" id="collapseRaffleDraw">
                        <li>
                            <router-link :to="'/SARaffleItemCreate'">
                                <a >Create New Item</a>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="'/SARaffleItems'">
                                <a >Fetch Items</a>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" style="opacity: 0" data-toggle="tooltip" data-placement="right" title="Add listing">
                <a class="nav-link board" >
                    <router-link :to="'/SAaddNewSuperAdmin'">
                    <i class="fa fa-fw fa-plus-circle" style="color: white"></i>
                    <span class="nav-link-text" style="color: white">Lifter</span>
                    </router-link>
                </a>
                </li>
                <!-- <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Components">
                    <a class="nav-link nav-link-collapse collapsed" data-toggle="collapse" href="#collapseComponents" data-parent="#exampleAccordion">
                        <i class="fa fa-fw fa-gear"></i>
                        <span class="nav-link-text">Black list</span>
                    </a>
                    <ul class="sidenav-second-level collapse" id="collapseComponents">
                        <li>
                        <a href="/SAhome">Coures</a>
                        </li>
                        <li>
                        <a href="/SAhome">Instructors</a>
                        </li>
                        <li>
                        <a href="/SAhome">Users</a>
                        </li>
                        <li>
                        <a href="/SAhome">Institutions</a>
                        </li>
                    </ul>
                </li> -->
            </ul>
            <ul class="navbar-nav sidenav-toggler" v-if="isSuperLoggedIn" style="background-color: #EF6421">
                <li class="nav-item">
                <a class="nav-link text-center" id="sidenavToggler">
                    <i class="fa fa-fw fa-angle-left"></i>
                </a>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto" v-if="isSuperLoggedIn">
                <li class="nav-item dropdown" v-if="totalUnseenMessages>0">
                
                <a class="nav-link dropdown-toggle mr-lg-2" id="messagesDropdown" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <router-link :to="'/SAMessages'">
                        <i class="fa fa-fw fa-envelope"></i>
                        <span class="d-lg-none">Messages
                        <span class="badge badge-pill badge-primary">12 New</span>
                        </span>
                        <span class="indicator text-primary d-none d-lg-block">
                        <i class="fa fa-fw fa-circle"></i>
                        </span>
                    </router-link>
                </a>
                <a> <router-link :to="'/SAMessages'">{{totalUnseenMessages}} new</router-link></a>
                <!-- <div class="dropdown-menu" aria-labelledby="messagesDropdown">
                    <h6 class="dropdown-header">New Messages:</h6>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">
                    <strong>David Miller</strong>
                    <span class="small float-right text-muted">11:21 AM</span>
                    <div class="dropdown-message small">Hey there! This new version of SB Admin is pretty awesome! These messages clip off when they reach the end of the box so they don't overflow over to the sides!</div>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">
                    <strong>Jane Smith</strong>
                    <span class="small float-right text-muted">11:21 AM</span>
                    <div class="dropdown-message small">I was wondering if you could meet for an appointment at 3:00 instead of 4:00. Thanks!</div>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">
                    <strong>John Doe</strong>
                    <span class="small float-right text-muted">11:21 AM</span>
                    <div class="dropdown-message small">I've sent the final files over to you for review. When you're able to sign off of them let me know and we can discuss distribution.</div>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item small" href="#">View all messages</a>
                </div> -->
                </li>
                <!-- <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle mr-lg-2" id="alertsDropdown" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-fw fa-bell"></i>
                    <span class="d-lg-none">Alerts
                    <span class="badge badge-pill badge-warning">6 New</span>
                    </span>
                    <span class="indicator text-warning d-none d-lg-block">
                    <i class="fa fa-fw fa-circle"></i>
                    </span>
                </a>
                <div class="dropdown-menu" aria-labelledby="alertsDropdown">
                    <h6 class="dropdown-header">New Alerts:</h6>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">
                    <span class="text-success">
                        <strong>
                        <i class="fa fa-long-arrow-up fa-fw"></i>Status Update</strong>
                    </span>
                    <span class="small float-right text-muted">11:21 AM</span>
                    <div class="dropdown-message small">This is an automated server response message. All systems are online.</div>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">
                    <span class="text-danger">
                        <strong>
                        <i class="fa fa-long-arrow-down fa-fw"></i>Status Update</strong>
                    </span>
                    <span class="small float-right text-muted">11:21 AM</span>
                    <div class="dropdown-message small">This is an automated server response message. All systems are online.</div>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">
                    <span class="text-success">
                        <strong>
                        <i class="fa fa-long-arrow-up fa-fw"></i>Status Update</strong>
                    </span>
                    <span class="small float-right text-muted">11:21 AM</span>
                    <div class="dropdown-message small">This is an automated server response message. All systems are online.</div>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item small" href="#">View all alerts</a>
                </div>
                </li> -->
                <li class="nav-item">
                </li>
                <li class="nav-item" v-if="isSuperLoggedIn" @click.prevent="logOutAdmin">
                    <a class="nav-link">
                    <i class="fa fa-fw fa-sign-out"></i>Logout</a>
                </li>
            </ul>
            </div>
        </nav>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data(){
        return{
            page : 'SAhome',
            Admin: '',
            adminId: '',
            totalUnseenMessages: 0
        }
    },
    created() {
		var currentUrl = window.location.pathname+'';
		this.page = currentUrl.split('/')[1]
    },
    computed: {
        ...mapGetters(['isSuperLoggedIn'])
    },
    methods: {
        ...mapActions(['superLogout','fetchSuperAdmin','countNewPublicMessages__Admin']),

        logOutAdmin(){
            this.superLogout()
        }
    },
    mounted(){ 
        window.scrollTo(0, 0)
        this.adminId = localStorage.getItem('fmrsuperadmin'); 
        this.fetchSuperAdmin(this.adminId)
        .then(res => {
            this.Admin = res.data
        })
        this.countNewPublicMessages__Admin('seen')
        .then(res => {
            this.totalUnseenMessages = res.data.total
        })
    }
    
}
</script>
<style >
    .activee{
        background-color: red;
    }
    .board{
        color: white;
        text-decoration: none;
    }
</style>
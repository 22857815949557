<template>
    <div>
        <Header />

        <div class="content-wrapper">
            <div class="container-fluid">
            <!-- Breadcrumbs-->
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                <a href="#">Dashboard</a>
                </li>
                <li class="breadcrumb-item active">Your Courses</li>
            </ol>
                <div class="box_general">
                    <div class="header_box">
                        <h2 class="d-inline-block">Your Courses</h2>
                        <div class="card-header">
                        <p class="verifiedButton" :class="{active : published=='all'}"  @click="switchPublish('all')">All</p>
                        <p class="verifiedButton" :class="{active : published=='published'}"  @click="switchPublish('published')">Published</p>
                        <p class="verifiedButton" :class="{active : published=='un-published'}" @click="switchPublish('un-published')">Not Published</p>
                        </div>
                    </div>
                    <div class="bigspinner" v-if="Loading">
                        <div class="smallspiner">
                        </div>
                    </div>
                    <div class="list_general" >
                        <ul v-for="p in posts" :key="p">
                            <li>
                                <figure>
                                    <img v-bind:src="publicFiles+p.imageFile" alt="">
                                </figure>
                                <h4>{{p.title}} <i class="pending" v-if="p.published">Published</i></h4>
                                <!-- <h4>{{c.title}}</h4> -->
                                <ul class="course_list" v-if="p.superAdmin.length>0">
                                    <li><strong>Teacher</strong> {{p.superAdmin[0].name}} </li>
                                    <li style="color: green" ><strong>{{p.superAdmin[0].type}}</strong></li>
                                </ul>
                                <h6>Course description</h6> 
                                <p v-if="p.contents.length>0" v-html="formatText(p.contents[0].text)"></p> 
                                <p v-else>No contents to show yet</p>
                                <ul class="buttons">
                                    <router-link :to="{ path: '/SAeditPost/'+ p._id}">
                                        <li><a href="#0" class="btn_1 gray approve"><i></i> View </a></li>
                                    </router-link>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div> 
                <nav aria-label="...">
                    <ul class="pagination pagination-sm add_bottom_30">
                        <li class="page-item" v-if="page>1" @click="fetchNew(page-1)">
                        <a class="page-link" tabindex="-1" >Previous</a>
                        </li>
                        <li class="page-item" v-for="p in pages" :key="p"><a class="page-link" :class="{activePage: p==page}" @click="fetchNew(p)" >{{p}}</a></li>
                        <li class="page-item" v-if="page<numberOfPages" @click="fetchNew(page+1)">
                        <a class="page-link">Next </a>
                        </li>
                        <li class="page-item">
                        <a class="page-link" > total record : {{total}}</a>
                        </li>
                    </ul>
                </nav>
                <p>
                    <a href="#0" class="btn_1 medium" v-if="Loading">Please Wait ...</a>
                </p>
            </div>
            <!-- /container-fluid-->
            </div>
            <!-- /.container-wrapper-->
            <Footer />
            <!-- Scroll to Top Button-->
            <a class="scroll-to-top rounded" href="#page-top">
            <i class="fa fa-angle-up"></i>
            </a>
            <!-- Logout Modal-->
            <Modal />
        
    </div>
</template>
<script>
import Header from '@/components/superAdmin/Header.vue'
import Modal from '@/components/superAdmin/Modal.vue'
import Footer from '@/components/superAdmin/Footer.vue'
import { mapActions, mapGetters } from "vuex";
export default {
    data(){
        return{
            posts: "",
            published: "all",
            limit: 10,
            Loading: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            reachedFinalUserLimit: false,
            pages : [],
            numberOfPages: 0,
            total: 0
        }
    },
    components: {
        Header,
        Modal,
        Footer
    },
    computed: {
        ...mapGetters(['publicFiles'])
    },
    methods: {
        ...mapActions(['fetchBlogPosts__Admin']),
        switchPublish(published) {
            this.published = published
            this.page = 1;
            this.Loading = true;
           this.fetchBlogPosts__Admin({published: this.published, page: this.page, limit: this.limit})
            .then(res => {
                this.posts = res.data.blogPosts
                this.total = res.data.total
                this.getPages();
                this.Loading = false;
            })
        },
        getPages() {
            this.pages = []
            this.numberOfPages = ~~(this.total/this.limit)
            if((this.total/this.limit)>this.numberOfPages){
                this.numberOfPages = this.numberOfPages + 1
            }
            for (let i = 1; i <= this.numberOfPages; i++) {
                this.pages.push(i)
            }
        },
        fetchNew(page) {
            this.page = page;
            this.Loading = true;
            this.fetchBlogPosts__Admin({published: this.published, page: this.page, limit: this.limit})
            .then(res => {
                this.posts = res.data.blogPosts
                this.total = res.data.total
                this.getPages();
                this.Loading = false;
            })
        },
        formatText(text) {
            const parser = new DOMParser();
            //const realText = {{p.contents[0].text.substring(0, 50)}}<span v-if="p.contents[0].text.toString().length > 30">...</span>
            const doc = parser.parseFromString(text, 'text/html');
            return doc.body.innerHTML.substring(0, 200) + "...";
	    },
    },
    mounted(){
        this.Loading = true;
        this.fetchBlogPosts__Admin({published: this.published, page: this.page, limit: this.limit})
        .then(res => {
            this.posts = res.data.blogPosts
            this.total = res.data.total
            this.getPages();
            this.Loading = false;
        })
    }
}
</script>
<style scoped>
	@import url("./assets/css/admin.css");
    @import url("./assets/css/custom.css");
    @import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
    @import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");

     .verifiedButton{
        color: white;
        background-color: grey;
        border-radius: 10px;
        padding: 5px;
        display: inline;
        margin-left: 10px;
        cursor: pointer;
    }

    .active{
        background-color: red;
    }

          .bigspinner{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border-top: 5px solid grey;
        display: inline-block;
        animation-name: spin;
        animation-duration: 3000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear; 
    }
    .saymessage{
        display: inline-block;
        margin-left: 10px;
    }
    .smallspiner{
        height: 30px;
        width: 30px;
        margin: 5px;
        border-radius: 50%;
        border-top: 5px solid grey;
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes spin{
        from {
        transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }
</style>
<template>
  <div>
    <div
      class="modal vd_mdl fade"
      id="videoModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-lg"
        style="min-height: 360px; min-width: 150px"
        role="document"
      >
        <div class="modal-content" style="min-height: 360px; min-width: 150px">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div
            class="modal-body"
            style="min-height: 360px; min-width: 150px; background-color: black"
          >
            <video
              :src="url"
              width="640"
              height="360"
              style="opacity: 1"
              controlsList="nodownload"
              v-if="
                modalFocus == 'preview' && coursePreview.fileType == 'video/mp4'
              "
              controls
            ></video>
            <iframe
              :src="
                coursePreview.embedLink +
                '#zoom=85&scrollbar=0&toolbar=0&navpanes=0'
              "
              style="min-height: 360px width=100%"
              v-if="
                modalFocus == 'preview' && coursePreview.fileType == 'embedLink'
              "
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <Modal>
      <!-- <video :src="url" width="640" height="360" style="opacity: 1;" controls controlsList="nodownload" v-if="modalFocus=='preview'"></video> -->
      <div
        class="
          ui-accordion-content
          ui-helper-reset
          ui-widget-content
          ui-corner-bottom
        "
        v-if="modalFocus == 'memberships'"
      >
        <a
          href="javascript:void(0)"
          class="
            accordion-header
            ui-accordion-header
            ui-helper-reset
            ui-state-default
            ui-accordion-icons
            ui-corner-all
          "
        >
          <div class="section-header-left">
            <h3>My Memberships</h3>
            <p v-if="myMemberships.length != 0 && otherMemberships != 0">
              Choose a Membership
            </p>
          </div>
        </a>
        <div class="lecture-container" v-for="m in myMemberships" :key="m">
          <div class="left-content">
            <i class="uil uil-users-alt"></i>
            <div class="top">
              <router-link :to="'/DlectureRoom/hh'">
                <a href="" class="prfle12link">
                  <span>
                    for {{ m.max }} members <br />{{ dropDiscount(m.max) }}%
                    discount <br /><del> ₦{{ m.max * amount }}.00</del></span
                  >
                </a>
              </router-link>
              <div class="title">
                <i class="fas fa-check-circle" style="color: green"></i>
                {{ m.title }}
              </div>
            </div>
          </div>
          <div class="details">
            <button
              type="button"
              class="btn_adcart"
              @click.prevent="
                buyNowForMembers(
                  ~~(((100 - dropDiscount(m.max)) / 100) * (m.max * amount)),
                  m._id
                )
              "
            >
              Buy @ ₦{{
                ~~(((100 - dropDiscount(m.max)) / 100) * (m.max * amount))
              }}
            </button>
          </div>
        </div>
        <div class="lecture-container" v-for="o in otherMemberships" :key="o">
          <div class="left-content">
            <i class="uil uil-users-alt"></i>
            <div class="top">
              <router-link :to="'/DlectureRoom/hh'">
                <a href="" class="prfle12link">
                  <span>
                    for {{ o.membershipId[0].max }} members <br />{{
                      dropDiscount(o.membershipId[0].max)
                    }}% discount <br /><del>
                      ₦{{ o.membershipId[0].max * amount }}.00</del
                    ></span
                  >
                </a>
              </router-link>
              <div class="title">
                <i class="fas fa-check-circle" style="color: green"></i>
                {{ o.membershipId[0].title }}
              </div>
            </div>
          </div>
          <div class="details">
            <button
              type="button"
              class="btn_adcart"
              @click.prevent="
                buyNowForMembers(
                  ~~(
                    ((100 - dropDiscount(o.membershipId[0].max)) / 100) *
                    (o.membershipId[0].max * amount)
                  ),
                  o.membershipId[0]._id
                )
              "
            >
              Buy @ ₦{{
                ~~(
                  ((100 - dropDiscount(o.membershipId[0].max)) / 100) *
                  (o.membershipId[0].max * amount)
                )
              }}
            </button>
          </div>
        </div>
        <div
          class="lecture-container"
          v-if="myMemberships.length != 0 && otherMemberships != 0"
        >
          <p>
            Do you want to buy course for a different maximum number of members?
          </p>
          <br />
          <div>
            <a href="/DregisterMembership">
              <p class="takecourse">
                Create a new Membership <i class="fas fa-arrow-right"></i>
              </p>
            </a>
          </div>
        </div>
        <div
          class="lecture-container"
          v-if="myMemberships.length == 0 && otherMemberships == 0"
        >
          <p>
            You do not belong to any Membership, would you like to create one?
          </p>
          <br />
          <div>
            <a href="/DregisterMembership">
              <p class="takecourse">
                Proceed to create a Membership
                <i class="fas fa-arrow-right"></i>
              </p>
            </a>
          </div>
        </div>
      </div>
    </Modal>
    <!-- Header Start -->
    <Dheader @getCurrency="changeCurrency" @selectedCurrency="selectedCur"/>
    <!-- Header End -->
    <!-- Left Sidebar Start -->
    <Dnav1 />
    <!-- Left Sidebar End -->
    <!-- Body Start -->
    <SkeletonLoader />
    <div class="wrapper _bg4586">
      <div class="_215b01">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="section3125">
                <div class="row justify-content-center">
                  <div class="col-xl-4 col-lg-5 col-md-6">
                    <div class="preview_video">
                      <a
                        href="#"
                        class="fcrse_img"
                        data-toggle="modal"
                        data-target="#videoModal"
                      >
                        <!-- <img v-bind:src="backEnd+'public/courseImage/'+courseId" alt=""> -->
                        <CourseImage :name="course.thumbnailFile" />
                        <div class="course-overlay">
                          <div class="badge_seller">Bestseller</div>
                          <span
                            class="play_btn1"
                            v-if="coursePreview != ''"
                            @click="activateModal('preview')"
                            ><i class="uil uil-play"></i
                          ></span>
                          <span
                            class="_215b02"
                            v-if="coursePreview != ''"
                            @click="activateModal('preview')"
                            >Preview this course</span
                          >
                        </div>
                      </a>
                    </div>
                    <div class="_215b10" v-if="course != ''">
                      <span v-if="rate">
                        <a href="#" class="_215b11">
                          <span>
                            <i class="uil uil-money-bill"></i>
                          </span>
                          <span v-if="amount != 0 && amountGotten" >
                            <span v-if="selectedCurrency==='NGN'">
                              ₦{{
                                (amount*parseFloat(rate)).toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }}
                            </span>
                            <span v-if="selectedCurrency==='USD'">
                              ${{
                                (amount*parseFloat(rate)).toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }}
                            </span>
                            <span v-if="selectedCurrency==='GBP'"> 
                              £{{
                                (amount*parseFloat(rate)).toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }}
                            </span>
                          </span>
                          <span v-else>FREE</span>
                        </a>
                        <a
                          href="#"
                          class="_215b12"
                          v-if="amount != 0 && amountGotten"
                        >
                          <Discount
                            v-if="amountGotten"
                            :id="courseId"
                            :price="amount"
                            :discount="discount"
                            :rate = rate
                            :selectedCurrency="selectedCurrency"
                          />
                        </a>
						          </span>
						          <span v-else>
							          <a href="#" class="_215b11">
                          <span><i class="uil uil-money-bill"></i></span>
                          <span v-if="amount != 0 && amountGotten">
                            ₦{{
                              amount
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}
                          </span>
                          <span v-else>FREE</span>
                        </a>
                        <a
                          href="#"
                          class="_215b12"
                          v-if="amount != 0 && amountGotten"
                        >
                          <Discount
                            v-if="amountGotten"
                            :id="courseId"
                            :price="amount"
                            :discount="discount"
                          />
                        </a>
						          </span>
                    </div>
                  </div>
                  <div class="col-xl-8 col-lg-7 col-md-6">
                    <div class="_215b03">
                      <h2>{{ course.title }}</h2>
                      <span class="_215b04">{{ course.secondTitle }}</span>
                    </div>
                    <!-- <div class="_215b05">
                      <div class="crse_reviews mr-2">
                        <i class="uil uil-star"></i>{{avrating}}
                      </div>
                      ({{allLikes.like+allLikes.dislike}} ratings)
                    </div> -->

                    
                    <!-- <div class="_215b05">
                      {{ AllPurchased }} students enrolled
                    </div> -->


                    <div class="_215b06">
                      <div class="_215b07">
                        <span><i class="uil uil-comment"></i></span>
                        English 
                      </div>
                      <div class="_215b08">
                        <span><i class="uil uil-closed-captioning"></i></span>
                      </div>
                    </div>
                    <div
                      class="_215b05"
                      v-if="course.updatedAt != '' && course.updatedAt != null"
                    >
                      Last updated : {{ course.updatedAt.split("T")[0] }}
                    </div>
                    <div
                      class="_215b05"
                      v-if="buyingCourseNow"
                    >
                      <div class="cmmnt_1526">
                        <h3>Do you have a Coupon?</h3>
                        <p >A coupon is a code you can add to slash the price of a course</p>
                        <div class="input-group">
                          <div class="ui left icon input swdh11 swdh19">
                            <input class="prompt srch_explore" type="text" v-model="couponId" name="expertise" maxlength="64" placeholder="Enter coupon code here">															
                          </div>
                        </div>
                        <p style="color: red" v-if="couponError">{{couponError}}</p>
                        <button class="cmnt-btn" type="button" @click="manageBuyingCourse(false)">Cancel</button>
                        <button class="cmnt-btn" type="button" v-if="couponId&&newAmountFromCoupon==null&&!couponLoading" @click.prevent="getNewPriceFromCoupon()">Verify Coupon</button>
                        <button class="cmnt-btn" style="background-color: #006400" type="button" v-if="couponId&&newAmountFromCoupon!=null&&!couponLoading" @click.prevent="buyNow()">Buy course for ₦{{newAmountFromCoupon}}</button>
                        <button class="cmnt-btn" type="button" v-if="!couponId&&!couponLoading" @click.prevent="buyNow()">Buy without Coupon</button>
                        <button class="cmnt-btn" type="button" v-if="couponLoading" >Please Wait ...</button>
                      </div>
                    </div>
                    <ul class="_215b31" v-if="amount != 0 && amountGotten && !buyingCourseNow">
                      <!-- v-if="!purchased&&course.user[0]._id!=''" -->
                      <!-- <li v-if="exist"><button class="btn_adcart">Now in Cart</button> </li>
                      <li v-else><button class="btn_adcart" @click="saveToCart()">Add to Cart</button></li> -->
                      <!-- <a><li @click="alert('add to cart then buy from button that will appear bellow')"><button class="btn_buy" id="paymentForm">Buy Now</button></li></a> -->
                      <!-- <a :href="'buy.html?email='+user.email+'&amount='+amount+'&name='+user.firstName+'&title='+course.title+'&courseId='+courseId"><li><button class="btn_buy" id="paymentForm">Buy Now</button></li></a> -->
                      
                      <a v-if="canBuy&&!purchased&&course.user[0]._id!=''">
                        <li v-if="course.visibilty=='Only institution Students'">
                          <button v-if="isStudent" class="btn_adcart" id="paymentForm" @click="manageBuyingCourse(true)">Buy Now</button>
                          <button v-else class="btn_buy" id="paymentForm" ><router-link :to="'/Dinstitude/'+uni._id"> Be a student on {{uni.institutionName.substring(0,30)+".."}} to buy this course</router-link></button>
                        </li>
                        <li v-else>
                          <button class="btn_adcart" id="paymentForm" @click="manageBuyingCourse(true)">Buy Now</button>
                        </li>
                      </a>
                      <a v-if="canBuy&&course.user[0]._id!=''"><li><button class="btn_adcart" id="paymentForm" @click="activateModal('memberships')">Buy for a Membership </button></li></a>
                      <a v-if="!canBuy&&course.user[0]._id!=''"><li><button class="btn_buy" id="paymentForm" >Sorry you can not buy now</button></li></a>
                      <a v-if="purchased&&course.user[0]._id!=''"><li><button class="btn_buy" id="paymentForm" @click.prevent="startLearning()">Start Learning</button></li></a>
                      
                    </ul>
                    <ul class="_215b31" v-if="amount == 0 && amountGotten">
                      <a><li><button class="btn_buy" id="paymentForm" @click.prevent="startLearning()">Start Learning</button></li></a>
                    </ul>  
								<div class="_215fgt1" v-if="course.accessType=='partly-before-purchase'">										
									<b>You can access the first section of this course for free</b>
								</div>
							</div>							
						</div>							
					</div>							
				</div>															
			</div>
		</div>
	</div>
	<!--  -->
	<div class="_215b155 _byt1458">
		<div class="container-fluid">
			<div class="">
				<div class="col-lg-12">
					<div class="user_dt5">
						<div class="user_dt_left">
							<div class="live_user_dt">
								<div class="user_img5">
									<!-- <a href="#"><img src="./assets/images/left-imgs/img-1.jpg" alt=""></a> -->
									<ProfileImage :id="course.user[0]._id" :name="course.user[0].imageFile" />												
								</div>
								<div class="user_cntnt">
									<a href="#" class="_df7852">{{course.user[0].firstName}} {{course.user[0].lastName}}</a>
									<router-link :to="'/Dprofile/'+course.user[0]._id" v-if="course.user[0]._id!=''">
									<button class="subscribe-btn">instructor Profile</button>
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="course_tabs">
						<nav>
							<div class="nav nav-tabs tab_crse justify-content-center" id="nav-tab" role="tablist">
								<a class="nav-item nav-link" v-bind:class="{ active: about }" @click.prevent="switchtap('about')" aria-selected="true">About</a>
								<a class="nav-item nav-link" v-bind:class="{ active: content }" @click.prevent="switchtap('content')" aria-selected="false">Content</a>
								<a class="nav-item nav-link" v-bind:class="{ active: review }" @click.prevent="switchtap('review')" aria-selected="false">Reviews</a>
								<a class="nav-item nav-link" v-bind:class="{ active: test }" @click.prevent="switchtap('test')" v-if="purchased" aria-selected="false">Certificate</a>
							</div>
						</nav>						
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="_215b17">
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-12">
					<div class="course_tab_content">
						<div class="tab-content" id="nav-tabContent">
							<div class="tab-pane fade show" style="background-color: transparent" v-bind:class="{ active: about }" id="nav-about" role="tabpanel">
								<div class="_htg451" style="background-color: transparent">
									<div class="_htg452">
										<h3>Requirements</h3>
										<p>{{course.requirement}}</p>
										<!-- <ul>
											<li><span class="_5f7g11">Have a computer with Internet</span></li>
											<li><span class="_5f7g11">Be ready to learn an insane amount of awesome stuff</span></li>
											<li><span class="_5f7g11">Prepare to build real web apps!</span></li>
										</ul> -->
									</div>
									<div class="_htg452 mt-35">
										<h3>Description</h3>
										<span class="_abc123"></span>
										<p v-html="sanitizePurchasedDescription(course.description)" v-if="course.description!=''&&course.description!=null&&purchased"></p>
										<p v-html="sanitizeDescription(course.description)" v-if="course.description!=''&&course.description!=null&&!purchased"></p>
									</div>
									<div class="_htg452 mt-35">
										<h3>Who this course is for :</h3>
										<ul class="_abc124">												
											<li><span class="_5f7g11">Who ever has all the requiremts</span></li>
										</ul>
									</div>	
									<div class="_htgdrt mt-35">
										<h3>You will learn :</h3>
										<div class="_scd123">
											<div class="row">
												<div class="col-lg-6">
													<ul class="_htg452 _abcd145">												
														<li v-for="what in whatYouLearn" :key="what"><div class="_5f7g15"><i class="fas fa-check-circle"></i><span>{{what}}</span></div></li>
													</ul>
												</div>
											</div>
										</div>
									</div>							
								</div>							
							</div>
							<div class="tab-pane fade show" style="background-color: transparent" v-bind:class="{ active: content }" id="nav-courses" role="tabpanel">
								<div class="crse_content" style="background-color: transparent" >
									<br>
									<div class="progress progress1" v-if="purchased">
										<div class="progress-bar " :style="'width: '+progress+'%; background-color: red;'"></div>
									</div>
									<p style="float: left" v-if="purchased">My Progress:  {{~~progress}}%</p>
									<br v-if="purchased">
									<h3>Course content</h3>
									<div class="_112456">
										<ul class="accordion-expand-holder">
											<li><span class="accordion-expand-all _d1452">Expand all</span></li>
											<li><span class="_fgr123"> {{totalLectures}} lectures</span></li>
										</ul>
									</div>
									<div id="accordion" class="ui-accordion ui-widget ui-helper-reset">
										<div v-for="play in playlist" :key="play">
											<a href="javascript:void(0)" class="accordion-header ui-accordion-header ui-helper-reset ui-state-default ui-accordion-icons ui-corner-all">												
												<div class="section-header-left">
													<span class="section-title-wrapper">
														<i class='uil uil-presentation-play crse_icon'></i>
														<span class="section-title-text">{{play.sectionTitle}}</span>
														<a title="Expand" class="gray-s" @click="toggleTable(play.id)" style="color: red" v-if="play.description!=null&&play.externalLink!=null">
															<i class="uil uil-eye-slash" :id="'eye'+play.id"></i>
														</a>
													</span>
													<div :id="'options'+play.id" v-if="play.description!=null&&play.externalLink!=null&&purchased">
														<small style="color: grey" v-if="play.description!=null" v-html="sanitizePurchasedDescription(play.description)"></small>
														<br>
														<small v-if="play.externalLink!=null" v-html="sanitizePurchasedDescription(play.externalLink)"></small>
													</div>
													<div :id="'options'+play.id" v-show="play.description!=null&&play.externalLink!=null&&!purchased">
														<small style="color: grey" v-if="play.description!=null" v-html="sanitizeDescription(play.description)"></small>
														<br>
														<small v-if="play.externalLink!=null" v-html="sanitizeDescription(play.externalLink)"></small>
													</div>
												</div>
												<div class="section-header-right">
													<span class="num-items-in-section">{{play.videos.length}} lectures</span>
													<span class="section-header-length">Duration</span>
												</div>
											</a>
											<div class="ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom">
												<!-- /DlectureRoom/:id -->
												<div class="lecture-container"  v-for="content in play.videos" :key="content">
													<div class="left-content">
														<i class='uil uil-file icon_142' v-if="content.fileType=='application/pdf'"></i>
														<i class='uil uil-play-circle icon_142' v-else></i>
														<div class="top">
															<router-link v-if="!outletKey" :to="'/DlectureRoom/'+content._id">
                                <a href="" class="prfle12link" v-if="purchased||(course.accessType=='partly-before-purchase'&&playlist.indexOf(play)==0)">
                                  <i v-if="lecturelog.includes(content._id)" class="fas fa-check-circle" style="color: green"></i>
                                  <span class="takecourse"> Take lecture <i class="fas fa-arrow-right"></i></span>
                                </a>
															</router-link>
                              <router-link v-else :to="'/DlectureRoom/'+content._id + outletCredentials">
                                <a href="" class="prfle12link" v-if="purchased||(course.accessType=='partly-before-purchase'&&playlist.indexOf(play)==0)">
                                  <i v-if="lecturelog.includes(content._id)" class="fas fa-check-circle" style="color: green"></i>
                                  <span class="takecourse"> Take lecture <i class="fas fa-arrow-right"></i></span>
                                </a>
															</router-link>
															<div class="title">{{content.title}}</div>
														</div>
													</div>
													<div class="details">
														<span class="content-summary" v-if="content.duration">{{content.duration.$numberDecimal}}(s)</span>
													</div>
												</div>
												<!-- <div class="lecture-container">
													<div class="left-content">
														<i class='uil uil-file-download-alt icon_142'></i>
														<div class="top">
															<div class="title">Syllabus Download</div>
														</div>
													</div>
													<div class="details">
														<a href="#" class="preview-text">Preview</a>
														<span class="content-summary">2 pages</span>
													</div>
												</div>
												<div class="lecture-container">
													<div class="left-content">
														<i class='uil uil-play-circle icon_142'></i>
														<div class="top">
															<div class="title">Syllabus Walkthrough</div>
														</div>
													</div>
													<div class="details">
														<a href="#" class="preview-text">Preview</a>
														<span class="content-summary">01:42</span>
													</div>
												</div> -->
                          </div>
                        </div>
                      </div>
                      <!-- <a class="btn1458" href="#">20 More Sections</a> -->
                    </div>
                  </div>
                  <div
                    class="tab-pane fade show"
                    style="background-color: transparent"
                    v-bind:class="{ active: review }"
                    id="nav-reviews"
                    role="tabpanel"
                  >
                    <RatingsDetails
                      :id="$route.params.id"
                      :type="'course'"
                      :canContribute="true"
                      v-if="purchased"
                    />
                    <RatingsDetails
                      :id="$route.params.id"
                      :type="'course'"
                      :canContribute="false"
                      v-else
                    />
                  </div>
                  <div
                    class="tab-pane fade show"
                    style="background-color: transparent"
                    v-bind:class="{ active: test }"
                    v-if="purchased"
                    id="nav-test"
                    role="tabpanel"
                  >
                    <h1>Certification</h1>
                    <div class="_htg452" v-if="(testDetails.length == 0) ||  (testDetails[0].description == 'default')">
                      <div v-if="progress>=100">
                        <p>
                        Congratulations on Completing Your Course! <br>

                        We're thrilled to celebrate your accomplishment of completing your course on Faslearn! This is a major milestone, and we're incredibly proud of your dedication and hard work.

                        <br> You've Achieved Something Special

                        <br>  Completing an online course takes dedication, discipline, and a love of learning. You've shown all of these qualities in abundance, and you should be incredibly proud of yourself.
                        </p>
                        <button
                          class="subscribe-btn"
                          @click.prevent="generatedefaultCerts()"
                          v-if="!Loading"
                        >
                          Get Certificate
                        </button>
                        <button
                          class="subscribe-btn"
                          v-else
                        >
                          Please wait ...
                        </button>
                      </div>
                      <div v-else>
                        <p>
                          We're so excited you're interested in earning your certificate! However, before you can claim your prize, you just need to take one more step completing the course.

                          <br> This comprehensive course will equip you with all the knowledge and skills you need. Once you've finished, simply head back to this page and you'll be able to download your well-deserved certificate!

                          <br> Let us know if you have any questions along the way!
                        </p>
                      </div>
                    </div>
                    <div v-else>
                      <div class="_htg452" v-if="testDetails.length != 0">
                        <h3>Test Description</h3>
                        <p>{{ testDetails[0].description }}</p>
                      </div>
                      <div class="_htg452 mt-35" v-if="testDetails.length != 0">
                        <h3>Maximum Time</h3>
                        <span class="_abc123"></span>
                        <p>{{ testDetails[0].time }}</p>
                      </div>
                      <router-link :to="'/Dtestrules/' + courseId">
                        <button
                          class="subscribe-btn"
                          v-if="
                            testDetails.length != 0 &&
                            userTestStatus == '' &&
                            !testAttemptsLimitExceeded
                          "
                        >
                          Take Test
                        </button>
                        <button
                          class="subscribe-btn"
                          v-if="
                            testDetails.length != 0 &&
                            userTestStatus == 'hasStarted' &&
                            !testAttemptsLimitExceeded
                          "
                        >
                          Continue Test
                        </button>
                        <button
                          class="subscribe-btn"
                          v-if="
                            testDetails.length != 0 &&
                            userTestStatus == 'canRetry' &&
                            !testAttemptsLimitExceeded
                          "
                        >
                          Retake Test
                        </button>
                      </router-link>
                      <a
                        :href="'/DcourseCertificate/' + courseId + '/' + testId"
                        v-if="
                          testlog != '' &&
                          testlog != null &&
                          testlog != 'undefined'
                        "
                      >
                        <button
                          class="subscribe-btn"
                          v-if="
                            testDetails.length != 0 &&
                            (userTestStatus == 'hasEnded' ||
                              userTestStatus == 'canRetry') &&
                            (testlog.result / testlog.total) * 100 >= 70
                          "
                        >
                          Get Certificate
                        </button>
                      </a>
                      <br /><br />
                      <i
                        v-if="
                          testlog != '' &&
                          testlog != null &&
                          testlog != 'undefined'
                        "
                        >you have {{ 3 - testlog.attempts }} left</i
                      >
                      
                    </div>
                    <br /><br /><br /><br /><br /><br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dfooter />
    </div>
    <!-- Body End -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
//import paystack from "vue-paystack";
import Discount from "@/components/Discount";
import ProfileImage from "@/components/ProfileImage";
import CourseImage from "@/components/CourseImage";
import Modal from "@/components/Modal.vue";
import Dheader from "@/components/dashboard/Header.vue";
import Dnav1 from "@/components/dashboard/Nav1.vue";
import Dfooter from "@/components/dashboard/Footer.vue";

import RatingsDetails from "@/components/dashboard/RatingsDetails.vue";
export default {
  name: "Dhome",
  data() {
    return {
      public_key: "pk_test_b66d0123c39e5274812de14cde47231d2011e8d9",
      course: {
        user: [{ firstName: "", lastName: "", _id: "", imageFile: null }],
      },
      instructor: "",
      allBundles: "",
      paymentDetails: "",
      canBuy: false,
      playlist: "",
      courseId: "",
      userId: "",
      whatYouLearn: "",
      about: true,
      content: false,
      review: false,
      test: false,
      amount: 100,
      amountGotten: false,
      discount: 0,
      user: "",
      exist: false,
      purchased: false,
      error: "",
      success: false,
      AllPurchased: "",
      Loading: false,
      coursePreview: "",
      prevewVideoId: "",
      testDetails: [{ _id: "" }],
      testId: "",
      testAttemptsLimitExceeded: false,
      userTestStatus: "",
      testlog: "",
      skip: 0,
      url: "",
      totalLectures: 0,
      log: [],
      lecturelog: [],
      progress: 0,
      modalFocus: "",
      myMemberships: "",
      otherMemberships: "",
      discounts: [],
      isStudent: false,
      uni: { institutionName: "", _id: "" },
	    rate: null,
      selectedCurrency:'',
      buyingCourseNow: false,
      couponId: null,
      couponError: null,
      couponLoading: false,
      newAmountFromCoupon: null,
      outletUserId : "",
      outletKey : "",
      outletCredentials: ""
    };
  },
  components: {
    Dheader,
    Dnav1,
    Modal,
    RatingsDetails,
    CourseImage,
    ProfileImage,
    Discount,
    //paystack,
    Dfooter,
  },
  watch: {
    circle: function () {
      if (this.circle == "loading") {
        this.Loading = true;
      } else {
        this.Loading = false;
      }
    },
  },
  computed: {
    ...mapGetters(["isInstructor"]),
    ...mapGetters(["circle"]),
    ...mapGetters(["backEnd"]),
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },
  methods: {
    ...mapActions(["check_user_instructor"]),
    ...mapActions(["fetch_course_details"]),
    ...mapActions(["fetch_user"]),
    ...mapActions(["addCart"]),
    ...mapActions(["verify_course_payment","checkBundleByCourseId","coursePriceWithCoupon","savePaymentForFreeCouponCourses","verifyOutlet"]),
    ...mapActions(["submit_Review"]),
    ...mapActions(["checkIf_UserAlreadyReviewedAndFetchAll"]),
    ...mapActions(["fetchAllPurchaseForCourse"]),
    ...mapActions(["fetch_TestForCourseDetails","makeDefaultCertificate"]),
    ...mapActions(["checkIf_UserAlreadyStartedTest"]),
    ...mapActions([
      "generate_V4ReadSignedUrl",
      "check_IsUserInstitutionStudent",
      "fetch_CourseOwnerUniversity",
    ]),
    ...mapActions([
      "fetch_UserLectureLog",
      "fetch_FounderMembership",
      "fetch_UserMembership",
      "fetch_Discounts",
      "check_UserPurchaseOnMembership",
    ]),
    switchtap(tap) {
      this.skip = 0;
      switch (tap) {
        case "about":
          this.about = true;
          this.content = false;
          this.review = false;
          this.test = false;
          break;
        case "content":
          this.about = false;
          this.content = true;
          this.review = false;
          this.test = false;
          break;
        case "review":
          this.about = false;
          this.content = false;
          this.review = true;
          this.test = false;
          break;
        case "test":
          this.about = false;
          this.content = false;
          this.review = false;
          this.test = true;
          break;

        default:
          break;
      }
    },
    getNewPriceFromCoupon(){
      this.couponLoading = true
      const object = {
        courseId: this.courseId, 
        couponId: this.couponId
      }
      this.coursePriceWithCoupon(object)
      .then(res => {
        this.couponLoading = false
        if (res.data.success) {
          this.newAmountFromCoupon = res.data.price
          this.couponError = null
        }else{
          this.couponError = res.data.message
        }
      }).catch(err => {
        this.couponLoading = false
        this.couponError = "invalid coupon"
        console.log(err)
      })
    },
    manageBuyingCourse(state){
      this.buyingCourseNow = state
    },
    saveToCart() {
      let cart = [];
      let ref = localStorage.getItem("fmrcart");
      if (ref == "" || ref == null) {
        cart = [];
      } else {
        cart = JSON.parse(localStorage.getItem("fmrcart"));
      }
      var element = this.course;
      element.email = this.user.email;
      cart.push(element);
      this.addCart(cart.length);
      cart = JSON.stringify(cart);
      localStorage.setItem("fmrcart", cart);
      this.exist = true;
      //console.log(cart)
      //alert('item successfully added to your cart');
    },
    startLearning() {
      this.switchtap('content')
      window.scrollTo(0,500);
    },
    checkCoursePreview() {
      for (let i = 0; i < this.playlist.length; i++) {
        const element = this.playlist[i];
        if (element.sectionTitle == "Preview") {
          for (let j = 0; j < element.videos.length; j++) {
            const elementj = element.videos[j];
            if (elementj.isPreview) {
              this.coursePreview = elementj;
              let id = this.coursePreview.uri + "";
              let cleanId = id;
              this.prevewVideoId = cleanId;
              // let cleanId = id.split('/videos/')[1]
              // this.prevewVideoId = cleanId
              if (this.coursePreview.fileType != "embedLink") {
                this.generate_V4ReadSignedUrl(id).then((res) => {
                  this.url = res.data;
                });
              }
            }
          }
          if (this.url == "" && element.videos.length != 0) {
            this.coursePreview = element.videos[0];
            if (this.coursePreview.fileType != "application/pdf") {
              this.prevewVideoId = this.coursePreview.uri + "";
              if (this.coursePreview.fileType != "embedLink") {
                this.generate_V4ReadSignedUrl(this.coursePreview.uri).then(
                  (res) => {
                    this.url = res.data;
                  }
                );
              }
            }
          }
        }
      }
    },
    buyNow() {
      if (this.paymentDetails == "") {
        alert("please wait for minuite and try again");
        return;
      }
      let object = {
        price: this.course.price.$numberDecimal,
        paymentDetails: this.paymentDetails,
        email: this.user.email,
        title: this.course.title,
        id: this.course._id,
        membershipId: null,
        couponId: null,
        userId: this.userId
      };
      if (this.newAmountFromCoupon!=null) {
        object.price = this.newAmountFromCoupon
        object.couponId = this.couponId
      }

      if (object.price>0) {
        localStorage.setItem("fmrcart", JSON.stringify(object));
        let str = window.location.href + "";
        const myArr = str.split("/Dcoursedetai");
        window.location.href = myArr[0] + "/Dcoursedetail/buy.html";
      } else {
        this.savePaymentForFreeCouponCourses(object)
        .then(res => {
          if (res.data.status) {
            this.purchased = true
            this.amount = 0
            this.buyingCourseNow = false
          }
        })
      }
      
    },
    buyNowForMembers(price, membershipId) {
      if (this.paymentDetails == "") {
        alert("please wait for minuite and try again");
        return;
      }
      let object = {
        price: price,
        paymentDetails: this.paymentDetails,
        email: this.user.email,
        title: this.course.title,
        id: this.course._id,
        membershipId: membershipId,
        couponId: null
      };
      //console.log(object)
      localStorage.setItem("fmrcart", JSON.stringify(object));
      //localStorage.setItem("fmrlastreference", 103434038);
      let str = window.location.href + "";
      const myArr = str.split("/Dcoursedetai");
      window.location.href = myArr[0] + "/Dcoursedetail/buy.html";
    },
    processPayment: () => {
      window.alert("Payment recieved");
    },
    close: () => {
      console.log("You closed checkout page");
    },
    activateModal(myModal) {
      this.modalFocus = myModal;
      var modal;
      if (myModal == "preview") {
        modal = document.getElementById("videoModal");
      } else {
        modal = document.getElementById("myModal");
      }
      modal.setAttribute("class", "modal vd_mdl fade show");
      modal.style.display = "block";
    },
    approximateMax(max) {
      let result = ~~(max / 10);
      return result * 10;
    },
    dropDiscount(max) {
      let maximum = this.approximateMax(max);
      if (maximum >= 100) {
        maximum = 100;
      }
      for (let i = 0; i < this.discounts.length; i++) {
        const element = this.discounts[i];
        if ("above " + maximum == element.entity) {
          return element.percent;
        }
        if ("bellow " + maximum == element.entity) {
          return element.percent;
        }
      }
      return 0;
    },
    regexLink(link) {
      var expression = /https:\/\/[a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}/;
      var regex = new RegExp(expression);
      if (link != "") {
        if (link.match(regex)) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    sanitizePurchasedDescription(text) {
      const words = text.split(" ");
      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        if (this.regexLink(word)) {
          words[i] =
            '<a href="' +
            word +
            '" target="_blank" rel="noopener noreferrer">Click here to access link</a>';
        }
      }
      var newDescription = "";
      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        newDescription = newDescription + " " + word;
      }
      return newDescription;
    },
    sanitizeDescription(text) {
      const words = text.split(" ");
      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        if (this.regexLink(word)) {
          words[i] =
            '<a target="_blank" rel="noopener noreferrer">buy course to access link</a>';
        }
      }
      var newDescription = "";
      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        newDescription = newDescription + " " + word;
      }
      return newDescription;
    },
    toggleTable(id) {
      document.getElementById("options" + id).classList.toggle("closed");
      document.getElementById("eye" + id).classList.toggle("uil-eye");
    },
	  changeCurrency(rate) {
      this.rate = rate;
    },
    selectedCur(cur){
      this.selectedCurrency = cur
    },
    generatedefaultCerts() {
      this.Loading = true
      const obj = {
        courseId: this.courseId, 
        userId: this.userId 
      }
      this.makeDefaultCertificate(obj)
      .then(res => {
        this.Loading = false
        const testlog = res.data;
        let str = window.location.href + "";
        const myArr = str.split("/Dcoursedetai");
        window.location.href = myArr[0] + '/DcourseCertificate/'+this.courseId+'/'+testlog.test[0];
      })
    }
    
  },
  mounted() {
    this.courseId = this.$route.params.id;
    this.userId = localStorage.getItem("fmruser");
    this.outletUserId = this.$route.query.outletUserId;
    this.outletKey = this.$route.query.key;
    this.outletCredentials = "?outletUserId="+this.outletUserId+"&key="+this.outletKey
    let object = {
      courseId: this.courseId,
      userId: this.userId,
    };
    let url = this.courseId + "";
    let size = url.split("?");
    if (size.length > 1) {
      console.log("empty");
    } else {
      this.fetch_course_details(object)
        .then((res) => {
          this.playlist = res.data.modules;
          this.course = res.data.course;
          this.amount = this.course.price.$numberDecimal;
          if (this.amount == 0) {
            this.purchased = true;
          }else{
            const userCourseObject = this.course.user[0]
            if(this.outletUserId && this.outletKey && userCourseObject){
              if(this.outletUserId == userCourseObject._id){
                this.verifyOutlet({userId: this.outletUserId, key: this.outletKey})
                .then(res=>{
                  this.outlet = res.data
                  if(!this.outlet){
                    this.purchased = false
                  } else if (new Date(this.outlet.expire).getTime() < new Date().getTime()) {
                    this.purchased = false
                  } else {
                    this.purchased = true
                  }
                })
              } else {
                console.log("unauthorized")
              }
            } else {
              this.verify_course_payment(userObject).then((res) => {
                this.purchased = res.data.isPurchased;
                if (!this.purchased) {
                  this.check_UserPurchaseOnMembership(userObject).then((res) => {
                    this.purchased = res.data.purchased;
                    if (this.purchased) {
                      this.fetch_TestForCourseDetails(this.courseId).then((res) => {
                        this.testDetails = res.data;
                        if (res.data.length > 0) {
                          this.testId = this.testDetails[0]._id;
                          let myObject = {
                            user: this.userId,
                            test: this.testDetails[0]._id,
                          };
                          this.checkIf_UserAlreadyStartedTest(myObject).then((res) => {
                            this.testlog = res.data.test[0];
                            if (this.testlog.attempts >= 3) {
                              this.testAttemptsLimitExceeded = true;
                            }
                            if (res.data.alreadyStarted) {
                              this.userTestStatus = "hasStarted";
                              if (
                                res.data.test[0].completed ||
                                res.data.test[0].canceled
                              ) {
                                this.userTestStatus = "hasEnded";
                                if (this.testlog.attempts < 3) {
                                  this.userTestStatus = "canRetry";
                                }
                              }
                            }
                          });
                        }
                      });
                    }
                  });
                }

                if (this.purchased) {
                  this.fetch_TestForCourseDetails(this.courseId).then((res) => {
                    this.testDetails = res.data;
                    if (res.data.length > 0) {
                      this.testId = this.testDetails[0]._id;
                      let myObject = {
                        user: this.userId,
                        test: this.testDetails[0]._id,
                      };
                      this.checkIf_UserAlreadyStartedTest(myObject).then((res) => {
                        this.testlog = res.data.test[0];
                        if (this.testlog.attempts >= 3) {
                          this.testAttemptsLimitExceeded = true;
                        }
                        if (res.data.alreadyStarted) {
                          this.userTestStatus = "hasStarted";
                          if (res.data.test[0].completed || res.data.test[0].canceled) {
                            this.userTestStatus = "hasEnded";
                            if (this.testlog.attempts < 3) {
                              this.userTestStatus = "canRetry";
                            }
                          }
                        }
                      });
                    }
                  });
                }
              });
            }
          }
          this.amountGotten = true;
          this.discount = this.course.discountPercent;
          this.instructor = res.data.instructor;
          let what = this.course.whatYouLearn + "";
          this.whatYouLearn = what.split(",");
          this.paymentDetails = res.data.paymentDetails;
          if (
            this.paymentDetails.bankDetails.subaccount_code == "false" ||
            this.paymentDetails.bankDetails.subaccount_code == false ||
            this.paymentDetails.bankDetails.subaccount_code == null ||
            this.paymentDetails.bankDetails.subaccount_code == ""
          ) {
            this.canBuy = false;
          } else {
            this.canBuy = true;
            if (this.course.visibilty == "Only institution Students") {
              this.fetch_CourseOwnerUniversity(this.course.user[0]._id).then(
                (res) => {
                  this.uni = res.data[0];
                  let object = {
                    institutionId: this.uni._id,
                    userId: this.userId,
                  };
                  this.check_IsUserInstitutionStudent(object).then((res) => {
                    //this.alreadyApplied = res.data.isApplied
                    this.isStudent = res.data.isStudent;
                    //console.log(res.data.isStudent)
                  });
                }
              );
            }
          }
          this.checkCoursePreview();
          for (let i = 0; i < this.playlist.length; i++) {
            const element = this.playlist[i];
            for (let j = 0; j < element.videos.length; j++) {
              this.totalLectures++;
            }
          }
          let fetchLog = {
            user: this.userId,
            id: this.courseId,
            type: "course",
          };
          this.fetch_UserLectureLog(fetchLog)
            .then((res) => {
              this.log = res.data.log;
              this.progress = (this.log.length / this.totalLectures) * 100;
              for (let i = 0; i < this.log.length; i++) {
                const element = this.log[i];
                this.lecturelog.push(element.lecture);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    this.fetch_user(this.userId)
      .then((res) => {
        this.user = res.data.user;
      })
      .catch((err) => {
        console.log(err);
      });
    let userObject = {
      userId: this.userId,
      courseId: this.courseId,
    };

    // let cart = localStorage.getItem('fmrcart')
    // if (cart == '' || cart == null) {
    // 	cart = [];
    // 	this.exist = false
    // }else{
    // 	cart = JSON.parse(localStorage.getItem('fmrcart'));
    // 	let courseId = this.$route.params.id
    // 	for (let i = 0; i < cart.length; i++) {
    // 		const item = cart[i];
    // 		if (item._id == courseId) {
    // 			this.exist = true
    // 		}
    // 	}
    // }

    // let reviewObject= {
    // 	user : this.userId,
    // 	id : this.courseId,
    // 	type : 'course'
    // }
    // this.checkIf_UserAlreadyReviewedAndFetchAll(reviewObject)
    // .then(res => {
    // 	this.alreadyReviewed = res.data.alreadyReviewed
    // 	this.reviews = res.data.Reviews
    // 	this.stars = res.data.stars
    // 	this.one = this.checkStar(1)
    // 	this.two = this.checkStar(2)
    // 	this.three = this.checkStar(3)
    // 	this.four = this.checkStar(4)
    // 	this.five = this.checkStar(5)
    // 	this.avrating = this.checkAveageStar()
    // 	this.allLikes = this.countlikes()
    // })

    this.fetchAllPurchaseForCourse({ courseId: this.courseId, skip: 0 }).then(
      (res) => {
        this.AllPurchased = res.data.people.length;
      }
    );
    this.fetch_Discounts("membership").then((res) => {
      this.discounts = res.data.discount;
      this.fetch_FounderMembership(this.userId).then((res) => {
        this.myMemberships = res.data.members;
      });
      this.fetch_UserMembership(this.userId).then((res) => {
        this.otherMemberships = res.data.members.membership;
      });
    });
  },
};
</script>
<style scoped>
@import url("./assets/css/style.css");
/* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
@import url("./assets/vendor/unicons-2.0.1/css/unicons.css");
@import url("./assets/css/instructor-dashboard.css");
@import url("./assets/css/vertical-responsive-menu.min.css");
@import url("./assets/css/instructor-dashboard.css");
@import url("./assets/css/instructor-responsive.css");
@import url("./assets/css/responsive.css");
@import url("./assets/css/night-mode.css");
@import url("./assets/vendor/fontawesome-free/css/all.min.css");
@import url("./assets/vendor/OwlCarousel/assets/owl.carousel.css");
@import url("./assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
@import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
@import url("./assets/vendor/semantic/semantic.min.css");

.nav-link {
  cursor: pointer;
}

.liked {
  background-color: yellowgreen;
  border: 2px solid yellowgreen;
}

.likeButton {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  padding: 10px;
}

.liking {
  width: 60px;
  height: 60px;
  display: inline-block;
  margin: 5px;
  padding-top: 10px;
}

.darkest {
  background-color: black;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0px;
}

.takecourse {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: rgb(231, 69, 40);
  border-radius: 10px;
  color: white;
}

.closed {
  display: none;
}
.open {
  display: block;
}
</style>
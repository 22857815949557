<template>
    <div>
        <Header />

        <div class="content-wrapper">
            <div class="container-fluid">
                <!-- Breadcrumbs-->
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                    <a href="#">Dashboard</a>
                    </li>
                    <li class="breadcrumb-item active">Add listing</li>
                </ol>
                <div class="box_general padding_bottom">
                    <div class="header_box version_2" v-if="donation!=''">
                        <h2><i class="fa fa-user"></i>Donation details</h2>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group" v-if="donation!=''">
                                <CourseImage :name="donation.course[0].thumbnailFile" style="height: 200px; width: 250px; " />
                            </div>
                        </div>
                        <div class="col-md-8 add_top_30" v-if="donation!=''">
                            <div class="bigspinner" v-if="Loading">
                                <div class="smallspiner">
                                    
                                </div>
                            </div>
                            <Success v-if="Success">
                                <ul>
                                    <li>Action Successfull</li>
                                </ul>
                            </Success>
                            <div class="row" v-if="donation!=''">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <p>{{donation.user[0].firstName}} {{donation.user[0].lastName}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Address</label>
                                        <p>{{donation.user[0].address}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="donation!=''">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>MemberShip Size</label>
                                        <p>{{membership.max}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Used Slots</label>
                                        <p>{{members.length}}</p>
                                    </div>
                                </div>
                            </div> 
                            <div class="row" v-if="donation!=''">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Telephone</label>
                                        <p>{{donation.user[0].phone}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <p>{{donation.user[0].email}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="donation!=''">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Created At</label>
                                        <p>{{donation.createdAt}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6" >
                                    <div class="form-group">
                                        <label> Donated amount</label>
                                        <p>{{donation.price.$numberDecimal}} </p>
                                    </div>
                                </div>
                            </div> 
                            <div class="row">
                                <!-- <div class="col-md-6" v-if="institution.percent!=null">
                                    <div class="form-group">
                                        <label>Percent</label>
                                        <p>{{institution.percent}}</p>
                                    </div>
                                </div> -->
                                <!-- <div class="col-md-6" >
                                    <div class="form-group">
                                        <label>Input Percentage value for donation</label>
                                        <input class="form-control" type="number" v-model="percent" placeholder="00%">
                                    </div>
                                </div> -->
                            </div>
                            
                        </div>
                    </div>
                </div>



              
                        
                <div class="row">
                    <div class="col-md-12">
                        <div class="box_general padding_bottom">
                            <div class="header_box version_2">
                                <h2><i class="fa fa-lock"></i>Add New Member</h2>
                            </div>
                            <div class="form-group">
                                <label>User's Email</label>
                                <input class="form-control" type="text" v-model="email">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <Errors v-if="error">
                            <ul>
                                <li >{{error}}</li>	
                            </ul>
                        </Errors>
                        <Success v-if="success">
                            <ul>
                                <li>Member created successfully</li>
                            </ul>
                        </Success>
                    </div>
                </div>
                
                <div v-if="canAdd">
                    <p @click.prevent="manageMember()" v-if="!Loading"><a style="color: white" class="btn_1 medium">Save Member</a></p>
                    <p v-else><a style="color: white" class="btn_1 medium">Please Wait ...</a></p>
                </div>
                <div v-else>
                    <p ><a style="color: white" class="btn_1 medium">MemberShip has reached its peak </a></p>
                </div>



                <div class="card-body">
                    <div class="table-responsive">
                    <Loading v-if="Loading" />
                    <table
                        class="table stripe table-bordered"
                        id="dataTable"
                        width="100%"
                        cellspacing="0"
                    >
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tfoot>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Action</th>
                        </tr>
                        </tfoot>
                        <tbody >
                        <tr v-for="member in members" :key="member">
                            <td>{{member.firstName}} {{member.lastName}}</td>
                            <td>{{member.email}}</td>
                            <td>
                                <button
                                    type="button"
                                    class="btn ma-1"
                                    data-toggle="modal"
                                    data-placement="top"
                                    data-target="#editAdmin"
                                    title="Delete Admin"
                                    @click.prevent="removeMember(member.email, member.firstName+' '+member.lastName)"
                                >
                                    <i class="fa fa-fw fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <!-- pagination -->
                    </div>
                </div>
                


            </div>
	    <!-- /.container-fluid-->
   	    </div>
        <!-- /.container-wrapper-->
        <Footer />
        <!-- Scroll to Top Button-->
        <a class="scroll-to-top rounded" href="#page-top">
        <i class="fa fa-angle-up"></i>
        </a>
        <!-- Logout Modal-->
        <Modal />
        
    </div>
</template>
<script>
import Header from '@/components/superAdmin/Header.vue'
import Modal from '@/components/superAdmin/Modal.vue'
import Footer from '@/components/superAdmin/Footer.vue'
import Success from "@/components/Success.vue";
import CourseImage from '@/components/CourseImage.vue'
import Errors from '@/components/Errors.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return{
            donation: '',
            donationId: this.$route.params.id,
            Loading: false,
            success: false,
            update: false,
            percent: null,
            subaccount: '',
            paystackId: '',
            usedSlots: "",
            email: this.email, 
            membership: this.membership, 
            members: "",
            error : "",
            canAdd : false
        }
    },
    components: {
        Header,
        Modal,
        Success,
        Errors,
        CourseImage,
        Footer
    },
    computed: {
        ...mapGetters(['backEnd','publicFiles'])
    },
    methods: {
        ...mapActions(['fetchDonation__Admin','checkMemberShipMax__Admin', 'manageMembership__Admin']),
        ...mapActions(['fetch_Membership','fetch_MembershipMembers','manage_Membership','check_MembershipStatus','fetch_MembershipCourses']),
        ...mapActions(['fetch_UserLectureLog','fetch_UsersProgressMembership']),
        manageMember(){
            this.Loading = true
            this.error = ''
            this.success = ''
            let object = {
                email: this.email, 
                membershipId: this.membership._id, 
            }
            this.manageMembership__Admin(object)
            .then(res => {
                if (res.data.error) {
                    this.error = res.data.message
                    this.Loading = false
                }else{  
                    this.success = res.data.message
                    this.email = ""
                    this.members = res.data.membership
                    this.Loading = false
                    if (this.members.length<this.membership.max) {
                        this.canAdd = true
                    }else{
                        this.canAdd = false
                    }
                }
                this.email = ''
            })
        },
        removeMember(email, name){
            if (confirm("Do you REALLY want to remove "+name+" ?")) {
                this.email = email
                this.manageMember()
            }else{
                alert('ok, we will ignore your delete request')
            }
            
        },
		fetchMore(){
            this.Loading = true
			this.skip = this.skip + 10
			this.fetch_MembershipCourses({id: this.membership._id, skip: this.skip})
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    this.courses.push(element)
                }
                this.Loading = false
            })		
        },
        fetchMoreProgress(){
            this.Loading = true
			this.skipProgress = this.skipProgress + 10
			this.fetch_UsersProgressMembership({id: this.membershipId, skip: this.skipProgress})
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    this.pogressLogs.push(element)
                }
                this.Loading = false
            })	
        }
    },
    mounted(){
        this.donationId = this.$route.params.id
        this.fetchDonation__Admin(this.donationId)
        .then(res => {
            this.donation = res.data
            this.membership = this.donation.membership
            this.membership = res.data.membership[0]
            this.fetch_MembershipMembers(this.membership._id)
            .then(res => {
                this.members = res.data.members
                if (this.members.length<this.membership.max) {
                    this.canAdd = true
                }
            })
            this.check_MembershipStatus({userId: this.userId, membershipId: this.membershipId})
            .then(res => {
                this.status = res.data.status
            })
            this.Loading = true
            this.fetch_UsersProgressMembership({id: this.membership._id, skip: this.skip})
            .then(res => {
                this.pogressLogs = res.data
                this.Loading = false
            })
        })
        this.checkMemberShipMax__Admin(this.donationId)
        .then(res => {
            this.usedSlots = res.data
        })
    }
}
</script>
<style scoped>
	@import url("./assets/css/admin.css");
    @import url("./assets/css/custom.css");
    @import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
    @import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    .approve{
        color: white;
        background-color: green;
        border-radius: 15px;
        padding: 10px;
        display: inline;
        margin-left: 10px;
        cursor: pointer;
    }

    .delete{
        color: white;
        background-color: red;
        border-radius: 15px;
        padding: 10px;
        display: inline;
        margin-left: 10px;
        cursor: pointer;
    }

        .bigspinner{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border-top: 5px solid grey;
        display: inline-block;
        animation-name: spin;
        animation-duration: 3000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear; 
    }
    .saymessage{
        display: inline-block;
        margin-left: 10px;
    }
    .smallspiner{
        height: 30px;
        width: 30px;
        margin: 5px;
        border-radius: 50%;
        border-top: 5px solid grey;
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes spin{
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }
</style>
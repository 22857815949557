<template>
  <div>
    <Header />

    <div class="content-wrapper">
      <div class="container-fluid">
        <!-- Breadcrumbs-->
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Dashboard</a>
          </li>
          <li class="breadcrumb-item active">Tables</li>
        </ol>
        <!-- Example DataTables Card-->
        <br><br> 
        <div class="card mb-3">
          <div class="card-header">
            <i class="fa fa-table"></i> Data Table Example
            
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <Loading v-if="Loading" />
              <table
                class="table stripe table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Type</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
                <tbody >
                  <tr v-for="admin in admins" :key="admin">
                    <td>{{admin.name}}</td>
                    <td>{{admin.email}}</td>
                    <td>{{admin.type}}</td>
                    <td>
                      <button
                        type="button"
                        class="btn ma-1"
                        data-toggle="modal"
                        data-placement="top"
                        data-target="#editAdmin"
                        title="Edit Admin"
                        @click="setEdit(admin)"
                      >
                        <i class="fa fa-fw fa-pencil"></i>
                      </button>
                      <button
                        class="btn ma-1"
                        data-target="#deleteAdmin"
                        data-toggle="modal"
                        data-placement="top"
                        title="Remove Admin"
                        @click="setDelete(admin)"
                      >
                        <i class="fa fa-fw fa-trash"></i>
                      </button>
                      <!-- modal for edit and remove -->
                      <!-- Edit Admin Modal-->
                      <div
                        class="modal fade"
                        id="editAdmin"
                        tabindex="-1"
                        aria-labelledby="editAdminLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="editAdminLabel">
                                Edit Admin
                              </h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              
                              <div class="col-md-12">
                                <div class="box_general padding_bottom">
                                  <div class="form-group">
                                    <label>Admin Username</label>
                                    <input class="form-control" type="text" v-model="name">
                                  </div>
                                  <div class="form-group">
                                    <label>Admin Type</label>
                                    <select class="form-control" v-model="type">
                                      <option value="General">General Admin</option>
                                      <option value="Manager">Manager Role</option>
                                      <option value="Editor">Content Editor</option>
                                      <option value="Analyst">Quality Analyst</option>
                                      <option value="Customer">Customer Care</option>
                                    </select>
                                  </div>
                                  <div class="form-group">
                                    <label>Admin Email Address</label>
                                    <input class="form-control" type="email" v-model="email">
                                  </div>
                                  <div class="form-group">
                                    <label>Password</label>
                                    <input class="form-control" type="text" v-model="password">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="successEdit" class="alert alert-success" role="alert">
                              Admin updated successfully
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" data-dismiss="modal" class="btn btn-primary" v-if="!Loading" @click="EditAdmin()">
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Remove admin -->
                      <div
                        class="modal fade"
                        id="deleteAdmin"
                        tabindex="-1"
                        aria-labelledby="deleteAdminLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="deleteAdminLabel">
                                Delete Admin
                              </h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <p v-if="!successDelete">Are you sure you want to delete {{name}} from Admins</p>
                              <div v-if="successDelete" class="alert alert-success" role="alert">
                                {{name}} has been deleted successfully
                              </div>
                              <button
                                type="button"
                                class="btn mr-3 btn-secondary"
                                data-dismiss="modal"
                                v-if="!successDelete"
                              >
                                No
                              </button>
                              <button type="button" data-dismiss="modal" class="btn ml-3 btn-danger" v-if="!Loading&&!successDelete" @click="DeleteAdmin()">
                                Yes
                              </button>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- modal ends here -->
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- pagination -->
            </div>
          </div>
          <!-- <div class="card-footer small text-muted">Updated yesterday at 11:59 PM</div> -->
        </div>
        <!-- /tables-->
        <p>
          <router-link :to="'/SAaddNewSuperAdmin'">
          <a
            href="#0"
            class="btn_1 medium"
            >Create new admin</a
          >
          </router-link>
        </p>

      </div>
      <!-- /container-fluid-->
    </div>
    <!-- /.container-wrapper-->
    <Footer />
    <!-- Scroll to Top Button-->
    <a class="scroll-to-top rounded" href="#page-top">
      <i class="fa fa-angle-up"></i>
    </a>
    <!-- Logout Modal-->
    <Modal />
  </div>
</template>
<script>
import Header from "@/components/superAdmin/Header.vue";
import Modal from "@/components/superAdmin/Modal.vue";
import Footer from "@/components/superAdmin/Footer.vue";
import Loading from "@/components/Loading.vue";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      admins: "",
      type: "",
      name: "",
      password: "",
      email: "",
      actionId: "",
      modal:"",
      Loading: false,
      successEdit: false,
      successDelete: false
    };
  },
  components: {
    Header,
    Modal,
    Loading,
    Footer,
  },
  methods: {
    ...mapActions(["fetchAllSuperAdmin__Admin", "editSuperAdmin__Admin", "deleteSuperAdmin__Admin"]),
    setEdit(admin){
      this.actionId = admin._id
      this.type= admin.type
      this.name= admin.name
      this.password= admin.password
      this.email= admin.email
      this.successEdit = false
    },
    setDelete(admin){
      this.actionId = admin._id
      this.name= admin.name
      this.successDelete = false
    },
    EditAdmin(){
      this.Loading = true
      let object = {
        id : this.actionId,
        type: this.type,
        name: this.name,
        password: this.password,
        email: this.email
      }
      this.editSuperAdmin__Admin(object)
      .then(res => {
        console.log(res)
        for (let i = 0; i < this.admins.length; i++) {
          const element = this.admins[i];
          if (object.id == element._id) {
            this.admins[i].type= this.type
            this.admins[i].name= this.name
            this.admins[i].password= this.password
            this.admins[i].email= this.email
          }
        }
        this.Loading = false
        this.successEdit = true
      })
    },
    DeleteAdmin(){
      this.Loading = true
      this.deleteSuperAdmin__Admin(this.actionId)
      .then(res => {
        console.log(res)
        let newAdmins = []
        for (let i = 0; i < this.admins.length; i++) {
          const element = this.admins[i];
          if (this.actionId != element._id) {
            newAdmins.push(element)
          }
        }
        this.admins = newAdmins
        this.Loading = false
        this.successDelete = true
      })
    },
  },
  mounted() {
    this.fetchAllSuperAdmin__Admin()
    .then((res) => {
      this.admins = res.data;
    });
  },
};
</script>
<style scoped>
@import url("./assets/css/admin.css");
@import url("./assets/css/custom.css");
@import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
@import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
@import url("./assets/vendor/bootstrap/css/bootstrap.min.css");

.verifiedButton {
  color: white;
  background-color: grey;
  border-radius: 10px;
  padding: 5px;
  display: inline;
  margin-left: 10px;
  cursor: pointer;
}

.active {
  background-color: red;
}
</style>
<template>
    <div>
        <Header />

        <div class="content-wrapper">
            <div class="container-fluid">
                <!-- Breadcrumbs-->
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                    <a href="#">Dashboard</a>
                    </li>
                    <li class="breadcrumb-item active">Tables</li>
                </ol>
                <br><br>
                    <!-- Example DataTables Card-->
                <div class="card mb-3">
                    <div class="card-header">
                    <i class="fa fa-table"></i> Coupons
            
                    <p class="verifiedButton" :class="{active : type=='all'}" @click="switchExpired('all')">All</p>
                    <p class="verifiedButton" :class="{active : type=='active'}"  @click="switchExpired('active')">Active</p>
                    <p class="verifiedButton" :class="{active : type=='expired'}"  @click="switchExpired('expired')">Expired</p>
                    </div>

                    <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                            <th>Title</th>
                            <th>Code</th>
                            <th>Courses</th>
                            <th>Type</th>
                            <th>Expire</th>
                            <th>Controls</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                            <th>Title</th>
                            <th>Code</th>
                            <th>Courses</th>
                            <th>Type</th>
                            <th>Expire</th>
                            <th>Controls</th>
                            </tr>
                        </tfoot>
                        <tbody v-if="coupons!=''">
                            <tr v-for="i in coupons" :key="i">
                                <td>{{i.title}}</td>
                                <td>{{i._id}}</td>
                                <td>{{i.courses.length}}</td>
                                <td>{{i.type}}</td>
                                <td>{{i.expire}}</td>
                                <td @click.prevent="deleteCoupon(i.title, i._id)" style="cursor: pointer">
                                    Delete
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                    <!-- <div class="card-footer small text-muted">Updated yesterday at 11:59 PM</div> -->
                </div>
                <!-- /tables-->
                <p><a href="#0" class="btn_1 medium" @click.prevent="fetchMore()" v-if="!Loading">Fetch More coupons</a></p>
                <p><a href="#0" class="btn_1 medium" v-if="Loading">Please Wait ...</a></p>
                </div>
                <!-- /container-fluid-->
            </div>
            <!-- /.container-wrapper-->
            <Footer />
            <!-- Scroll to Top Button-->
            <a class="scroll-to-top rounded" href="#page-top">
            <i class="fa fa-angle-up"></i>
            </a>
            <!-- Logout Modal-->
            <Modal />
        
    </div>
</template>
<script>
import Header from '@/components/superAdmin/Header.vue'
import Modal from '@/components/superAdmin/Modal.vue'
import Footer from '@/components/superAdmin/Footer.vue'
import { mapActions } from "vuex";
export default {
    data(){
        return{
            skip: 0,
            coupons: '',
            type: 'all',
            Loading: false
        }
    },
    components: {
        Header,
        Modal,
        Footer
    },
    methods: {
        ...mapActions(['getCoupons__Admin', 'deleteCoupon__Admin']),
        switchExpired(type){
            this.type = type
            this.skip = 0
            this.Loading = true
            this.getCoupons__Admin({type: this.type, skip: this.skip})
            .then(res => {
                this.coupons = res.data
                this.Loading = false
            })
        },
        fetchMore(){
            this.skip = this.skip + 10
            this.Loading = true
            this.getCoupons__Admin({type: this.type, skip: this.skip})
                .then(res => {
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        this.coupons.push(element)
                    }
                    this.Loading = false
                })
        },
        deleteCoupon(couponName, id){
            this.Loading = true
            if(confirm("Are you sure you want to delete "+couponName)){
                this.deleteCoupon__Admin(id)
                .then(res => {
                    if (res.data) {
                        this.coupons = this.coupons.filter(function (c)
                            {
                                return c._id != id
                            }
                        )
                    }
                    this.Loading = false
                })
            }
        }
    },
    mounted(){
        this.getCoupons__Admin({type: this.type, skip: this.skip})
        .then(res => {
            this.coupons = res.data
        })
    }
}
</script>
<style scoped>
	@import url("./assets/css/admin.css");
    @import url("./assets/css/custom.css");
    @import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
    @import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    .verifiedButton{
        color: white;
        background-color: grey;
        border-radius: 10px;
        padding: 5px;
        display: inline;
        margin-left: 10px;
        cursor: pointer;
    }

    .active{
        background-color: red;
    }
</style>
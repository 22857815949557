<template>
  <div>
    <!-- Header Start -->
    <Dheader @getCurrency="changeCurrency" @selectedCurrency="selectedCur" />
    <!-- Header End -->
    <!-- Left Sidebar Start -->
    <Dnav1 />
    <!-- Left Sidebar End -->
    <!-- Body Start -->
    <div class="wrapper">
      <div class="sa4d25">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-9 col-lg-8">
              <!-- <div class="section3125">
							<h4 class="item_title">Live Streams</h4>
							<a href="live_streams.html" class="see150">See all</a>
							<div class="la5lo1">
								<div class="live_stream owl-theme">
                                    <div class="scroll">
                                        <div class="item">
                                                <div class="stream_1">
                                                <a href="live_output.html" class="stream_bg">
                                                    <img src="./assets/images/left-imgs/img-1.jpg" alt="">
                                                    <h4>John Doe</h4>
                                                    <p>live<span></span></p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
								</div>
							</div>
						</div> -->
              <div class="section3125 mt-50">
                <h4 class="item_title">Our Newest Courses</h4>
                <router-link :to="'/Dallcourses/e'">
                  <a href="#" class="see150">See all</a>
                </router-link>
                <div class="la5lo1">
                  <div class="featured_courses owl-theme">
                    <div class="scroll">
                      <CourseEntity
                        v-for="c in recentCourses"
                        :key="c"
                        :id="c._id"
                        :title="c.title"
                        :file="c.thumbnailFile"
                        :category="c.category[0].name"
                        :price="c.price.$numberDecimal"
                        :firstName="c.user[0].firstName"
                        :lastName="c.user[0].lastName"
                        :userId="c.user[0]._id"
                        :discount="c.discountPercent"
                        :rate="rate"
                        :selectedCurrency="selectedCurrency"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="section3125 mt-30">
                <h4 class="item_title">Your Purchased Courses</h4>
                <router-link :to="'/Dprofile/' + user_id">
                  <a href="#" class="see150">See all</a>
                </router-link>
                <div class="la5lo1">
                  <div class="featured_courses owl-theme">
                    <div class="scroll">
                      <CourseEntity
                        v-for="c in purchasedCourses"
                        :key="c"
                        :id="c._id"
                        :title="c.title"
                        :file="c.thumbnailFile"
                        :category="c.category[0].name"
                        :price="c.price.$numberDecimal"
                        :firstName="c.user[0].firstName"
                        :lastName="c.user[0].lastName"
                        :userId="c.user[0]._id"
                        :discount="c.discountPercent"
                        :rate="rate"
                        :selectedCurrency="selectedCurrency"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="section3125" v-if="packages.length != 0">
                <h4 class="item_title">Bundles</h4>
                <router-link to="/Dallpackages"
                  ><a class="see150">See all</a></router-link
                >
                <div class="la5lo1">
                  <div class="Student_says owl-theme">
                    <div class="scroll">
                      <div class="item" v-for="p in packages" :key="p">
                        <router-link :to="'/Dpackage/' + p._id">
                          <div class="fcrse_4 mb-20">
                            <div class="st_group">
                              <div class="stud_img">
                                <span v-for="c in p.courses" :key="c">
                                  <img
                                    :src="publicFiles + c.thumbnailFile"
                                    v-if="p.courses.indexOf(c) < 5"
                                    alt=""
                                  />
                                </span>
                              </div>
                            </div>
                            <small style="color: grey"
                              >{{ p.courses.length }} courses</small
                            >
                            <h4>
                              By {{ p.user[0].firstName }}
                              {{ p.user[0].firstName }}
                            </h4>
                            <div
                              class="say_content"
                              style="word-wrap: break-word; margin-top: -10px"
                            >
                              <div class="tutor_cate">
                                Valid till: {{ p.date.split("T")[0] }}
                              </div>
                              <div class="tut1250" style="margin-top: -10px">
                                <span class="vdt15"
                                  >{{ p.percent }}% off
                                  {{ p.courses.length }} courses</span
                                >
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section3125 mt-50">
                <h4 class="item_title">Popular Instructors</h4>
                <router-link :to="'/Dteachers/e'">
                  <a href="" class="see150">See all</a>
                </router-link>
                <div class="la5lo1">
                  <div class="top_instrutors owl-theme">
                    <div class="scroll">
                      <div
                        class="item"
                        v-for="ins in all_Instructors"
                        :key="ins"
                      >
                        <router-link :to="'/Dprofile/' + ins.user[0]._id">
                          <div class="fcrse_1 mb-20">
                            <div class="tutor_img">
                              <ProfileImage
                                :id="ins.user[0]._id"
                                :name="ins.user[0].imageFile"
                              />
                              <!-- <a href=""><img src="./assets/images/left-imgs/img-1.jpg" alt=""></a>												 -->
                            </div>
                            <div class="tutor_content_dt">
                              <div class="tutor150">
                                <a href="" class="tutor_name"
                                  >{{ ins.user[0].firstName }}
                                  {{ ins.user[0].lastName }}</a
                                >
                                <div class="mef78" title="Verify">
                                  <i class="uil uil-check-circle"></i>
                                </div>
                              </div>
                              <Ratings
                                :id="ins.user[0]._id"
                                :type="'instructor'"
                              />
                              <div class="tutor_cate">{{ ins.expertise }}</div>
                              <ul class="tutor_social_links">
                                <li>
                                  <a href="#" class="fb"
                                    ><i class="fab fa-facebook-f"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="#" class="tw"
                                    ><i class="fab fa-twitter"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="#" class="ln"
                                    ><i class="fab fa-linkedin-in"></i
                                  ></a>
                                </li>
                                <li>
                                  <a href="#" class="yu"
                                    ><i class="fab fa-youtube"></i
                                  ></a>
                                </li>
                              </ul>
                              <div class="tut1250">
                                <span class="vdt15">{{
                                  ins.category[0].name
                                }}</span>
                                <!-- <span class="vdt15">100K Students</span>
                                                        <span class="vdt15">15 Courses</span> -->
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4">
              <div class="right_side">
                <router-link :to="'/Dprofile/' + user._id">
                  <div class="fcrse_2 mb-30">
                    <div class="tutor_img">
                      <ProfileImage :id="userId" :name="user.imageFile" />
                    </div>
                    <div class="tutor_content_dt">
                      <div class="tutor150">
                        <a href="" class="tutor_name"
                          >{{ user.firstName }} {{ user.lastName }}</a
                        >
                        <div
                          class="mef78"
                          title="Verify"
                          v-if="user.isVerified"
                        >
                          <i class="uil uil-check-circle"></i>
                        </div>
                      </div>
                      <div class="tutor_cate" v-if="isInstructor">
                        Instructor
                      </div>
                      <ul class="tutor_social_links">
                        <li>
                          <a href="#" class="fb"
                            ><i class="fab fa-facebook-f"></i
                          ></a>
                        </li>
                        <li>
                          <a href="#" class="tw"
                            ><i class="fab fa-twitter"></i
                          ></a>
                        </li>
                        <li>
                          <a href="#" class="ln"
                            ><i class="fab fa-linkedin-in"></i
                          ></a>
                        </li>
                        <li>
                          <a href="#" class="yu"
                            ><i class="fab fa-youtube"></i
                          ></a>
                        </li>
                      </ul>
                      <div class="tut1250" v-if="isInstructor">
                        <!-- <span class="vdt15">615K Students</span>
										<span class="vdt15">12 Courses</span> -->
                      </div>
                      <a href="" class="prfle12link">Go To Profile</a>
                    </div>
                  </div>
                </router-link>
                <div class="fcrse_3" v-if="isInstructor">
                  <div class="cater_ttle">
                    <h4>CREATE COURSE</h4>
                  </div>
                  <div class="live_text">
                    <div class="live_icon"><i class="uil uil-kayak"></i></div>
                    <div class="live-content">
                      <p>
                        Set up your channel and add courses for your students
                      </p>
                      <router-link :to="'/Dnewcourse'">
                        <button class="live_link">Get Started</button>
                      </router-link>
                      <span class="livinfo"
                        >Info : This feature only for 'Instructors'.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="get1452">
                  <h4>Create a membership for your business</h4>
                  <p>
                    Buy courses for a group of members in your company with
                    discount
                  </p>
                  <a href="/DregisterMembership"
                    ><button class="Get_btn">Get Started</button></a
                  >
                </div>
                <div class="fcrse_3">
                  <div class="cater_ttle">
                    <h4>Top Categories</h4>
                  </div>
                  <ul class="allcate15">
                    <li>
                      <a href="#" class="ct_item"><h4>Categories</h4></a>
                    </li>
                    <router-link
                      :to="'/Dallcourses/' + c._id"
                      v-for="c in categories"
                      :key="c"
                    >
                      <li>
                        <a href="#" class="ct_item"
                          ><i class="uil uil-arrow"></i>{{ c.name }}</a
                        >
                      </li>
                    </router-link>
                  </ul>
                </div>
                <div class="strttech120" v-if="!isInstructor">
                  <h4>Become an Instructor</h4>
                  <p>
                    Top instructors from around the world teach millions of
                    students on Faslearn. We provide the tools and skills to
                    teach what you love.
                  </p>
                  <router-link to="/DregisterTeacher"
                    ><button class="Get_btn" type="button">
                      Start Teaching
                    </button></router-link
                  >
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12">
              <div class="section3125 mt-30">
                <h4 class="item_title">Institutions</h4>
                <router-link to="/Dallinstitutions/all"
                  ><a class="see150">See all</a></router-link
                >
                <div class="la5lo1">
                  <div class="Student_says owl-theme">
                    <div class="scroll">
                      <div
                        class="item"
                        v-for="institude in allInstitutions"
                        :key="institude"
                      >
                        <router-link
                          :to="'/' + institude.handle + '/dashboard'"
                          v-if="institude.handle"
                        >
                          <div class="fcrse_4 mb-20">
                            <div
                              class="say_content"
                              style="word-wrap: break-word"
                            >
                              <p>@ {{ institude.address }}</p>
                              <Ratings
                                :id="institude._id"
                                :type="'institution'"
                              />
                            </div>

                            <div class="st_group">
                              <div class="stud_img">
                                <img
                                  :src="
                                    publicFiles + institude.institutionLogoFile
                                  "
                                  alt=""
                                />
                              </div>
                              <h4>{{ institude.institutionName }}</h4>
                            </div>
                          </div>
                        </router-link>
                        <router-link
                          :to="'/' + institude._id + '/dashboard'"
                          v-else
                        >
                          <div class="fcrse_4 mb-20">
                            <div
                              class="say_content"
                              style="word-wrap: break-word"
                            >
                              <p>@ {{ institude.address }}</p>
                              <Ratings
                                :id="institude._id"
                                :type="'institution'"
                              />
                            </div>

                            <div class="st_group">
                              <div class="stud_img">
                                <img
                                  :src="
                                    publicFiles + institude.institutionLogoFile
                                  "
                                  alt=""
                                />
                              </div>
                              <h4>{{ institude.institutionName }}</h4>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <cartegory
        style="background-color: #000"
        @close="close()"
        v-if="notSelected"
      >
        <div
          id="form"
          class="card mx-auto"
          style="border: #000 2px 2px solid; padding: 10px"
        >
          <div class="card-header text-center">
            <h2>Please select at least one area of interest</h2>
          </div>
          <div class="card-body">
            <div class="card">
              <div class="card-body">
                <div class="cat"  v-for="cat in categories" :key="cat" v-bind:class="{catActive: usersInterest.includes(cat._id)}"  @click="selectCategory(cat._id)">{{ cat.name }}</div>
              </div>
            </div>
            <button
              style="background: #c46233; border: #c46233"
              class="btn btn-primary mb-2"
              @click.prevent="submitCartegory()"
              v-if="!Loading"
            >
              Submit
            </button>
            <button
              style="background: #c46233; border: #c46233"
              class="btn btn-primary mb-2"
              v-if="Loading"
            >
              please wait ...
            </button>
            <button
              style="background: #c46233; float: right; border: #c46233"
              class="btn btn-primary mb-2"
              @click="close"
            >
              close
            </button>
          </div>
        </div>
      </cartegory>

      <Dfooter />
    </div>
    <!-- Body End -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ProfileImage from "@/components/ProfileImage";
import Cartegory from "@/components/dashboard/Cartegory.vue";
import CourseEntity from "@/components/dashboard/CourseEntity";
import Dheader from "@/components/dashboard/Header.vue";
import Dnav1 from "@/components/dashboard/Nav1.vue";
import Ratings from "@/components/dashboard/Ratings.vue";
import Dfooter from "@/components/dashboard/Footer.vue";
export default {
  name: "Dhome",
  data() {
    return {
      notSelected: false,
      Loading : false,
      usersInterest: "",
      selectedCartegory: [],
      userId: "",
      name: "",
      all_Instructors: "",
      recentCourses: "",
      allInstitutions: "",
      purchasedCourses: "",
      user: "",
      categories: "",
      isProfilePhoto: false,
      packages: "",
      rate: null,
      selectedCurrency: "",
    };
  },
  components: {
    Dheader,
    Dnav1,
    Cartegory,
    ProfileImage,
    Ratings,
    CourseEntity,
    Dfooter,
  },
  computed: {
    ...mapGetters(["isInstructor"]),
    ...mapGetters(["backEnd", "publicFiles"]),
  },
  methods: {
    ...mapActions(["check_user_instructor"]),
    ...mapActions(["fetch_recent_courses"]),
    ...mapActions(["fetch_all_institutions"]),
    ...mapActions(["fetch_purchased_courses"]),
    ...mapActions(["fetch_user"]),
    ...mapActions(["fetchAllInstructors"]),
    ...mapActions(["check_empty_image"]),
    ...mapActions(["fetch_categories"]),
    ...mapActions(["fetchAllPackages"]),
    ...mapActions(["addCategoriesToUser","fetchUserInterestCategories"]),
    changeCurrency(rate) {
      this.rate = rate;
    },
    close() {
      this.notSelected = false;
    },
    selectedCur(cur) {
      this.selectedCurrency = cur;
    },
    selectCategory(catId){
      if (this.usersInterest.includes(catId)) {
        this.usersInterest = this.usersInterest.filter(cat => cat !== catId)
      }else{
        this.usersInterest.push(catId)
      }
    },
    submitCartegory(){
      this.Loading = true
      const object = {
        userId: this.userId, 
        categories: this.usersInterest
      }
      this.addCategoriesToUser(object)
      .then(res => {
        console.log(res)
        this.Loading = false
        this.notSelected= false
      })
    }
  },
  mounted() {
    let user_id = localStorage.getItem("fmruser");
    this.userId = user_id;
    this.fetch_recent_courses(0).then((res) => {
      this.recentCourses = res.data;
    });
    this.fetch_all_institutions({ skip: 0, type: "all" }).then((res) => {
      this.allInstitutions = res.data;
    });
    this.fetch_user(user_id).then((res) => {
      this.user = res.data.user;
    });
    this.fetch_purchased_courses({ user_id, skip: 0 }).then((res) => {
      this.purchasedCourses = res.data;
    });
    this.fetchAllInstructors().then((res) => {
      this.all_Instructors = res.data;
    });
    this.check_empty_image(user_id).then((res) => {
      if (res.data.image == true) {
        this.isProfilePhoto = true;
      }
    });
    this.fetch_categories().then((res) => {
      this.categories = res.data;
      console.log(this.categories)
    });
    this.fetchAllPackages().then((res) => {
      this.packages = res.data;
    });
    this.fetchUserInterestCategories(this.userId)
    .then(res => {
      this.usersInterest = res.data.categories;
      if (this.usersInterest.length<1) {
        this.notSelected = true
      }
    })
  },
};
</script>
<style scoped>
@import url("./assets/css/style.css");
/* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
@import url("./assets/vendor/unicons-2.0.1/css/unicons.css");
@import url("./assets/css/instructor-dashboard.css");
@import url("./assets/css/vertical-responsive-menu.min.css");
@import url("./assets/css/instructor-dashboard.css");
@import url("./assets/css/instructor-responsive.css");
@import url("./assets/css/responsive.css");
@import url("./assets/css/night-mode.css");
@import url("./assets/vendor/fontawesome-free/css/all.min.css");
@import url("./assets/vendor/OwlCarousel/assets/owl.carousel.css");
@import url("./assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
@import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
@import url("./assets/vendor/semantic/semantic.min.css");

.cat{
  margin: 5px;
  display: inline-block;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
  background-color: gray;
  color: white;
}

.catActive{
  padding: 7px;
  cursor: pointer;
  border-radius: 3px;
  background-color: red;
  color: white;
}

.scroll {
  min-height: 200px;
  min-width: 1500px;
}

.live_stream {
  overflow: hidden;
}

.featured_courses {
  overflow: hidden;
}

.top_instrutors {
  overflow: hidden;
}

.Student_says {
  overflow: hidden;
}

.featured_courses .scroll {
  min-height: 200px;
  min-width: 3750px;
}

.top_instrutors .scroll {
  min-height: 200px;
  min-width: 3750px;
}

.Student_says .scroll {
  align-items: left;
  min-height: 200px;
  min-width: 3750px;
}

.item {
  width: 150px;
  display: inline-block;
  margin-left: 10px;
}

.featured_courses .item {
  width: 350px;
  display: inline-block;
  margin-left: 10px;
}

.top_instrutors .item {
  width: 350px;
  display: inline-block;
  margin-left: 10px;
}

.Student_says .item {
  width: 350px;
  display: inline-block;
  margin-left: 10px;
}

@media (max-width: 575.98px) {
  .live_stream {
    overflow-x: scroll;
  }

  .featured_courses {
    overflow-x: scroll;
  }

  .top_instrutors {
    overflow-x: scroll;
  }

  .Student_says {
    overflow-x: scroll;
  }
}

.live_stream:hover {
  overflow-x: scroll;
}

.featured_courses:hover {
  overflow-x: scroll;
}

.top_instrutors:hover {
  overflow-x: scroll;
}

.Student_says:hover {
  overflow-x: scroll;
}

::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}
</style>
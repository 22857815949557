<template>
  <div>
    <Modal>
      <object width="100%" height="500" type="application/pdf" v-bind:data="url" id="pdf_content" >
        <p>Sorry but your browser doesn't support pdf, We cant display pdf on this browser. Try using a desktop or laptop computer</p>
      </object>
    </Modal>
    <!-- Header Start -->
    <Dheader />
    <!-- Header End -->
    <!-- Left Sidebar Start -->
    <Dnav1 />
    <!-- Left Sidebar End -->
    <!-- Body Start -->
    <SkeletonLoader />

    <div class="wrapper _bg4586">
      <div class="_215b01">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="section3125">
                <div class="row justify-content-center">
                  <div class="col-xl-4 col-lg-5 col-md-6">
                    <div class="preview_video">
                      <a
                        href="#"
                        class="fcrse_img"
                        data-toggle="modal"
                        data-target="#videoModal"
                      >
                        <img
                          :src = publicFiles+book.thumbnailFile
                          width="500"
                          height="200"
                          alt=""
                        />
                      </a>
                    </div>
                    <!-- <div class="_215b10" v-if="book != ''">
                      <span v-if="rate">
                        <a href="#" class="_215b11">
                          <span>
                            <i class="uil uil-money-bill"></i>
                          </span>
                          <span v-if="amount != 0 && amountGotten" >
                            <span v-if="selectedCurrency==='NGN'">
                              ₦{{
                                (amount*parseFloat(rate)).toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }}
                            </span>
                            <span v-if="selectedCurrency==='USD'">
                              ${{
                                (amount*parseFloat(rate)).toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }}
                            </span>
                            <span v-if="selectedCurrency==='GBP'"> 
                              £{{
                                (amount*parseFloat(rate)).toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }}
                            </span>
                          </span>
                          <span v-else>FREE</span>
                        </a>
                        <a
                          href="#"
                          class="_215b12"
                          v-if="amount != 0 && amountGotten"
                        >
                          <span><i class="uil uil-windsock"></i></span>
                          <Discount
                            v-if="amountGotten"
                            :id="bookId"
                            :price="amount"
                            :discount="discount"
                            :rate = rate
                            :selectedCurrency="selectedCurrency"
                          />
                        </a>
						          </span>
						          <span v-else>
							          <a href="#" class="_215b11">
                          <span><i class="uil uil-money-bill"></i></span>
                          <span v-if="amount != 0 && amountGotten">
                            ₦{{
                              amount
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}
                          </span>
                          <span v-else>FREE</span>
                        </a>
                        <a
                          href="#"
                          class="_215b12"
                          v-if="amount != 0 && amountGotten"
                        >
                          <span><i class="uil uil-windsock"></i></span>
                          <Discount
                            v-if="amountGotten"
                            :id="bookId"
                            :price="amount"
                            :discount="discount"
                          />
                        </a>
						          </span>
                    </div> -->
                  </div>
                  <div class="col-xl-8 col-lg-7 col-md-6">
                    <div class="_215b03">
                      <h2>{{ book.title }}</h2>
                      <span class="_215b04">{{ book.secondTitle }}</span>
                    </div>
                    <div class="_215b03">
                      <span class="_215b04">Publisher {{book.publisher}}</span>
                    </div>
                    <div class="_215b03">
                      <span class="_215b04">Year {{book.year}}</span>
                    </div>
                    <!-- <div class="_215b05">
                      {{ AllPurchased }} students enrolled
                    </div> -->
                    <div class="_215b06">
                      <div class="_215b07">
                        <span><i class="uil uil-comment"></i></span>
                        English 
                      </div>
                      <div class="_215b08">
                        <span><i class="uil uil-closed-captioning"></i></span>
                      </div>
                    </div>
                    <!-- <div
                      class="_215b05"
                      v-if="book.updatedAt != '' && book.updatedAt != null"
                    >
                      Last updated : {{ book.updatedAt.split("T")[0] }}
                    </div> -->
                   
                    <ul class="_215b31">
                      
                      <a v-if="!purchased"><li><button class="btn_adcart" id="paymentForm" @click="buyNow()">Buy Now</button></li></a>
                      <a @click.prevent="activateModal()" v-else><li><button class="btn_buy" id="paymentForm" >Read now</button></li></a>
                      
                    </ul>
                    <!-- <ul class="_215b31" v-if="amount == 0 && amountGotten">
                      <a><li><button class="btn_buy" id="paymentForm" @click.prevent="startLearning()">Start Learning</button></li></a>
                    </ul>   -->
								<div class="_215fgt1" v-if="book.accessType=='partly-before-purchase'">										
									<b>You can access the first section of this book for free</b>
								</div>
							</div>							
						</div>							
					</div>							
				</div>															
			</div>
		</div>
	</div>

	<div class="_215b17">
		<div class="container-fluid">
      <div class="row">
				<div class="col-lg-12">
					<div class="user_dt5">
						<div class="user_dt_left">
							<div class="live_user_dt" style="padding-top: 20px">
								<div class="user_img5">
									<ProfileImage :id="book.user[0]._id" :name="book.user[0].imageFile" />												
								</div>
								<div class="user_cntnt">
									<a href="#" class="_df7852">{{book.user[0].firstName}} {{book.user[0].lastName}}</a>
									<router-link :to="'/Dprofile/'+book.user[0]._id" v-if="book.user[0]._id!=''">
									<button class="subscribe-btn">instructor Profile</button>
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="course_tabs">
						<nav>
							<div class="nav nav-tabs tab_crse justify-content-center" id="nav-tab" role="tablist">
								<a class="nav-item nav-link" v-bind:class="{ active: tab == 'about' }" @click.prevent="switchtap('about')" aria-selected="true">About</a>
								<a class="nav-item nav-link" v-bind:class="{ active: tab == 'review' }" @click.prevent="switchtap('review')" aria-selected="false">Reviews</a>
							</div>
						</nav>						
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="course_tab_content">
						<div class="tab-content" id="nav-tabContent">
							<div class="tab-pane fade show" style="background-color: transparent" v-bind:class="{ active: tab == 'about' }" id="nav-about" role="tabpanel">
								<div class="_htg451" style="background-color: transparent">
									<div class="_htgdrt mt-35">
										<h3>Description</h3>
										<div class="_scd123">
											<div class="row">
												<div class="col-lg-6">
                          <p>{{book.about}}</p>
												</div>
											</div>
										</div>
									</div>	
                  <div class="_htgdrt mt-35">
										<h3>Preface</h3>
										<div class="_scd123">
											<div class="row">
												<div class="col-lg-6">
                          <p>{{book.preface}}</p>
												</div>
											</div>
										</div>
									</div>						
								</div>							
							</div>
							<div
                class="tab-pane fade show"
                style="background-color: transparent"
                v-bind:class="{ active: tab == 'review' }"
                id="nav-reviews"
                role="tabpanel"
              >
                <!-- <RatingsDetails
                  :id="$route.params.id"
                  :type="'book'"
                  :canContribute="true"
                  v-if="purchased"
                />
                <RatingsDetails
                  :id="$route.params.id"
                  :type="'book'"
                  :canContribute="false"
                  v-else
                /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dfooter />
    </div>
  </div>
</div> 












    <!-- <div class="wrapper _bg4586">
      <div class="container-fluid">
        <div class="row w-50 mx-auto justify-content-center">
          <div class="col-lg-12">
            <div class="row justify-content-center" >
              <img
                :src = publicFiles+book.thumbnailFile
                width="500"
                height="300"
                alt=""
              />

              <div class="course-overlay">
                <div class="badge_seller">Bestseller</div>
              </div>
            </div>
            <div class="row px-4">
              <div class="col-md-12">
                <h3 class="">{{book.title}}</h3>
              </div>
            </div>
            <div class="row px-4">
              <div class="col-md-6" v-if="book.price">
                <p>Price: ₦{{book.price.$numberDecimal}}</p>
                <p>Author:{{book.user[0].firstName}} {{book.user[0].lastName}}</p>
                <p>Language:{{book.language}}</p>
              </div>
              <div class="col-md-6">
                <button type="button" class="btn_adcart btn-lg mt-5" @click="buyNow()" v-if="!purchased">
                  Buy Now
                </button>
                <button type="button" class="btn_adcart btn-lg mt-5" @click.prevent="activateModal()" v-else>
                  Read now
                </button>
              </div>
            </div>

            <div class="row mt-3 px-4 mx-auto" v-if="book.price">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th colspan="2"><h2>About</h2></th>
                    <th><h2>Review</h2></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="2">
                      <table class="table table-borderless">
                     
                        <tbody>
                          <tr>
                            <td>Cartegory:</td>
                            <td>{{book.category[0].name}}</td>
                          </tr>
                          <tr>
                            <td>Year:</td>
                            <td>{{book.year}}</td>
                          </tr>
                          <tr>
                            <td >Publisher:</td>
                            <td>{{book.publisher}}</td>
                          </tr>
                          <tr>
                            <td>Pages:</td>
                            <td>{{book.pages}}</td>
                          </tr>
                          <tr>
                            <td>ISBN:</td>
                            <td>{{book.ISBN}}</td>
                          </tr>
                          <tr>
                            <td >Preface:</td>
                            <td>{{book.preface}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td colspan="1">
                      <div class="col-lg-5">
                        <div class="">
                            <div class="_r  ate004">
                                <div class="progress progress1">
                                    <div class="progress-bar " :style="'width: '+five+'%; background-color: red'"></div>
                                </div>
                                <div class="rating-box">
                                    <span class="rating-star full-star"></span>
                                    <span class="rating-star full-star"></span>
                                    <span class="rating-star full-star"></span>
                                    <span class="rating-star full-star"></span>
                                    <span class="rating-star full-star"></span>
                                </div>
                            </div>
                            <div class="_rate004">
                                <div class="progress progress1">
                                    <div class="progress-bar " :style="'width: '+four+'%; background-color: red'"></div>
                                </div>
                                <div class="rating-box">
                                    <span class="rating-star full-star"></span>
                                    <span class="rating-star full-star"></span>
                                    <span class="rating-star full-star"></span>
                                    <span class="rating-star full-star"></span>
                                    <span class="rating-star empty-star"></span>
                                </div>
                            </div>
                            <div class="_rate004">
                                <div class="progress progress1">
                                    <div class="progress-bar " :style="'width: '+three+'%; background-color: red'"></div>
                                </div>
                                <div class="rating-box">
                                    <span class="rating-star full-star"></span>
                                    <span class="rating-star full-star"></span>
                                    <span class="rating-star full-star"></span>
                                    <span class="rating-star empty-star"></span>
                                    <span class="rating-star empty-star"></span>
                                </div>
                            </div>
                            <div class="_rate004">
                                <div class="progress progress1">
                                    <div class="progress-bar " :style="'width: '+two+'%; background-color: red'"></div>
                                </div>
                                <div class="rating-box">
                                  <span class="rating-star full-star"></span>
                                  <span class="rating-star full-star"></span>
                                  <span class="rating-star empty-star"></span>
                                  <span class="rating-star empty-star"></span>
                                  <span class="rating-star empty-star"></span>
                                </div>
                            </div>
                            <div class="_rate004">
                                <div class="progress progress1">
                                  <div class="progress-bar " :style="'width: '+one+'%; background-color: red'"></div>
                                </div>
                                <div class="rating-box">
                                    <span class="rating-star full-star"></span>
                                    <span class="rating-star empty-star"></span>
                                    <span class="rating-star empty-star"></span>
                                    <span class="rating-star empty-star"></span>
                                    <span class="rating-star empty-star"></span>
                                </div>
                            </div>
                        </div>												
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

     <br><br><br>
      <Dfooter />
    </div> -->

    <!-- Body End -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
//import paystack from "vue-paystack";
import Modal from "@/components/Modal.vue";
import Dheader from "@/components/dashboard/Header.vue";
import Dnav1 from "@/components/dashboard/Nav1.vue";
import Dfooter from "@/components/dashboard/Footer.vue";
import ProfileImage from "@/components/ProfileImage";

// import RatingsDetails from "@/components/dashboard/RatingsDetails.vue";
export default {
  name: "Dhome",
  data() {
    return {
      public_key: "pk_test_b66d0123c39e5274812de14cde47231d2011e8d9",
      book: {
        user: [{ firstName: "", lastName: "", _id: "", imageFile: null }],
      },
      tab: "about",
      instructor: "",
      paymentDetails: "",
      canBuy: false,
      bookId: "",
      userId: "",
      url: "",
      whatYouLearn: "",
      amount: 100,
      amountGotten: false,
      discount: 0,
      user: "",
      exist: false,
      purchased: false,
      error: "",
      success: false,
      Loading: false,
      outletUserId : "",
      outletKey : ""
    };
  },
  components: {
    ProfileImage,
    Dheader,
    Dnav1,
    Modal,
    Dfooter,
  },
  watch: {
    circle: function () {
      if (this.circle == "loading") {
        this.Loading = true;
      } else {
        this.Loading = false;
      }
    },
  },
  computed: {
    ...mapGetters(["isInstructor"]),
    ...mapGetters(["circle"]),
    ...mapGetters(['publicFiles']),
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },
  methods: {
    ...mapActions(["check_user_instructor"]),
    ...mapActions(["viewBook"]),
    ...mapActions(["fetch_user"]),
    ...mapActions(["verifyBookPurchase","verifyOutlet","generate_V4ReadSignedUrl"]),

    switchtap(tab) {
      this.tab = tab
    },

    buyNow() {
      if (this.paymentDetails == "") {
        alert("please wait for minuite and try again");
        return;
      }
      let object = {
        price: this.book.price.$numberDecimal,
        paymentDetails: this.paymentDetails,
        email: this.user.email,
        title: this.book.title,
        id: this.book._id,
        membershipId: null,
      };

      localStorage.setItem("fmrbookcart", JSON.stringify(object));
      let str = window.location.href + "";
      const myArr = str.split("/Dboo");
      window.location.href = myArr[0] + "/Dbook/buy.html";
    },
    activateModal(){
      this.generate_V4ReadSignedUrl(this.book.file).then((res) => {
        this.url = res.data;
        let modal = document.getElementById('myModal');
        let modalBody = document.getElementById('modal-content-body');
        modal.setAttribute("class", "modal vd_mdl fade show");
        modal.style.display = 'block';
        modalBody.innerHTML = `<object width="100%" height="500"  data="`+this.url+'#zoom='+100+'&scrollbar=0&toolbar=0&navpanes=0'`" id="pdf_content" >
            <p>Sorry but your browser doesn't support pdf, We cant display pdf on this browser. Try using a desktop or laptop computer</p>
        </object>`
      });
    },
    // buyNowForMembers(price, membershipId) {
    //   if (this.paymentDetails == "") {
    //     alert("please wait for minuite and try again");
    //     return;
    //   }
    //   let object = {
    //     price: price,
    //     paymentDetails: this.paymentDetails,
    //     email: this.user.email,
    //     title: this.book.title,
    //     id: this.book._id,
    //     membershipId: membershipId,
    //   };
    //   //console.log(object)
    //   localStorage.setItem("fmrbookcart", JSON.stringify(object));
    //   //localStorage.setItem("fmrlastreference", 103434038);
    //   let str = window.location.href + "";
    //   const myArr = str.split("/Dboo");
    //   window.location.href = myArr[0] + "/Dbook/buy.html";
    // },
  },
  mounted() {
    this.bookId = this.$route.params.id;
    this.userId = localStorage.getItem("fmruser");
    this.outletUserId = this.$route.query.outletUserId;
    this.outletKey = this.$route.query.key;
    // let object = {
    //   bookId: this.bookId,
    //   userId: this.userId,
    // };
    this.viewBook(this.bookId)
      .then((res) => {
        this.book = res.data.book;
        let userObject = {
          userId: this.userId,
          bookId: this.bookId,
        };
        const userBookObject = this.book.user[0]
        if(this.outletUserId && this.outletKey && userBookObject){
          if(this.outletUserId == userBookObject._id){
            console.log("checked")
            this.verifyOutlet({userId: this.outletUserId, key: this.outletKey})
            .then(res=>{
              this.outlet = res.data
              if(!this.outlet){
                this.purchased = false
              } else if (new Date(this.outlet.expire).getTime() < new Date().getTime()) {
                this.purchased = false
              } else {
                this.purchased = true
              }
            })
          } else {
            console.log("unauthorized")
          }
        } else {
          this.verifyBookPurchase(userObject)
          .then(res => {
            this.purchased = res.data.isPurchased
          })
        }
        this.amount = this.book.price;
        if (this.amount == 0) {
          this.purchased = true;
        }
        this.amountGotten = true;
        this.discount = this.book.discountPercent;
        this.instructor = res.data.instructor;
        this.paymentDetails = res.data.paymentDetails;
        if (
          this.paymentDetails.bankDetails.subaccount_code == "false" ||
          this.paymentDetails.bankDetails.subaccount_code == false ||
          this.paymentDetails.bankDetails.subaccount_code == null ||
          this.paymentDetails.bankDetails.subaccount_code == ""
        ) {
          this.canBuy = false;
        } else {
          this.canBuy = true;
        }
        
      })
      .catch((err) => {
        console.log(err);
      });
    this.fetch_user(this.userId)
      .then((res) => {
        this.user = res.data.user;
      })
      .catch((err) => {
        console.log(err);
      });


  },
};
</script>
<style scoped>
@import url("./assets/css/style.css");
/* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
@import url("./assets/vendor/unicons-2.0.1/css/unicons.css");
@import url("./assets/css/instructor-dashboard.css");
@import url("./assets/css/vertical-responsive-menu.min.css");
@import url("./assets/css/instructor-dashboard.css");
@import url("./assets/css/instructor-responsive.css");
@import url("./assets/css/responsive.css");
@import url("./assets/css/night-mode.css");
@import url("./assets/vendor/fontawesome-free/css/all.min.css");
@import url("./assets/vendor/OwlCarousel/assets/owl.carousel.css");
@import url("./assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
@import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
@import url("./assets/vendor/semantic/semantic.min.css");

.nav-link {
  cursor: pointer;
}

.liked {
  background-color: yellowgreen;
  border: 2px solid yellowgreen;
}

.likeButton {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  padding: 10px;
}

.liking {
  width: 60px;
  height: 60px;
  display: inline-block;
  margin: 5px;
  padding-top: 10px;
}

.darkest {
  background-color: black;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0px;
}

.takecourse {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: rgb(231, 69, 40);
  border-radius: 10px;
  color: white;
}

.closed {
  display: none;
}
.open {
  display: block;
}
</style>
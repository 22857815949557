<template>
  <div>
    <Header />

    <div class="content-wrapper">
      <div class="container-fluid">
        <!-- Breadcrumbs-->
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Dashboard</a>
          </li>
          <li class="breadcrumb-item active">Your Courses</li>
        </ol>
        <div class="box_general">
          <div class="header_box">
            <h2 class="d-inline-block">Your Posts</h2>
          </div>
          <div class="bigspinner" v-if="Loading">
            <div class="smallspiner"></div>
          </div>
          <div class="box_general">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <!-- <form > -->
                  <div style="margin-bottom: 40px">
                    <div class="form-group">
                      <label for="">Title</label>
                      <input
                        v-model="title"
                        type="text"
                        class="form-control"
                        name="title"
                        placeholder="Title"
                      />
                    </div>
                    <div class="form-group">
                      <label> Title Image </label>
                      <div class="input-group">
                        <span class="input-group-btn">
                          <span class="btn btn-file">
                            <span
                              class="btn text-light"
                              v-if="titleImage == ''"
                              style="background: #ef6421"
                            >
                              Select
                            </span>

                            <input type="file" ref="thumbnail" @change="handleFileUpload" />
                            <br />
                            <!-- <img :src="titleImage" /> -->
                            <figure class="titleImage">
                              <img class="titleImage"  v-bind:src="'https://image.pngaaa.com/768/791768-middle.png'" id="thumbnailPreview" alt="">
                            </figure>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div class="form-group">

                      <Errors v-if="errors.length>0">
                        <ul>
                          <li v-for="error in errors" :key="error">{{error}}</li>	
                        </ul>
                      </Errors>
                      <br>
                      <router-link :to="{ path: '/SAeditPost/'+ post._id}">
                        <Success v-if="Success">
                          <ul>
                            <li>Action Successful</li>
                            <li>Go ahead and add post contents</li>	
                            <button  class="btn my-4" type="button" style="background-color: #006400" >Click here to add post contents</button>
                          </ul>
                          <p></p>
                        </Success>
                      </router-link>
                      <button
                        class="btn my-4"
                        style="color: #fff; background:#ef6421"
                        @click="createBlogPost"
                        v-if="!Loading&&!Success"
                      >
                        + Create Blog Post
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>

          <!-- <component :is="currentTab"></component> -->
        </div>
      </div>
      <!-- /container-fluid-->
    </div>
    <!-- /.container-wrapper-->
    <Footer />
    <!-- Scroll to Top Button-->
    <a class="scroll-to-top rounded" href="#page-top">
      <i class="fa fa-angle-up"></i>
    </a>
    <!-- Logout Modal-->
    <Modal />
  </div>
</template>
<script>
import Errors from '@/components/Errors.vue'
import Success from '@/components/Success.vue'
import Header from "@/components/superAdmin/Header.vue";
import Modal from "@/components/superAdmin/Modal.vue";
import Footer from "@/components/superAdmin/Footer.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      courses: "",
      post: "",
      skip: 0,
      title: "",
      type: "unverified",
      Loading: false,
      Success: false,
      isFilePresent: false,
      currentTab: "AddBlogPost",
      thumbnail: '',
      errors: []
    };
  },
  components: {
    Header,
    Modal,
    Errors,
    Success,
    Footer,
  },
  computed: {
    ...mapGetters(["backEnd"]),
  },
  methods: {
    ...mapActions(['add_blogPost']),
    createBlogPost(){
      this.Success = false
      this.Loading = true
      this.errors = []
      let formData = new FormData(); 
      formData.append('adminId', localStorage.getItem('fmrsuperadmin'));
      formData.append('title', this.title);
      formData.append('file', this.thumbnail);
      formData.append('isFilePresent', this.isFilePresent);
      this.add_blogPost(formData)
        .then(res => {
          this.Loading = false
          this.post = res.data.blogPost
          this.Success = true
        })
        .catch(err => {
          this.errors.push("Something went wrong")
          console.log(err)
        })
    },
    handleFileUpload(e){
			if (this.validateFile(e.target.files[0])) {
				this.thumbnail = e.target.files[0];
        this.isFilePresent = true
				const file = e.target.files[0];
				const previewImage = document.getElementById('thumbnailPreview');
				if (file) {
					const reader = new FileReader();
					reader.addEventListener("load", function () {
						previewImage.setAttribute('src', this.result);
					});
					reader.readAsDataURL(file);
				}
				//document.getElementById('thumbnaildisplay').innerHTML = this.thumbnail
			}
		},
    validateFile(file){
      let flag = true;
      let message = '';
      let error =[];
      const allowedExtensions =  ['jpg','png','jpeg','JPG','PNG','JPEG'];
      if (!allowedExtensions.includes(file.name.split(".").pop())) {
        message = 'Please insert a valid image file';
        error.push(message)
        flag = false
      }
      if (file.size > 1000000) {
        message = 'Please insert smaller size image less than 1mb and not '+file.size;
        error.push(message)
        flag = false
      }
      if (flag == true) {
        console.log('yes')
        this.errors = []
        return true
      }else{
        console.log('no')
        this.errors = error
        return false
      }
		},
    handleFile(e){
      this.file = e.target.files[0]
      this.isFilePresent = true
    },
  },
  mounted() {

  },
};
</script>
<style scoped>
@import url("./assets/css/admin.css");
@import url("./assets/css/custom.css");
@import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
@import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
@import url("./assets/vendor/bootstrap/css/bootstrap.min.css");

.tabButton {
  color: white;
  background-color: grey;
  border-radius: 10px;
  padding: 5px;
  display: inline;
  margin-left: 10px;
  cursor: pointer;
}

.active {
  background-color: red;
}

.titleImage{
  width: 100%;
  height: 200px;
}

.bigspinner {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border-top: 5px solid grey;
  display: inline-block;
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.saymessage {
  display: inline-block;
  margin-left: 10px;
}
.smallspiner {
  height: 30px;
  width: 30px;
  margin: 5px;
  border-radius: 50%;
  border-top: 5px solid grey;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

input[readonly] {
  background-color: white !important;
  cursor: text !important;
}

/* this is only due to codepen display don't use this outside of codepen */
.container {
  padding-top: 20px;
}
</style>
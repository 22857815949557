<template>
    <div>
    <!-- Header Start -->
    <Dheader />
	<!-- Header End -->
	<!-- Left Sidebar Start -->
    <Dnav1 />
	<!-- Left Sidebar End -->
	<!-- Body Start -->
	<div class="wrapper" >
		<div class="sa4d25">
			<div class="container-fluid">			
				<div class="row" v-if="isPurchased||course.accessType=='partly-before-purchase'">
					<div class="col-xl-8 col-lg-8" >
						<!-- v-if="isPurchased||" -->
						<div class="section3125" v-if="isPurchased||freePlaylist._id==lecture.playlist[0]">
							
                            <div class="live1452" v-if="lecture.fileType=='application/pdf'">
                                <!-- <embed v-bind:src="backEnd+'student/material/'+lecture._id" width="100%" height="500px" /> -->
                                <!-- <object width="100%" height="500" type="application/pdf" v-bind:data="backEnd+'student/material/'+lecture._id+'#zoom=40&scrollbar=0&toolbar=0&navpanes=0'" id="pdf_content" style="pointer-events: none;">
                                    <p>Sorry but your browser doesn't support pdf, We cant display pdf on this browser. Try using a desktop or laptop computer</p>
                                </object> -->
								<!-- <object width="100%" height="500" type="application/pdf" v-bind:data="backEnd+'student/material/'+lecture._id" id="pdf_content">
                                    <p>Sorry but your browser doesn't support pdf, We cant display pdf on this browser. Try using a desktop or laptop computer</p>
                                </object> -->
								<object width="100%" height="500" type="application/pdf" :data="url+'#zoom='+zoom+'&scrollbar=0&toolbar=0&navpanes=0'" id="pdf_content">
                                    <p>Sorry but your browser doesn't support pdf, We cant display pdf on this browser. Try using a desktop or laptop computer</p>
                                </object>
                            </div>
							<div class="live1452" style="width: 100%; min-height: 350px; opacity: 1; "  v-if="lecture.fileType.startsWith('video/')">
								<div><video id="videoPlayer" :src="url" width="640" height="360" style="opacity: 1" controls controlsList="nodownload"></video></div>
								
								<!-- <div id="subtitleContainer" ></div> -->
							</div>
							<div class="live1452" style="width: 100%; min-height: 400px; opacity: 1; background-color: grey"  v-if="lecture.fileType=='embedLink'">
								<iframe :src="lecture.embedLink+'#zoom=85&scrollbar=0&toolbar=0&navpanes=0'" style="min-height: 360px width=100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
							</div>
							<div class="user_dt5">
								<div class="fcrse_3">
									<ul class="allcate15">
										<div id="subtitleContainer"  v-if="lecture.fileType.startsWith('video/')"></div>
									</ul>
								</div>
								<div class="user_dt_left">
									<div class="live_user_dt">
										<div class="user_img5">
											<!-- <img src="../assets/images/left-imgs/img-4.jpg" alt="">	 -->
											<ProfileImage v-if="course.user" :id="course.user[0]._id" :name="course.user[0].imageFile" />												
										</div>
										<div class="user_cntnt">
											<h4>{{lecture.title}}</h4>
											<router-link :to="'/Dcoursedetail/'+courseId">
												<button class="subscribe-btn">Go back to Course</button>
											</router-link>
										</div>
									</div>
								</div>
								<div class="user_dt_right">
									<ul>
										<li>
											<a class="lkcm152"><i class='uil uil-eye'></i><span> {{log}}</span></a>
										</li>
										<li>
											<a class="lkcm152"><i class='uil uil-thumbs-up'></i><span>{{allLikes.like}}</span></a>
										</li>
										<li>
											<a class="lkcm152"><i class='uil uil-thumbs-down'></i><span>{{allLikes.dislike}}</span></a>
										</li>
										<li>
											<a class="lkcm152"><i class='uil uil-star'></i><span>{{avrating}}</span></a>
										</li>
									</ul>
								</div>
							</div>
							
							
						</div>
						<div class="section3125" v-else>
							
                            <div class="live1452" >
								<Errors >
									<h1 style="margin-top: 100px; margin-bottom: 100px;">Purchase the course to take this lecture</h1>
								</Errors>
                            </div>
							<div class="user_dt5">
								<div class="user_dt_left">
									<div class="live_user_dt">
										<div class="user_img5">
											<!-- <img src="../assets/images/left-imgs/img-4.jpg" alt="">	 -->
											<router-link :to="'/Dprofile/'+course.user[0]._id">
												<ProfileImage :id="course.user[0]._id" :name="course.user[0].imageFile" />
											</router-link>											
										</div>
										<div class="user_cntnt">
											<h4>{{lecture.title}}</h4>
											<p>By {{course.user[0].firstName}} {{course.user[0].lastName}}</p>
											<router-link :to="'/Dcoursedetail/'+courseId">
												<button class="subscribe-btn">Go back to Course</button>
											</router-link>
										</div>
									</div>
								</div>
								<div class="user_dt_right">
									<ul>
										<li>
											<a class="lkcm152"><i class='uil uil-eye'></i><span> {{log}}</span></a>
										</li>
										<li>
											<a class="lkcm152"><i class='uil uil-thumbs-up'></i><span>{{allLikes.like}}</span></a>
										</li>
										<li>
											<a class="lkcm152"><i class='uil uil-thumbs-down'></i><span>{{allLikes.dislike}}</span></a>
										</li>
										<li>
											<a class="lkcm152"><i class='uil uil-star'></i><span>{{avrating}}</span></a>
										</li>
									</ul>
								</div>
							</div>
							
							
						</div>							
					</div>
					<div class="col-xl-4 col-lg-4">
						<div class="right_side">							
							<div class="fcrse_3">
								<div class="cater_ttle">
									<h4>Questions</h4>
								</div>
								<div class="live_chat">
									<div class="chat1" v-if="lectureQuestions==''">
										<div class="questionbox">
											<center>
												<p class="question">No questions asked on this lecture yet</p>
												<p class="question">Feel free to ask a question</p>
											</center>
										</div>
									</div>
									<div class="chat1" v-else>
										<div class="questionbox" v-for="l in lectureQuestions" :key="l">
											<p class="question"><a>{{l.user[0].firstName}} {{l.user[0].lastName}} </a>{{l.question}}</p>
											<p class="respond"><a>Answer</a>{{l.respond}}</p>
										</div>
									</div>
									<div class="questionbox" v-if="success">
										<p class="question" style="color: green">Your question was sent but no one can see it untill the instructor answers it</p>
									</div>
								</div>
								<div class="live_comment">
									<input class="live_input" type="text" placeholder="Ask a question" v-model="question" />
									<button class="btn_live" @click.prevent="askQuestion" :disabled="question==''"><i class='uil uil-message'></i></button>
								</div>
							</div>													
						</div>
					</div>
					<div class="col-md-12">
						<div class="section3125 mb-15 mt-20">
							<!-- <a href="live_streams.html" class="see150">See all</a> -->
							<h5 class="item_title">Other lectures in same section</h5>
							<div class="la5lo1">
								<div class="live_stream owl-theme">
                                    <div class="scroll">
                                        <div class="item" v-for="lecture in otherLectures" :key="lecture">
                                            <div class="stream_1">
                                                <a @click="switchLecture(lecture._id)" style="cursor: pointer" class="stream_bg" v-bind:class="{ nowplaying: lecture._id == lectureId }">
                                                    <img src="../assets/images/left-imgs/img-9.jpg" alt="">
                                                    <h4 v-bind:class="{ nowplayingtext: lecture._id == lectureId }">{{lecture.title}}</h4>
                                                    <p v-bind:class="{ nowplayingtext: lecture._id == lectureId }">{{lecture.fileType}}<!-- <span></span> --></p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
								</div>
							</div>
						</div>
						<div class="section3125 mb-15 mt-20">
							<h5 class="item_title">Other Sections</h5>
							<div class="la5lo1" >
								<div class="live_stream owl-theme">
                                    <div class="scroll">
                                        <div class="item" v-for="p in playlist" :key="p">
                                            <div class="stream_1">
                                                <a class="stream_bg" style="cursor: pointer" @click="switchPlaylist(p.id)" v-bind:class="{ nowplaying: p.id == playlistId }">
                                                    <h4 v-bind:class="{ nowplayingtext: p.id == playlistId }">{{p.sectionTitle}}</h4>
                                                    <p v-bind:class="{ nowplayingtext: p.id == playlistId }">{{p.videos.length}} lectures</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
								</div>
							</div>
						</div>
					</div>					
				</div>
			</div>
		</div>
		<Dfooter />
	</div>
	<!-- Body End -->
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import Dheader from '@/components/dashboard/Header.vue'
import Dnav1 from '@/components/dashboard/Nav1.vue'
import Dfooter from '@/components/dashboard/Footer.vue'
import Errors from '@/components/Errors.vue'
import ProfileImage from "@/components/ProfileImage";
//import { createApp } from 'vue'

export default {
    name: 'DlectureRoom',
    data() {
        return{
            lectureId: '',
            lecture: '',
            next: '',
            previous: '',
            courseId: '',
			course: '',
			videoId: '',
			playlistId: '',
			otherLectures: '',
			lectureQuestions: '',
			userId: '',
			question: '',
			url: '',
			subtitleData: '',
			success: false,
			isPurchased: false,
			alreadyReviewed: true,
            reviews: '',
            stars: '',
            avrating: '',
			allLikes: '',
			seen: '',
			zoom: 80,
			modalAllowed: false,
			log: 0,
			freePlaylist: '',
			playlist: '',
			outletUserId : null,
      		outletKey : null,
        }
	},
	watch: {
		$route() {
		// react to route changes...
			this.lectureId = this.$route.params.id
		
		},
 
		lectureId: function() {
      		this.recieve_lectures(this.lectureId)
        	.then(res => {
				this.lecture = res.data.lecture
				let l = this.lecture.uri+''
				let l1 = l.split('/')
				this.videoId = l1[2]
				this.next = res.data.nextLecture
				this.previous = res.data.previousLecture
				this.courseId = res.data.courseId
				this.playlistId = res.data.lecture.playlist[0]
				//console.log('top '+this.playlistId)

				if (this.lecture.fileType!='embedLink') {
					// this.generate_V4ReadSignedUrl(this.lecture.uri)
					// .then(res => {
					// 	this.url = res.data
					// })
					this.fetchLecturesURLs(this.lecture._id)
					.then(res => {
						this.url = res.data.fileURL
						this.subtitleData = res.data.subtitleData 
						// Call the function to fetch and display subtitles
						this.fetchAndDisplaySubtitles();
						//console.log(res.data)
					})
				}

				let logObjecte = {
					user: this.userId, 
					lecture: this.lectureId, 
					playlist: this.playlistId, 
					course: this.courseId
				}
				this.manage_LectureLog(logObjecte)
				.then(res => {
					console.log(res)
					let fetchLog = {
						user: this.userId, 
						id: this.lectureId, 
						type: 'lecture'
					}
					this.fetch_UserLectureLog(fetchLog)
					.then(res => {
						this.log = 0
						for (let i = 0; i < res.data.log.length; i++) {
							const element = res.data.log[i];
							this.log = this.log + element.frequency
						}
					})
					.catch(err => {
						console.log(err)
					})
				})
				.catch(err => {
					console.log(err)
				})
			})
			.catch(err => {
				console.log(err)
			})

			this.fetch_lecture_Questions(this.lectureId)
			.then(res => {
				this.lectureQuestions = res.data
				this.question = ''
				this.success= false
			})


		},
		

	},
    components: {
		Dnav1,
		Dheader,
		Errors,
		ProfileImage,
        Dfooter
    },
    computed: {
		...mapGetters(['isInstructor']),
		...mapGetters(['backEnd'])
    },
    methods: {
        ...mapActions([
			'fetchplaylistlectures',
			'verify_course_payment',
			'check_UserPurchaseOnMembership',
			'fetchFreeCoursePlaylist',
			'fetch_course_details',
			'verifyOutlet'
		]),
		...mapActions(['recieve_lectures']),
		...mapActions(['generate_V4ReadSignedUrl','fetchLecturesURLs']),
		...mapActions(['Ask_Question']),
		...mapActions(['fetch_lecture_Questions']),
		...mapActions(['fetchReviewedPublic']),
		...mapActions(['checkTotalSee']),
		...mapActions(['manage_LectureLog','fetch_UserLectureLog']),
		askQuestion(){
			let object = {
				user : this.userId,
				lecture : this.lectureId,
				course : this.courseId[0],
				question: this.question
			}
			this.Ask_Question(object)
			.then(res => {
				this.lectureQuestions = res.data
				this.question = ''
				this.success = true
			})
		},
        checkAveageStar(){
            let total = 0;
            let answer = 0;
            if (this.stars.length == 0) {
                answer = 0;
            }else{
                for (let i = 0; i < this.stars.length; i++) {
                    const element = this.stars[i];
                    total = element.star + total
                }
                answer = total/this.stars.length;
            }
            return answer.toFixed(1);
        },
        countlikes(){
            let total = { like : 0, dislike : 0};
            for (let i = 0; i < this.stars.length; i++) {
                const element = this.stars[i];
                if (element.like == 1) {
                    total.like++
                }else{
                    total.dislike++
                }
            }
            return total;
        },
        fetchingReviews(){
            let reviewObject= { 
				id : this.courseId,
				type : 'course'
			}
			this.fetchReviewedPublic(reviewObject)
			.then(res => {
				this.alreadyReviewed = res.data.alreadyReviewed
				this.reviews = res.data.Reviews
				this.stars = res.data.stars
                this.avrating = this.checkAveageStar()
                this.allLikes = this.countlikes()
			})
		},
		zoomfunc(type){
			if (type == 'in') {
				this.zoom = this.zoom+10
			}else{
				this.zoom = this.zoom-10
			}
		},
		FullScreen(){
			this.modalAllowed = true
			let modal = document.getElementById('myModal');
            modal.setAttribute("class", "modal vd_mdl fade show");
            modal.style.display = 'block';
		},
		switchLecture(lectureId){
			this.lectureId = lectureId
		},
		switchPlaylist(playlistid){
			for (let i = 0; i < this.playlist.length; i++) {
				const p = this.playlist[i];
				if (p.id == playlistid) {
					this.playlistId = playlistid
					this.otherLectures = p.videos
					if (this.otherLectures.length>0) {
						this.lectureId = this.otherLectures[0]._id
					}
				}
			}
		},

		// Function to fetch and parse subtitle file
		async fetchAndDisplaySubtitles () {
			try {
				const video = document.getElementById('videoPlayer');
				
				video.addEventListener('timeupdate', () => {
					this.displaySubtitles(this.subtitleData, video.currentTime);
				});
			} catch (error) {
				console.log('Error fetching subtitles:', error);
			}
		},

		displaySubtitles(subtitleData, currentTime) {
			// Function to display subtitles at the specified time
			// Clear previous subtitles
			
  			const subtitleContainer = document.getElementById('subtitleContainer');
			subtitleContainer.innerHTML = '';

			// Find the subtitle to display at the current time
			const subtitle = subtitleData.find(sub => currentTime >= sub.start && currentTime <= sub.end);

			if (subtitle) {
				// Create a new subtitle element
				const subtitleElement = document.createElement('p');
				subtitleElement.textContent = subtitle.text;

				// Display the subtitle
				subtitleContainer.appendChild(subtitleElement);
			}
		}

    },  
    mounted(){
		this.lectureId = this.$route.params.id
		this.userId = localStorage.getItem('fmruser');
		this.outletUserId = this.$route.query.outletUserId;
    	this.outletKey = this.$route.query.key;
        //let user_id = localStorage.getItem('fmruser');
        "use strict";var tid=setInterval(function(){if("complete"===document.readyState){clearInterval(tid);var a=document.querySelector.bind(document),b=document.querySelector(".vertical_nav"),c=document.querySelector(".wrapper"),d=document.getElementById("js-menu"),e=d.querySelectorAll(".menu--item__has_sub_menu");a(".toggle_menu").onclick=function(){b.classList.toggle("vertical_nav__opened"),c.classList.toggle("toggle-content")},a(".collapse_menu").onclick=function(){b.classList.toggle("vertical_nav__minify"),c.classList.toggle("wrapper__minify");for(var a=0;a<e.length;a++)e[a].classList.remove("menu--subitens__opened")};for(var f=0;f<e.length;f++)e[f].classList.contains("menu--item__has_sub_menu")&&e[f].querySelector(".menu--link").addEventListener("click",function(a){for(var b=0;b<e.length;b++)a.target.offsetParent!=e[b]&&e[b].classList.remove("menu--subitens__opened");a.target.offsetParent.classList.toggle("menu--subitens__opened")},!1)}},100);
        this.recieve_lectures(this.lectureId)
        .then(res => {
            this.lecture = res.data.lecture
            let l = this.lecture.uri+''
            let l1 = l.split('/')
            this.videoId = l1[2]
            this.next = res.data.nextLecture
            this.previous = res.data.previousLecture
			this.courseId = res.data.courseId
			this.playlistId = res.data.lecture.playlist[0]
			//console.log('top '+this.playlistId)
			if (this.lecture.fileType!='embedLink') {
				
				this.fetchLecturesURLs(this.lecture._id)
				.then(res => {
					this.url = res.data.fileURL
					this.subtitleData = res.data.subtitleData 
					// Call the function to fetch and display subtitles
					this.fetchAndDisplaySubtitles();
				})
			}

			this.fetchFreeCoursePlaylist(this.courseId)
			.then(res => {
				this.freePlaylist = res.data.playlists[0]
			})
			
			this.fetchplaylistlectures(this.playlistId)
			.then(res => {
				this.otherLectures = res.data
			})
			
			let userObject = {
				userId : this.userId,
				courseId : this.courseId
			}

			if(this.outletUserId && this.outletKey){
				this.fetch_course_details(userObject)
				.then((res) => {
					this.playlist = res.data.modules;
					this.course = res.data.course;
					const userCourseObjectLecture = this.course.user[0]
					if(this.outletUserId == userCourseObjectLecture._id){
						this.verifyOutlet({userId: this.outletUserId, key: this.outletKey})
						.then(res=>{
						this.outlet = res.data
						if(!this.outlet){
							this.isPurchased  = false
						} else if (new Date(this.outlet.expire).getTime() < new Date().getTime()) {
							this.isPurchased  = false
						} else {
							this.isPurchased  = true
						}
						})
					} else {
						console.log("unauthorized")
					}
				})
            } else {
				this.verify_course_payment(userObject)
				.then(res => {
					this.isPurchased = res.data.isPurchased
					let course = res.data.course
					if (course.price.$numberDecimal == 0) {
						this.isPurchased = true
					}
					if (!this.isPurchased) {
						this.check_UserPurchaseOnMembership(userObject).then((res) => {
						this.isPurchased = res.data.purchased;
					});
				}
				})

				this.fetch_course_details(userObject)
				.then((res) => {
					this.playlist = res.data.modules;
					this.course = res.data.course;
				})
			}

			this.fetchingReviews()

			this.checkTotalSee(this.courseId)
			.then(res => {
				//this.created = res.data.timeCreated
				this.seen = res.data.totalPurchased
			})

			
        })
        .catch(err => {
            console.log(err)
		})
		this.fetch_lecture_Questions(this.lectureId)
		.then(res => {
			this.lectureQuestions = res.data
		})

		
    }
}
</script>
<style scoped>
    @import url("../assets/css/style.css");
    /* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
    @import url("../assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/vertical-responsive-menu.min.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/instructor-responsive.css");
    @import url("../assets/css/responsive.css");
    @import url("../assets/css/night-mode.css");
    @import url("../assets/vendor/fontawesome-free/css/all.min.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("../assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("../assets/vendor/semantic/semantic.min.css");

	#subtitleContainer{
		width: 100%; 
		padding: 10px;
		border-radius: 5px;
		margin-bottom: 10px;
	}

	#subtitleContainer::-webkit-scrollbar {
		width:4px;
	}
	#subtitleContainer::-webkit-scrollbar-thumb {
		height: 50px;
		background: hsla(0, 0%, 53.3%, 0.4);
	}
   
    .scroll{
		min-width: 1500px;
	}

	.nowplaying{
		background-color: #e42f2b;
		font-weight: bold;
		font-size: 15px;
	}
	.nowplayingtext{
		color: white;
		font-weight: bold;
		font-size: 15px;
	}
	.live_stream{
		overflow: hidden;
	}

	.featured_courses{
		overflow: hidden;
	}

	.top_instrutors{
		overflow: hidden;
	}

	.Student_says{
		overflow: hidden;
	}

	.featured_courses .scroll{
		min-height: 200px;
		min-width: 3000px;
	}

	.top_instrutors .scroll{
		min-height: 200px;
		min-width: 3000px;
	}

	.Student_says .scroll{
		align-items: left;
		min-height: 200px;
		min-width: 3000px;
	}

	.item{
		width: 150px;
		display: inline-block;
		margin-left: 10px;
	}

	.featured_courses .item{
		width: 350px;
		display: inline-block;
		margin-left: 10px;
	}

	.top_instrutors .item{
		width: 350px;
		display: inline-block;
		margin-left: 10px;
	}

	.Student_says .item{
		width: 350px;
		display: inline-block;
		margin-left: 10px;
	}

	@media (max-width: 575.98px) {
		.live_stream{
			overflow-x: scroll
		}

		.featured_courses{
			overflow-x: scroll;
		}

		.top_instrutors{
			overflow-x: scroll;
		}

		.Student_says{
			overflow-x: scroll;
		}
	}

	.live_stream:hover{
		overflow-x: scroll
	}

	.featured_courses:hover{
		overflow-x: scroll;
	}

	.top_instrutors:hover{
		overflow-x: scroll;
	}

	.Student_says:hover{
		overflow-x: scroll;
	}

	.questionbox{
		width: 100%;
		min-height: 100px;
		padding: 2px;
		margin-bottom: 10px;
	}

	.question{
		width: 100%;
		font-size: 13px;
		line-height: 0.5cm;
		border-radius: 3px;
		margin-bottom: 3px;
	}

	.respond{
		padding-left: 3px;
		padding-right: 3px;
		padding-bottom: 3px;
		background-color: rgb(233, 88, 100);
		font-size: 12px;
		line-height: 0.5cm;
		width: 100%;
		margin-top: -2px;
		border-radius: 3px;
		color: white;
	}
	::-webkit-scrollbar {
        width: 20px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }
        
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: red; 
        border-radius: 10px;
    }

</style>
<template>
    <div>
    <!-- Header Start -->
        <Dheader />
	<!-- Header End -->
	<!-- Left Sidebar Start -->
        <Dnav1 />
	<!-- Left Sidebar End -->
	<!-- Body Start -->
    <div class="wrapper">
		<div class="sa4d25">
			<div class="container-fluid">			
				<div class="row">
					<div class="col-lg-3 col-md-4 ">
						<div class="section3125 hstry142">
							<div class="tb_145">
                                <a href="" class="rmv-btn" :class="{inactive: type!='all'}" @click.prevent="switchTab('all')"><i class='uil uil-clipboard-alt'></i>All</a>
								<a href="" class="rmv-btn" :class="{inactive: type!='University'}" @click.prevent="switchTab('University')"><i class='uil uil-university'></i>Universities</a>
                                <a href="" class="rmv-btn" :class="{inactive: type!='Corporate'}" @click.prevent="switchTab('Corporate')"><i class='uil uil-building'></i>Corporate Organizations</a>
							</div>						
						</div>							
					</div>		

					<div class="col-md-9">
						<div class="_14d25 mb-20">						
							<div class="row">
								<div class="col-md-12">
									<h4 class="mhs_title" v-if="type=='all'">All Institutions</h4>
                                    <h4 class="mhs_title" v-if="type=='University'">Universities</h4>
                                    <h4 class="mhs_title" v-if="type=='Corperate'">Corporate Organizations</h4>
									<div class="fcrse_1 mt-30"  v-for="institude in allInstitutions" :key="institude">
                                        <router-link :to="'/'+institude.handle+'/dashboard'" v-if="institude.handle" >
                                            <div class="say_content" style="word-wrap: break-word;">
                                                <p>@ {{institude.address}}</p>
                                                <Ratings :id="institude._id" :type="'institution'" />
                                            </div>
                                            <div class="st_group">
                                                <div class="stud_img">
                                                    <!-- <img :src="backEnd+'public/institutionImage__public/'+institude._id" alt=""> -->
                                                    <img :src="publicFiles+institude.institutionLogoFile" alt="">													
                                                </div>
                                                <h4>{{institude.institutionName}}</h4>
                                            </div>											
                                        </router-link>
										<router-link :to="'/'+institude._id+'/dashboard'" v-else>
                                            <div class="say_content" style="word-wrap: break-word;">
                                                <p>@ {{institude.address}}</p>
                                                <Ratings :id="institude._id" :type="'institution'" />
                                            </div>
                                            <div class="st_group">
                                                <div class="stud_img">
                                                    <!-- <img :src="backEnd+'public/institutionImage__public/'+institude._id" alt=""> -->
                                                    <img :src="publicFiles+institude.institutionLogoFile" alt="">													
                                                </div>
                                                <h4>{{institude.institutionName}}</h4>
                                            </div>											
                                        </router-link>
									</div>
								</div>									
							</div>																			
						</div>	
                            <center>
                                <button class="live_link" type="button" v-if="!Loading" @click="fetchMore">Load More</button>
                                <button class="live_link" type="button" v-if="Loading" @click="fetchMore">Please Wait ...</button>	
                            </center>
                            <br><br><br>							
					</div>				
				</div>
			</div>
		</div>
        <Dfooter />
	</div>
	<!-- Body End -->
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Ratings from '@/components/dashboard/Ratings.vue'
import Dheader from '@/components/dashboard/Header.vue'
import Dnav1 from '@/components/dashboard/Nav1.vue'
import Dfooter from '@/components/dashboard/Footer.vue'
export default {
    name: 'Dhome',
    data() {
        return{
            all_Instructors: '',
            recentCourses: '',
            allInstitutions: '',
            purchasedCourses: '',
            user: '',
            skip: 0,
            filtered: '',
            Loading: false,
            type: 'all'
        }
    },
    components: {
        Dheader,
        Dnav1,
        Ratings,
        Dfooter
    },
    computed: {
        ...mapGetters(['isInstructor']),
        ...mapGetters(['backEnd','publicFiles'])
    },
    methods: {
        ...mapActions(['fetch_all_institutions']),
        fetchMore(){
            this.Loading = true
            this.skip = this.skip + 10
            this.fetch_all_institutions({skip: this.skip, type: this.type})
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    this.allInstitutions.push(element)
                }
                this.Loading = false
            })
        },
        switchTab(type){
            this.type = type
            this.skip = 0
            this.fetch_all_institutions({skip: this.skip, type: this.type})
            .then(res => {
                this.allInstitutions = res.data
            })
        }
    },  
    mounted(){
        this.type = this.$route.params.type
        this.fetch_all_institutions({skip: this.skip, type: this.type})
        .then(res => {
            this.allInstitutions = res.data
        })
    }
}
</script>
<style scoped>
    @import url("./assets/css/style.css");
    /* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
    @import url("./assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/vertical-responsive-menu.min.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/instructor-responsive.css");
    @import url("./assets/css/responsive.css");
    @import url("./assets/css/night-mode.css");
    @import url("./assets/vendor/fontawesome-free/css/all.min.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("./assets/vendor/semantic/semantic.min.css");
   
   
    .inactive{
        background-color: grey
    }
</style>
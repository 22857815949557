<template>
    <div>
    <!-- Header Start -->
        <Dheader />
	<!-- Header End -->
		<Dnav1 />
	<!-- Body Start -->
	<div class="wrapper">		
		<div class="_215cd2">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">	
						<div class="title129 mt-35 mb-35">	
							<h2>{{membership.title}}</h2>
                            <div class="col-lg-12">
                                <div class="title589 text-center" v-if="membership!=''">
                                    <h2>Status: {{status}}</h2>
                                    <p v-if="status=='null'" style="margin: 0px">You can not take a course here because you are not a member</p>
                                    <p v-else style="margin: 0px">You are a member</p>
							        <img class="line-title" src="./assets/images/line.svg" alt="">
                                </div>
                            </div>
						</div>					
						<div class="course_tabs">
							<nav>
								<div class="nav nav-tabs tab_crse  justify-content-center">
									<!-- <a class="nav-item nav-link" v-bind:class="{ active: blog }" @click.prevent="switchtap('blog')">Blog</a> -->
									<a class="nav-item nav-link" v-bind:class="{ active: focus == 'general' }" @click.prevent="switchtap('general')">About</a>
									<a class="nav-item nav-link" v-bind:class="{ active: focus == 'members' }" @click.prevent="switchtap('members')">Members</a>
									<a class="nav-item nav-link" v-bind:class="{ active: focus == 'courses' }" @click.prevent="switchtap('courses')">Courses</a>
                                    <a class="nav-item nav-link" v-bind:class="{ active: focus == 'progress' }" @click.prevent="switchtap('progress')" v-if="isFounder">Progress</a>
								</div>
							</nav>						
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="_215zd5" v-if="focus == 'general'" id="General">
			<div class="container">
				<div class="row">
					<div class="col-md-6">
						<div class="title484">
							<h2>Description</h2>
							<img class="line-title" src="./assets/images/line.svg" alt="">
							<p>{{membership.description}}</p >				
						</div>
					</div>
					<div class="col-md-6">
						<div class="story125">
							<!-- <img :src="backEnd+'public/institutionImage__public/'+institutionId" style="width: 250px; height: 250px; border-radius: 50%;" alt=""> -->
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12">
						<div class="title589 text-center" v-if="membership!=''">
							<h2>Admin</h2>
							<p>{{membership.user[0].firstName}} {{membership.user[0].lastName}}</p>
							<img class="line-title" src="./assets/images/line.svg" alt="">
						</div>
					</div>
				</div>
				<br><br>
			</div>
		</div>
        <div class="_215zd5" v-if="focus == 'members'" id="members">
			<div class="container">
				<div class="course__form" v-if="isFounder">
                    <div class="row">
                        <div class="col-lg-12">		
                            <div class="extra_info">		
                                <h4 class="part__title" v-if="canAdd">New Member</h4>
                                <h4 class="part__title" v-if="!canAdd">Max Members Reached</h4>
                            </div>
                            <div class="view_info10" v-if="canAdd">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">															
                                        <div class="ui search focus mt-30 lbel25">
                                            <h2>max: {{membership.max}}</h2>
							                <img class="line-title" src="./assets/images/line.svg" alt="">
                                            <label>Insert the user email used in creating their account on faslearn *</label>
                                            <div class="ui left icon input swdh19">
                                                <input class="prompt srch_explore" type="email" placeholder="New Member Email" maxlength="60" v-model="email">															
                                            </div>
                                        </div>									
                                    </div>
                                    
                                    <div class="col-lg-12 col-md-12">
                                        <br>
                                        <center>
                                            <Errors v-if="error != ''">
                                                <ul>
                                                    <li>{{error}}</li>	
                                                </ul>		
                                            </Errors>
                                        </center>
                                        <br>
                                        <center>
                                            <Success v-if="success != ''">
                                                <ul>
                                                    <li>Action Successful</li>
                                                    <li>{{success}}</li>
                                                </ul>
                                            </Success>
                                        </center>
                                        <br>
                                        <Loading v-if="Loading" />
                                        <br>
                                        <div class="save_content" v-if="!Loading">
                                            <button class="save_content_btn" type="Submit" id="btn" @click.prevent="manageMember()" :disabled="email==''">Add member</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive mt-30">
                    <div class="cover" style="width: 100%" >
                        <div class="part__title">
                            <h5 class="">All Members : {{members.length}}</h5>	
                            <div class="controls">
                                <a title="Expand" class="gray-s" ><i class="uil uil-eye-slash"></i></a>
                                <a title="Delete" class="gray-s"  ><i class="uil uil-trash-alt"></i></a>
                            </div>
                        </div>
                        <table class="table ucp-table" >
                            <thead class="thead-s">
                                <tr>
                                    <th class="text-center" scope="col">SN</th>
                                    <th class="cell-ta">firstName</th>
                                    <th class="text-center" scope="col">lastName</th>
                                    <th class="text-center" scope="col">Email</th>
                                    <th class="text-center" scope="col">Controls</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="m in members" :key="m">
                                    <td class="text-center">{{1+(members.indexOf(m))}}</td>
                                    <td class="cell-ta">{{m.firstName}}</td>
                                    <td class="text-center">{{m.lastName}}</td>
                                    <td class="text-center">{{m.email}}</td>
                                    <td class="text-center">
                                        <!-- <a href="#" title="Delete" class="gray-s" v-if="isFounder" @click.prevent="removeMember(m.email, m.firstName+' '+m.lastName)"><i class="uil uil-trash-alt"></i></a> -->
                                        <router-link :to="'/Dprofile/'+m._id">
                                            <a href="#" title="view" class="gray-s" ><i class="uil uil-eye"></i></a>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
			</div>
		</div>
        <div class="_215zd5" v-if="focus == 'courses'" id="courses">
			<div class="container">
				<div class="row">
					<div class="col-md-6">
						<div class="title484">
							<h2>All Courses</h2>
							<img class="line-title" src="./assets/images/line.svg" alt="">				
						</div>
					</div>
				</div>
				
				<div class="branches_all">
					<div class="row">
                        <CourseEntity v-for="c in courses" :key="c" 
                            :id="c._id" 
                            :title="fieldName"
                            :file="c.thumbnailFile"
                            :category="c.category[0].name"
                            :price="c.price.$numberDecimal"
                            :firstName="c.user[0].firstName"
                            :lastName="c.user[0].lastName"
                            :userId="c.user[0]._id"
                            :discount="c.discountPercent"
                        />					
					</div>	
					<br><br><br>
					<center>
						<button class="live_link" type="button" v-if="!Loading" @click="fetchMore()">Load More</button>
                        <button class="live_link" type="button" v-else>Please Wait ...</button>	
					</center>
					<br><br><br>				
				</div>
				<br><br>
			</div>
		</div>
        <div class="_215zd5" v-if="focus == 'progress'" id="progress">
			<div class="container">
				<div class="row">
                    <Loading v-if="Loading" />
                </div>   
                <div class="_htg451" style="background-color: transparent">
                    <div class="_htg452">
                        <h2>Members Progress</h2>
                        <button class="live_link" @click.prevent="downloadXLSX()">Download Excel</button>
                        <button class="live_link" @click.prevent="exportPdf()">Download PDF</button>
                        <div class="row">
                            <div class="col-md-9">
                                <div class="fcrse_1 mt-20" v-for="p in pogressLogs" :key="p">
                                    <router-link :to="'/Dprofile/'+p.user._id">
                                    <h3><i class='uil uil-nerd menu--icon'></i> {{p.user.firstName}} {{p.user.lastName}}</h3>
                                    </router-link>
                                    <div class="ui search focus mt-30 lbel25" v-for="c in p.courses" :key="c">
                                        
                                        <strong><h4>{{c.title}}</h4></strong>
                                        <div class="progress progress1" >
                                            <div class="progress-bar " :style="'width: '+c.progress+'%; background-color: red;'"></div>
                                        </div>
                                        <p style="margin: 0px; padding: 0px">Progress :  {{c.progress}}%</p>
                                    </div>
                                </div>
                            </div>									
                        </div>
                        <br><br><br>
                        <center>
                            <button class="live_link" type="button" v-if="!Loading" @click="fetchMoreProgress()">Load More</button>
                            <button class="live_link" type="button" v-if="Loading" >Please wait ...</button>	
                        </center>	
                    </div>																	
                </div>
            </div>
        </div>
		
		<Dfooter />
	</div>
	<!-- Body End -->
    </div>
</template>
<script>
import Dheader from '@/components/dashboard/Header.vue'
import Dnav1 from '@/components/dashboard/Nav1.vue'
import Dfooter from '@/components/dashboard/Footer.vue'
import { mapActions, mapGetters } from 'vuex'
import Loading from '@/components/Loading.vue'
import Success from '@/components/Success.vue'
import Errors from '@/components/Errors.vue'
import CourseEntity from "@/components/dashboard/CourseEntity";
import jsPDF from "jspdf";
import { utils, writeFile } from "xlsx";
import "jspdf-autotable";
export default {
	name: 'Dhome',
	data() {
		return{
            membershipId: '',
            membership: '',
            members: '',
            userId: '',			
            Loading: false,
            focus: 'general',
            isFounder: false,
            canAdd: false,
            isMember: false,
            email: '',
            error: '',
            success: '',
            status: 'null',
            courses: [],
            skip: 0,
            skipProgress: 0,
            pogressLogs: []
		}
	},
    components: {
		Dheader,
		Dnav1,
		Dfooter,
		Loading,
		Errors,
		Success,
		CourseEntity,
	},
	computed: {
        ...mapGetters(['backEnd'])
	},
	methods: {
        ...mapActions(['fetch_Membership','fetch_MembershipMembers','manage_Membership','check_MembershipStatus','fetch_MembershipCourses']),
        ...mapActions(['fetch_UserLectureLog','fetch_UsersProgressMembership']),
		switchFocus(to){
			this.skip = 0
			this.focus = to
		},
		switchtap(to){
            this.focus = to
        },
        manageMember(){
            this.Loading = true
            this.error = ''
            this.success = ''
            let object = {
                email: this.email, 
                membershipId: this.membershipId, 
                adminId: this.userId
            }
            this.manage_Membership(object)
            .then(res => {
                if (res.data.error) {
                    this.error = res.data.message
                    this.Loading = false
                }else{  
                    this.success = res.data.message
                    this.members = res.data.membership
                    this.Loading = false
                    if (this.members.length<this.membership.max) {
                        this.canAdd = true
                    }else{
                        this.canAdd = false
                    }
                }
                this.email = ''
            })
        },
        removeMember(email, name){
            if (confirm("Do you REALLY want to remove "+name+" ?")) {
                this.email = email
                this.manageMember()
            }else{
                alert('ok, we will ignore your delete request')
            }
            
        },
		fetchMore(){
            this.Loading = true
			this.skip = this.skip + 10
			this.fetch_MembershipCourses({id: this.membershipId, skip: this.skip})
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    this.courses.push(element)
                }
                this.Loading = false
            })		
        },
        fetchMoreProgress(){
            this.Loading = true
			this.skipProgress = this.skipProgress + 10
			this.fetch_UsersProgressMembership({id: this.membershipId, skip: this.skipProgress})
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    this.pogressLogs.push(element)
                }
                this.Loading = false
            })	
        },

        exportPdf() {
            var logs = this.formatLogs()
            if (logs.length < 1) {
                return
            }
            var columns = this.logFieldNamesColumns(logs[0])
            const doc = new jsPDF("p", "pt");
            doc.text("Report", 30, 20);
            doc.autoTable(columns, logs);
            doc.save("faslearn_members.pdf");
        },
            
        downloadXLSX(){
            this.processing = true;
            const exportData = this.formatLogs()
            const sheet = utils.json_to_sheet(exportData);
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, sheet, 'agents');
            writeFile(workbook, `faslearn_members.xlsx`);
            this.processing = false;
        },

        formatLogs(){
            const logs = []
            for (let i = 0; i < this.pogressLogs.length; i++) {
                const p = this.pogressLogs[i];
                const log = {
                    MEMBER : p.user.firstName +" "+p.user.lastName, 
                    EMAIL  : p.user.email
                }
                for (let j = 0; j < p.courses.length; j++) {
                    const c = p.courses[j];
                    log[c.title.toUpperCase() + " %"] = c.progress;
                }
                logs.push(log)
            }
            return logs                        
        },

        logFieldNamesColumns(obj) {
            const fieldNames = Object.keys(obj);
            const fieldArray = []
            fieldNames.forEach(fieldName => {
                const obj = {}
                obj.title = fieldName.toUpperCase()
                obj.dataKey = fieldName
                fieldArray.push(obj)
            });
            return fieldArray
        }

	},   
    mounted(){
		this.userId = localStorage.getItem('fmruser')
        this.membershipId = this.$route.params.id
        this.fetch_Membership(this.membershipId)
        .then(res => {
            this.membership = res.data.membership
            if(this.userId==this.membership.user[0]._id){
                this.isFounder = true
            }
            this.fetch_MembershipMembers(this.membershipId)
            .then(res => {
                this.members = res.data.members
                if (this.members.length<this.membership.max) {
                    this.canAdd = true
                }
            })
        })	
        this.check_MembershipStatus({userId: this.userId, membershipId: this.membershipId})
        .then(res => {
            this.status = res.data.status
        })
        this.fetch_MembershipCourses({id: this.membershipId, skip: this.skip})
        .then(res => {
            this.courses = res.data
        })
        this.Loading = true
        this.fetch_UsersProgressMembership({id: this.membershipId, skip: this.skip})
        .then(res => {
            this.pogressLogs = res.data
            this.Loading = false
        })
    }
}
</script>
<style scoped>
	@import url("./assets/css/style.css");
    @import url("./assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/vertical-responsive-menu.min.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/instructor-responsive.css");
    @import url("./assets/css/responsive.css");
    @import url("./assets/css/night-mode.css");
    @import url("./assets/vendor/fontawesome-free/css/all.min.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("./assets/vendor/semantic/semantic.min.css");

    .nav-link{
		cursor: pointer;
		border-radius: 50%;
	}

	.liked{
		background-color: yellowgreen;
		border: 2px solid yellowgreen;
	}

	.likeButton{
		width: 50px;
		height: 50px;
		border-radius: 10px;
		padding: 10px;
	}

	.liking{
		width: 60px;
		height: 60px;
		display: inline-block;
		margin: 5px;
		padding-top: 10px;
	}
	

</style>
<template>
    <div class="mover"><i class='uil uil-process'></i></div>
</template>
<script>
export default {
    name: 'processing'
}
</script>
<style scoped>
    .mover{
        display: inline-block;
        animation-name: spinerman;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes spinerman{
        from {
        transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }
</style>
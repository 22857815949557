

<template>
    <div class="item col-lg-3 col-md-4" >
        <div class="fcrse_1 mb-20">
            <router-link :to="'/Dbook/'+id">
            <a  class="fcrse_img">
                <EbookImage :name="thumbnailFile" style="height: 150px" />
                <div class="course-overlay">
                    <div class="crse_timer" >
                        {{pages}} Pages in {{language}}
                    </div>	
                </div>
            </a>
            </router-link>
            <div class="fcrse_content">
                <div class="eps_dots more_dropdown">
                    <a><i class='uil uil-ellipsis-v'></i></a>
                    <div class="dropdown-content">
                        <span  @click="copyURL('https://faslearn.com/Dbook/'+ id +'?affiliateUserRef='+fmruser)"><i class='uil uil-share-alt'></i>Copy Link</span>
                        <!-- <span><i class="uil uil-clock-three"></i>Save</span>
                        <span><i class='uil uil-ban'></i>Not Interested</span>
                        <span><i class="uil uil-windsock"></i>Report</span> -->
                    </div>																									
                </div>
                <router-link :to="'/Dbook/'+id+outletCredentials">
                <span ><i class="uil uil-calendar-alt"></i>{{year}}  {{publisher}} </span>
                <a  class="crse14s" style="font-size: 14px">{{title.substring(0,20)}}<span v-if="title.toString().length>20">...</span></a>
                <a href="#" class="crse-cate">{{category}}</a>
                </router-link>
                <div class="auth1lnkprce">
                    <EbookOwner :id="userId" :firstName="firstName" :lastName="lastName" /><br>
                    <div class="prce142" style="font-size: 12px"><span v-if="price!=0">₦{{price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span><span v-else>FREE</span> <br>
                        <small v-if="price!=0">
                            <Discount :id="id" :price="price"  :discount="discount"  />
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import EbookImage from '@/components/BookImage'
// import Ratings from '@/components/dashboard/Ratings.vue'
import EbookOwner from "@/components/EbookOwner"

export default {
	data(){
        return{
            total: 0,
            fmruser: localStorage.getItem('fmruser')
        }
    },
    props: {
        id: String,
        ISBN: String,
        category: String,
        createdAt: String,
        discountPercent: Number,
        language: String,
        pages: Number,
        preface: String,
        price: String,
        publisher: String,
        secondTitle: String,
        thumbnailFile: String,
        title: String,
        firstName: String,
        lastName: String,
        userId: String,
        year: String,
        outletCredentials: String
    },
	components: {
        // Ratings,
        EbookImage,
        EbookOwner
	},
	computed: {
		...mapGetters(['backEnd'])
    },
    methods: {
        async copyURL(mytext) {
            try {
                await navigator.clipboard.writeText(mytext);
                alert('Copied');
            } catch($e) {
                alert('Cannot copy');
            }
        }
    }
}
</script>
<style>

</style>
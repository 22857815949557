<template>
    <div>
        <div class="wrapper _bg4586 _new89 p-0">		
            <div class="container">
                <div class="row justify-content-md-center">
                    <div class="cert_body">
                        <div class="col-md-8 overlap">
                            <canvas id="c" ref="device" width="800" height="500">
                            </canvas>
                        </div>
                        <br />
                        <button class="download-button" id="save">Download</button>
                    </div>
                </div>
            </div>	
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import myImage from "../../assets/faslearn_certificate.jpg";
import partnerCerts from "../../assets/partner_certificate.jpg";
import unnImage from "../../assets/unn_certificate.jpg";
//import Loading from '@/components/Loading.vue'
export default {
	name: 'Dcertificate',
	data() {
        return{
            userId: '',
            testId: '',
            courseId: '',
            test : '',
            unn: true,
            certUser : {firstName: '', lastName: ''},
            certTestLog :  [{result: '', total: '', _id: ''}],
            certCourse: {title: '', user: [{firstName: '', lastName: '', _id: ''}], affiliatedInstitutions: [{institutionId: [{institutionName: '', _id: ''}]}]},
            myInstitution: '',
            Institution: ''
        }
    },
    computed: {
        ...mapGetters(['backEnd']),
        ...mapGetters(['publicFiles'])
    },
	methods: {
        ...mapActions(['fetch_StudentCertificate']),
        
        checkInstitution(){
            if (this.certCourse.affiliatedInstitutions != ''&&this.certCourse.affiliatedInstitutions != null) {
                for (let i = 0; i < this.certCourse.affiliatedInstitutions.length; i++) {
                    const element = this.certCourse.affiliatedInstitutions[i];
                    if (!element.accepted) {
                        for (let j = 0; j < this.certUser.admission.length; j++) {
                            const elementj = this.certUser.admission[j];
                            //this.myInstitution = element
                            if (elementj.accepted&&elementj.institutionId[0]==element.institutionId[0]._id) {
                                this.myInstitution = element
                            }
                        }
                    }
                }
            }
        },

        triggerRightClick() {
            const canvas = this.$refs.device;
            const rect = canvas.getBoundingClientRect();
            const rightClickEvent = new MouseEvent('contextmenu', {
                bubbles: true,
                cancelable: true,
                view: window,
                clientX: rect.left + canvas.width / 2,
                clientY: rect.top + canvas.height / 2
            });
            canvas.dispatchEvent(rightClickEvent);
        }
        // checkInstitution(){
        //     if (this.certCourse.affiliatedInstitutions != ''&&this.certCourse.affiliatedInstitutions != null) {
        //         for (let i = 0; i < this.certCourse.affiliatedInstitutions.length; i++) {
        //             const element = this.certCourse.affiliatedInstitutions[i];
        //             if (!element.accepted) {
        //                 for (let j = 0; j < this.certUser.admission.length; j++) {
        //                     const elementj = this.certUser.admission[j];
        //                     //this.myInstitution = element
        //                     if (!elementj.accepted&&elementj.institutionId[0]==element.institutionId[0]._id) {
        //                         this.myInstitution = element
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
        
    }, 
    mounted(){
        this.userId = localStorage.getItem('fmruser');
        this.courseId = this.$route.params.courseId;
        this.testId = this.$route.params.testId;
        var title = "";
        let object = {
            user : this.userId, 
            test : this.testId, 
            course : this.courseId
        }
        this.fetch_StudentCertificate(object)
        .then(res => {
            this.certUser = res.data.userDetails
            this.certTestLog = res.data.testLog
            this.certCourse = res.data.courseDetails
            title = res.data.courseDetails.title
            this.Institution = res.data.institution[0]
            this.checkInstitution()
            if (this.Institution!=null&&this.Institution!=''&&this.Institution!=undefined) {
                if (this.Institution.institutionType=="Corperate"||this.Institution.institutionType=="corperate") {
                    addTextToPartnerImage(
                        partnerCerts, 
                        this.certUser.firstName+ ' '+this.certUser.lastName, 
                        this.certCourse.title, 
                        this.certTestLog[0]._id,
                        this.Institution ? this.Institution.institutionName : "Faslearn Institution",
                        this.certCourse.user[0].firstName,
                        this.certCourse.user[0].lastName
                    );
                    return
                }
            }
            if (this.certCourse.user[0]._id=='6149ce1530b2f819bc672e42') {
                addTextToUnnImage(
                    unnImage, 
                    this.certUser.firstName+ ' '+this.certUser.lastName, 
                    this.certCourse.title, 
                    this.certTestLog[0]._id
                );
            }else{
                addTextToImage(
                    myImage, 
                    this.certUser.firstName+ ' '+this.certUser.lastName, 
                    this.certCourse.title, 
                    this.certCourse.user[0].firstName+ '.' +this.certCourse.user[0].lastName,
                    this.certTestLog[0]._id,
                    this.publicFiles
                );

            }
            
        })
        
        const gCanvas = this.$refs.device // document.querySelector('#c');
        const gCtx = gCanvas.getContext('2d');
        document.querySelector('#save').addEventListener('click', onSave);

        function toTitleCase(str) {
            return str.replace(/\w\S*/g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        }

        function onSave(){
            var link = document.createElement('a');
            link.download = title+' faslearn certificate.png';
            link.href = document.getElementById('c').toDataURL()
            link.click();
        }

        function addTextToImage(imagePath, studentName, courseName, InstructorName, certificateId,) {

            var date =  (new Date()).toString().split(' ').splice(1,3).join(' ');
            var img = new Image();
            img.src = imagePath;

            studentName = toTitleCase(studentName);
            courseName = toTitleCase(courseName);
            InstructorName = toTitleCase(InstructorName);

            img.onload = function () {
                gCtx.drawImage(img, 0, 0, 800, 500);
                gCtx.lineWidth = 1;
                gCtx.fillStyle = "#000";
                gCtx.lineStyle = "#000";
                gCtx.font = "15px sans-serif";
                gCtx.fillText(studentName, 90, 188);
                gCtx.fillText(courseName, 90, 270);
                gCtx.fillText(date, 130, 327);
                gCtx.fillText(certificateId, 390, 325);
                gCtx.fillText(InstructorName, 80, 400);
                gCtx.font = "25px 'Dancing Script', regular";
                gCtx.fillText(InstructorName, 90, 450);
            };


        }
        

        function addTextToUnnImage(imagePath, studentName, courseName, certificateId ) {

            var date =  (new Date()).toString().split(' ').splice(1,3).join(' ');
            var img = new Image();
            img.src = imagePath;

            img.onload = function () {
                gCtx.drawImage(img, 0, 0, 800, 500);
                gCtx.lineWidth = 1;
                gCtx.fillStyle = "#000";
                gCtx.lineStyle = "#000";
                gCtx.font = "15px sans-serif";
                gCtx.fillText(toTitleCase(studentName), 90, 210);
                gCtx.fillText(toTitleCase(courseName), 90, 290);
                gCtx.fillText(date, 145, 390);
                gCtx.fillText(certificateId, 340, 390);
                gCtx.font = "19px Cursive Brush Script MT";
            };

        }

        function addTextToPartnerImage(imagePath, studentName, courseName, certificateId, institutionName, authorFirstName, authorLastName) {
            var date =  (new Date()).toString().split(' ').splice(1,3).join(' ');
            var img = new Image();
            img.src = imagePath;

            img.onload = function () {
                gCtx.drawImage(img, 0, 0, 800, 500);
                gCtx.lineWidth = 1;
                gCtx.fillStyle = "#000";
                gCtx.lineStyle = "#000";
                gCtx.font = "15px sans-serif";
                gCtx.fillText(toTitleCase(institutionName), getCenter(institutionName), 165);
                gCtx.fillText(toTitleCase(studentName), getCenter(studentName), 225);
                gCtx.fillText(toTitleCase(courseName), getCenter(courseName), 275);
                gCtx.fillText(date, 240, 337);
                gCtx.fillText(certificateId, 530, 337);
                gCtx.fillText(toTitleCase(authorFirstName+" "+authorLastName), 210, 390);
                
                // gCtx.fillText(toTitleCase(authorName), 210, 430);
                // gCtx.font = "19px Cursive Brush Script MT";

                gCtx.font = "25px 'Dancing Script', regular";
                gCtx.fillText(toTitleCase(authorFirstName+"."+authorLastName), 210, 430);
            };
        }

        function getCenter(text) {
            var canvasWidth = 800; // Assuming the width of your canvas is 800px
            var textWidth = gCtx.measureText(text).width;
            console.log((canvasWidth - textWidth) / 2)
            return (canvasWidth - textWidth) / 2;
        }

        
        
	}
}
</script>
<style scoped>
	@import url("./assets/css/style.css");
    @import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
    @import url("./assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/vertical-responsive-menu.min.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/instructor-responsive.css");
    @import url("./assets/css/responsive.css");
    @import url("./assets/css/night-mode.css");
    @import url("./assets/vendor/fontawesome-free/css/all.min.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("./assets/vendor/semantic/semantic.min.css");

    /* canvas{
		border: 2px solid red;
	} */
	
	.cert_body{
        width: 100%;
        height: 850px;
        /* background-color: darkslategray; */
        align-items: center;
        padding-top: 5%;
    }

    h1{
        font-family : Fantasy;
        font-size: 50px;
    }

    h4{
        font-family: cursive;
        margin-top: 0px;
    }
    p{
        font-family: cursive;
        font-size: 16px;
        width: 70%;

    }
/* #EF6421 */
    .center_body{
        width: 95%;
        height: 95%;
        margin-left: 2.5%;
        background-color: white;
        border: 20px solid white;
        box-shadow: 1px 1px 2px #ebe0e7, 0 0 25px darkslategray, 0 0 5px darkblue;
    }
    .divider{
        width: 70%;
        height: 30px;
        background-color: white;
        margin: 0px;
        padding: 0px;
    }
    .side{
        width: 45%;
        display: inline-block;
        height: 2px;
        background-image: linear-gradient(to bottom right, darkslategray, #ebe0e7)
    }
    .middle{
        display: inline-block;
        height: 100%;
        width: 10%;
        border-radius: 50%;
        border: 2px solid red;
    }
    .parts{
        width: 100%;
        height: 24%;
        background-color: white;
        padding: 10px;
    }

    .parts .left{
        float: left;
        width: 40%;
        height: 100%;
    }

    .parts .left img{
        width: 100%;
        height: 70%;
        border-radius: 50%;
    }

    .parts .right{
        float: right;
        width: 60%;
        height: 100%;
    }

    .line{
        width: 100%;
        height: 5%;
        background-color: #ebe0e7;
        border-top: 5px solid #EF6421;
        border-bottom: 5px solid #EF6421;
    }

    .signature{
        width: 30%;
        display: inline-block;
        font-size: 12px;
        margin-right: 3%;
    }
    .signature img{
        height: 70%;
        width: 70%;
    }

    .overlap{
        overflow-x: scroll;
    }

    .download-button {
        background-color: #FFA500; /* Orange */
        border: none;
        color: white;
        padding: 15px 30px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 12px;
        box-shadow: 0 4px #999;
        transition: all 0.3s ease;
    }

    .download-button:hover {
        background-color: #e59400; /* Darker Orange */
    }

    .download-button:active {
        background-color: #cc8400; /* Even Darker Orange */
        box-shadow: 0 2px #666;
        transform: translateY(2px);
    }

</style>
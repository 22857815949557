<template>
  <div>
    <Header />

    <div class="content-wrapper">
      <div class="container-fluid">
        <!-- Breadcrumbs-->
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Dashboard</a>
          </li>
          <li class="breadcrumb-item active">Your Courses</li>
        </ol>
        <div class="box_general">
          <div class="header_box">
              <h2 class="d-inline-block">Raffle Items</h2>
              <div class="card-header">

			  <p class="tabButton" :class="{active : tab=='all'}"  @click="switchTab('all')">All</p>
              <p class="tabButton" :class="{active : tab=='new'}"  @click="switchTab('new')">New</p>
              <p class="tabButton" :class="{active : tab=='winner'}" @click="switchTab('winner')">Won</p>
			  <p class="tabButton" :class="{active : tab=='delivered'}"  @click="switchTab('delivered')">Delivered</p>
              <p class="tabButton" :class="{active : tab=='received'}" @click="switchTab('received')">Recieved</p>
              </div>
          </div>
          <div class="bigspinner" v-if="Loading">
              <div class="smallspiner">
              </div>
          </div>
          <div class="box_general">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <!-- <form > -->
                  <div style="margin-bottom: 40px">
                      
                      <div class="card-body">
                          <div class="table-responsive">
                              <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                                  <thead>
                                      <tr>
                                      <th>Item</th>
                                      <th>Attempts Per Winner</th>
                                      <th>Actions</th>
                                      </tr>
                                  </thead>
                                  <tfoot>
                                      <tr>
                                      <th>Item</th>
                                      <th>Attempts Per Winner</th>
                                      <th>Actions</th>
                                      </tr>
                                  </tfoot>
                                  <tbody v-if="raffleItems">
                                      <tr v-for="r in raffleItems" :key="r">
                                          <td>{{r.item}}</td>
                                          <td>{{r.attempts}}</td>
                                          <td>
                                              <router-link :to="'/SARaffleItemEdit/'+r._id">
                                              View
                                              </router-link>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>

          <!-- <component :is="currentTab"></component> -->
        </div>
      </div>
      <!-- /container-fluid-->
    </div>
    <!-- /.container-wrapper-->
    <Footer />
    <!-- Scroll to Top Button-->
    <a class="scroll-to-top rounded" href="#page-top">
      <i class="fa fa-angle-up"></i>
    </a>
    <!-- Logout Modal-->
    <Modal />
  </div>
</template>
<script>
import Header from "@/components/superAdmin/Header.vue";
import Modal from "@/components/superAdmin/Modal.vue";
import Footer from "@/components/superAdmin/Footer.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
	  tab: 'all',
      slug: "",
      item: "",
      attempts: "",
      raffleItems: [],
      skip: 0,
      title: "",
      type: "unverified",
      Loading: false,
      Success: false,
      errors: []
    };
  },
  components: {
    Header,
    Modal,
    Footer,
  },
  computed: {
    ...mapGetters(["backEnd"]),
  },
  methods: {
    ...mapActions(['createRaffleDrawItems__Admin', 'getRaffleDrawItems__Admin']),
	switchTab(tab){
		this.Loading = true
		this.skip = 0
		this.tab = tab
		switch(tab){
			case "all":
				this.slug = "?skip="+this.skip+"&winner="+false+"&delivered="+false+"&received="+false+"&all="+true
				this.getRaffleDrawItems__Admin(this.slug)
				.then(res => {
					this.Loading = false
					this.raffleItems = res.data
				})
				break;
			case "new":
				this.slug = "?skip="+this.skip+"&winner="+false+"&delivered="+false+"&received="+false+"&all="+false
				this.getRaffleDrawItems__Admin(this.slug)
				.then(res => {
					this.Loading = false
					this.raffleItems = res.data
				})
				break;
			case "winner":
				this.slug = "?skip="+this.skip+"&winner="+true+"&delivered="+false+"&received="+false+"&all="+false
				this.getRaffleDrawItems__Admin(this.slug)
				.then(res => {
					this.Loading = false
					this.raffleItems = res.data
				})
				break;
			case "delivered":
				this.slug = "?skip="+this.skip+"&winner="+false+"&delivered="+true+"&received="+false+"&all="+false
				this.getRaffleDrawItems__Admin(this.slug)
				.then(res => {
					this.Loading = false
					this.raffleItems = res.data
				})
				break;
			case "received":
				this.slug = "?skip="+this.skip+"&winner="+false+"&delivered="+false+"&received="+true+"&all="+false
				this.getRaffleDrawItems__Admin(this.slug)
				.then(res => {
					this.Loading = false
					this.raffleItems = res.data
				})
				break;
			default:
				this.slug = "?skip="+this.skip+"&winner="+false+"&delivered="+false+"&received="+false+"&all="+true
				this.getRaffleDrawItems__Admin(this.slug)
				.then(res => {
					this.Loading = false
					this.raffleItems = res.data
				})
				break;
		}
	},
	fetchMore(){
		this.skip = this.skip + 10
		this.Loading = true
		switch(this.tab){
			case "all":
				this.slug = "?skip="+this.skip+"&winner="+false+"&delivered="+false+"&received="+false+"&all="+true
				this.getRaffleDrawItems__Admin(this.slug)
				.then(res => {
					for (let i = 0; i < res.data.length; i++) {
                    	const element = res.data[i];
                    	this.raffleItems.push(element)
                	}
					this.Loading = false
				})
				break;
			case "winner":
				this.slug = "?skip="+this.skip+"&winner="+true+"&delivered="+false+"&received="+false+"&all="+false
				this.getRaffleDrawItems__Admin(this.slug)
				.then(res => {
					for (let i = 0; i < res.data.length; i++) {
                    	const element = res.data[i];
                    	this.raffleItems.push(element)
                	}
					this.Loading = false
				})
				break;
			case "delivered":
				this.slug = "?skip="+this.skip+"&winner="+false+"&delivered="+true+"&received="+false+"&all="+false
				this.getRaffleDrawItems__Admin(this.slug)
				.then(res => {
					for (let i = 0; i < res.data.length; i++) {
                    	const element = res.data[i];
                    	this.raffleItems.push(element)
                	}
					this.Loading = false
				})
				break;
			case "received":
				this.slug = "?skip="+this.skip+"&winner="+false+"&delivered="+false+"&received="+true+"&all="+false
				this.getRaffleDrawItems__Admin(this.slug)
				.then(res => {
					for (let i = 0; i < res.data.length; i++) {
                    	const element = res.data[i];
                    	this.raffleItems.push(element)
                	}
					this.Loading = false
				})
				break;
			default:
				this.slug = "?skip="+this.skip+"&winner="+false+"&delivered="+false+"&received="+false+"&all="+true
				this.getRaffleDrawItems__Admin(this.slug)
				.then(res => {
					for (let i = 0; i < res.data.length; i++) {
                    	const element = res.data[i];
                    	this.raffleItems.push(element)
                	}
					this.Loading = false
				})
				break;
		}
	}

  },
	mounted() {
		this.slug = "?skip="+this.skip+"&winner="+false+"&delivered="+false+"&received="+false+"&all="+true
		this.getRaffleDrawItems__Admin(this.slug)
		.then(res => {
			this.raffleItems = res.data
		})
	},
	};
</script>
<style scoped>
@import url("./assets/css/admin.css");
@import url("./assets/css/custom.css");
@import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
@import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
@import url("./assets/vendor/bootstrap/css/bootstrap.min.css");

.tabButton {
  color: white;
  background-color: grey;
  border-radius: 10px;
  padding: 5px;
  display: inline;
  margin-left: 10px;
  cursor: pointer;
}

.active {
  background-color: red;
}

.titleImage{
  width: 100%;
  height: 200px;
}

.bigspinner {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border-top: 5px solid grey;
  display: inline-block;
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.saymessage {
  display: inline-block;
  margin-left: 10px;
}
.smallspiner {
  height: 30px;
  width: 30px;
  margin: 5px;
  border-radius: 50%;
  border-top: 5px solid grey;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

input[readonly] {
  background-color: white !important;
  cursor: text !important;
}

/* this is only due to codepen display don't use this outside of codepen */
.container {
  padding-top: 20px;
}
.tabButton{
	color: white;
	background-color: grey;
	border-radius: 10px;
	padding: 10px;
	padding-left: 10;
	padding-right: 10;
	display: inline;
	margin-left: 10px;
	cursor: pointer;
}
.active {
  background-color: red;
}
</style>
<template>
<div>
<!-- Header Start -->
<Dheader />
<!-- Header End -->
<!-- Left Sidebar Start -->
<Dnav2 />
<!-- Left Sidebar End -->
<!-- Body Start -->
<div class="wrapper" >
	<div class="sa4d25">
		<div class="container" v-if="userId!=''&&userId!=null&&userId!='undefined'">			
			<div class="row">
				<div class="col-lg-12">	
					<h2 class="st_title"><i class="uil uil-book"></i> Create New Book</h2>
				</div>					
			</div>				
			<div class="row" v-if="isInstructor||isAdmin">
				<div class="col-lg-12">
					<div class="course_tabs_1">
						<div id="add-course-tab" class="step-app">
							<div>
								<div class="step-tab-panel step-tab-info active" style="background-color: transparent" id="tab_step1"> 
									<div class="tab-from-content">
										<div class="course__form">
											<div class="general_info10">
												
												<div class="row">
													
													<div class="col-lg-6 col-md-6" v-if="canChangeOwnership">
														<div class="ui search focus mt-30 lbel25">
															<div class="mt-30 lbel25">
																<label>Author</label>
																<small>Any account you choose here takes all the credit for the book</small>
															</div>
															<select class="ui left icon input swdh19 prompt srch_explore" v-model="uniOwner">
																<option value="null" :selected="true">Me</option>
																<option :value="u.user"  v-for="u in uni" :key="u">for {{u.name}}</option>
															</select>
														</div>
													</div>
													<div class="col-lg-6 col-md-6" >
														<div class="ui search focus mt-30 lbel25">
															<div class="mt-30 lbel25">
																<label>Outlet</label>
																<small>If yes, this book will only be visible in your outlet</small>
															</div>
															<select v-model="outlet" class="ui left icon input swdh19 prompt srch_explore">
																<option value=true>YES</option>
																<option value=false :selected="true">NO</option>
															</select>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-lg-6 col-md-6">															
														<div class="ui search focus mt-30 lbel25">
															<label>Book Title*</label>
															<div class="ui left icon input swdh19">
																<input class="prompt srch_explore" type="text" placeholder="Insert your course title." name="title" data-purpose="edit-course-title" maxlength="60" v-model="title">															
																<div class="badge_num">60</div>
															</div>
														</div>									
													</div>
													<div class="col-lg-6 col-md-6">															
														<div class="ui search focus mt-30 lbel25">
															<label>Book Subtitle*</label>
															<div class="ui left icon input swdh19">
																<input class="prompt srch_explore" type="text" placeholder="Insert your course Subtitle." name="subtitle" data-purpose="edit-course-title" maxlength="60" v-model="secondTitle">															
																<div class="badge_num2">120</div>
															</div>
														</div>									
													</div>
													<div class="col-lg-6 col-md-6">															
														<div class="ui search focus mt-30 lbel25">
															<label>Publisher*</label>
															<div class="ui left icon input swdh19">
																<input class="prompt srch_explore" type="text" placeholder="Enter the publisher of the book" v-model="publisher">															
																<div class="badge_num2">120</div>
															</div>
														</div>									
													</div>
													<div class="col-lg-6 col-md-6">															
														<div class="ui search focus mt-30 lbel25">
															<label>Year*</label>
															<div class="ui left icon input swdh19">
																<input class="prompt srch_explore" type="text" placeholder="Enter the year of the book" v-model="year">															
																<div class="badge_num2">120</div>
															</div>
														</div>									
													</div>
													
													<div class="col-lg-6 col-md-6">
														<div class="ui search focus mt-30 lbel25">
															<label>Book Category*</label>
														
															<select class="ui left icon input swdh19 prompt srch_explore" v-model="category" @change="setSubCat()">
																<option value="" :selected="true">Select Category</option>
																<option :value="c._id" v-for="c in categories" :key="c">{{c.name}}</option>
															</select>
														</div>
													</div>
													<div class="col-lg-6 col-md-6">
														<div class="ui search focus mt-30 lbel25">
															<label>Book Subcategory*</label>
														
															<select class="ui left icon input swdh19 prompt srch_explore" v-model="subCategory">
																<option value="">Select Subcategory</option>
																<option :value="c._id" v-for="c in subCategories" :key="c">{{c.name}}</option>
															</select>
														</div>
													</div>	
												</div>
												
												<div class="row">
													<div class="col-lg-4 col-md-4 col-sm-6">
														<div class="ui search focus mt-30 lbel25">
															<label>Language</label>
														
															<select class="ui left icon input swdh19 prompt srch_explore" v-model="language">
																<option value="">Select Language</option>
																<option value="English">English</option>
															</select>
														</div>
													</div>
													
													<div class="col-lg-4 col-md-4 col-sm-6">
														<div class="ui search focus mt-30 lbel25">
															<label>ISBN</label>
															<div class="ui left icon input swdh19">
																<input class="prompt srch_explore" type="number" placeholder="Insert a ISBN" v-model="ISBN">															
																<div class="badge_num2">002</div>
															</div>
														</div>	
													</div>
													<div class="col-lg-4 col-md-4 col-sm-6">
														<div class="ui search focus mt-30 lbel25">
															<label>File Type</label>
															<select class="ui left icon input swdh19 prompt srch_explore" v-model="fileType">
																<option value="">Select File Type</option>
																<option value="PDF">PDF</option>
																<!-- <option value="ePUB">ePUB</option> -->
															</select>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-lg-12 col-md-12">
														<div class="course_des_textarea mt-30 lbel25">
															<label>About Book*</label>
															<div class="course_des_bg">
																<div class="textarea_dt">															
																	<div class="ui form swdh339">
																		<div class="field">
																			<textarea rows="5" name="description" id="id_course_description" placeholder="Insert your course description" v-model="about"></textarea>
																		</div>
																	</div>										
																</div>
															</div>
														</div>
													</div>
													<div class="col-lg-12 col-md-12">
														<div class="course_des_textarea mt-30 lbel25">
															<label>Preface*</label>
															<div class="course_des_bg">
																<div class="textarea_dt">															
																	<div class="ui form swdh339">
																		<div class="field">
																			<textarea rows="5" name="description" id="id_course_description" placeholder="Insert your book preface" v-model="preface"></textarea>
																		</div>
																	</div>										
																</div>
															</div>
														</div>
													</div>
													<div class="col-lg-6 col-md-6">
														<div class="course_des_textarea mt-30 lbel25">
															<label>Total number of Pages*</label>
															<div class="ui left icon input swdh19">
																<input class="prompt srch_explore" type="number"  placeholder="Insert your book books size in pages" v-model="pages">															
																<div class="badge_num2">120</div>
															</div>	
														</div>
													</div>
												</div>
											</div>
				
											<div class="price_course">
												<div class="row">
													<div class="col-lg-12">
														<div class="price_title">
															<h4><i class="uil uil-dollar-sign-alt"></i>Pricing</h4>
														</div>
													</div>
													<div class="col-lg-2 col-md-3 col-sm-6">
														<div class="ui search focus mt-30 lbel25">
															<label>Currency*</label>
														
															<select class="ui left icon input swdh19 prompt srch_explore">
																<option value="naira">Naira</option>
															</select>
														</div>
													</div>
													<div class="col-lg-4 col-md-4">															
														<div class="ui search focus mt-30 lbel25">
															<label>What is Book Price*</label>
															<div class="ui left icon input swdh19">
																<input class="prompt srch_explore" type="number" placeholder="Insert Book Price" v-model="price">															
																<div class="badge_num2">120</div>
															</div>
														</div>									
													</div>
													<div class="col-lg-4 col-md-4">															
														<div class="ui search focus mt-30 lbel25">
															<label>What is Book discount percent</label>
															<div class="ui left icon input swdh19">
																<input class="prompt srch_explore" type="number" placeholder="Insert a number between 1 - 50" v-model="discountPercent">															
																<div class="badge_num2">002</div>
															</div>
														</div>									
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="tab-from-content">
										<div class="title-icon">
											<h3 class="title"><i class="uil uil-image-upload"></i>View</h3>
										</div>
										<div class="course__form">
											<div class="view_info10">
												<div class="row">
													<div class="col-lg-12">	
														<div class="view_all_dt">	
															<div class="view_img_left">	
																<div class="view__img">	
																	<img src="../assets/images/courses/add_img.jpg" alt="" id="previewImage">
																</div>
															</div>
															<div class="view_img_right">	
																<h4>Cover Image</h4>
																<p>Upload your course image here. It must meet our course image quality standards to be accepted. Important guidelines: 750x422 pixels; .jpg, .jpeg,. gif, or .png. no text on the image.</p>
																<div class="upload__input">
																	<div class="input-group">
																		<div class="custom-file">
																			<input type="file" class="custom-file-input" id="thumbnail" ref="thumbnail" v-on:change="handleFileUpload()">
																			<label class="custom-file-label" for="inputGroupFile04" id="thumbnaildisplay">No Choose file</label>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														
													</div>
												</div>
											</div>
										</div>
										<div class="course__form">
											<div class="view_info10">
												<div class="row">
													<div class="col-lg-12">	
														<div class="view_all_dt">	
															<div class="view_img_left">	
																<div class="view__img">	
																	<img src="../assets/images/about/title_bg.jpg" style="height:200px" v-if="pdf==''" alt="" id="previewImage">
																	<object data="" v-show="pdf!=''" type="application/pdf" id="previewPDF" style="width: 100%; height: 100%">
																		<p>Sorry but your browser doesn't support pdf, We cant display pdf on this browser. Try using a desktop or laptop computer</p>
																	</object>
																</div>
															</div>
															<div class="view_img_right">	
																<h4>PDF Book File</h4>
																<p>Upload your book file here. It must be a PDF file and less than 100mb </p>
																<div class="upload__input">
																	<div class="input-group">
																		<div class="custom-file">
																			<input type="file" class="custom-file-input" id="thumbnail" ref="pdf" v-on:change="handlePDFUpload()">
																			<label class="custom-file-label" for="inputGroupFile04" id="pdfdisplay">No Choose file</label>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<Errors v-if="error">
								<ul>
									<li v-for="error in error" :key="error">{{error}}</li>	
								</ul>
							</Errors>
							<br>
							<Success v-if="success">
								<ul>
									<li>Action Successful</li>
									<li>Book created on faslearn </li>	
									<!-- <button  class="login-btn" type="button" style="background-color: #006400" >Click here to add course contents</button> -->
								</ul>
								<p></p>
							</Success>
							<Loading v-if="Loading"/>
							<div class="step-footer step-tab-pager" v-if="isInstructor||isAdmin">
								<button v-if="!success&&!Loading" class="btn btn-default steps_btn" @click.prevent="registerBook()" id="btn">Submit for Review</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-else>
				<div class="col-12">
					<div class="course_tabs_1">
						<div id="add-course-tab" class="step-app">
							<ul class="step-steps">
								<li class="pageActive">
									<a >
										<span class="number"></span> 
										<span class="step-name" style="color: white;">General Information</span>
									</a>
								</li>
							</ul>
							<div class="step-content">
								<div class="step-tab-panel step-tab-info active" style="background-color: transparent" id="tab_step1"> 
									<div class="tab-from-content">
										<div class="title-icon">
											<h3 class="title"><i class="uil uil-info-circle"></i>ACCESS DENIED</h3>
											<p><strong><b>Oops!!!</b></strong> You have to be an instructor or an admin to an institution to add a course to faslearn</p>
											
											<div class="step-footer step-tab-pager" >
												
												<p>If you have applied to be an instructor or admin to a new institution, then your application has not been approved yet or possibly declined, please check status on your workspace dashboard</p>
												
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<br><br><br>
	<Dfooter />
</div>
<!-- Body End -->
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Errors from '@/components/Errors.vue'
import Success from '@/components/Success.vue'
import Loading from '@/components/Loading.vue'
import Dheader from '@/components/dashboard/Header.vue'
import Dnav2 from '@/components/dashboard/Nav2.vue'
import Dfooter from '@/components/dashboard/Footer.vue'
export default {
	name: 'Dhome',
	data(){
		return{
			userId:'', 
			uniOwner: null,
			psuedoUser: null,
			title:'', 
			secondTitle:'', 
			category:'', 
			subCategory: '',
			price:'',
			discountPercent:0,
			thumbnail:'',
			pdf: '',
			year: '',
			preface: '',
			about: '',
			pages: '',
			language: '',
			publisher: '',
			ISBN: '',
			fileType: '',
			outlet: false,
			newBookId: '',
			categories: '',
			subCategories: '',
			visibilty: '',
			success : false,
			error: '',
			Loading: false,
			lecturer : '',
			canChangeOwnership : false,
			uni : [],
		}
	},
	computed:{
		//...mapGetters(['error']),
        ...mapGetters(['isInstructor']),
		...mapGetters(['isAdmin'])
	},
    components: {
        Dheader,
        Dnav2,
		Dfooter,
		Errors,
		Success,
		Loading
	},
	methods: {
		...mapActions(['createNewBook']),
		...mapActions(['check_user_instructor','fetchInstructor_Details']),
		...mapActions(['fetch_categories']),
		registerBook(){
			if (this.validateInputs()) {
				this.Loading = true
				let courseOwner = this.userId
				if (this.uniOwner!=null&&this.uniOwner!="null") {
					this.psuedoUser = this.userId
					courseOwner = this.uniOwner
				}
				document.getElementById('btn').style.display = 'none';
				let formData = new FormData();
				formData.append('userId', courseOwner);
				formData.append('psuedoUser', this.psuedoUser);
				formData.append('title', this.title);
				formData.append('secondTitle', this.secondTitle);
				formData.append('category', this.category);
				formData.append('subCategory', this.subCategory);
				formData.append('price', this.price);
				formData.append('discountPercent', this.discountPercent);
				formData.append('thumbnail', this.thumbnail);
				formData.append('pdf', this.pdf);
				formData.append('year', this.year);
				formData.append('preface', this.preface);
				formData.append('about', this.about);
				formData.append('pages', this.pages);
				formData.append('language', this.language);
				formData.append('publisher', this.publisher);
				formData.append('ISBN', this.ISBN);
				formData.append('outlet', this.outlet);
				formData.append('fileType', this.fileType);

				this.createNewBook(formData)
				.then(res => {
					this.newBookId = res.data._id;
					this.Loading = false
					this.success = true;
				})
				.catch(err => {
					this.Loading = false
					document.getElementById('btn').style.display = 'block';
					console.log(err)
				})
				
			}
			
		},

		handleFileUpload(){
			if (this.validateFile(this.$refs.thumbnail.files[0])) {
				this.thumbnail = this.$refs.thumbnail.files[0];
				const file = this.$refs.thumbnail.files[0];
				const previewImage = document.getElementById('previewImage');
				if (file) {
					const reader = new FileReader();
					reader.addEventListener("load", function () {
						previewImage.setAttribute('src', this.result);
					});
					reader.readAsDataURL(file);
				}
				document.getElementById('thumbnaildisplay').innerHTML = this.thumbnail.name
			}
		},

		handlePDFUpload(){
			if (this.validatePDF(this.$refs.pdf.files[0])) {
				this.pdf = this.$refs.pdf.files[0];
				const file = this.$refs.pdf.files[0];
				const previewImage = document.getElementById('previewPDF');
				if (file) {
					const reader = new FileReader();
					reader.addEventListener("load", function () {
						previewImage.setAttribute('data', this.result);
					});
					reader.readAsDataURL(file);
				}
				document.getElementById('pdfdisplay').innerHTML = this.pdf.name
			}
		},

		setSubCat(){
            this.subCategory = ''
            for (let i = 0; i < this.categories.length; i++) {
                const element = this.categories[i];
                if (element._id == this.category) {
                    this.subCategories = element.subCategory
                }
                
            }
        },
        validateFile(file){
            let flag = true;
            let message = '';
            let error =[];
            const allowedExtensions =  ['jpg','png','jpeg','JPG','PNG','JPEG'];
            //const allowedExtensionsL =  ['jpg','png','jpeg','JPG','PNG','JPEG','pdf','PDF']
            if (!allowedExtensions.includes(file.name.split(".").pop())) {
                message = 'Please insert a valid image file';
                error.push(message)
                flag = false
            }
            
            if (file.size > 1000000) {
                message = 'Please insert smaller size image less than 1mb and not '+file.size;
                error.push(message)
                flag = false
            }
            if (flag == true) {
                this.error = ''
                return true
            }else{
                this.error = error
                return false
            }
		},
		validatePDF(pdf){
            let flag = true;
            let message = '';
            let error =[];
            const allowedExtensions =  ['pdf','PDF'];
            if (!allowedExtensions.includes(pdf.name.split(".").pop())) {
                message = 'Please insert a valid image pdf';
                error.push(message)
                flag = false
            }
            
            if (pdf.size > 100000000) {
                message = 'Please insert smaller size image less than 100mb and not '+pdf.size;
                error.push(message)
                flag = false
            }
            if (flag == true) {
                this.error = ''
                return true
            }else{
                this.error = error
                return false
            }
		},
		validateInputs(){
            let flag = true;
            let message = '';
			let error =[];
			if (this.thumbnail=='') {
                message = 'Please input image thumbnail';
                error.push(message)
                flag = false
            }
			if (this.pdf=='') {
                message = 'Please input pdf file';
                error.push(message)
                flag = false
            }
			if (this.price=='') {
                message = 'Please input price ';
                error.push(message)
                flag = false
            }
			if (this.discountPercent==0||this.discountPercent==null) {
                message = 'Please input discount Percent ';
                error.push(message)
                flag = false
            }
			if (this.discountPercent<1||this.discountPercent>100) {
                message = 'discount Percent can not be less than 1 or greater than 100';
                error.push(message)
                flag = false
            }
			if (this.subCategory=='') {
                message = 'Please input subCategory ';
                error.push(message)
                flag = false
            }
			if (this.category=='') {
                message = 'Please input category ';
                error.push(message)
                flag = false
            }
			if (this.title=='') {
                message = 'Please input title ';
                error.push(message)
                flag = false
            }
			if (this.secondTitle=='') {
                message = 'Please input second Title ';
                error.push(message)
                flag = false
            }
			if (this.year=='') {
                message = 'Please input year';
                error.push(message)
                flag = false
            }
			if (this.preface=='') {
                message = 'Please input preface';
                error.push(message)
                flag = false
            }
			if (this.about=='') {
                message = 'Please input about';
                error.push(message)
                flag = false
            }
			if (this.pages=='') {
                message = 'Please input pages';
                error.push(message)
                flag = false
            }
			if (this.language=='') {
                message = 'Please input language';
                error.push(message)
                flag = false
            }
			if (this.publisher=='') {
                message = 'Please input publisher';
                error.push(message)
                flag = false
            }
			if (this.ISBN=='') {
                message = 'Please input ISBN';
                error.push(message)
                flag = false
            }
			if (this.fileType=='') {
                message = 'Please input fileType';
                error.push(message)
                flag = false
            }
            if (flag == true) {
                this.error = ''
                return true
            }else{
                this.error = error
                return false
            }
        },
	},  
    mounted(){ 
		this.userId = localStorage.getItem('fmruser');
		this.fetch_categories()
        .then(res => {
            this.categories = res.data
		})
		
		if (this.isInstructor) {
			this.fetchInstructor_Details(this.userId)
			.then(res => {
				this.lecturer = res.data[0]
				for (let i = 0; i < this.lecturer.institutionsEmployed.length; i++) {
					var obj = {
						name : '',
						user: ''
					}
					const element = this.lecturer.institutionsEmployed[i];
					if (element.institutionId.length!=0) {
						if (element.accepted) {
							obj.name = element.institutionId[0].institutionName 
							obj.user = element.institutionId[0].user[0] 
							this.uni.push(obj)
						}
					}
				}
				this.canChangeOwnership = true
			})	
		}
    }
}
</script>
<style scoped>
	@import url("../assets/css/style.css");
    /* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
    @import url("../assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/vertical-responsive-menu.min.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/instructor-responsive.css");
    @import url("../assets/css/responsive.css");
    @import url("../assets/css/night-mode.css");
    @import url("../assets/vendor/fontawesome-free/css/all.min.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("../assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("../assets/vendor/semantic/semantic.min.css");

	.pageInActive{
        padding: 10px;
        width: 33%;
    }

    .pageActive{
        padding: 10px;
        background-color: red;
        color: white;
        width: 33%;
        border-radius: 10px;
    }
</style>
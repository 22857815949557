<template>
    <div>
        <Modal>
            <div style="background-color: #b2bdb5" v-if="modalType=='affilliation'">
                <div >
                    <div class="panel-heading" role="tab" id="headingOne">
                        <div class="panel-title adcrse1250">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                View Course Affiliation Details
                            </a>
                        </div>
                    </div>
                    <div class="panel-body adcrse_body">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">	
                                <div class="ui search focus mt-20 lbel25">
                                    <label>Instructor</label>
                                    <div class="ui left icon input swdh19">
                                        <p>{{affiliationObject.user[0].firstName}} {{affiliationObject.user[0].lastName}}</p>
                                        <p>{{affiliationObject.user[0].email}}</p>																
                                    </div>
                                    <label>Instructor Email</label>
                                    <div class="ui left icon input swdh19">
                                        <p>{{affiliationObject.user[0].email}}</p>																
                                    </div>
                                </div>										
                            </div>
                            <div class="col-lg-6 col-md-6">	
                                <div class="ui search focus mt-20 lbel25">
                                    <h4>Affiliated Institions for this Course</h4>
                                    <div class="ui left icon input swdh19">
                                        <ol>
                                            <li v-for="a in affiliationObject.affiliatedInstitutions" :key="a"><strong>{{a.institutionId[0].institutionName}}</strong> <span style="color: red; font-size: 10px">{{a.institutionId[0].institutionType}}</span></li>
                                        </ol>														
                                    </div>
                                </div>
                                <div class="ui search focus mt-20 lbel25">
                                    <div class="col-lg-6 col-md-6">	
                                        <router-link :to="'/Dcoursedetail/'+affiliationObject._id">
                                        <button class="discount_btn" type="submit">View More Details</button>	
                                        </router-link>								
                                    </div>
                                </div>										
                            </div>
                            
                            <div class="col-lg-6 col-md-6">
                                <h4>Course</h4>
                                <div class="mt-20 lbel25">	
                                    <label>{{affiliationObject.title}}</label>
                                    <p>{{affiliationObject.secondTitle}}</p>
                                </div>
                                <div class="mt-20 lbel25">
                                    <i>whatYouLearn</i>	
                                    <p>{{affiliationObject.whatYouLearn}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <Loading v-if="Loading" />
                    <Success v-if="success">
                        <ul>
                            <li>Action Successful</li>
                        </ul>
                    </Success>
                    <div class="responds" v-for="a in affiliationObject.affiliatedInstitutions" :key="a">
                        <div v-if="a.institutionId[0].user[0] == userId&&!a.accepted&&!a.rejected">	
                            <button class="discount_btn" type="button" @click.prevent="manageAffiliation('grant',a._id)" style="background-color: green; display: inline-block; ">Accept Affiliation for {{a.institutionId[0].institutionName}}</button>
                            <button class="discount_btn" type="button" @click.prevent="manageAffiliation('deny',a._id)" style="display: inline-block; ">Deny Affiliation for {{a.institutionId[0].institutionName}}</button>									
                        </div>
                    </div>
                </div>
            </div>
            <div style="background-color: #b2bdb5" v-if="modalType=='addPackage'">
                <div >
                    <div class="panel-heading" role="tab" id="headingOne">
                        <div class="panel-title adcrse1250">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                Add new Bundle
                            </a>
                        </div>
                    </div>
                    <div class="panel-body adcrse_body">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">															
                                <div class="ui search focus mt-30 lbel25">
                                    <label>Bundle Title *</label>
                                    <div class="ui left icon input swdh19">
                                        <input class="prompt srch_explore" type="text" placeholder="Insert your bundle title." name="title"  maxlength="60" v-model="packageTitle">															
                                        <div class="badge_num">60</div>
                                    </div>
                                </div>									
                            </div>
                            <div class="col-lg-6 col-md-6">															
                                <div class="ui search focus mt-30 lbel25">
                                    <label>Bundle Percent *</label>
                                    <div class="ui left icon input swdh19">
                                        <input class="prompt srch_explore" type="number" placeholder="Insert your percentage off each bundle." name="percent"  maxlength="60" v-model="packagePercent">															
                                        <div class="badge_num">100</div>
                                    </div>
                                </div>									
                            </div>
                            <div class="col-lg-6 col-md-6">															
                                <div class="ui search focus mt-30 lbel25">
                                    <label>Bundle Expiring Date *</label>
                                    <div class="ui left icon input swdh19">
                                        <input class="prompt srch_explore" type="date" placeholder="Insert your percentage off each course." name="percent"  maxlength="60" v-model="packageDate">															
                                        
                                    </div>
                                </div>									
                            </div>
                            <br>
                            <div class="col-lg-12 col-md-12">	
                                <br>
                                <Loading v-if="Loading" />
                                <Errors v-if="error.length!=0">
                                    <ul>
                                        <li v-for="error in error" :key="error">{{error}}</li>	
                                    </ul>
                                </Errors>
                                <Success v-if="success">
                                    <ul>
                                        <li>Action Successful</li>
                                    </ul>
                                </Success>
                            </div>
                            <div class="responds">
                                <br>
                                <div v-if="packages.length<10" class="step-footer step-tab-pager">
                                    <button class="btn btn-default steps_btn" @click.prevent="registerPackage()" id="btn">Create Package</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="background-color: #b2bdb5" v-if="modalType=='editPackage'">
                <div >
                    <div class="panel-heading" role="tab" id="headingOne">
                        <div class="panel-title adcrse1250">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                Edit Bundle
                            </a>
                        </div>
                    </div>
                    <div class="panel-body adcrse_body">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">															
                                <div class="ui search focus mt-30 lbel25">
                                    <label>Bundle Title *</label>
                                    <div class="ui left icon input swdh19">
                                        <input class="prompt srch_explore" type="text" placeholder="Insert your Bundle title." name="title"  maxlength="60" v-model="packageTitle">															
                                        <div class="badge_num">60</div>
                                    </div>
                                </div>									
                            </div>
                            <div class="col-lg-6 col-md-6">															
                                <div class="ui search focus mt-30 lbel25">
                                    <label>Bundle Percent *</label>
                                    <div class="ui left icon input swdh19">
                                        <input class="prompt srch_explore" type="number" placeholder="Insert your percentage off each course." name="percent"  maxlength="60" v-model="packagePercent">															
                                        <div class="badge_num">100</div>
                                    </div>
                                </div>									
                            </div>
                            <div class="col-lg-6 col-md-6">															
                                <div class="ui search focus mt-30 lbel25">
                                    <label>Package Expiring Date * {{packageDate}}</label>
                                    <div class="ui left icon input swdh19">
                                        <input class="prompt srch_explore" type="date" placeholder="Insert your percentage off each course." name="percent"  maxlength="60" v-model="packageDate">															
                                        
                                    </div>
                                </div>									
                            </div>
                            <br>
                            <div class="col-lg-12 col-md-12">	
                                <br>
                                <Loading v-if="Loading" />
                                <Errors v-if="error.length!=0">
                                    <ul>
                                        <li v-for="error in error" :key="error">{{error}}</li>	
                                    </ul>
                                </Errors>
                                <Success v-if="success">
                                    <ul>
                                        <li>Action Successful</li>
                                    </ul>
                                </Success>
                            </div>
                            <div class="responds">
                                <br>
                                <div v-if="packages.length<10" class="step-footer step-tab-pager">
                                    <button class="btn btn-default steps_btn" @click.prevent="updatePackage()" id="btn">Update Package</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="background-color: #b2bdb5" v-if="modalType=='addCourse'">
                <div >
                    <div class="panel-heading" role="tab" id="headingOne">
                        <div class="panel-title adcrse1250">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                Add Course to Bundle
                            </a>
                        </div>
                    </div>
                    
                    <div class="panel-body adcrse_body">
                        <h3>{{packageTitle}}</h3>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">															
                                <div class="ui search focus mt-30 lbel25">
                                    <label>Select courses for this Bundle *</label>
                                    <div v-for="c in myCourses" :key="c">
                                        <input :name="c._id" type="checkbox" style="display: inline-block; width: 20px; height: 20px;" v-if="c.isApproved" :value="c._id" v-model="selectedCourses">
                                        <label :for="c._id" style="display: inline-block; margin-left: 10px" v-if="c.isApproved">{{c.title}}</label>
                                    </div>
                                </div>									
                            </div>
                            <br>
                            <div class="col-lg-12 col-md-12">	
                                <br>
                                <Loading v-if="Loading" />
                                <Errors v-if="error.length!=0">
                                    <ul>
                                        <li v-for="error in error" :key="error">{{error}}</li>	
                                    </ul>
                                </Errors>
                                <Success v-if="success">
                                    <ul>
                                        <li>Action Successful</li>
                                    </ul>
                                </Success>
                            </div>
                            <div class="responds">
                                <br>
                                <div v-if="packages.length<10" class="step-footer step-tab-pager">
                                    <button class="btn btn-default steps_btn" @click.prevent="submitCourses()" id="btn">Update Package</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
	    </Modal>

        <Dheader />

        <Dnav2 />
        
        <div class="wrapper">
            <div class="sa4d25">
                <div class="container-fluid">			
                    <div class="row">
                        <div class="col-lg-12">	
                            <h2 class="st_title"><i class="uil uil-book-alt"></i>Courses</h2>
                        </div>			
                        <div class="col-md-12" v-if="isInstructor||isAdmin">
                            <div class="card_dash1">
                                <div class="card_dash_left1"> 
                                    <i class="uil uil-book-alt"></i>
                                    <h1>Jump Into Course Creation</h1>
                                </div>
                                <router-link to="/Dnewcourse">
                                <div class="card_dash_right1">
                                    <button class="create_btn_dash" type="button">Create Your Course </button>
                                </div>
                                </router-link>
                            </div>
                        </div>					
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="my_courses_tabs">
                                <ul class="nav nav-pills my_crse_nav" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="pills-my-courses-tab" data-toggle="pill" href="#pills-my-courses" role="tab" aria-controls="pills-my-courses" aria-selected="true"><i class="uil uil-download-alt"></i>My Purchases</a>
                                    </li>
                                    <li class="nav-item" v-if="isAdmin||isInstructor">
                                        <a class="nav-link" id="pills-my-purchases-tab" data-toggle="pill" href="#pills-my-purchases" role="tab" aria-controls="pills-my-purchases" aria-selected="false"><i class="uil uil-book-alt"></i>My Courses</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-upcoming-courses-tab" data-toggle="pill" href="#pills-upcoming-courses" role="tab" aria-controls="pills-upcoming-courses" aria-selected="false"><i class="uil uil-upload-alt"></i>Pseudo Uploads</a>
                                    </li>
                                    <li class="nav-item" v-if="isAdmin">
                                        <a class="nav-link" id="pills-discount-tab" data-toggle="pill" href="#pills-discount" role="tab" aria-controls="pills-discount" aria-selected="false"><i class="uil uil-tag-alt"></i>Affiliations Request</a>
                                    </li>
                                    <li class="nav-item" v-if="isAdmin||isInstructor">
                                        <a class="nav-link" id="pills-promotions-tab" data-toggle="pill" href="#pills-promotions" role="tab" aria-controls="pills-promotions" aria-selected="false"><i class="uil uil-package"></i>Bundles</a>
                                    </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" style="background-color: transparent" id="pills-my-courses" role="tabpanel">
                                        <div class="table-responsive mt-30" style="background-color: transparent">
                                            <table class="table ucp-table">
                                                <thead class="thead-s">
                                                    <tr>
                                                        <th class="text-center" scope="col">Item No.</th>
                                                        <th>Title</th>
                                                        <th class="text-center" scope="col">2nd Title</th>
                                                        <!-- <th class="text-center" scope="col">Sales</th>
                                                        <th class="text-center" scope="col">Parts</th> -->
                                                        <th class="text-center" scope="col">Category</th>
                                                        <th class="text-center" scope="col">Status</th>
                                                        <th class="text-center" scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="p in purchasedCourses" :key="p">
                                                        <td class="text-center">{{1+(purchasedCourses.indexOf(p))}}</td>
                                                        <td>{{p.title}}</td>
                                                        <td class="text-center">{{p.secondTitle}}</td>
                                                        <!-- <td class="text-center">15</td>
                                                        <td class="text-center">5</td> -->
                                                        <td class="text-center"><a href="#">{{p.category[0].name}}</a></td>
                                                        <td class="text-center"><b class="course_active">Active</b></td>
                                                        <router-link :to="'/Dcoursedetail/'+p._id">
                                                        <td class="text-center">
                                                            <a href="#" title="Edit" class="gray-s"><i class="uil uil-eye"></i></a>
                                                        </td>
                                                        </router-link>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br><br><br>
                                        <center>
                                            <button class="live_link" type="button" v-if="!Loading" @click="fetchMore('myPurchaseSkip')">Load More</button>	
                                        </center>
                                        <br><br><br>
                                    </div>
                                    <div class="tab-pane fade" style="background-color: transparent" id="pills-my-purchases" role="tabpanel">
                                        <div class="table-responsive mt-30" style="background-color: transparent">
                                            <table class="table ucp-table">
                                                <thead class="thead-s">
                                                    <tr>
                                                        <th class="text-center" scope="col">Item No.</th>
                                                        <th class="cell-ta" scope="col">Title</th>
                                                        <th class="text-center" scope="col">2nd Title</th>
                                                        <!-- <th class="cell-ta" scope="col">Vendor</th> -->
                                                        <th class="cell-ta" scope="col">Category</th>
                                                        <th class="text-center" scope="col">Status</th>
                                                        <!-- <th class="text-center" scope="col">Price</th>
                                                        <th class="text-center" scope="col">Purchase Date</th> -->
                                                        <th class="text-center" scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="p in myCourses" :key="p">
                                                        <td class="text-center">{{1+(myCourses.indexOf(p))}}</td>
                                                        <td class="cell-ta">{{p.title}}</td>
                                                        <td class="text-center">{{p.secondTitle}}</td>
                                                        <!-- <td class="cell-ta"><a href="#">Zoena Singh</a></td> -->
                                                        <td class="cell-ta">{{p.category[0].name}}</td>
                                                        <td class="text-center">
                                                            <b class="course_active" style="color: green" v-if="p.isApproved">Approved</b>
                                                            <b  class="course_active" v-else>
                                                                <b v-if="p.adminSeen" style="color: red">Denied</b>
                                                                <b v-else style="color: orange">Pending</b>
                                                            </b>
                                                        </td>
                                                        <!-- <td class="text-center">$15</td>
                                                        <td class="text-center">25 March 2020</td> -->
                                                        <td class="text-center">
                                                            <router-link :to="'/Deditcourse/'+p._id">
                                                            <a title="Edit" class="gray-s"><i class="uil uil-edit-alt"></i></a>
                                                            </router-link>
                                                            <!-- <a title="Delete" class="gray-s" @click="deleteCourse(p._id,p.title)"><i class="uil uil-trash-alt"></i></a> -->
                                                            <router-link :to="'/Dcoursedetail/'+p._id">
                                                            <a title="View" class="gray-s"><i class="uil uil-eye"></i></a>
                                                            </router-link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>	
                                        <br><br><br>
                                        <center>
                                            <button class="live_link" type="button" v-if="!Loading" @click="fetchMore('instructorSkip')">Load More</button>	
                                        </center>
                                        <br><br><br>							
                                    </div>
                                    <div class="tab-pane fade" style="background-color: transparent" id="pills-upcoming-courses" role="tabpanel">
                                        <div class="table-responsive mt-30" style="background-color: transparent">
                                            <table class="table ucp-table">
                                                <thead class="thead-s">
                                                    <tr>
                                                        <th class="text-center" scope="col">Item No.</th>
                                                        <th class="cell-ta" scope="col">Title</th>
                                                        <th class="text-center" scope="col">2nd Title</th>
                                                        <th class="cell-ta" scope="col">Category</th>
                                                        <th class="cell-ta" scope="col">Status</th>
                                                        <th class="text-center" scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="p in psuedoCourses" :key="p" :class="{ off : p.user[0]._id == p.psuedoUser[0] }">
                                                        <td class="text-center">{{1+(psuedoCourses.indexOf(p))}}</td>
                                                        <td class="cell-ta">{{p.title}}</td>
                                                        <td class="text-center">{{p.secondTitle}}</td>
                                                        <!-- <td class="cell-ta"><a href="#">Zoena Singh</a></td> -->
                                                        <td class="cell-ta">{{p.category[0].name}}</td>
                                                        <td class="text-center">
                                                            <b class="course_active" style="color: green" v-if="p.isApproved">Approved</b>
                                                            <b class="course_active" v-else>
                                                                <b v-if="p.adminSeen" style="color: red">Denied</b>
                                                                <b v-else style="color: orange">Pending</b>
                                                            </b>
                                                        </td>
                                                        <td class="text-center">
                                                            <router-link :to="'/Deditcourse/'+p._id">
                                                            <a title="Edit" class="gray-s"><i class="uil uil-edit-alt"></i></a>
                                                            </router-link>
                                                            <router-link :to="'/Dcoursedetail/'+p._id">
                                                            <a title="View" class="gray-s"><i class="uil uil-eye"></i></a>
                                                            </router-link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br><br><br>
                                        <center>
                                            <button class="live_link" type="button" v-if="!Loading" @click="fetchMore('myCourseSkip')">Load More</button>	
                                        </center>
                                        <br><br><br>
                                    </div>
                                    <div class="tab-pane fade" style="background-color: transparent" id="pills-discount" role="tabpanel">
                                        <div class="table-responsive mt-30" style="background-color: transparent">
                                            <table class="table ucp-table">
                                                <thead class="thead-s">
                                                    <tr>
                                                        <th class="text-center" scope="col">Item No.</th>
                                                        <th class="cell-ta">Course</th>
                                                        <th class="text-center" scope="col">Category</th>
                                                        <th class="text-center" scope="col">Price</th>
                                                        <th class="text-center" scope="col">Percentage</th>
                                                        <th class="text-center" scope="col">Status</th>
                                                        <th class="text-center" scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="a in myAffiliations" :key="a">
                                                        <td class="text-center">{{1+(myAffiliations.indexOf(a))}}</td>
                                                        <td class="cell-ta">{{a.title}}</td>
                                                        <td class="text-center">{{a.category[0].name}}</td>
                                                        <td class="text-center">{{a.price.$numberDecimal}}</td>
                                                        <td class="text-center">{{(1/(a.affiliatedInstitutions.length+1))*100}}%</td>
                                                        <td class="text-center">
                                                            <b class="course_active" v-for="i in a.affiliatedInstitutions" :key="i">
                                                                <i v-if="i.institutionId[0].user[0]==userId && i.accepted" style="color: green">
                                                                    Active
                                                                </i>
                                                                <i v-if="i.institutionId[0].user[0]==userId && !i.accepted">
                                                                    Pending
                                                                </i>
                                                            </b>
                                                        </td>
                                                        <td class="text-center">
                                                            <a title="View Details" class="gray-s" @click="activateModal(a._id, 'affilliation')"><i class="uil uil-eye"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" style="background-color: transparent" id="pills-promotions" role="tabpanel" aria-labelledby="pills-promotions-tab">
                                        <div class="table-responsive mt-30" style="background-color: transparent">
                                            <br>
                                            <center>
                                                <button class="live_link" type="button" v-if="!Loading" @click="activateModal(null, 'addPackage')"><i class="uil uil-plus"></i> Add new Bundle</button>	
                                            </center>
                                            <br>
                                            <div v-for="p in packages" :key="p">
                                                <h4 style="margin-top: 5px; margin-left: 5px">{{p.title}}</h4>
                                                <div class="controls">
                                                    <a title="Edit" class="gray-s"  @click="activateModal(p._id, 'editPackage')"><i class="uil uil-edit"></i></a>
                                                    <a title="Upload" class="gray-s" @click="activateModal(p._id, 'addCourse')"><i class="uil uil-upload"></i></a>
                                                    <!-- <a title="Delete" class="gray-s" ><i class="uil uil-trash-alt"></i></a> -->
                                                </div>
                                                <table class="table ucp-table">
                                                    <thead class="thead-s">
                                                        <tr>
                                                            <th class="text-center" scope="col">Item No.</th>
                                                            <th class="cell-ta">Course</th>
                                                            <th class="text-center" scope="col">Price</th>
                                                            <th class="text-center" scope="col">Percentage</th>
                                                            <th class="text-center" scope="col">Status</th>
                                                            <th class="text-center" scope="col">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="a in p.courses" :key="a">
                                                            <td class="text-center">{{1+(p.courses.indexOf(a))}}</td>
                                                            <td class="cell-ta">{{a.title}}</td>
                                                            <td class="text-center">{{a.price.$numberDecimal}}</td>
                                                            <td class="text-center">{{(1/(a.affiliatedInstitutions.length+1))*100}}%</td>
                                                            <td class="text-center">
                                                                <b class="course_active" style="color: green" v-if="a.isApproved">Approved</b>
                                                                <b class="course_active" v-else>
                                                                    <b v-if="a.adminSeen" style="color: red">Denied</b>
                                                                    <b v-else style="color: orange">Pending</b>
                                                                </b>
                                                            </td>
                                                            <td class="text-center">
                                                                <router-link :to="'/Dcoursedetail/'+a._id">
                                                                    <a title="View Details" class="gray-s"><i class="uil uil-eye"></i></a>
                                                                </router-link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <br>
                                                <br>
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dfooter />
        </div>
    </div>

</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Errors from '@/components/Errors.vue'
import Dheader from '@/components/dashboard/Header.vue'
import Dnav2 from '@/components/dashboard/Nav2.vue'
import Dfooter from '@/components/dashboard/Footer.vue'
import Modal from "@/components/Modal.vue";
import Loading from "@/components/Loading.vue"
import Success from "@/components/Success.vue"
export default {
    name: 'Dhome',
    data(){
        return{
            userId: '',
            purchasedCourses: '',
            myCourses: '',
            psuedoCourses: '',
            myAffiliations: '',
            Loading: false,
            success: false,
            courseId: '',
            recentCourses: '',
            affiliationObject: { user: [{firstName: '', lastName: '', email: ''}] },
            myCourseSkip: 0,
            myPurchaseSkip: 0,
            instructorSkip: 0,
            affiliateSkip : 0,
            packages: [],
            modalType: "affilliation",
            packageId: '',
            packageSkip: 0,
            packageTitle: '',
            packagePercent: '',
            packageDate: '',
            error: [],
            selectedCourses: []
        }
    },
    components: {
        Dheader,
        Dnav2,
        Dfooter,
        Modal,
        Success,
        Errors,
        Loading
    }, 
    computed: {
        ...mapGetters(['isInstructor']),
        ...mapGetters(['isAdmin'])
    }, 
    methods: {
        ...mapActions(['fetch_purchased_courses']),
        ...mapActions(['fetch_recent_courses']),
        ...mapActions(['fetchInstructorCoursesForInstructorView','fetch_PsuedoUploadCourses']),
        ...mapActions(['delete_course']),
        ...mapActions(['fetchAll_InstitutionCourseAffiliations']),
        ...mapActions(['manage_CourseAffiliations']),
        ...mapActions(['createPackage','editPackage','addCourseToPackage','removeCourseFromoPackage','fetchPackages','fetchPackage']),
        deleteCourse(id,name){
            if (confirm("Do you REALLY want to delete "+name+" ?")) {
                this.delete_course(id)
				.then(res => {
					this.myCourses = res.data.courses
				})
            }else{
                alert('ok, we will ignore your delete request')
            }
        },
        registerPackage(){
            if (this.ValidatePackage()) {
                this.Loading = true
                let object = {
                    userId: this.userId,
                    title: this.packageTitle, 
                    percent: this.packagePercent,
                    date: this.packageDate
                }
                this.createPackage(object)
                .then(res => {
                    this.success = true
                    this.packages = res.data.packages
                    this.packageDate = ''
                    this.packageTitle = ''
                    this.packagePercent = ''
                    this.Loading = false
                })
            }
        },
        updatePackage(){
            if (this.ValidatePackage()) {
                this.Loading = true
                let object = {
                    userId: this.userId,
                    packageId: this.packageId,
                    title: this.packageTitle, 
                    percent: this.packagePercent,
                    date: this.packageDate
                }
                this.editPackage(object)
                .then(res => {
                    this.success = true
                    this.packages = res.data.packages
                    this.packageDate = ''
                    this.packageTitle = ''
                    this.packagePercent = ''
                    this.Loading = false
                })
            }
        },
        submitCourses(){
            this.Loading = true
            let object = {
                userId: this.userId,
                courses: this.selectedCourses,
                packageId: this.packageId
            }
            this.addCourseToPackage(object)
            .then(res => {
                this.packages = res.data.packages
                this.Loading = false
                this.success = true
            })
        },
        ValidatePackage(){
            let flag = true;
            let message = '';
			let error =[];
			if (this.packagePercent==''||this.packagePercent==0) {
                message = 'Please input package percent ';
                error.push(message)
                flag = false
            }
			if (this.packagePercent>70||this.packagePercent<1) {
                message = 'package percent can not be greater than 70 or less than 1';
                error.push(message)
                flag = false
            }
			if (this.packageTitle==''||this.packageTitle==null) {
                message = 'Please input package title';
                error.push(message)
                flag = false
            }
            if (this.packageDate==''||this.packageDate==null) {
                message = 'Please input package expiring date';
                error.push(message)
                flag = false
            }
            if (flag == true) {
                this.error = ''
                return true
            }else{
                this.error = error
                return false
            }
        },
        manageAffiliation(typee,affiliationId){
            this.Loading = true
            let object = {
                institutionAdminUserId: this.userId,
                courseId: this.courseId,
                affiliatedInstitutionsId: affiliationId,
                type: typee
            }
            this.manage_CourseAffiliations(object)
            .then(res => {
                this.myAffiliations = res.data,
                this.success= true,
                this.Loading = false,
                this.affiliationObject= { user: [{firstName: '', lastName: '', email: ''}] }
            })
        },
        activateModal(Id, type){
            this.success = false
            this.error = []
            if (type) {
                this.modalType = type 
            }
            this.packageTitle = ''
            this.packagePercent = ''
            this.selectedCourses = []
            switch (type) {
                case 'affilliation':
                    this.courseId = Id
                    this.success= false
                    for (let i = 0; i < this.myAffiliations.length; i++) {
                        const element = this.myAffiliations[i];
                        if (element._id==this.courseId) {
                        this.affiliationObject = element 
                        }
                    }
                    break;
                case 'addPackage':
                    break;
                case 'editPackage':
                    this.packageId = Id
                    for (let i = 0; i < this.packages.length; i++) {
                        const element = this.packages[i];
                        if (element._id == Id) {
                            this.packageTitle = element.title
                            this.packagePercent = element.percent
                            if (element.date) { 
                                this.packageDate = element.date.split('T')[0]
                            }else{
                                this.packageDate = element.date
                            }
                        }
                    }
                    break;
                case 'addCourse':
                    this.packageId = Id
                    this.selectedCourses = []
                    for (let i = 0; i < this.packages.length; i++) {
                        const element = this.packages[i];
                        if (element._id == Id) {
                            this.packageTitle = element.title
                            for (let j = 0; j < element.courses.length; j++) {
                                const elementj = element.courses[j];
                                this.selectedCourses.push(elementj._id)
                            }
                        }
                    }
                    break;
                default:
                    break;
            }
            let modal = document.getElementById('myModal');
            modal.setAttribute("class", "modal vd_mdl fade show");
            modal.style.display = 'block';
        },
        fetchMore(type){
            this.Loading = true
            switch (type) {
                case 'myCourseSkip':
                   this.myCourseSkip = this.myCourseSkip + 10
                   this.fetch_PsuedoUploadCourses({instructor_id: this.userId, skip: this.myCourseSkip})
                    .then(res => {
                        for (let i = 0; i < res.data.length; i++) {
                            const element = res.data[i];
                            this.psuedoCourses.push(element)
                        }
                        this.Loading = false
                    })
                    break;
                case 'myPurchaseSkip':
                    this.myPurchaseSkip = this.myPurchaseSkip + 10
                    this.fetch_purchased_courses({user_id: this.userId, skip: this.myPurchaseSkip})
                    .then(res => {
                        for (let i = 0; i < res.data.length; i++) {
                            const element = res.data[i];
                            this.purchasedCourses.push(element)
                        }
                        this.Loading = false
                    })
                    break;
                case 'instructorSkip':
                    this.instructorSkip = this.instructorSkip + 10
                    this.fetchInstructorCoursesForInstructorView({instructor_id: this.userId, skip: this.instructorSkip})
                    .then(res => {
                        for (let i = 0; i < res.data.length; i++) {
                            const element = res.data[i];
                            this.myCourses.push(element)
                        }
                        this.Loading = false
                    })
                    break;
            
                default:
                    break;
            }
        }
    }, 
    mounted(){
        this.userId = localStorage.getItem('fmruser');
        this.fetch_purchased_courses({user_id: this.userId, skip: this.myPurchaseSkip})
        .then(res => {
            this.purchasedCourses = res.data
        })
        // this.fetch_recent_courses(this.myCourseSkip)
        // .then(res => {
        //     this.recentCourses = res.data
        // })

        if (this.isInstructor||this.isAdmin) {
            this.fetch_PsuedoUploadCourses({instructor_id: this.userId, skip: this.myCourseSkip})
            .then(res => {
                this.psuedoCourses = res.data
            })
            this.fetchInstructorCoursesForInstructorView({instructor_id: this.userId, skip: this.instructorSkip})
            .then(res => {
                this.myCourses = res.data
            })

            this.fetchAll_InstitutionCourseAffiliations({user_id: this.userId})
            .then(res=> {
                this.myAffiliations = res.data
            })
            this.fetchPackages({userId: this.userId, skip: this.packageSkip})
            .then(res => {
                this.packages = res.data
            })
        }
    }
}
</script>
<style>
    @import url("../assets/css/style.css");
    /* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
    @import url("../assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/vertical-responsive-menu.min.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/instructor-responsive.css");
    @import url("../assets/css/responsive.css");
    @import url("../assets/css/night-mode.css");
    @import url("../assets/vendor/fontawesome-free/css/all.min.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("../assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("../assets/vendor/semantic/semantic.min.css");

    .modal-body{
        max-height: 600px;
        overflow-y: scroll;
    }
    .off{
        display: none;
    }

</style>
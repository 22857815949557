<template>
    <div>
      <Header />
  
      <div class="content-wrapper">
        <div class="container-fluid">
          <!-- Breadcrumbs-->
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#">Dashboard</a>
            </li>
            <li class="breadcrumb-item active">Your Courses</li>
          </ol>
          <div class="box_general">
            <div class="header_box">
              <h2 class="d-inline-block">Raffle Draw Items</h2>
            </div>
            <div class="bigspinner" v-if="Loading">
              <div class="smallspiner"></div>
            </div>
            <div class="box_general">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <!-- <form > -->
                    <div style="margin-bottom: 40px">
                        <div class="form-group">
                            <label for="">Item Title</label>
                            <input
                                v-model="item"
                                type="text"
                                class="form-control"
                                name="title"
                                placeholder="Title"
                            />
                        </div>
                        <div class="form-group">
                            <label for="">Max Attempts Per Winner</label>
                            <input
                                v-model="attempts"
                                type="text"
                                class="form-control"
                                name="title"
                                placeholder="Attempts"
                            />
                        </div>
                      
                        <div class="form-group">
    
                            <Errors v-if="errors.length>0">
                                <ul>
                                    <li v-for="error in errors" :key="error">{{error}}</li>	
                                </ul>
                            </Errors>
                            <br>
                            
                            <Success v-if="Success">
                                <ul>
                                    <li>Action Successful</li>
                                </ul>
                                <p></p>
                            </Success>
                            <button
                                class="btn my-4"
                                style="color: #fff; background:#ef6421"
                                @click="createRaffleDrawItems"
                                v-if="!Loading"
                            >
                                + Create Item
                            </button>
                            <button
                                class="btn my-4"
                                style="color: #fff; background:#ef6421"
                                disabled
                                v-if="Loading"
                            >
                                Please Wait ...
                            </button>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                        <th>Item</th>
                                        <th>Attempts Per Winner</th>
                                        <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                        <th>Item</th>
                                        <th>Attempts Per Winner</th>
                                        <th>Actions</th>
                                        </tr>
                                    </tfoot>
                                    <tbody v-if="raffleItems">
                                        <tr v-for="r in raffleItems" :key="r">
                                            <td>{{r.item}}</td>
                                            <td>{{r.attempts}}</td>
                                            <td>
                                                <router-link :to="'/SARaffleItemEdit/'+r._id">
                                                View
                                                </router-link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
  
            <!-- <component :is="currentTab"></component> -->
          </div>
        </div>
        <!-- /container-fluid-->
      </div>
      <!-- /.container-wrapper-->
      <Footer />
      <!-- Scroll to Top Button-->
      <a class="scroll-to-top rounded" href="#page-top">
        <i class="fa fa-angle-up"></i>
      </a>
      <!-- Logout Modal-->
      <Modal />
    </div>
  </template>
  <script>
  import Errors from '@/components/Errors.vue'
  import Success from '@/components/Success.vue'
  import Header from "@/components/superAdmin/Header.vue";
  import Modal from "@/components/superAdmin/Modal.vue";
  import Footer from "@/components/superAdmin/Footer.vue";
  import { mapActions, mapGetters } from "vuex";
  export default {
    data() {
      return {
        slug: "",
        item: "",
        attempts: "",
        raffleItems: [],
        skip: 0,
        title: "",
        type: "unverified",
        Loading: false,
        Success: false,
        errors: []
      };
    },
    components: {
      Header,
      Modal,
      Errors,
      Success,
      Footer,
    },
    computed: {
      ...mapGetters(["backEnd"]),
    },
    methods: {
        ...mapActions(['createRaffleDrawItems__Admin', 'getRaffleDrawItems__Admin']),
        createRaffleDrawItems(){
            this.Success = false
            this.Loading = true
            this.errors = []
            const raffle = {
                item: this.item,
                attempts: this.attempts
            }
            this.createRaffleDrawItems__Admin(raffle)
            .then(res => {
                this.Loading = false
                this.raffleItems.push(res.data)
                this.Success = true
            })
            .catch(err => {
                this.errors.push("Something went wrong")
                console.log(err)
            })
        }
    },
    mounted() {
        this.slug = "?skip="+this.skip+"&winner="+false+"&delivered="+false+"&received="+false
        this.getRaffleDrawItems__Admin(this.slug)
        .then(res => {
            this.raffleItems = res.data
        })
    },
  };
  </script>
  <style scoped>
  @import url("./assets/css/admin.css");
  @import url("./assets/css/custom.css");
  @import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
  @import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
  @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
  
  .tabButton {
    color: white;
    background-color: grey;
    border-radius: 10px;
    padding: 5px;
    display: inline;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .active {
    background-color: red;
  }
  
  .titleImage{
    width: 100%;
    height: 200px;
  }
  
  .bigspinner {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border-top: 5px solid grey;
    display: inline-block;
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .saymessage {
    display: inline-block;
    margin-left: 10px;
  }
  .smallspiner {
    height: 30px;
    width: 30px;
    margin: 5px;
    border-radius: 50%;
    border-top: 5px solid grey;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .btn-file {
    position: relative;
    overflow: hidden;
  }
  
  .btn-file input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
  }
  
  input[readonly] {
    background-color: white !important;
    cursor: text !important;
  }
  
  /* this is only due to codepen display don't use this outside of codepen */
  .container {
    padding-top: 20px;
  }
  </style>
<template>
    <div>
        <Header />

        <div class="content-wrapper">
            <div class="container-fluid">
      <!-- Breadcrumbs-->
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                <a href="#">Dashboard</a>
                </li>
                <li class="breadcrumb-item active">Add listing</li>
            </ol>
                
                <br><br>
                <!-- /box_general-->
                <div class="row">
                    <div class="col-md-6">
                        <div class="box_general padding_bottom">
                            <div class="header_box version_2">
                                <h2><i class="fa fa-lock"></i>Admin Register</h2>
                            </div>
                            <div class="form-group">
                                <label>Admin Username</label>
                                <input class="form-control" type="text" v-model="name">
                            </div>
                            <div class="form-group">
                                <label>Admin Type</label>
                                <select class="form-control" v-model="type">
                                    <option value="General">General Admin</option>
                                    <option value="Manager">Manager Role</option>
                                    <option value="Editor">Content Editor</option>
                                    <option value="Analyst">Quality Analyst</option>
                                    <option value="Customer">Customer Care</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Admin Email Address</label>
                                <input class="form-control" type="email" v-model="email">
                            </div>
                            <div class="form-group">
                                <label>Password</label>
                                <input class="form-control" type="password" v-model="password">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <Errors v-if="error.length!=0">
                            <ul>
                                <li v-for="error in error" :key="error">{{error}}</li>	
                            </ul>
                        </Errors>
                        <Success v-if="Success">
                            <ul>
                                <li>Admin created successfully</li>
                                <li>Please copy the admins generated login detail below</li>
                                <li>Admin Id address : {{AdminId}}</li>
                                <li>Password : {{AdminPassword}}</li>
                            </ul>
                        </Success>
                    </div>
                </div>
                <Loading v-if="Loading" />
                
                <!-- /row-->
                <p @click.prevent="addAdmin" v-if="!Loading"><a href="#0" class="btn_1 medium">Submit</a></p>
            </div>
            <!-- /.container-fluid-->
            </div>
            <!-- /.container-wrapper-->
            <Footer />
            <!-- Scroll to Top Button-->
            <a class="scroll-to-top rounded" href="#page-top">
            <i class="fa fa-angle-up"></i>
            </a>
            <!-- Logout Modal-->
            <Modal />
        
    </div>
</template>
<script>
import { mapActions } from "vuex";
import Loading from '@/components/Loading.vue'
import Errors from '@/components/Errors.vue'
import Success from '@/components/Success.vue'
import Header from '@/components/superAdmin/Header.vue'
import Modal from '@/components/superAdmin/Modal.vue'
import Footer from '@/components/superAdmin/Footer.vue'
export default {
    data(){
        return {
            name : '',
            email : '',
            password : '',
            type : '',
            Loading : false,
            Success : false,
            errors : "",
            AdminId: '',
            error: []
        }
    },
    components: {
        Header,
        Modal,
        Footer,
        Loading,
        Errors,
        Success
    },
    methods: {
        ...mapActions(['add_SuperAdmin']),
        addAdmin(){
            this.Success = false
            if(this.validate()){
                this.Loading = true
                this.error = []
                let admin = {
                    name: this.name,
                    email: this.email,
                    password : this.password,
                    type : this.type
                }
                this.add_SuperAdmin(admin)
                .then(res => {
                    
                    this.Loading = false
                    this.AdminId = res.data._id
                    this.AdminPassword = res.data.password
                    if (res.data._id == null) {
                        this.error.push("Ooops!! something went wrong, refresh page and try again")
                    }else{
                        this.Success = true
                    }
                    this.name = ''
                    this.email = ''
                    this.password = ''
                    this.type = ''
                })
                .catch(err => {
                    this.errors = "Something went wrong"
                    console.log(err)
                })
            }
            
        },
        validate(){
            var flag = false
            if (this.type=='') {
                flag = true
                this.error.push("Please select the admin type")
            }

            if (this.email=='') {
                flag = true
                this.error.push("Please select the admin email")
            }

            if (this.name=='') {
                flag = true
                this.error.push("Please select the admin name")
            }

            if (this.password=='') {
                flag = true
                this.error.push("Please select the admin password")
            }

            if (flag) {
                return false
            }else{
                return true
            }
        }
    }
}
</script>
<style >
	@import url("./assets/css/admin.css");
    @import url("./assets/css/custom.css");
    @import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
    @import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
</style>
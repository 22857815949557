<template>
    <div>
        <Header />

        <div class="content-wrapper">
            <div class="container-fluid">
                <!-- Breadcrumbs-->
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                    <a href="#">Dashboard</a>
                    </li>
                    <li class="breadcrumb-item active">Tables</li>
                </ol>
                    <!-- Example DataTables Card-->
                <div class="card mb-3">
                    <div class="card-header">
                    <i class="fa fa-table"></i> Data Table Example</div>
                    <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                            <th>Bank Name</th>
                            <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Amount</th>
                            <th>Applied date</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                            <th>Bank Name</th>
                            <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Amount</th>
                            <th>Applied date</th>
                            <th>Action</th>
                            </tr>
                        </tfoot>
                        <tbody>
                            <tr v-for="p in payouts" :key="p">
                                <td>{{p.bankName}}</td>
                                <td>{{p.accountName}}</td>
                                <td>{{p.accountNumber}}</td>
                                <td :id="'salary'+p._id">₦<Salary :userId="p.user[0]" /></td>
                                <td>{{p.updatedAt}}</td>
                                <td>
                                    <button class="notpayed" @click.prevent="markPayment(p._id)" :id="'notpayed'+p._id">Mark Payed</button>
                                    <button class="payed" :id="'payed'+p._id" style="display: none">Payed</button>
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                    <!-- <div class="card-footer small text-muted">Updated yesterday at 11:59 PM</div> -->
                </div>
                <p><a href="#0" class="btn_1 medium" @click.prevent="fetchMore()" v-if="!Loading">Fetch More Payouts</a></p>
                <p><a href="#0" class="btn_1 medium" v-if="Loading">Please Wait ...</a></p>
                <!-- /tables-->
                </div>
                <!-- /container-fluid-->
            </div>
            <!-- /.container-wrapper-->
            <Footer />
            <!-- Scroll to Top Button-->
            <a class="scroll-to-top rounded" href="#page-top">
            <i class="fa fa-angle-up"></i>
            </a>
            <!-- Logout Modal-->
            <Modal />
        
    </div>
</template>
<script>
import Header from '@/components/superAdmin/Header.vue'
import Salary from '@/components/superAdmin/Salary.vue'
import Modal from '@/components/superAdmin/Modal.vue'
import Footer from '@/components/superAdmin/Footer.vue'
import { mapActions } from "vuex";
export default {
    data(){
        return{
            skip: 0,
            payouts: '',
            Loading: false
        }
    },
    components: {
        Header,
        Modal,
        Salary,
        Footer
    },
    methods: {
        ...mapActions(['fetchNewPayouts__Admin', 'markPaidPayout__Admin']),
        markPayment(payoutId){
            var element = document.getElementById('salary'+payoutId)
            document.getElementById('notpayed'+payoutId).style.display = "none"
            document.getElementById('payed'+payoutId).style.display = "block"
            let object = {
                payoutId: payoutId, 
                amount: parseFloat(element.getElementsByTagName('b')[0].innerHTML)
            }
            this.markPaidPayout__Admin(object)
            .then(res => {
                this.payouts = res.data
            })
        },
        fetchMore(){
            this.skip = this.skip + 10
            this.Loading = true
            this.fetchNewPayouts__Admin(this.skip)
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    this.payouts.push(element)
                }
                this.Loading = false
            })
        }
    },
    mounted(){
        this.fetchNewPayouts__Admin(this.skip)
        .then(res => {
            this.payouts = res.data
        })
        
    }
}
</script>
<style scoped>
	@import url("./assets/css/admin.css");
    @import url("./assets/css/custom.css");
    @import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
    @import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    .payed{
        color: white;
        background-color: green;
        padding: 5px;
        border-radius: 10px;
        border: none;
    }
    .notpayed{
        color: white;
        background-color: red;
        padding: 8px;
        border-radius: 10px;
        border: none;
    }
</style>
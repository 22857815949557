<template>
  <!-- Button trigger modal -->
  <div class="modal-backdrop">
    <div class="card" >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity:0.9;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: transparent; */
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
<template>
    <div>
      <Header />
  
      <div class="content-wrapper">
        <div class="container-fluid">
          <!-- Breadcrumbs-->
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#">Dashboard</a>
            </li>
            <li class="breadcrumb-item active">Your Courses</li>
          </ol>
          <div class="box_general">
            <br />
            <br />
            <div class="header_box version_2">
              <p class="delete" @click.prevent="deleteRaffleDrawItems()" v-if="!Loading">Delete</p>
              <p class="delete" v-if="Loading">Please Wait ...</p>
            </div>
            <div class="header_box">
              <h2 class="d-inline-block">Raffle Draw Created At :  {{ raffle.createdAt }}</h2>
            </div>
            <div class="bigspinner" v-if="Loading">
              <div class="smallspiner"></div>
            </div>
            <div class="box_general">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <!-- <form > -->
                    
                    <div style="margin-bottom: 40px">
                        <br/>
                        
                        <div class="form-group" v-if="raffle">
                          <h5 for="">Item Status : <b> {{ raffle.received ? "Received" : ( raffle.delivered ? "Delivered" : "None") }} </b></h5>
                          <button
                            class="btn my-4"
                            style="color: #fff; background:#ef6421"
                            @click.prevent="manageDelivery('delivered')"
                            v-if="raffle.winner.length>0&&!raffle.delivered&&!SLoading"
                          >
                            Mark As Delivered
                          </button>
                          <button
                            class="btn my-4"
                            style="color: #fff; background:#ef6421"
                            @click.prevent="manageDelivery('received')"
                            v-if="raffle.winner.length>0&&!SLoading&&!raffle.received&&raffle.delivered"
                          >
                            Mark As Received
                          </button>

                          <button
                            class="btn my-4"
                            style="color: #fff; background:#ef6421"
                            disabled
                            v-if="SLoading"
                          >
                            Please Wait ...
                          </button>
                        </div>
                        <div class="form-group" v-if="raffle.winner && raffle.winner.length==0">
                            <label for="">Item Title</label>
                            <input
                                v-model="item"
                                type="text"
                                class="form-control"
                                name="title"
                                placeholder="Title"
                            />
                        </div>
                        <div class="form-group"  v-if="raffle.winner && raffle.winner.length==0">
                            <label for="">Max Attempts Per Winner</label>
                            <input
                                v-model="attempts"
                                type="text"
                                class="form-control"
                                name="title"
                                placeholder="Attempts"
                            />
                        </div>
                      
                        <div class="form-group"  v-if="raffle.winner && raffle.winner.length==0">
    
                          <Errors v-if="errors.length>0">
                              <ul>
                                  <li v-for="error in errors" :key="error">{{error}}</li>	
                              </ul>
                          </Errors>
                          <br>
                          
                          <Success v-if="Success">
                              <ul>
                                  <li>Action Successful</li>
                              </ul>
                              <p></p>
                          </Success>
                          <button
                              class="btn my-2"
                              style="color: #fff; background:#ef6421"
                              @click.prevent="updateRaffleDrawItems"
                              v-if="!Loading"
                          >
                              + Update Item
                          </button>
                          <button
                              class="btn my-2"
                              style="color: #fff; background:#ef6421"
                              disabled
                              v-if="Loading"
                          >
                              Please Wait ...
                          </button>
                        </div>

                        
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th>Address</th>
                                        <th>Course</th>
                                        <th>Winner</th>
                                      </tr>
                                    </thead>
                                    <tfoot>
                                      <tr>
                                        <th>Name</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th>Address</th>
                                        <th>Course</th>
                                        <th>Winner</th>
                                      </tr>
                                    </tfoot>
                                    <tbody v-if="raffleAttempts">
                                      <tr v-for="r in raffleAttempts" :key="r">
                                        <td>{{r.user[0].firstName}} {{r.user[0].lastName}}</td>
                                        <td>{{r.user[0].phone}}</td>
                                        <td>{{r.user[0].email}}</td>
                                        <td>{{r.user[0].address}}</td>
                                        <td>{{r.course[0].title}}</td>
                                        <td>{{r.winner}}</td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
  
            <!-- <component :is="currentTab"></component> -->
          </div>
        </div>
        <!-- /container-fluid-->
      </div>
      <!-- /.container-wrapper-->
      <Footer />
      <!-- Scroll to Top Button-->
      <a class="scroll-to-top rounded" href="#page-top">
        <i class="fa fa-angle-up"></i>
      </a>
      <!-- Logout Modal-->
      <Modal />
    </div>
  </template>
  <script>
  import Errors from '@/components/Errors.vue'
  import Success from '@/components/Success.vue'
  import Header from "@/components/superAdmin/Header.vue";
  import Modal from "@/components/superAdmin/Modal.vue";
  import Footer from "@/components/superAdmin/Footer.vue";
  import { mapActions, mapGetters } from "vuex";
  export default {
    data() {
      return {
        item: "",
        attempts: "", 
        raffleId: this.$route.params.id,
        raffleAttempts: [],
        raffle: "",
        skip: 0,
        title: "",
        type: "unverified",
        Loading: false,
        Success: false,
        errors: []
      };
    },
    components: {
      Header,
      Modal,
      Errors,
      Success,
      Footer,
    },
    computed: {
      ...mapGetters(["backEnd"]),
    },
    methods: {
      ...mapActions([
        'getRaffleDrawAttempts__Admin',
        'deleteRaffleDrawItems__Admin',
        'raffleDrawItemRecieved__Admin',
        'raffleDrawItemDelivered__Admin',
        'updateRaffleDrawItem__Admin'
      ]),

      manageDelivery(type){
        this.Success = false
        this.SLoading = true
        this.errors = []
        const raffle = {
          raffleDrawItem: this.raffleId
        }
        if(type == "delivered"){
          this.raffleDrawItemDelivered__Admin(raffle)
          .then(res => {
            console.log(res)
            this.SLoading = false
            this.raffle.delivered = true
          })
          .catch(err => {
            this.errors.push("Something went wrong")
            console.log(err)
          })
        }
        if(type == "received"){
          this.raffleDrawItemRecieved__Admin(raffle)
          .then(res => {
            console.log(res)
            this.SLoading = false
            this.raffle.received = true
          })
          .catch(err => {
            this.errors.push("Something went wrong")
            console.log(err)
          })
        }
        
      },
      updateRaffleDrawItems(){
        this.Success = false
        this.Loading = true
        this.errors = []
        const raffle = {
          raffleDrawItem: this.raffleId,
          item: this.item,
          attempts: this.attempts
        }
        this.updateRaffleDrawItem__Admin(raffle)
        .then(res => {
          console.log(res)
          this.Loading = false
          this.Success = true
        })
        .catch(err => {
          this.errors.push("Something went wrong")
          console.log(err)
        })
      },

      deleteRaffleDrawItems(){
        if(confirm("Are you sure you want to delete this item")){
          this.Success = false
          this.Loading = true
          this.errors = []
          this.deleteRaffleDrawItems__Admin(this.raffleId)
          .then(res => {
            console.log(res)
            this.Loading = false
            this.Success = true
            let str = window.location.href+"";
            const myArr = str.split("/SARaffleItemEdit");
            window.location.href = myArr[0] + "/SARaffleItems"
          })
          .catch(err => {
            this.errors.push("Something went wrong")
            console.log(err)
          })
        }
      }
    },
    mounted() {
        this.getRaffleDrawAttempts__Admin({id: this.raffleId, skip: this.skip})
        .then(res => {
            console.log(res.data)
            this.raffle = res.data.raffle
            this.item = this.raffle.item
            this.attempts = this.raffle.attempts
            this.raffleAttempts = res.data.attempts
        })
    },
  };
  </script>
  <style scoped>
  @import url("./assets/css/admin.css");
  @import url("./assets/css/custom.css");
  @import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
  @import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
  @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
  
  .tabButton {
    color: white;
    background-color: grey;
    border-radius: 10px;
    padding: 5px;
    display: inline;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .active {
    background-color: red;
  }
  
  .titleImage{
    width: 100%;
    height: 200px;
  }
  
  .bigspinner {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border-top: 5px solid grey;
    display: inline-block;
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .saymessage {
    display: inline-block;
    margin-left: 10px;
  }
  .smallspiner {
    height: 30px;
    width: 30px;
    margin: 5px;
    border-radius: 50%;
    border-top: 5px solid grey;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .btn-file {
    position: relative;
    overflow: hidden;
  }
  
  .btn-file input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
  }
  
  input[readonly] {
    background-color: white !important;
    cursor: text !important;
  }
  
  /* this is only due to codepen display don't use this outside of codepen */
  .container {
    padding-top: 20px;
  }

  .delete{
    color: white;
    background-color: red;
    border-radius: 5px;
    padding: 10px;
    display: inline;
    margin-top: 20px;
    margin: 10px;
    cursor: pointer;
  }
  </style>
<template>
    <div class="fcrse_1" >
        <router-link :to="'/Dcoursedetail/'+id">
        <a href="" class="hf_img">
            <!-- <img v-bind:src="backEnd+'public/courseImage/'+id" alt="" style="height: 170px"> -->
            <CourseImage :name="file" style="height: 170px" />
            <div class="course-overlay">
                <div class="badge_seller">Bestseller</div>
                <Ratings :id="id" :type="'course'" />
                <span class="play_btn1"><i class="uil uil-play"></i></span>
                <CourseTime :id="id" :type="'dashboard'"/>
            </div>
        </a>
        </router-link>
        <div class="hs_content">
            <div class="eps_dots eps_dots10 more_dropdown">
                <a href="#"><i class="uil uil-ellipsis-v"></i></a>
                <div class="dropdown-content">
                    <span  @click="copyURL('https://faslearn.com/Dcoursedetail/'+ id  +'?affiliateUserRef='+fmruser)"><i class='uil uil-share-alt'></i>Copy Link</span>															
                </div>																											
            </div>
            <router-link :to="'/Dcoursedetail/'+id">
            <TotalSeenCourse :id="id" :type="'dashboard'" />
            <a href="" class="crse14s title900">{{title}}</a>
            <a href="#" class="crse-cate">{{category}}</a>
            </router-link>
            <div class="auth1lnkprce">
                <CourseOwner :id="userId" :firstName="firstName" :lastName="lastName" />
                <div class="prce142"><span v-if="price!=0">₦{{price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span><span v-else>FREE</span> <br>
                    <small v-if="price!=0"><Discount :id="id" :price="price"  :discount="discount"  /></small>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import CourseTime from "@/components/CourseTime"
import CourseOwner from "@/components/CourseOwner"
import TotalSeenCourse from "@/components/TotalSeenCourse"
import CourseImage from "@/components/CourseImage"
import Discount from '@/components/Discount'
import Ratings from '@/components/dashboard/Ratings.vue'
export default {
	data(){
        return{
            total: 0,
            fmruser: localStorage.getItem('fmruser')
        }
    },
    props: {
        id: String,
        title: String,
        category: String,
        price: String,
        firstName: String,
        lastName: String,
        userId: String,
        file: String,
        discount: Number,
    },
	components: {
        CourseTime,
        TotalSeenCourse,
        CourseOwner,
        CourseImage,
        Discount,
        Ratings
	},
	computed: {
		...mapGetters(['backEnd'])
    },
    methods: {
        async copyURL(mytext) {
            try {
            await navigator.clipboard.writeText(mytext);
            alert('Copied');
            } catch($e) {
            alert('Cannot copy');
            }
        }
    }
}
</script>
<style>

</style>
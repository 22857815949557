<template>
 <div>
	 <Modal v-if="isAuthor">
		<div class="container" style="background-color: #b2bdb5" v-if="modalUpload">	
			<div class="row">
				<div class="col-12">
					<div class="course_tabs_1">
						<div id="add-course-tab" class="step-app">
							<div class="step-content" style="margin-top: -100px">
								<div class="step-tab-panel step-tab-location" id="tab_step3">
									<div class="tab-from-content">
										<div class="title-icon">
											<h3 class="title"><i class="uil uil-film"></i>Lecture Content</h3>
										</div>
										<div class="course__form">
											<div class="row">
												<div class="col-lg-12">		
													<div class="extra_info">		
														<h4 class="part__title">New Lecture Content</h4>
													</div>
													<div class="view_info10">
														<div class="row">
															<div class="col-lg-12 col-md-12">	
																<div class="lecture_title">
																	<h4>Add Lecture</h4>
																</div>
															</div>
                                                            <div class="col-lg-12 col-md-12">															
																<div class="ui search focus mt-30 lbel25">
																	<label>Are you uploading a file or link to a file</label>
																	<div class="ui left icon input swdh19">
																		<button class="lectureType" v-bind:class="{red: lectureType=='file'}" @click="switchLectureType('file')">file</button>
																		<button class="lectureType" v-bind:class="{red: lectureType=='link'}" @click="switchLectureType('link')">link</button>															
																	</div>
																</div>									
															</div>
															<div class="col-lg-4 col-md-12">															
																<div class="ui search focus mt-30 lbel25">
																	<label>Lecture Title*</label>
																	<div class="ui left icon input swdh19">
																		<input class="prompt srch_explore" type="text" placeholder="Insert your lecture title." maxlength="60" v-model="title">															
																	</div>
																</div>									
															</div>
															<div class="col-lg-4 col-md-6" v-if="lectureType=='file'">
																<div class="part_input mt-30 lbel25">
																	<label>File*</label>
																	<div class="input-group">
																		<div class="custom-file">
                                                                            <input type="file" class="custom-file-input" id="inputGroupFile06" ref="file" v-on:change="handleFileUpload()">
																			<label class="custom-file-label" for="inputGroupFile06"><i v-if="file.name!=''">PRESENT</i></label>
																		</div>
																	</div>
																	<h5>Choosen File: <i v-if="file.name==''" style="color:red">EMPTY</i> <i style="color:green" v-else>{{file.name}}</i></h5>
																</div>
															</div>
															<div class="col-lg-4 col-md-6" v-if="lectureType=='link'">
																<div class="part_input mt-30 lbel25">
																	<label>Link*</label>
																	<div class="input-group">
																		<div class="ui left icon input swdh19">
                                                                            <input class="prompt srch_explore" type="text" placeholder="Insert your lecture title." v-model="link" >
																			<!-- <label class="custom-file-label" for="inputGroupFile06"><i v-if="file.name!=''">PRESENT</i></label> -->
																		</div>
																	</div>
																	<!-- <h5>
																		<span v-if="link==''" style="color:red" >EMPTY <i class="uil uil-cancel"></i></span>
																		<span v-if="!validLink&&link!=''" style="color:red">Invalid Link <i class="uil uil-stop-circle"></i></span>
																		<span v-if="validLink" style="color:red">Valid Link <i class="uil uil-check-circle"></i></span>  
																	</h5> -->
																</div>
															</div>
															<div class="col-lg-4 col-md-12">															
																<div class="ui search focus mt-30 lbel25">
																	<label>Lecture duration in Minutes</label>
																	<div class="ui left icon input swdh19">
																		<input class="prompt srch_explore" type="number" placeholder="Insert your lecture title." maxlength="60" v-model="duration">															
																	</div>
																</div>									
															</div>
															
															<div class="col-lg-12 col-md-12" v-if="showPreviewLink&&lectureType=='link'">															
																<div class="ui search focus mt-30 lbel25">
																	<label>Video preview</label>
																	
																	<iframe :src="link" frameborder="0"></iframe>
																		
																</div>									
															</div>
															<div class="col-lg-12 col-md-12" >
															<Success v-if="successLecture">
																<ul>
																	<li>Action Successful</li>
																	<li>New lecture added</li>		
																</ul>
															</Success>
															<Loading v-if="Loading" />
															<Errors v-if="errorLecture">
																<ul>
																	<li v-for="error in errorLecture" :key="error">{{error}}</li>	
																</ul>
															</Errors>
															</div>
															<div class="col-lg-2 col-md-12" v-if="lectureType=='link'&&!showPreviewLink">
																<button class="part_btn_save prt-sv" type="submit" @click.prevent="startPreview()">Preview</button>
															</div>
															<div class="col-lg-2 col-md-12" v-if="lectureType=='link'&&validLink&&showPreviewLink">
																<button class="part_btn_save prt-sv" type="submit" @click.prevent="registerLinklecture()">Save Lecture</button>
															</div>
															<div class="col-lg-2 col-md-12" v-if="lectureType=='file'">
																<button class="part_btn_save prt-sv" type="submit" @click.prevent="registerlecture()">Save Lecture</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>	
							</div>
							<div class="step-footer step-tab-pager" style="opacity: 0">
								<button v-if="!Loading" class="btn btn-default steps_btn"  @click.prevent="registerlecture()">Submit for Review</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container" style="background-color: #b2bdb5" v-if="modalEdit">	
			<div class="row">
				<div class="col-12">
					<div class="course_tabs_1">
						<div id="add-course-tab" class="step-app">
							<div class="step-content" style="margin-top: -100px">
								<div class="step-tab-panel step-tab-location" id="tab_step3">
									<div class="tab-from-content">
										<div class="title-icon">
											<h3 class="title"><i class="uil uil-film"></i>Edit Modal</h3>
										</div>
                                        <Success v-if="successEdit">
                                            <ul>
                                                <li>Action Successful</li>		
                                            </ul>
                                        </Success>
										<Loading v-if="Loading" />
										<Errors v-if="errorEdit!=''">
											<ul>
												<li>{{errorEdit}}</li>	
											</ul>
										</Errors>
										<div class="course__form">
											<div class="row">
												<div class="col-lg-12">		
													<div class="extra_info">		
														<h4 class="part__title">Edit Section</h4>
													</div>
													<div class="view_info10">
														<div class="row">
															<form>
																<br>
																<p>Will you like to change the title of this Section</p>
																<div class="ui search focus">	
																	<div class="help-block">Section Title.</div>														
																	<div class="ui left icon input swdh11 swdh19">
																		<div class="ui left icon input swdh19">
																			<input class="prompt srch_explore" type="text" placeholder="Insert your Section title." maxlength="60" v-model="updateSectionTitle">															
																		</div>
																	</div>	
																	<div class="help-block">Section Description.</div>														
																	<div class="ui left icon input swdh11 swdh19">
																		<div class="ui left icon input swdh19">
																			<input class="prompt srch_explore" type="text" placeholder="Insert your Section Description." maxlength="60" v-model="updateSectionDescription">															
																		</div>
																	</div>
																	<div class="help-block">Section external link.</div>														
																	<div class="ui left icon input swdh11 swdh19">
																		<div class="ui left icon input swdh19">
																			<input class="prompt srch_explore" type="text" placeholder="Insert your Section external link." maxlength="60" v-model="updateSectionExternalLink">															
																		</div>
																	</div>							
																</div>

																<br><br>
																<p>Will you like to move this section to another position, don't touch this if you are pleased with its current position</p>
																<div class="ui search focus">
																	<div class="help-block">Place this section infont of</div>
																	<div class="ui left icon input swdh11 swdh19">
																		<div class="ui left icon input swdh19">
																			<select name="" class="ui hj145 dropdown cntry152 prompt srch_explore" id="" v-model="updateSectionBeforeId" @change="drag=true">
																				<option :value="play.id" v-for="play in playlists" :key="play">{{play.sectionTitle}}</option>
																			</select>
																		</div>
																	</div>
																</div>
																<div class="col-lg-2 col-md-12">
																	<button class="part_btn_save prt-sv" type="submit" @click.prevent="updateSec()">Update</button>
																</div>
															</form>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>	
							</div>
							<div class="step-footer step-tab-pager" style="opacity: 0">
								<button v-if="!Loading" class="btn btn-default steps_btn" >Submit for Review</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Modal>
<!-- Header Start -->
	<Dheader />
<!-- Header End -->
<!-- Left Sidebar Start -->
	<Dnav2 />
<!-- Left Sidebar End -->
<!-- Body Start -->
<div class="wrapper">
	<div class="sa4d25" >
		<div class="container">			
			<div class="row">
				<div class="col-lg-12">	
					<h2 class="st_title"><i class="uil uil-analysis"></i> Manage Course Sections</h2>
					<strong>
						<span class="statusTag green" v-if="isApproved">Status: Approved</span>
						<span v-else>
							<span class="statusTag red" v-if="adminSeen">Status: Denied</span> 
							<span class="statusTag orange" v-else>Status: Pending</span> 
						</span>
					</strong>
				</div>					
			</div>				
			<div class="row">
				<div class="col-12">
					<div class="course_tabs_1">
						<div id="add-course-tab" class="step-app">
							<CourseSections :courseId="courseId"/>
							<Loading v-if="courseDetails==''" />
							<div class="step-content" v-if="isAuthor">
								<div class="step-tab-panel step-tab-location" style="background-color: transparent" id="tab_step3">
									<div class="tab-from-content">
										<div class="title-icon">
											<h3 class="title"><i class="uil uil-film"></i>Course Content</h3>
										</div>
											
										<div class="tab-from-content">
											<div class="course__form">
												<div class="view_info10">
													<div class="row">
														<div class="col-lg-12">
															<div class="view_img_left" style=" width:100%;">	
																<div class="view__img" >	
																	<img src="../assets/images/courses/add_video.jpg" id="previewVideothumbnail" style="width: 100px; height: 50px;" alt="" >
																	<!-- <video src="" id="previewVideo" style="width: 150px; height: 70px;"></video> -->
																</div>
															</div>
															<div class="view_all_dt" style="padding: 20px" v-if="!isPreviewPresent">		
																<div class="view_img_right" style="width:100%">	
																	<h4 style="margin: 0px">Preview Video</h4>
																	<p style="margin: 0px"><b>NB</b> Students who have not purchased your course can see this, it is for promotion only <br> Students who watch a well-made preview video are 5X more likely to enroll in your course. We've seen that statistic go up to 10X for exceptionally awesome videos. Learn how to make yours awesome!</p>
																	<div class="upload__input">
																		<div class="input-group">
																			<div class="custom-file">
																				<input type="file" class="custom-file-input" id="inputGroupFile05" @change="handlePreviewFileUpload">
																				<label class="custom-file-label" for="inputGroupFile05">{{previewVideo.name}}</label>
																			</div>
																		</div>
																	</div>
																	<br>
																	<button class="save_content_btn" id="btn"  :disabled="previewVideo==''||Loading" @click.prevent="registerPreviewVideo()">Save preview Video</button>
																</div>
															</div>
															<div class="view_all_dt" style="padding: 20px" v-else>		
																<div class="view_img_right" style="width:100%">	
																	<h4 style="margin: 0px">Preview Video</h4>
																	<p style="margin: 0px">This course already has a preview video, to change it, delete the "course preview video" on preview section.</p>
																</div>
															</div>
															
														</div> 
													</div>
												</div>
											</div>
										</div>
										<br>
										<Loading v-if="Loading" />
										<Errors v-if="error">
											<ul>
												<li v-for="error in error" :key="error">{{error}}</li>	
											</ul>
										</Errors>
										<Success v-if="success">
											<ul>
												<li>Action Successful</li>	
											</ul>
											<p></p>
										</Success>
										<div class="course__form">
											<div class="row">
												<div class="col-lg-12">		
													<div class="extra_info">		
														<h4 class="part__title">Course Content Sections </h4>
													</div>
                                                    <div class="view_info10">
                                                        <div class="row">
                                                            <div class="col-lg-12 col-md-12">															
																<div class="ui search focus mt-30 lbel25">
																	<label>Section Title*</label>
																	<div class="ui left icon input swdh19">
																		<input class="prompt srch_explore" type="text" placeholder="Insert section title." maxlength="60" v-model="name">															
																	</div>
																</div>
																<div class="ui search focus mt-30 lbel25">
																	<label>Section Description*</label>
																	<div class="ui left icon input swdh19">
																		<input class="prompt srch_explore" type="text" placeholder="Insert section description if you have any." maxlength="500" v-model="description">															
																	</div>
																</div>
																<div class="ui search focus mt-30 lbel25">
																	<label>Section external Link*</label>
																	<div class="ui left icon input swdh19">
																		<input class="prompt srch_explore" type="text" placeholder="Insert section external Link if you have any" maxlength="160" v-model="externalLink">															
																	</div>
																</div>								
															</div>
                                                            <div class="col-lg-12 col-md-12">
																<div class="save_content" v-if="!Loading">
																	<button class="save_content_btn" type="Submit" id="btn" @click.prevent="registerPlaylist()" :disabled="name==''||name=='Preview'||name=='preview'||name=='PREVIEW'">Save New Folder</button>
																</div>
															</div>
                                                        </div>
                                                    </div>
												</div>
											</div>
										</div>
										<div class="table-responsive mt-30">
											<div class="cover" style="width: 100%" v-for="play in playlists" :key="play">
												<div class="part__title">
													<h5 class="">{{play.sectionTitle}}</h5>	
													<p>{{play.description}}</p>
													<a :href="play.externalLink">{{play.externalLink}}</a>
													<div class="controls">
														<a title="Expand" class="gray-s" :id="'eyeS'+play.id" @click="toggleTable(play.id)"><i class="uil uil-eye-slash"></i></a>
														<a title="Upload" class="gray-s" @click="activateModal(play.id, 'upload')"><i class="uil uil-upload"></i></a>
														<a title="Edit" class="gray-s" @click="activateModal(play.id, 'edit')" v-if="play.sectionTitle!='Preview'"><i class="uil uil-edit-alt"></i></a>
														<a title="Delete" class="gray-s" @click.prevent="deleteSection(play.id,play.sectionTitle)" v-if="play.sectionTitle!='Preview'"><i class="uil uil-trash-alt"></i></a>
													</div>
												</div>
												<table class="table ucp-table" :id="'table'+play.id">
													<thead class="thead-s">
														<tr>
															<th class="text-center" scope="col">Lecture</th>
															<th class="cell-ta">Title</th>
															<th class="text-center" scope="col">File</th>
															<th class="text-center" scope="col">Controls</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="v in play.videos" :key="v">
															<td class="text-center">{{1+(play.videos.indexOf(v))}}</td>
															<td class="cell-ta">{{v.title}}</td>
															<td class="text-center"><a >{{v.fileType}}</a></td>
															<td class="text-center">
																<a href="#" title="Delete" class="gray-s" @click.prevent="deleteLectureid(v._id,v.title)"><i class="uil uil-trash-alt"></i></a>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>	
							</div>
							<div class="step-footer step-tab-pager" style="opacity: 0">
								<button v-if="!success" class="btn btn-default steps_btn" @click="scroll()">Submit for Review</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<Dfooter />
</div>
<!-- Body End -->
</div>   
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
//import axios from 'axios';
import Errors from '@/components/Errors.vue'
import Success from '@/components/Success.vue'
import Loading from '@/components/Loading.vue'
import Dheader from '@/components/dashboard/Header.vue'
import Dnav2 from '@/components/dashboard/Nav2.vue'
import Dfooter from '@/components/dashboard/Footer.vue'
import Modal from "@/components/Modal.vue";
import CourseSections from '@/components/dashboard/CourseSections.vue'
export default {
	name: 'Dhome',
	data(){
		return{
			courseId: '',
			userId: '',
			isAuthor: '',
            name: '',
			description: '', 
			externalLink: '',
			playlists:'',
			preview: false,
			success : false,
			successLecture: false,
			Loading: false,
			previewPlaylistId: '',
			isPreviewPresent: false,
			error: '',
			errorLecture: '',
			playId: '',
			playlistId: '', 
            title: '',
			file: {name: ''},
			previewVideo: '',
			isApproved : false,
			adminSeen : false,
			modalUpload : false,
			modalEdit : false,
			successEdit: false,
			errorEdit: '',
			updateSectionTitle: '',
			updateSectionBeforeId: '',
			updateSection: '',
			updateSectionExternalLink: '',
			updateSectionDescription: '',
			drag: false,
			duration: 0,
			courseDetails: '',
			lectureType: 'file',
			link: '',
			showPreviewLink: false,
			validLink: false
		}
	},
	computed:{
		//...mapGetters(['error']),
		...mapGetters(['isInstructor']),
		...mapGetters(['frontEnd'])
	},
    components: {
        Dheader,
        Dnav2,
		Dfooter,
		Errors,
		Success,
		Loading,
		CourseSections,
		Modal
	},
	methods: {
        ...mapActions(['createPlaylist','update_Playlist']),
		...mapActions(['bringPlaylist']),
		...mapActions(['deleteLecture']),
		...mapActions(['delete_Playlist']),
		...mapActions(['createVideoLecture','createLinkLecture']),
		...mapActions(['createMaterialLecture']),
		...mapActions(['fetchplaylistlectures']),
		...mapActions(['view_CourseForEdit']),
		registerPreviewVideo(){
            let formData = new FormData();
            formData.append('title', 'Course Preview Video');
			formData.append('playlistId', this.previewPlaylistId);
			formData.append('isPreview', true);
			formData.append('file', this.previewVideo);
			this.Loading = true
			this.createVideoLecture(formData)
			.then(res => {
				this.playlists = res.data;
				this.Loading = false
				this.success = true;
				this.previewVideo= '';
				this.isPreviewPresent= true
				window.location.reload()
			})
			.catch(err => {
				console.log(err)
			})
		},
		registerlecture(){
			if (this.validateLecture()) {
				let file = this.file;
				let formData = new FormData();
				formData.append('title', this.title);
				formData.append('duration', (this.duration*60));
				formData.append('playlistId', this.playlistId);
				formData.append('isPreview', false);
				formData.append('file', this.file);

				if (file.type == 'application/pdf') {
					this.Loading = true
					this.createMaterialLecture(formData)
					.then(res => {
						this.playlists = res.data;
						this.Loading = false
						this.successLecture = true;
						this.title= '';
						this.file= {name: ''};
						this.duration = 0
						setTimeout(() => {
							this.successLecture = false;
						}, 3000);
					})
					.catch(err => {
						this.Loading = false
						let error = []
						error.push("OOPS!! something went wrong, please refresh page and try again")
						this.errorLecture = error;
						console.log(err)
						
					})
				}else if (file.type == 'video/mp4'||file.type == 'video/MOV'||file.type == 'video/WMV'||file.type == 'video/FLV'||file.type == 'video/AVI'){
					this.Loading = true
					this.createVideoLecture(formData)
					.then(res => {
						this.playlists = res.data;
						this.Loading = false
						this.successLecture = true;
						this.title= '';
						this.file= {name: ''};
						this.duration = 0
						setTimeout(() => {
							this.successLecture = false;
						}, 3000);
					})
					.catch(err => {
						this.Loading = false
						let error = []
						error.push("OOPS!! something went wrong, please refresh page and try again")
						this.errorLecture = error;
						console.log(err)
						
					})
				}else{
					alert('Invalid file, please use only a video or a pdf file');
				}
			}
        },
		registerLinklecture(){
			if (this.validateLinkLecture()) {
				let object = {
					title: this.title,
					duration: this.duration*60,
					playlistId: this.playlistId,
					isPreview: false,
					embedLink: this.link
				}

				this.createLinkLecture(object)
					.then(res => {
						this.playlists = res.data;
						this.Loading = false
						this.successLecture = true;
						this.title= '';
						this.link= '';
						this.file= {name: ''};
						this.duration = 0
						this.showPreviewLink = false
						this.validLink = false
						setTimeout(() => {
							this.successLecture = false;
						}, 3000);
					})
					.catch(err => {
						this.Loading = false
						let error = []
						error.push("OOPS!! something went wrong, please refresh page and try again")
						this.errorLecture = error;
						console.log(err)
						
					})
			}
        },
        handleFileUpload(){
            this.file = this.$refs.file.files[0];
        },
		switchLectureType(type){
			this.lectureType = type
		},
		registerPlaylist(){
			if (!this.regexLink(this.externalLink)) {
				return
			}
			this.Loading = true
			//let id = localStorage.getItem('fmruser');
			let play = {
				name : this.name,
				courseId : this.courseId,
				description: this.description, 
				externalLink: this.externalLink,
			} 

			this.createPlaylist(play)
            .then(res => {
				if (res.status == 201) {
					this.playlists = res.data.modules;
					//document.getElementById('myLoader').style.display = 'none';
					this.name = '';
					this.description = '';
					this.externalLink = ''
				}
				this.scroll()
				this.Loading = false
			})
			.catch(err => {
				console.log(err)
				this.Loading = false
			})
		},
		scroll(){
			window.scrollBy(0, 150)
		},
		deleteSection(id,name){
            if (confirm("Do you REALLY want to delete this section? "+name)) {
				this.delete_Playlist(id)
				.then(res => {
					this.playlists = res.data.modules
				})
                //alert('this feature has not been implemented yet '+id)
            }else{
                alert('ok, we will ignore your delete request')
            }
            
		},
		deleteLectureid(id,name){
            if (confirm("Do you REALLY want to delete this lecture?"+name)) {
				this.deleteLecture(id)
				.then(res => {
					this.playlists = res.data.modules
				})
            }else{
                alert('ok, we will ignore your delete request')
            }
            
		},
		toggleTable(id){
			document.getElementById('table'+id).classList.toggle("closed")
			document.getElementById('eyeS'+id).classList.toggle("uil-eye")
		},
		activateModal(playId, myModal){
			this.successEdit=false
			if (myModal=="upload") {
				this.modalUpload = true
				this.modalEdit = false
			}
			if (myModal=="edit") {
				this.modalUpload = false
				this.modalEdit = true
			}
			this.playId = playId
			this.playlistId = playId
			for (let i = 0; i < this.playlists.length; i++) {
				const element = this.playlists[i];
				if (element.id == this.playlistId) {
					this.updateSection = element
					this.updateSectionTitle = element.sectionTitle
					this.updateSectionExternalLink = element.externalLink
					this.updateSectionDescription = element.description
					if ((i+1) < this.playlists.length) {
						this.updateSectionBeforeId= this.playlists[i+1].id
					}else{
						this.updateSectionBeforeId= ''
					}
				}
			}
            let modal = document.getElementById('myModal');
            modal.setAttribute("class", "modal vd_mdl fade show");
			modal.style.display = 'block';
		},
		updateSec(){
			if(this.updateSectionExternalLink!=null&&this.updateSectionExternalLink!=''){
				if (!this.regexLink(this.updateSectionExternalLink)) {
					return
				}
			}
			this.Loading = true
			let object={
				drag: this.drag,
				courseId: this.courseId,
				updateSectionId : this.playlistId,
				updateSectionTitle:	this.updateSectionTitle,
				updateSectionBeforeId: 	this.updateSectionBeforeId,
				description: this.updateSectionDescription, 
				externalLink: this.updateSectionExternalLink
			}
			this.update_Playlist(object)
			.then(res => {
				this.playlists = res.data.modules;
				this.scroll()
				this.Loading = false
				this.successEdit=true
			})
			.catch(err=> {
				this.errorEdit = err
				console.log(err)
			})
			this.drag = false
		},
		handlePreviewFileUpload(e){
			if (this.validatePreviewFile(e.target.files[0])) {
				this.previewVideo = e.target.files[0];
			}
		},
		validatePreviewFile(file){
            let flag = true;
            let message = '';
            let error =[];
            const allowedExtensions =  ['mov','mp4','MOV','MP4'];
            //const allowedExtensionsL =  ['jpg','png','jpeg','JPG','PNG','JPEG','pdf','PDF']
            if (!allowedExtensions.includes(file.name.split(".").pop())) {
                message = 'Please insert a valid video file';
                error.push(message)
                flag = false
            }
            
            if (file.size > 50000000) {
                message = 'Please insert smaller size video less than 50mb and not '+(file.size/1000000);
                error.push(message)
                flag = false
			}
            if (flag == true) {
                this.error = ''
                return true
            }else{
                this.error = error
                return false
            }
		},
		validateLecture(){
			let flag = true;
			let file = this.file;
            let message = '';
            let error =[];
			const allowedExtensions =  ['mov','mp4','MOV','MP4','PDF','pdf'];
			const pdf =  ['PDF','pdf'];
			if (!allowedExtensions.includes(file.name.split(".").pop())) {
                message = 'Please insert a valid video file or PDF file';
                error.push(message)
                flag = false
            }
            if (file.size > 50000000) {
                message = 'Please insert smaller size video less than 50mb and not '+(file.size/1000000);
                error.push(message)
                flag = false
			}
			if (file.name== '') {
				message = 'Please insert a lecture file';
                error.push(message)
                flag = false
			}
			if (!pdf.includes(file.name.split(".").pop())) {
                if (this.duration == 0) {
					message = 'Please insert the duration of this video';
					error.push(message)
					flag = false
				}
				if (this.duration > 15) {
					message = 'Duration of this video can not be more than 15 minutes';
					error.push(message)
					flag = false
				}

				if (this.duration < 0) {
					message = 'Duration of this video can not be less than 0 minute';
					error.push(message)
					flag = false
				}

            }
			if (this.title == '') {
				message = 'Please insert a lecture title';
                error.push(message)
                flag = false
			}
            if (flag == true) {
                this.errorLecture = ''
                return true
            }else{
                this.errorLecture = error
                return false
            }
		},
		validateLinkLecture(){
			let flag = true;
            let message = '';
            let error =[];
			if (this.link == '') {
				message = 'Please insert a lecture link';
                error.push(message)
                flag = false
			}
			if (this.title == '') {
				message = 'Please insert a lecture title';
                error.push(message)
                flag = false
			}
			if (this.duration == 0) {
				message = 'Please insert the duration of this video';
				error.push(message)
				flag = false
			}
			if (this.duration > 15) {
				message = 'Duration of this video can not be more than 15 minutes';
				error.push(message)
				flag = false
			}

			if (this.duration < 0) {
				message = 'Duration of this video can not be less than 0 minute';
				error.push(message)
				flag = false
			}
            if (flag == true) {
                this.errorLecture = ''
                return true
            }else{
                this.errorLecture = error
                return false
            }
		},
		startPreview(){
			if (this.regexLink(this.link)) {
				this.showPreviewLink = true
				this.validLink = true
			}
		},
		regexLink(link){
			var expression = /https:\/\/[a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}/;
			var regex = new RegExp(expression);
			if (link!='') {
				if (link.match(regex)) {
					return true
				} else {
					alert("Invalid Link, a good link starts with https://")
					return false
				}
			}
			return true
		}
	},  
    mounted(){
		this.courseId = this.$route.params.id
		this.userId = localStorage.getItem('fmruser')
        this.bringPlaylist(this.$route.params.id)
        .then(res => {
			this.playlists = res.data.modules;
			for (let i = 0; i < this.playlists.length; i++) {
				const element = this.playlists[i];
				if (element.sectionTitle=='Preview') {
					this.previewPlaylistId = element.id
					for (let i = 0; i < element.videos.length; i++) {
						const elementinner = element.videos[i];
						if (elementinner.isPreview==true||elementinner.isPreview=='true') {
							this.isPreviewPresent = true
						}
					}
				}
			}
		})
		this.view_CourseForEdit(this.courseId)
		.then(res => {
			this.courseDetails = res.data
			this.isApproved = res.data.isApproved 
			this.adminSeen = res.data.adminSeen 
			if (this.courseDetails.user==this.userId||this.courseDetails.psuedoUser==this.userId) {
				this.isAuthor = true
			}
		})

		// var big = document.getElementsByClassName('bigspinner')[0]
		// var small = document.getElementsByClassName('smallspiner')[0]
		// big.style.borderTop = '5px solid black'
		// small.style.borderTop = '5px solid black'
		
		// for (let i = 0; i < big.length; i++) {
		// 	const element = big[i];
		// 	element.style.borderTop = 'black'
		// }
		// for (let i = 0; i < big.length; i++) {
		// 	const element = big[i];
		// 	element.style.borderTop = 'black'
		// }
    }

    //http://localhost:8080/Dnewcoursecontent/606dbb80b1fc8e099c5ff8fc
}
</script>
<style scoped>
    @import url("../assets/css/style.css");
    @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500");
    @import url("../assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/vertical-responsive-menu.min.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/instructor-responsive.css");
    @import url("../assets/css/responsive.css");
    @import url("../assets/css/night-mode.css");
    @import url("../assets/vendor/fontawesome-free/css/all.min.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("../assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("../assets/vendor/semantic/semantic.min.css");

	.pageInActive{
        padding: 5px;
        /* width: 30%; */
    }

    .pageActive{
        padding: 5px;
        color: white;
        /* width: 33%; */
        border-radius: 10px;
    }

	.pageInActive a{
		text-decoration: none;
		color: white;
		/* height: 100%; */
		padding: 5px;
		border-radius: 20px;
		/* width: 100%; */
		background-color: grey;
	}

	.pageActive a{
		text-decoration: none;
		color: white;
		padding: 10px; 
		border-radius: 35px;
		background-color: red;
	}

	.lectureType{
		text-decoration: none;
		color: white;
		margin-left: 10px;
		padding: 5px; 
		padding-left: 10px; 
		padding-right: 10px; 
		border-radius: 25px;
		border: none;
		background-color: grey;
	}

	.partss{
		margin: 10px;
	}
	.full{
		width: 100%;
		display: block;
	}
	.closed{
		display: none;
	}
	.open{
		display: block;
	}

	.statusTag {
        padding: 5px;
        border-radius: 5px;
        color: white;
    } 
    .orange {
        background-color: orange;
    }
    .green{
        background-color: green;
    }
	.red{
		background-color: red;
	}
</style>
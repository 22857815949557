<template>
    <div>
        <Header />

        <div class="content-wrapper">
            <div class="container-fluid">
                <!-- Breadcrumbs-->
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                    <a href="#">Dashboard</a>
                    </li>
                    <li class="breadcrumb-item active">Add listing</li>
                </ol>
                <div class="box_general padding_bottom">
                    <div class="header_box version_2" v-if="book!=''">
                        <h2><i class="fa fa-user"></i>Book details </h2>
                        <p class="approve" @click.prevent="manageBook('verify')" v-if="!Loading&&book.user.length!=0" >Verify</p>
                        <p class="delete" @click.prevent="manageBook('block')" v-if="!Loading">Block</p>
                        <!-- <p class="delete" @click.prevent="manageInstitude('delete')" v-if="!Loading">Delete</p> -->
                    </div>
                    <div class="bigspinner" v-if="Loading">
                        <div class="smallspiner">
                            
                        </div>
                    </div>
                    <Success v-if="Success">
                        <ul>
                            <li>Action Successfull</li>
                        </ul>
                    </Success>
                    <div class="row" v-if="book!=''">
                        <div class="col-md-4">
                            <div class="form-group">
                                <CourseImage :name="book.thumbnailFile" style="height: 200px; width: 250px; " />
                            </div>
                        </div> 
                        <div class="col-md-8 add_top_30" v-if="Admin.type=='Manager'||Admin.type=='General'||Admin.type=='Editor'">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Teacher Name</label>
                                        <p v-if="book.user.length!=0">{{book.user[0].firstName}} {{book.user[0].lastName}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Teacher Email</label>
                                        <p v-if="book.user.length!=0" >{{book.user[0].email}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Title</label>
                                        <input class="form-control" v-model="title" type="text" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Second Title</label>
                                        <input class="form-control" v-model="secondTitle" type="text" placeholder="Name">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Category</label><br>
                                        <select class="form-control" v-model="category" @change="setSubCat()">
                                            <option value="" :selected="true">Select Category</option>
                                            <option :value="c._id" v-for="c in categories" :key="c">{{c.name}}</option>
                                        </select><br>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Sub Category</label><br>
                                        <select class="form-control" v-model="subCategory">
                                            <option value="">Select Subcategory</option>
                                            <option :value="c._id" v-for="c in subCategories" :key="c">{{c.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Price</label>
                                        <input class="form-control" v-model="price" type="number" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Discount Percent</label>
                                        <input class="form-control" v-model="discountPercent" type="number" placeholder="Name">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Publisher</label>
                                        <input class="form-control" v-model="publisher" type="number" placeholder="publisher">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Pages</label>
                                        <input class="form-control" v-model="pages" type="number" placeholder="Pages">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>About</label>
                                        <input class="form-control" v-model="about" type="text" placeholder="about">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Preface</label>
                                        <input class="form-control" v-model="preface" type="text" placeholder="preface">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>ISBN</label>
                                        <p>{{book.ISBN}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Language</label>
                                        <p>{{book.language}}</p>
                                    </div>
                                </div>
                            </div>
                            <Errors v-if="error">
								<ul>
									<li v-for="error in error" :key="error">{{error}}</li>	
								</ul>
							</Errors>
                            <!-- <button class="btn_1 medium" v-if="subCategory!=''&&!Loading" @click.prevent="saveChanges()">Update Book</button> -->
                            <hr>
                            <!-- <div class="row" v-if="canChangeOwnership">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Change Ownership from {{ownerName}}</label><br>
                                        <select class="form-control" v-model="newOwner" @change="changingOwner=true">
                                            <option :value="psuedoOwner" :selected="true">To Original Author</option>
                                            <option :value="u.user"  v-for="u in uni" :key="u">To {{u.name}}</option>
                                        </select>
                                        <small v-if="changedSuccess" style="color: green">The ownership of this book has been changed to {{ownerName}}</small><br>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <br><br>
                                        <div class="bigspinner" v-if="Loading">
                                            <div class="smallspiner">
                                            </div>
                                        </div>
                                        <button class="btn_1 medium" v-if="!Loading&&changingOwner" @click.prevent="changeOwner()">Save new owner</button>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="col-md-8 add_top_30" v-else>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <p>{{book.title}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Category</label>
                                        <p>{{book.category[0].name}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Teacher Name</label>
                                        <p>{{book.user[0].firstName}} {{book.user[0].lastName}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Teacher Email</label>
                                        <p>{{book.user[0].email}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>What You Learn</label>
                                        <p>{{book.whatYouLearn}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Requirement</label>
                                        <p>{{book.requirement}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Second Title</label>
                                        <p>{{book.secondTitle}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Price</label>
                                        <p>{{book.price.$numberDecimal}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Publisher</label>
                                        <p>{{book.publisher}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Pages</label>
                                        <p>{{book.pages}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>ISBN</label>
                                        <p>{{book.ISBN}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Language</label>
                                        <p>{{book.language}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>About</label>
                                        <p>{{book.about}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Preface</label>
                                        <p>{{book.preface}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box_general padding_bottom">
                    <h1>FILE</h1> 
                    <div class="row" style="width:100%">
                        <div class="col-md-12" >
                            <div class="box_general padding_bottom">
                                <object type="application/pdf" width="100%" height="600" v-bind:data="url" id="pdf_content" >
                                    <p>Sorry but your browser doesn't support pdf, We cant display pdf on this browser. Try using a desktop or laptop computer</p>
                                </object>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <p><a href="#0" class="btn_1 medium">Save</a></p> -->
            </div>
   	    </div>
        <!-- /.container-wrapper-->
        <Footer />
        <!-- Scroll to Top Button-->
        <a class="scroll-to-top rounded" href="#page-top">
        <i class="fa fa-angle-up"></i>
        </a>
        <!-- Logout Modal-->
        <Modal />
        
    </div>
</template>
<script>
import Header from '@/components/superAdmin/Header.vue'
import Modal from '@/components/superAdmin/Modal.vue'
import Footer from '@/components/superAdmin/Footer.vue'
import Success from "@/components/Success.vue";
import Errors from "@/components/Errors.vue"
import CourseImage from '@/components/CourseImage.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return{
            book: '',
            modules: '',
            bookId: this.$route.params.id,
            Admin: '',
            adminId: '',
            Loading: false,
            Success: false,
			userId:'', 
			title:'', 
			whatYouLearn:'',
			requirement:'', 
			about:'', 
            publisher: '',
            preface: '',
            pages: '',
            file: '',
            url: '',
			secondTitle:'', 
            category:'', 
            subCategory: '',
            discountPercent: 0,
			price:'',
            thumbnail:'',
            categories: '',
            subCategories: '',
            isImagePresent: false,
            visibilty: '',
            bookOwner: '',
            psuedoOwner: '',
            lecturer: '',
            uni: [],
            canChangeOwnership: false,
            newOwner: '',
            changingOwner: false,
            ownerName: '',
            error: '',
            changedSuccess: false
        }
    },
    components: {
        Header,
        Modal,
        Success,
        CourseImage,
        Errors,
        Footer
    },
    computed: {
        ...mapGetters(['backEnd','publicFiles'])
    },
    methods: {
        ...mapActions(['viewBook__Admin','generate_V4ReadSignedUrl','manageVerifyBook_Admin','fetchSuperAdmin','fetch_categories']),
        ...mapActions(['editBook','view_BookForEdit','fetchInstructor_Details','changeBookOwner_Admin']),
        manageBook(type){
            this.Loading = true
            let object ={
                bookId: this.bookId, 
                actionType : type,
                bookType : null
            }
            this.manageVerifyBook_Admin(object)
            .then(res => {
                if (res.data.success) {
                    this.Loading = false
                    this.Success = true
                        
                }
            })
        },
        changeOwner(){
            this.Loading = true
            let object ={
                bookId: this.bookId, 
                newOwnerId:  this.newOwner //"61475a096c226a0a09ffa944"  
            }
            this.changeBookOwner_Admin(object)
            .then(res => {
                if (res.data.success) {
                    this.Loading = false
                    this.changedSuccess = true 
                    this.changingOwner = false
                    let exist = false
                    for (let i = 0; i < this.uni.length; i++) {
                        const element = this.uni[i];
                        if (this.newOwner == element.user) {
                            this.ownerName = element.name
                            exist = true
                        }
                    }
                    if (!exist) {
                        this.ownerName = "Original Author"
                    }
                }
            })
        },
		saveChanges(){
            if(this.validateInputs()){

                this.Loading = true
                let formData = new FormData();
                formData.append('bookId', this.bookId);
				formData.append('title', this.title);
				formData.append('secondTitle', this.secondTitle);
				formData.append('category', this.category);
				formData.append('subCategory', this.subCategory);
				formData.append('price', this.price);
				formData.append('discountPercent', this.discountPercent);
				formData.append('year', this.year);
				formData.append('preface', this.preface);
				formData.append('about', this.about);
				formData.append('pages', this.pages);
				formData.append('language', this.language);
				formData.append('publisher', this.publisher);
				formData.append('ISBN', this.ISBN);
                this.editBook(formData)
                .then(res => {
                    this.Loading = false
                    this.Success = res.data.success;
                })
                .catch(err => {
                    this.Loading = false
                    console.log(err)
                })
            }
		},
        validateInputs(){
            let flag = true;
            let message = '';
			let error =[];
			if (this.price=='') {
                message = 'Please input price ';
                error.push(message)
                flag = false
            }
			if (this.discountPercent==0||this.discountPercent==null) {
                message = 'Please input discount Percent ';
                error.push(message)
                flag = false
            }
			if (this.discountPercent<1||this.discountPercent>100) {
                message = 'discount Percent can not be less than 1 or greater than 100';
                error.push(message)
                flag = false
            }
			if (this.subCategory=='') {
                message = 'Please input subCategory ';
                error.push(message)
                flag = false
            }
			if (this.category=='') {
                message = 'Please input category ';
                error.push(message)
                flag = false
            }
			if (this.title=='') {
                message = 'Please input title ';
                error.push(message)
                flag = false
            }
			if (this.secondTitle=='') {
                message = 'Please input second Title ';
                error.push(message)
                flag = false
            }
			if (this.requirement=='') {
                message = 'Please input requirements ';
                error.push(message)
                flag = false
            }
            if (this.whatYouLearn=='') {
                message = 'Please input what you learn ';
                error.push(message)
                flag = false
            }
            if (flag == true) {
                this.error = ''
                return true
            }else{
                this.error = error
                return false
            }
        },

		handleFileUpload(){
            if (this.validateFile(this.$refs.thumbnail.files[0])) {
                this.thumbnail = this.$refs.thumbnail.files[0];
                const file = this.$refs.thumbnail.files[0];
                const previewImage = document.getElementById('previewImage');
                if (file) {
                    const reader = new FileReader();
                    reader.addEventListener("load", function () {
                        previewImage.setAttribute('src', this.result);
                    });
                    reader.readAsDataURL(file);
                }
                document.getElementById('thumbnaildisplay').innerHTML = this.thumbnail
                this.isImagePresent = true
            }
        },
        setSubCat(){
            this.subCategory = ''
            for (let i = 0; i < this.categories.length; i++) {
                const element = this.categories[i];
                if (element._id == this.category) {
                    this.subCategories = element.subCategory
                }
                
            }
        },
        validateFile(file){
            let flag = true;
            let message = '';
            let error =[];
            const allowedExtensions =  ['jpg','png','jpeg','JPG','PNG','JPEG'];
            if (!allowedExtensions.includes(file.name.split(".").pop())) {
                message = 'Please insert a valid image file';
                error.push(message)
                flag = false
            }
            
            if (file.name.size > 1000000) {
                message = 'Please insert smaller size image less than 1mb';
                error.push(message)
                flag = false
            }
            if (flag == true) {
                this.error = ''
                return true
            }else{
                this.error = error
                return false
            }
        },
        getUserInstitutions(){
            if (this.lecturer=='undefined'||this.lecturer==null||this.lecturer=='') {
                return
            }
            for (let i = 0; i < this.lecturer.institutionsEmployed.length; i++) {
                var obj = {
                    name : '',
                    user: ''
                }
                const element = this.lecturer.institutionsEmployed[i];
                if (element.institutionId.length!=0) {
                    if (element.accepted) {
                        obj.name = element.institutionId[0].institutionName 
                        obj.user = element.institutionId[0].user[0]
                        if (obj.user==this.bookOwner) {
                            this.ownerName = obj.name
                        }
                        this.uni.push(obj)
                    }
                }
            }
            if (this.ownerName=='') {
                this.ownerName = 'Original Author'
            }
            this.canChangeOwnership = true
        },
        

    },
    mounted(){
        this.bookId = this.$route.params.id
        this.fetch_categories()
        .then(res => {
            this.categories = res.data
            this.viewBook__Admin(this.bookId)
            .then(res => { 
                this.book = res.data
                this.file = res.data.file
                this.title= res.data.title 
                this.whatYouLearn= res.data.whatYouLearn
                this.about= res.data.about 
                this.secondTitle= res.data.secondTitle 
                this.category= res.data.category[0]._id 
                this.visibilty = res.data.visibilty
                this.isApproved = res.data.isApproved 
                this.pages = res.data.pages
                this.preface = res.data.preface
                this.publisher = res.data.publisher
                this.adminSeen = res.data.adminSeen 
                this.subCategory= res.data.subCategory[0]
                this.price= res.data.price.$numberDecimal
                this.discountPercent= res.data.discountPercent 
                for (let i = 0; i < this.categories.length; i++) {
                    const element = this.categories[i];
                    if (element._id == this.category) {
                        this.subCategories = element.subCategory
                    }
                    
                }
                this.generate_V4ReadSignedUrl(this.file)
                .then(res => {
                    this.url = res.data
                }) 
            })

            
        }),
        
        this.adminId = localStorage.getItem('fmrsuperadmin'); 
        this.fetchSuperAdmin(this.adminId)
        .then(res => {
            this.Admin = res.data
        })

    }
}
</script>
<style scoped>
	@import url("./assets/css/admin.css");
    @import url("./assets/css/custom.css");
    @import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
    @import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    .approve{
        color: white;
        background-color: green;
        border-radius: 15px;
        padding: 10px;
        display: inline;
        margin-left: 10px;
        cursor: pointer;
    }

    .delete{
        color: white;
        background-color: red;
        border-radius: 15px;
        padding: 10px;
        display: inline;
        margin-left: 10px;
        cursor: pointer;
    }

        .bigspinner{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border-top: 5px solid grey;
        display: inline-block;
        animation-name: spin;
        animation-duration: 3000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear; 
    }
    .saymessage{
        display: inline-block;
        margin-left: 10px;
    }
    .smallspiner{
        height: 30px;
        width: 30px;
        margin: 5px;
        border-radius: 50%;
        border-top: 5px solid grey;
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes spin{
        from {
        transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }
</style>
<template>
    <div>
    <!-- Header Start -->
        <Dheader />
	<!-- Header End -->
	<!-- Left Sidebar Start -->
        <Dnav1 />
	<!-- Left Sidebar End -->
	<!-- Body Start -->
        <div class="wrapper">
            <div class="sa4d25">
                <div class="container-fluid">			
                    <div class="row">
                        <Loading v-if="Loading"/>
                        <div class="col-md-12">
                            <div class="section3125">
                                <h4 class="item_title">Bundles</h4>
                                <div class="la5lo1">
                                    <div class="Student_says owl-theme">
                                        <div class="item" v-for="p in results" :key="p">
                                            <router-link :to="'/Dpackage/'+p._id">
                                            <div class="fcrse_4 mb-20">
                                                <div class="st_group">
                                                    <div class="stud_img">
                                                        <span v-for="c in p.courses" :key="c">
                                                            <img :src="publicFiles+c.thumbnailFile" v-if="(p.courses.indexOf(c)<20)" alt="">	
                                                        </span>
                                                    </div>
                                                </div>	
                                                <small style="color: grey">{{p.courses.length}} courses</small>
                                                <h4>By {{p.user[0].firstName}} {{p.user[0].firstName}}</h4>
                                                <div class="say_content" style="word-wrap: break-word; margin-top: -10px">
                                                    <div class="tutor_cate">Valid till: {{p.date.split('T')[0]}}</div>
                                                    <div class="tut1250" style="margin-top: -10px">
                                                        <span class="vdt15">{{p.percent}}% off {{p.courses.length}} courses</span>
                                                    </div>
                                                </div>							
                                            </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br><br><br>
							<center>
								<button class="live_link" type="button" v-if="!Loading" @click="fetchMore">Load More</button>	
							</center>
							<br><br><br>
                        </div>
                        <div class="col-md-12" v-if="Loading">
                            <div class="main-loader mt-50">													
                                <div class="spinner">
                                    <div class="bounce1"></div>
                                    <div class="bounce2"></div>
                                    <div class="bounce3"></div>
                                </div>																										
                            </div>
                        </div>		
                    </div>
                </div>
            </div>
            <br><br><br><br><br><br>
            <Dfooter />
        </div>
	<!-- Body End -->
    </div>
</template>
<script>
import Dheader from '@/components/dashboard/Header.vue'
import Dnav1 from '@/components/dashboard/Nav1.vue'
import Dfooter from '@/components/dashboard/Footer.vue'
import Loading from "@/components/Loading.vue";
import { mapActions, mapGetters } from "vuex";
export default {
    name: 'Dexplore',
    data(){
        return{
            searchItem: '',
            type: 'instructor',
            results: '',
            Loading: false,
            tried: false,
            skip : 0
        }
    },
    components: {
        Dheader,
        Dnav1,
        Loading,
        Dfooter
    },
    computed: {
        ...mapGetters(['backEnd', 'publicFiles'])
    },
    methods: {
        ...mapActions(['fetchAllPackages']),
        fetchMore(){
            this.skip = this.skip + 10
            this.fetchAllPackages(this.skip)
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    this.results.push(element)
                }
            })
            
        }
    },   
    mounted(){
        this.fetchAllPackages(this.skip)
        .then(res => {
            this.results = res.data
        })
    }
}
</script>
<style scoped>
    @import url("./assets/css/style.css");
    /* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
    @import url("./assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/vertical-responsive-menu.min.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/instructor-responsive.css");
    @import url("./assets/css/responsive.css");
    @import url("./assets/css/night-mode.css");
    @import url("./assets/vendor/fontawesome-free/css/all.min.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("./assets/vendor/semantic/semantic.min.css");

    
</style>
<template>
    <div>
        <Header />

        <div class="content-wrapper">
            <div class="container-fluid">
                <!-- Breadcrumbs-->
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                    <a href="#">Dashboard</a>
                    </li>
                    <li class="breadcrumb-item active">Add listing</li>
                </ol>
                <div class="box_general padding_bottom">
                    <div class="header_box version_2" v-if="course!=''">
                        <h2><i class="fa fa-user"></i>Course details</h2>
                        <p class="approve" @click.prevent="manageCourse('verify')" v-if="!Loading&&course.user.length!=0" >Verify</p>
                        <p class="delete" @click.prevent="manageCourse('block')" v-if="!Loading">Block</p>
                        <!-- <p class="delete" @click.prevent="manageInstitude('delete')" v-if="!Loading">Delete</p> -->
                    </div>
                    <div class="bigspinner" v-if="Loading">
                        <div class="smallspiner">
                            
                        </div>
                    </div>
                    <Success v-if="Success">
                        <ul>
                            <li>Action Successfull</li>
                        </ul>
                    </Success>
                    <div class="row" v-if="course!=''">
                        <div class="col-md-4">
                            <div class="form-group">
                                <CourseImage :name="course.thumbnailFile" style="height: 200px; width: 250px; " />
                            </div>
                        </div> 
                        <div class="col-md-8 add_top_30" v-if="Admin.type=='Manager'||Admin.type=='General'||Admin.type=='Editor'">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Teacher Name</label>
                                        <p v-if="course.user.length!=0">{{course.user[0].firstName}} {{course.user[0].lastName}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Teacher Email</label>
                                        <p v-if="course.user.length!=0" >{{course.user[0].email}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Title</label>
                                        <input class="form-control" v-model="title" type="text" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Second Title</label>
                                        <input class="form-control" v-model="secondTitle" type="text" placeholder="Name">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Category</label><br>
                                        <select class="form-control" v-model="category" @change="setSubCat()">
                                            <option value="" :selected="true">Select Category</option>
                                            <option :value="c._id" v-for="c in categories" :key="c">{{c.name}}</option>
                                        </select><br>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Sub Category</label><br>
                                        <select class="form-control" v-model="subCategory">
                                            <option value="">Select Subcategory</option>
                                            <option :value="c._id" v-for="c in subCategories" :key="c">{{c.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>What You Learn</label>
                                        <input class="form-control" v-model="whatYouLearn" type="text" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Requirement</label><br>
                                        <select class="form-control" v-model="requirement">
                                            <option value="">Select requirement</option>
                                            <option value="None">None</option>
                                            <option value="O_level">O'level</option>
                                            <option value="Bachelor degree">Bachelor degree</option>
                                            <option value="Masters">Masters</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Visibilty</label><br>
                                        <select class="form-control" v-model="visibilty">
                                            <option value="Everyone">Everyone</option>
                                            <option value="Only institution Students">Only Institution Students</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Price</label>
                                        <input class="form-control" v-model="price" type="number" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Discount Percent</label>
                                        <input class="form-control" v-model="discountPercent" type="number" placeholder="Name">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Description</label>
                                        <input class="form-control" v-model="description" type="text" placeholder="Name">
                                    </div>
                                </div>
                            </div>
                            <Errors v-if="error">
								<ul>
									<li v-for="error in error" :key="error">{{error}}</li>	
								</ul>
							</Errors>
                            <button class="btn_1 medium" v-if="subCategory!=''&&!Loading" @click.prevent="saveChanges()">Update Course</button>
                            <hr>
                            <!-- <div class="row" v-if="canChangeOwnership">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Change Ownership from {{ownerName}}</label><br>
                                        <select class="form-control" v-model="newOwner" @change="changingOwner=true">
                                            <option :value="psuedoOwner" :selected="true">To Original Author</option>
                                            <option :value="u.user"  v-for="u in uni" :key="u">To {{u.name}}</option>
                                        </select>
                                        <small v-if="changedSuccess" style="color: green">The ownership of this course has been changed to {{ownerName}}</small><br>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <br><br>
                                        <div class="bigspinner" v-if="Loading">
                                            <div class="smallspiner">
                                            </div>
                                        </div>
                                        <button class="btn_1 medium" v-if="!Loading&&changingOwner" @click.prevent="changeOwner()">Save new owner</button>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="col-md-8 add_top_30" v-else>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <p>{{course.title}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Category</label>
                                        <p>{{course.category[0].name}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Teacher Name</label>
                                        <p>{{course.user[0].firstName}} {{course.user[0].lastName}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Teacher Email</label>
                                        <p>{{course.user[0].email}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>What You Learn</label>
                                        <p>{{course.whatYouLearn}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Requirement</label>
                                        <p>{{course.requirement}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Second Title</label>
                                        <p>{{course.secondTitle}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Price</label>
                                        <p>{{course.price.$numberDecimal}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Description</label>
                                        <p>{{course.description}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box_general padding_bottom">
                    <h1>Modules</h1> 
                    <div class="row" style="width:100%">
                        <div class="col-md-12" v-if="modules!=''">
                            <div class="form-group" v-for="m in modules" :key="m">
                                <label style="border-bottom: 1px solid black">
                                    <strong>{{m.sectionTitle}}</strong>
                                    <br>
                                    <small>{{m.description}}</small>
                                    <br>
                                    <small>{{m.externalLink}}</small>
                                </label>
                                <router-link v-for="v in m.videos" :key="v" :to="'/SAlecturePage/'+v._id">
                                    <p >{{v.title}} <span style="color: red">{{v.fileType}}</span></p>
                                </router-link>
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <p><a href="#0" class="btn_1 medium">Save</a></p> -->
            </div>
   	    </div>
        <!-- /.container-wrapper-->
        <Footer />
        <!-- Scroll to Top Button-->
        <a class="scroll-to-top rounded" href="#page-top">
        <i class="fa fa-angle-up"></i>
        </a>
        <!-- Logout Modal-->
        <Modal />
        
    </div>
</template>
<script>
import Header from '@/components/superAdmin/Header.vue'
import Modal from '@/components/superAdmin/Modal.vue'
import Footer from '@/components/superAdmin/Footer.vue'
import Success from "@/components/Success.vue";
import Errors from "@/components/Errors.vue"
import CourseImage from '@/components/CourseImage.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return{
            course: '',
            modules: '',
            courseId: this.$route.params.id,
            Admin: '',
            adminId: '',
            Loading: false,
            Success: false,
			userId:'', 
			title:'', 
			whatYouLearn:'',
			requirement:'', 
			description:'', 
			secondTitle:'', 
            category:'', 
            subCategory: '',
            discountPercent: 0,
			price:'',
            thumbnail:'',
            categories: '',
            subCategories: '',
            isImagePresent: false,
            visibilty: '',
            courseOwner: '',
            psuedoOwner: '',
            lecturer: '',
            uni: [],
            canChangeOwnership: false,
            newOwner: '',
            changingOwner: false,
            ownerName: '',
            error: '',
            changedSuccess: false
        }
    },
    components: {
        Header,
        Modal,
        Success,
        CourseImage,
        Errors,
        Footer
    },
    computed: {
        ...mapGetters(['backEnd'])
    },
    methods: {
        ...mapActions(['viewCourse__Admin','manageVerifyCourse_Admin','fetchSuperAdmin','fetch_categories']),
        ...mapActions(['editCourse','view_CourseForEdit','fetchInstructor_Details','changeCourseOwner_Admin']),
        manageCourse(type){
            this.Loading = true
            let object ={
                courseId: this.courseId, 
                actionType : type,
                courseType : null
            }
            this.manageVerifyCourse_Admin(object)
            .then(res => {
                if (res.data.success) {
                    this.Loading = false
                    this.Success = true
                        
                }
            })
        },
        changeOwner(){
            this.Loading = true
            let object ={
                courseId: this.courseId, 
                newOwnerId:  this.newOwner //"61475a096c226a0a09ffa944"  
            }
            this.changeCourseOwner_Admin(object)
            .then(res => {
                if (res.data.success) {
                    this.Loading = false
                    this.changedSuccess = true 
                    this.changingOwner = false
                    let exist = false
                    for (let i = 0; i < this.uni.length; i++) {
                        const element = this.uni[i];
                        if (this.newOwner == element.user) {
                            this.ownerName = element.name
                            exist = true
                        }
                    }
                    if (!exist) {
                        this.ownerName = "Original Author"
                    }
                }
            })
        },
		saveChanges(){
            if(this.validateInputs()){

                this.Loading = true
                let formData = new FormData();
                formData.append('courseId', this.courseId);
                formData.append('title', this.title);
                formData.append('whatYouLearn', this.whatYouLearn);
                formData.append('requirement', this.requirement);
                formData.append('description', this.description);
                formData.append('secondTitle', this.secondTitle);
                formData.append('category', this.category);
                formData.append('subCategory', this.subCategory);
                formData.append('price', this.price);
                formData.append('visibilty', this.visibilty); 
                formData.append('discountPercent', this.discountPercent);
                formData.append('thumbnail', this.thumbnail);
                formData.append('isImagePresent', this.isImagePresent);
                this.editCourse(formData)
                .then(res => {
                    this.Loading = false
                    this.Success = res.data.success;
                })
                .catch(err => {
                    this.Loading = false
                    console.log(err)
                })
            }
		},
        validateInputs(){
            let flag = true;
            let message = '';
			let error =[];
			if (this.price=='') {
                message = 'Please input price ';
                error.push(message)
                flag = false
            }
			if (this.discountPercent==0||this.discountPercent==null) {
                message = 'Please input discount Percent ';
                error.push(message)
                flag = false
            }
			if (this.discountPercent<1||this.discountPercent>100) {
                message = 'discount Percent can not be less than 1 or greater than 100';
                error.push(message)
                flag = false
            }
			if (this.subCategory=='') {
                message = 'Please input subCategory ';
                error.push(message)
                flag = false
            }
			if (this.category=='') {
                message = 'Please input category ';
                error.push(message)
                flag = false
            }
			if (this.title=='') {
                message = 'Please input title ';
                error.push(message)
                flag = false
            }
			if (this.secondTitle=='') {
                message = 'Please input second Title ';
                error.push(message)
                flag = false
            }
			if (this.requirement=='') {
                message = 'Please input requirements ';
                error.push(message)
                flag = false
            }
            if (this.whatYouLearn=='') {
                message = 'Please input what you learn ';
                error.push(message)
                flag = false
            }
            if (flag == true) {
                this.error = ''
                return true
            }else{
                this.error = error
                return false
            }
        },

		handleFileUpload(){
            if (this.validateFile(this.$refs.thumbnail.files[0])) {
                this.thumbnail = this.$refs.thumbnail.files[0];
                const file = this.$refs.thumbnail.files[0];
                const previewImage = document.getElementById('previewImage');
                if (file) {
                    const reader = new FileReader();
                    reader.addEventListener("load", function () {
                        previewImage.setAttribute('src', this.result);
                    });
                    reader.readAsDataURL(file);
                }
                document.getElementById('thumbnaildisplay').innerHTML = this.thumbnail
                this.isImagePresent = true
            }
        },
        setSubCat(){
            this.subCategory = ''
            for (let i = 0; i < this.categories.length; i++) {
                const element = this.categories[i];
                if (element._id == this.category) {
                    this.subCategories = element.subCategory
                }
                
            }
        },
        validateFile(file){
            let flag = true;
            let message = '';
            let error =[];
            const allowedExtensions =  ['jpg','png','jpeg','JPG','PNG','JPEG'];
            if (!allowedExtensions.includes(file.name.split(".").pop())) {
                message = 'Please insert a valid image file';
                error.push(message)
                flag = false
            }
            
            if (file.name.size > 1000000) {
                message = 'Please insert smaller size image less than 1mb';
                error.push(message)
                flag = false
            }
            if (flag == true) {
                this.error = ''
                return true
            }else{
                this.error = error
                return false
            }
        },
        getUserInstitutions(){
            if (this.lecturer=='undefined'||this.lecturer==null||this.lecturer=='') {
                return
            }
            for (let i = 0; i < this.lecturer.institutionsEmployed.length; i++) {
                var obj = {
                    name : '',
                    user: ''
                }
                const element = this.lecturer.institutionsEmployed[i];
                if (element.institutionId.length!=0) {
                    if (element.accepted) {
                        obj.name = element.institutionId[0].institutionName 
                        obj.user = element.institutionId[0].user[0]
                        if (obj.user==this.courseOwner) {
                            this.ownerName = obj.name
                        }
                        this.uni.push(obj)
                    }
                }
            }
            if (this.ownerName=='') {
                this.ownerName = 'Original Author'
            }
            this.canChangeOwnership = true
        }

    },
    mounted(){
        this.courseId = this.$route.params.id
        this.fetch_categories()
        .then(res => {
            this.categories = res.data
            this.viewCourse__Admin(this.courseId)
            .then(res => { 
                this.course = res.data.course
                this.modules = res.data.modules
            })

            this.view_CourseForEdit(this.courseId)
			.then(res =>{
				this.title= res.data.title 
                this.whatYouLearn= res.data.whatYouLearn
                this.requirement = res.data.requirement 
                this.description= res.data.description 
                this.secondTitle= res.data.secondTitle 
                this.category= res.data.category[0] 
                this.visibilty = res.data.visibilty
                this.isApproved = res.data.isApproved 
                this.adminSeen = res.data.adminSeen 
                this.subCategory= res.data.subCategory[0]
                this.price= res.data.price.$numberDecimal
                this.discountPercent= res.data.discountPercent 
                for (let i = 0; i < this.categories.length; i++) {
                    const element = this.categories[i];
                    if (element._id == this.category) {
                        this.subCategories = element.subCategory
                    }
                    
                }
                this.courseOwner = res.data.user
                this.psuedoOwner = res.data.psuedoUser
                if (!(this.courseOwner == ''|| this.courseOwner==null || this.courseOwner == 'undefined' || this.courseOwner.length == 0)) {
                    this.fetchInstructor_Details(this.courseOwner[0])
                    .then(res => {
                        this.lecturer = res.data[0]
                        if (this.lecturer==null||this.lecturer=="null"||this.lecturer=={}) {
                            if (this.psuedoOwner!=null&&this.psuedoOwner!=''&&this.psuedoOwner.length!=0) {
                                //console.log("this is it "+this.psuedoOwner.length)
                                this.fetchInstructor_Details(this.psuedoOwner[0])
                                .then(res => {
                                    this.lecturer = res.data[0]
                                    this.getUserInstitutions()
                                }) 
                            }
                        }else{
                            this.getUserInstitutions()
                        }
                    })	
                }  
            })
        }),
        
        this.adminId = localStorage.getItem('fmrsuperadmin'); 
        this.fetchSuperAdmin(this.adminId)
        .then(res => {
            this.Admin = res.data
        })

    }
}
</script>
<style scoped>
	@import url("./assets/css/admin.css");
    @import url("./assets/css/custom.css");
    @import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
    @import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    .approve{
        color: white;
        background-color: green;
        border-radius: 15px;
        padding: 10px;
        display: inline;
        margin-left: 10px;
        cursor: pointer;
    }

    .delete{
        color: white;
        background-color: red;
        border-radius: 15px;
        padding: 10px;
        display: inline;
        margin-left: 10px;
        cursor: pointer;
    }

        .bigspinner{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border-top: 5px solid grey;
        display: inline-block;
        animation-name: spin;
        animation-duration: 3000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear; 
    }
    .saymessage{
        display: inline-block;
        margin-left: 10px;
    }
    .smallspiner{
        height: 30px;
        width: 30px;
        margin: 5px;
        border-radius: 50%;
        border-top: 5px solid grey;
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes spin{
        from {
        transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }
</style>
<template>
    <div>
        <Header />

        <div class="content-wrapper">
            <div class="container-fluid">
      <!-- Breadcrumbs-->
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                <a href="#">Dashboard</a>
                </li>
                <li class="breadcrumb-item active">Add listing</li>
            </ol>
                
                <br><br>
                <!-- /box_general-->
                <div class="row">
                    <div class="col-md-6">
                        <div class="box_general padding_bottom">
                            <div class="header_box version_2">
                                <h2><i class="fa fa-lock"></i>New Coupon</h2>
                            </div>
                            <div class="form-group">
                                <label>Coupon Title</label>
                                <input class="form-control" type="text" v-model="title">
                            </div>
                            <div class="form-group">
                                <label>Coupon Type</label>
                                <select class="form-control" v-model="type">
                                    <option value="percentage">Percentage</option>
                                    <option value="fixed_cart">Fixed Cart</option>
                                    <!-- <option value="fixed_product">Fixed Product</option> -->
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Coupon Description</label>
                                <input class="form-control" type="email" v-model="description">
                            </div>
                            <div class="form-group" v-if="type=='percentage'">
                                <label>Percent</label>
                                <input class="form-control" type="number" v-model="percent">
                            </div>
                            <div class="form-group" v-if="type=='fixed_cart'||type=='fixed_product'">
                                <label>Slash Amount</label>
                                <input class="form-control" type="number" v-model="slashAmount">
                            </div>
                            <div class="form-group">
                                <label>Expire Date</label>
                                <input class="form-control" type="date" v-model="expire">
                            </div>
                            <div class="form-group"> 
                                <label>Usage Limit</label>
                                <input class="form-control" type="number" v-model="usageLimit">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <Errors v-if="error.length!=0">
                            <ul>
                                <li v-for="error in error" :key="error">{{error}}</li>	
                            </ul>
                        </Errors>
                        <Success v-if="Success">
                            <ul>
                                <li>Coupon created successfully</li>
                                <li>Please copy the coupon code below and add to courses</li>
                                <li>Coupon code: {{newcoupon}}</li>
                            </ul>
                        </Success>
                    </div>
                </div>
                <Loading v-if="Loading" />
                
                <!-- /row-->
                <p @click.prevent="addCoupon" v-if="!Loading"><a href="#0" class="btn_1 medium">Submit</a></p>
            </div>
            <!-- /.container-fluid-->
            </div>
            <!-- /.container-wrapper-->
            <Footer />
            <!-- Scroll to Top Button-->
            <a class="scroll-to-top rounded" href="#page-top">
            <i class="fa fa-angle-up"></i>
            </a>
            <!-- Logout Modal-->
            <Modal />
        
    </div>
</template>
<script>
import { mapActions } from "vuex";
import Loading from '@/components/Loading.vue'
import Errors from '@/components/Errors.vue'
import Success from '@/components/Success.vue'
import Header from '@/components/superAdmin/Header.vue'
import Modal from '@/components/superAdmin/Modal.vue'
import Footer from '@/components/superAdmin/Footer.vue'
export default {
    data(){
        return {
            type: "", 
            percent: "",
            slashAmount: "", 
            title: "", 
            description: "", 
            expire: "",  
            usageLimit: "",
            Loading : false,
            Success : false,
            newcoupon : "",
            errors : "",
            error: []
        }
    },
    components: {
        Header,
        Modal,
        Footer,
        Loading,
        Errors,
        Success
    },
    methods: {
        ...mapActions(['createCoupon__Admin']),
        addCoupon(){
            this.Success = false
            if(this.validate()){
                this.Loading = true
                this.error = []
                let coupon = {
                    type: this.type, 
                    percent: this.percent,
                    slashAmount: this.slashAmount, 
                    title: this.title, 
                    description: this.description, 
                    expire: this.expire,  
                    usageLimit: this.usageLimit,
                }
                this.createCoupon__Admin(coupon)
                .then(res => {   
                    this.Loading = false
                    if (res.data._id == null) {
                        this.error.push("Ooops!! something went wrong, refresh page and try again")
                    }else{
                        this.Success = true
                    }
                    this.newcoupon = res.data._id
                    this.type= "" 
                    this.percent= ""
                    this.slashAmount= "" 
                    this.title= "" 
                    this.description= "" 
                    this.expire= ""  
                    this.usageLimit= ""
                })
                .catch(err => {
                    this.error.push("Ooops!! something went wrong, refresh page and try again")
                    console.log(err)
                })
            }
            
        },
        validate(){
            var flag = false
            if (this.type=='') {
                flag = true
                this.error.push("Please select coupon type")
            }
            if (this.type=="percentage") {
                if (this.percent=='') {
                    flag = true
                    this.error.push("percent is required")
                }   
                if (this.percent> 100) {
                    flag = true
                    this.error.push("percent can not be greater than 100")
                }   
            }else{
                if (this.slashAmount=='') {
                    flag = true
                    this.error.push("slashAmount is required")
                }
            }
            
            if (this.title=='') {
                flag = true
                this.error.push("title is required")
            }
            if (this.description=='') {
                flag = true
                this.error.push("description is required")
            }
            if (this.expire=='') {
                flag = true
                this.error.push("expire is required")
            }
            if (this.usageLimit=='') {
                flag = true
                this.error.push("usageLimit is required")
            } 

            if (flag) {
                return false
            }else{
                return true
            }
        }
    }
}
</script>
<style >
	@import url("./assets/css/admin.css");
    @import url("./assets/css/custom.css");
    @import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
    @import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
</style>
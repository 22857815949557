<template>
<div>
<!-- Header Start -->
	<Dheader />
<!-- Header End -->
<!-- Left Sidebar Start -->
	<Dnav1 />
<!-- Left Sidebar End -->
<!-- Body Start -->
<SkeletonLoader />
<div class="wrapper _bg4586" >
	<div class="_215b01">
		<div class="container-fluid">			
			<div class="row">
				<div class="col-lg-12">
					<div class="section3125">							
						<div class="row justify-content-center" v-if="bundle!=''">	
							<div class="col-xl-12 col-lg-12 col-md-6" v-if="new Date(bundle.date) > new Date()">
								<div class="_215b03">
									<h2>{{bundle.title}}</h2>
								</div>
								<div class="_215b05">
									<div class="crse_reviews mr-2">
										<i class="uil uil-star"></i> {{bundle.percent}}% off
									</div>
								</div>
								<div class="_215b05" >										
									{{bundle.courses.length}} courses
								</div>
								<!-- <div class="_215b05" v-if="course.updatedAt!=''&&course.updatedAt!=null">										
									Last updated : {{course.updatedAt.split('T')[0]}}
								</div> -->
								<ul class="_215b31" v-if="paymentDetails">
									<!-- <a ><li><button class="btn_adcart" id="paymentForm" @click="activateModal('memberships')">Buy for a Membership </button></li></a> -->
									<a v-if="paymentDetails.bankDetails.account_number">
										<li v-if="purchased"><button class="btn_buy" id="paymentForm" >Already Purchased</button></li>
										<li v-else><button class="btn_adcart" id="paymentForm" @click="buyNow()">Buy Now</button></li>
									</a>	
									<a v-else><li><button class="btn_buy" id="paymentForm" >Sorry you can not buy now</button></li></a>
								</ul>
								
								<div class="_215b10">										
									<a href="#" class="_215b11">										
										<span><i class='uil uil-money-bill'></i></span><span v-if="amount!=0">₦{{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span><span v-else>FREE</span>
									</a>
									<a href="#" class="_215b12" v-if="bundle.date">										
										<span><i class="uil uil-windsock"></i></span> Expires {{bundle.date.split('T')[0]}}
									</a>
								</div>
							</div>
							<div class="col-xl-12 col-lg-12 col-md-6" v-else>
								<div class="_215b03">
									<h2>{{bundle.title}}</h2>
								</div>
								<div class="_215b05">
									<div class="crse_reviews mr-2">
										<i class="uil uil-star"></i> {{bundle.percent}}% off
									</div>
								</div>
								<div class="_215b05" >										
									{{bundle.courses.length}} courses
								</div>
								<!-- <div class="_215b05" v-if="course.updatedAt!=''&&course.updatedAt!=null">										
									Last updated : {{course.updatedAt.split('T')[0]}}
								</div> -->
								<ul class="_215b31" v-if="paymentDetails">
									
									<a ><li><button class="btn_buy" id="paymentForm" >This Bundle is no longer available </button></li></a>
								</ul>
								
								<div class="_215b10">										
									<a href="#" class="_215b11">										
										<span><i class='uil uil-money-bill'></i></span><span v-if="amount!=0">₦{{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span><span v-else>FREE</span>
									</a>
									<a href="#" class="_215b12" v-if="bundle.date">										
										<span><i class="uil uil-windsock"></i></span> Expires {{bundle.date.split('T')[0]}}
									</a>
								</div>
							</div>							
						</div>							
					</div>							
				</div>															
			</div>
		</div>
	</div>
	<!--  -->
	
	<div class="_215b155 _byt1458" v-if="bundle!=''">
		<div class="container-fluid" v-if="new Date(bundle.date) > new Date()">
			<div class="">
				<div class="col-lg-12">
					<div class="user_dt5">
						<div class="user_dt_left">
							<div class="live_user_dt" v-if="bundle!=''">
								<div class="user_img5">
									<ProfileImage :id="bundle.user[0]._id" :name="bundle.user[0].imageFile" />												
								</div>
								<div class="user_cntnt">
									<a href="#" class="_df7852">{{bundle.user[0].firstName}} {{bundle.user[0].lastName}}</a>
									<router-link :to="'/Dprofile/'+bundle.user[0]._id" v-if="bundle.user[0]._id!=''">
									<button class="subscribe-btn">instructor Profile</button>
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="course_tabs">
						<nav>
							<div class="nav nav-tabs tab_crse justify-content-center" id="nav-tab" role="tablist">
								<a class="nav-item nav-link" v-bind:class="{ active: content }" @click.prevent="switchtap('content')" aria-selected="false">Content</a>
								<a class="nav-item nav-link" v-bind:class="{ active: about }" @click.prevent="switchtap('about')" aria-selected="true">About</a>
							</div>
						</nav>						
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="_215b17" v-if="bundle!=''">
		<div class="container-fluid" v-if="new Date(bundle.date) > new Date()">
			<div class="row">
				<div class="col-lg-12">
					<div class="course_tab_content">
						<div class="tab-content" id="nav-tabContent">
							<div class="tab-pane fade show" style="background-color: transparent" v-bind:class="{ active: content }" id="nav-about" role="tabpanel">
								<div class="_htg451" style="background-color: transparent">
									<div class="_htg452">
										<h3>Bundle Courses</h3>
										<div class="row">
											<CourseEntityTiny v-for="c in bundle.courses" :key="c" 
												:id="c._id" 
												:title="c.title"
												:file="c.thumbnailFile"
												:category="c.category[0].name"
												:price="c.price.$numberDecimal"
												:firstName="c.user[0].firstName"
												:lastName="c.user[0].lastName"
												:userId="c.user[0]._id"
												:discount="c.discountPercent"
											/>								
										</div>
										<br><br><br>	
									</div>																	
								</div>
							</div>
							<div class="tab-pane fade show" style="background-color: transparent" v-bind:class="{ active: about }" id="nav-courses" role="tabpanel">
								<div class="crse_content" style="background-color: transparent" >
									<br>
									<h3>About Instructor</h3>
									<div id="accordion" class="ui-accordion ui-widget ui-helper-reset" v-if="bundle!=''">
										{{bundle.user[0].bio}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<Dfooter />
</div>
<!-- Body End -->
</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import CourseEntityTiny from "@/components/dashboard/CourseEntityTiny";
import ProfileImage from "@/components/ProfileImage";
import Dheader from '@/components/dashboard/Header.vue'
import Dnav1 from '@/components/dashboard/Nav1.vue'
import Dfooter from '@/components/dashboard/Footer.vue'
export default {
	name: 'Dhome',
	data() {
        return{
			packageId: '',
            bundle: '',
            userId: '',
			user: 	'',
			about: false,
			content: true,
			amount: 0,
			paymentDetails: '',
			purchased: null,
        }
    },
    components: {
        Dheader,
		Dnav1,
		CourseEntityTiny,
		ProfileImage,
        Dfooter
	},
	computed: {
		...mapGetters(['isInstructor'])
    },
	methods: {
        ...mapActions(['fetchPackage','getPaymentDetails','fetch_user','checkPuchasedBundleById']),
		
		switchtap(tap){
			this.skip = 0
			switch (tap) {
				case 'about':
					this.about = true;
					this.content = false;
					break;
				case 'content':
					this.about = false;
					this.content = true;
					break;
				default:
					break;
			}
		},

		
		buyNow(){
			if (this.paymentDetails=='') {
				alert("please wait for minuite and try again")
				return
			}
			let object = {
				price : this.amount,
				paymentDetails: this.paymentDetails,
				email: this.user.email,
				title: this.bundle.title,
				id: this.bundle._id,
				membershipId: null
			}
			
			localStorage.setItem("fmrbundlecart", JSON.stringify(object));
			//localStorage.setItem("fmrlastbundlereference", "T639347276615033");//{reference: "T639347276615033"}
			
			let str = window.location.href+"";
			const myArr = str.split("/Dpackag");
			window.location.href = myArr[0] + "/Dpackage/buy.html"
		},
		// buyNowForMembers(price, membershipId){
		// 	if (this.paymentDetails=='') {
		// 		alert("please wait for minuite and try again")
		// 		return
		// 	}
		// 	let object = {
		// 		price : price,
		// 		paymentDetails: this.paymentDetails,
		// 		email: this.user.email,
		// 		title: this.course.title,
		// 		id: this.course._id,
		// 		membershipId: membershipId
		// 	}
		// 	//console.log(object)
		// 	localStorage.setItem("fmrpackagecart", JSON.stringify(object));
		// 	//localStorage.setItem("fmrlastreference", 103434038);
		// 	let str = window.location.href+"";
		// 	const myArr = str.split("/Dcoursedetai");
		// 	window.location.href = myArr[0] + "/Dcoursedetail/buy.html"
		// }
    },   
    mounted(){
		this.bundleId = this.$route.params.id
		this.userId = localStorage.getItem('fmruser');

		this.fetch_user(this.userId)
		.then((res) => {
			this.user = res.data.user;
		})
		.catch((err) => {
			console.log(err);
		});
			
        this.fetchPackage(this.bundleId)
        .then(res =>{
            this.bundle = res.data

			for (let i = 0; i < this.bundle.courses.length; i++) {
				const element = this.bundle.courses[i];
				this.amount = this.amount+ ((this.bundle.percent/100)*parseInt(element.price.$numberDecimal))
				this.amount = ~~this.amount
			}

			if (this.bundle.courses.length>0){
				this.getPaymentDetails(this.bundle.courses[0].user[0]._id)
				.then(res =>{
					this.paymentDetails = res.data.paymentDetails
				})
			}
			
        
        })

		this.checkPuchasedBundleById(this.bundleId)
		.then(res => {
			this.purchased = res.data
		})

	}
}
</script>
<style scoped>
	@import url("./assets/css/style.css");
    /* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
    @import url("./assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/vertical-responsive-menu.min.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/instructor-responsive.css");
    @import url("./assets/css/responsive.css");
    @import url("./assets/css/night-mode.css");
    @import url("./assets/vendor/fontawesome-free/css/all.min.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("./assets/vendor/semantic/semantic.min.css");
	
	.nav-link{
		cursor: pointer;
	}

	.liked{
		background-color: yellowgreen;
		border: 2px solid yellowgreen;
	}

	.likeButton{
		width: 50px;
		height: 50px;
		border-radius: 10px;
		padding: 10px;
	}

	.liking{
		width: 60px;
		height: 60px;
		display: inline-block;
		margin: 5px;
		padding-top: 10px;
	}

	.darkest{
		background-color: black;
		padding-top: 50px;
		padding-bottom: 50px;
		margin: 0px;
	}

	.takecourse{
        padding-top: 2px;
		padding-bottom: 2px;
		padding-left: 5px;
		padding-right: 5px;
        background-color: rgb(231, 69, 40);
        border-radius: 10px;
		color: white;
    }

	.closed{
		display: none;
	}
	.open{
		display: block;
	}

</style>
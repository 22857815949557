<template>
    <div>
        <Nav />

        <Tittle type="general">
            <h1 class="fadeInUp"><span></span>How Can We Help You?</h1>
        </Tittle>

		<div class="bg_color_1" style="margin: auto">
			<div class="container margin_120_95">
				<div class="row justify-content-between">
					
					<div class="col-lg-12">
						<h4>Send a message</h4>
						<p>And we will reply to your mail</p>
						<div id="message-contact"></div>
						<form method="post" action="assets/contact.php" id="contactform" autocomplete="off">
							<div class="row">
								<div class="col-md-12">
									<span class="input">
										<input class="input_field" type="text" placeholder="Please Enter Your Name" id="name_contact" name="name_contact" v-model="name">
										<label class="input_label">
										</label>
									</span>
									
								</div>
								
							</div>
							<!-- /row -->
							<div class="row">
								<div class="col-md-12">
									<span class="input">
										<input class="input_field" type="email" placeholder="Please Enter Your Email" id="email_contact" v-model="email" name="email_contact">
										<label class="input_label">
										</label>
									</span>
									
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<span class="input">
										<input class="input_field" type="mobile" placeholder="Please Enter Your Mobile number" id="mobile_contact" v-model="mobile" name="mobile_contact">
										<label class="input_label">
										</label>
									</span>
									
								</div>
							</div>
							<!-- /row -->
							<span class="input">
									<textarea class="input_field" placeholder="Now tell us how we could be of help" id="message_contact" v-model="content" name="message_contact" style="height:150px;"></textarea>
									<label class="input_label">
									</label>
							</span>
							<Loading v-if="Loading"/>
							<Errors v-if="errors.length">
								<ul>
									<li v-for="error in errors" :key="error">{{error}}</li>	
								</ul>
							</Errors>
							<Success v-if="Success">
								<ul>
									<li>Your message was sent Successfully</li>
									<li>We will get back to you as soon as possible</li>
								</ul>
							</Success>
							<p class="add_top_30" >
								<input v-if="!Loading" @click="sendMessage()" type="button" value="Submit" class="btn_1 rounded" id="submit-contact">
								<input v-else  type="button" value="Please Wait..." class="btn_1 rounded" id="submit-contact">
							</p>
						</form>
					</div>
				</div>
				<!-- /row -->
			</div>
			<!-- /container -->
		</div>
		<!-- /bg_color_1 -->

        <Footer />
    </div>
</template>

<script>
    import Nav from '@/components/site/Nav.vue'
    import Footer from '@/components/site/Footer.vue'
    import Tittle from '@/components/site/Tittle.vue'
	import Loading from '@/components/Loading.vue'
	import Errors from '@/components/Errors.vue'
	import Success from '@/components/Success.vue'
	import { mapActions, mapGetters } from 'vuex';
    export default {
        name: 'Contact',
		data(){
			return{
				content: '', 
				email: '', 
				name: '',  
				mobile: '',
				Loading: false,
				Success: false,
				errors: []
			}
		},
		computed: {
			...mapGetters(['publicToken'])
		},
		methods: {
			...mapActions(['send_StudentFeedbackPublicMessage']),
			sendMessage(){
				if (this.validate()){	
					this.Loading = true
					let object = {
						content: this.content, 
						email: this.email, 
						name: this.name,  
						mobile: this.mobile,
						publicToken: this.publicToken,
						seen: false,
						resolved: false
					}
					this.send_StudentFeedbackPublicMessage(object)
					.then(res => {
						this.Loading = false
						if (res.data.error!=true) {
							this.Success = true
							this.content = ''
							this.email= '' 
							this.name= ''  
							this.mobile= ''
						}else{
							this.errors.push(res.data.message);
						}
					})
					.catch(err => {
						console.log(err)
						this.errors.push("OOPs!, some thing went wrong, please make sure you are sending a valid email address")
					})
				}
			},
			validate(){
				let error = []
				if (this.content== '') {
					error.push("please enter the content of your message")
				}
				if (this.email== '') {
					error.push("please enter your email")
				}
				if (this.name== '') {
					error.push("please enter your name")
				}
				if (this.mobile== '') {
					error.push("please enter your mobile")
				}
				this.errors = error
				if (error.length==0) {
					return true
				}else{
					return false
				}
			}
		},
        components: {
            Nav,
            Tittle,
            Footer,
			Loading,
			Errors,
			Success
        },
        mounted(){
            let js = document.createElement('script')
            js.setAttribute('src', 'js/main.js')
            document.head.appendChild(js)
            let jsc = document.createElement('script')
            jsc.setAttribute('src', 'js/common_scripts.js')
            document.head.appendChild(jsc)
        }
    }
</script>

 
<style scoped>

	@import url("./asset/css/bootstrap.min.css"); 
	@import url("./asset/vendors/animation/animate.css"); 
	@import url("./asset/css/style.css");
	@import url("./asset/css/responsive.css"); 
 /* img{
	 border-radius: 10px;
 } */
</style>
<template>
<div>
    <!-- Header Start -->
        <Dheader />
    <!-- Header End -->
    <!-- Left Sidebar Start -->
        <Dnav2 />
    <!-- Left Sidebar End -->
    <!-- Body Start -->
    <div class="wrapper">
        <div class="sa4d25" >
            <div class="container">			
                <div class="row">
                    <div class="col-lg-12">	
                        <h2 class="st_title"><i class="uil uil-analysis"></i> Manage Course Sections</h2>
                        <strong>
                            <span class="statusTag green" v-if="isApproved">Status: Approved</span>
                            <span v-else>
                                <span class="statusTag red" v-if="adminSeen">Status: Denied</span> 
                                <span class="statusTag orange" v-else>Status: Pending</span> 
                            </span>
                        </strong>
                    </div>					
                </div>				
                <div class="row">
                    <div class="col-12">
                        <div class="course_tabs_1">
                            <div id="add-course-tab" class="step-app">
                                <CourseSections :courseId="courseId"/>
                                <Loading v-if="courseDetails==''" />
                                <div class="step-content" v-if="isAuthor">
                                    <div class="step-tab-panel step-tab-location" style="background-color: transparent" id="tab_step3">
                                        <div class="tab-from-content">
                                            <div class="title-icon">
                                                <h3 class="title"><i class="uil uil-film"></i>Course Coupons</h3>
                                            </div>
                                            <Loading v-if="Loading" />
                                            <Errors v-if="coupon==null||errors.length != 0">
                                                <ul>
                                                    <li v-if="coupon==null">Coupon does not exist</li>
                                                    <li v-for="error in errors" :key="error">{{error}}</li>		
                                                </ul>
                                            </Errors>
                                            <Success v-if="coupon&&errors.length == 0">
                                                <ul>
                                                    <li>Title: {{coupon.title}}</li>	
                                                    <li>Expiring date: {{coupon.expire}}</li>	
                                                    <li>Coupon Type: {{coupon.type}}</li>	
                                                    <li>Usage Limit: {{coupon.usageLimit}}</li>
                                                    <li v-if="coupon.type=='percentage'">Percentage: {{coupon.percent}}%</li>	
                                                    <li v-else>Slash Amount: {{coupon.slashAmount}}</li>	
                                                    <button  class="login-btn" type="button" style="background-color: #006400" @click.prevent="addCoupon()">Save coupon to course</button>
                                                </ul>
                                                <p></p>
                                            </Success>
                                            <div class="tab-from-content">
                                                <div class="course__form">
                                                    <div class="view_info10">
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="view_all_dt" style="padding: 20px" >		
                                                                    <div class="view_img_right" style="width:100%">	
                                                                        <h4 style="margin: 0px">New course coupon</h4>
                                                                        <div class="col-lg-6 col-md-6">															
                                                                            <div class="ui search focus mt-30 lbel25">
                                                                                <!-- <label>Course Title*</label> -->
                                                                                <div class="ui left icon input swdh19">
                                                                                    <input class="prompt srch_explore" type="text" placeholder="Enter Coupon code" name="title" data-purpose="edit-course-title" maxlength="60" v-model="couponcode">															
                                                                                    <div class="badge_num">60</div>
                                                                                </div>
                                                                            </div>									
                                                                        </div>
                                                                        <br>
                                                                        <button class="save_content_btn" id="btn"  :disabled="previewVideo==''||Loading" @click.prevent="fetchCouponDetails()">Preview Coupon Details</button>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="table-responsive mt-30">
                                                <div class="cover" style="width: 100%">
                                                    
                                                    <table class="table ucp-table">
                                                        <thead class="thead-s">
                                                            <tr>
                                                                <th class="cell-ta">Title</th>
                                                                <th class="text-center" scope="col">Code</th>
                                                                <th class="text-center" scope="col">Expire</th>
                                                                <th class="text-center" scope="col">Type</th>
                                                                <th class="text-center" scope="col">Percentage/Slash</th>
                                                                <th class="text-center" scope="col">Usage Limit</th>
                                                                <th class="text-center" scope="col">Controls</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="c in allCoupons" :key="c">
                                                                <td class="text-center">{{c.title}}</td>
                                                                <td class="cell-ta">{{c._id}}</td>
                                                                <td class="text-center">{{c.expire}}</td>
                                                                <td class="text-center">{{c.type}}</td>
                                                                <td class="text-center" v-if="c.type=='percentage'">{{c.percent}}%</td>
                                                                <td class="text-center" v-else>{{c.slashAmount}}</td>
                                                                <td class="text-center"><a >{{c.usageLimit}}</a></td>
                                                                <td class="text-center">
                                                                    <a href="#" title="Delete" class="gray-s" @click.prevent="removeCoupon(c._id, c.title)"><i class="uil uil-trash-alt"></i></a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>	
                                </div>
                                <div class="step-footer step-tab-pager" style="opacity: 0">
                                    <button v-if="!success" class="btn btn-default steps_btn" @click="scroll()">Submit for Review</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dfooter />
    </div>
    <!-- Body End -->
</div>   
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Errors from '@/components/Errors.vue'
import Success from '@/components/Success.vue'
import Loading from '@/components/Loading.vue'
import Dheader from '@/components/dashboard/Header.vue'
import Dnav2 from '@/components/dashboard/Nav2.vue'
import Dfooter from '@/components/dashboard/Footer.vue'
import CourseSections from '@/components/dashboard/CourseSections.vue'
export default {
	name: 'Dhome',
	data(){
		return{
			courseId: '',
			userId: '',
			isAuthor: '',
            courseDetails: '',
            name: '',
            errors: [],
			description: '', 
			externalLink: '',
            couponcode: '',
            coupon: '',
            allCoupons: []
		}
	},
	computed:{
		...mapGetters(['isInstructor']),
		...mapGetters(['frontEnd'])
	},
    components: {
        Dheader,
        Dnav2,
		Dfooter,
		Errors,
		Success,
		Loading,
		CourseSections,
	},
	methods: {
        ...mapActions(['view_CourseForEdit','getCoupon','addCourseToCoupon','removeCourseToCoupon','getCourseCoupons']),
        fetchCouponDetails(){
            this.Loading = true
            this.getCoupon(this.couponcode)
            .then(res => {
                this.Loading = false
                this.coupon = res.data
                for (let i = 0; i < this.coupon.courses.length; i++) {
                    const element = this.coupon.courses[i];
                    if (element == this.courseId) {
                        this.errors.push("coupon code already exist for this course")
                    }
                }
            })
            .catch(err => {
                console.log(err)
                this.coupon = null
            })
        },
        removeCoupon(couponcode, title){
            if (confirm("are you sure you want to remove "+title)) {

                this.Loading = true
                const object = {
                    courseId: this.courseId, 
                    couponId: couponcode
                }
                this.removeCourseToCoupon(object)
                .then(res => {
                    this.Loading = false
                    this.coupon = ""
                    this.couponcode = ""
                    this.allCoupons = this.allCoupons.filter(function (c)
                            {
                                return c._id != couponcode
                            }
                        )
                    console.log(res)
                })
                .catch(err => {
                    console.log(err)
                    this.coupon = null
                })
            }
        },
        addCoupon(){
            this.Loading = true
            const object = {
                courseId: this.courseId, 
                couponId: this.couponcode
            }
            this.addCourseToCoupon(object)
            .then(res => {
                this.Loading = false
                this.couponcode = ""
                this.allCoupons.push(this.coupon)
                this.coupon = ""
                console.log(res)
            })
            .catch(err => {
                console.log(err)
                this.coupon = null
            })
        }
	},  
    mounted(){
        this.courseId = this.$route.params.id
		this.userId = localStorage.getItem('fmruser')
		this.view_CourseForEdit(this.courseId)
		.then(res => {
			this.courseDetails = res.data 
			this.isApproved = res.data.isApproved 
			this.adminSeen = res.data.adminSeen 
			if (this.courseDetails.user==this.userId) {
				this.isAuthor = true
			}
        })
    
        this.getCourseCoupons({id: this.courseId, skip: 0})
        .then(res => {
            this.allCoupons = res.data
        })
    }

    //http://localhost:8080/Dnewcoursecontent/606dbb80b1fc8e099c5ff8fc
}
</script>
<style scoped>
    @import url("../assets/css/style.css");
    @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500");
    @import url("../assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/vertical-responsive-menu.min.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/instructor-responsive.css");
    @import url("../assets/css/responsive.css");
    @import url("../assets/css/night-mode.css");
    @import url("../assets/vendor/fontawesome-free/css/all.min.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("../assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("../assets/vendor/semantic/semantic.min.css");

	.pageInActive{
        padding: 5px;
        /* width: 30%; */
    }

    .pageActive{
        padding: 5px;
        color: white;
        /* width: 33%; */
        border-radius: 10px;
    }

	.pageInActive a{
		text-decoration: none;
		color: white;
		/* height: 100%; */
		padding: 5px;
		border-radius: 20px;
		/* width: 100%; */
		background-color: grey;
	}

	.pageActive a{
		text-decoration: none;
		color: white;
		padding: 10px; 
		border-radius: 35px;
		background-color: red;
	}

	.lectureType{
		text-decoration: none;
		color: white;
		margin-left: 10px;
		padding: 5px; 
		padding-left: 10px; 
		padding-right: 10px; 
		border-radius: 25px;
		border: none;
		background-color: grey;
	}

	.partss{
		margin: 10px;
	}
	.full{
		width: 100%;
		display: block;
	}
	.closed{
		display: none;
	}
	.open{
		display: block;
	}

	.statusTag {
        padding: 5px;
        border-radius: 5px;
        color: white;
    } 
    .orange {
        background-color: orange;
    }
    .green{
        background-color: green;
    }
	.red{
		background-color: red;
	}
</style>
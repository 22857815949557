<template>
    <div>
        <Dheader />

        <Dnav2 />
        
        <div class="wrapper">
            <div class="sa4d25">
                <div class="container-fluid">	
                    <div class="row" v-if="outlet">
                        <div class="col-md-12" v-if="isInstructor||isAdmin">
                            <div class="card_dash1">
                                <div class="card_dash_left1">
                                    <i class="uil uil-shop"></i>
                                    <h1>My Outlet</h1> <br>
                                </div>
                                <div class="card_dash_right1 margin">

                                    <div class="card_dash_right1 margin">
                                        <button class="create_btn_dash" @click.prevent="renewNow()" type="button">Renew Outlet ₦{{outlet.plan.limit * outlet.plan.rent_price}}</button>
                                    </div>
                                    <div class="card_dash_right1 margin">
                                        <router-link :to="outlet.link">
                                            <button class="create_btn_dash" type="button">View Outlet</button>
                                        </router-link>
                                    </div>
                                    <div class="card_dash_right1 margin">
                                        <button @click="generateOutletLink()" v-if="!outletSuccess&&!outletLoading" class="create_btn_dash" type="button">Regenerate Link</button>
                                        <button v-if="outletSuccess" class="create_btn_dash" type="button">Successful</button>
                                        <button v-if="outletLoading" class="create_btn_dash" type="button">Please wait...</button>
                                    </div>
                                    <div class="card_dash_right1 margin">
                                        <button @click="copyURL('https://faslearn.com/'+ outlet.link)" class="create_btn_dash" type="button">Copy Link</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Outlet Plan</h5>
                                    <span class="crdbg_1">{{outlet.plan.title}} <small>{{outlet.material_count}} Materials out of {{outlet.plan.limit}}</small> </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6" v-if="outlet">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Expiry Date</h5>
                                    <span class="crdbg_2">{{outlet.expire.split('T')[0]}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Address</h5>
                                    <center><span class="crdbg_3">{{user_id}}</span></center>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Key</h5>
                                    <center><span class="crdbg_4"><small>{{outlet.key}}</small></span></center>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br><br>
                    <div class="row" v-if="myInstructorStatus!='null'">
                        <div class="col-lg-12">	
                            <h2 class="st_title">
                                <i class="uil uil-apps"></i> Instructor Dashboard <br>
                                <br>
                                <small>
                                    <span class="statusTag trans" v-if="myInstructorStatus=='approved' && !loading && influencer!='pending' && influencer!='approved' && influencer!='denied'" @click="applyToInfluencer()">Apply for influencer</span>
                                    <span class="statusTag trans" v-if="myInstructorStatus=='approved' && loading && influencer!='pending' && influencer!='approved' && influencer!='denied'">Please wait ...</span>
                                    <span class="statusTag orange" v-if="myInstructorStatus=='approved'&& influencer=='pending' ">Influencer request pending</span>
                                    <span class="statusTag red" v-if="myInstructorStatus=='approved'&& influencer=='denied' ">Influencer request denied</span>
                                    <span class="statusTag green" v-if="myInstructorStatus=='approved'&& influencer=='approved' ">Influencer</span>
                                </small>
                            </h2>
                            <br>
                            <strong><span class="statusTag orange" v-if="myInstructorStatus=='pending'">Status: {{myInstructorStatus}}</span></strong>
                            <strong><span class="statusTag green" v-if="myInstructorStatus=='approved'">Status: {{myInstructorStatus}}</span></strong>
                            <strong><span class="statusTag red" v-if="myInstructorStatus=='denied'">Status: {{myInstructorStatus}}</span></strong>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Total Subscribers</h5>
                                    <h2>{{instructorDashboard.subscribes}}</h2>
                                    <!-- <span class="crdbg_1">New ₦50</span> -->
                                </div>
                                <div class="card_dash_right">
                                    <img src="../assets/images/dashboard/achievement.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Institutions Enroll</h5>
                                    <h2>{{instructorDashboard.institutions}}</h2>
                                    <!-- <span class="crdbg_2">New 125</span> -->
                                </div>
                                <div class="card_dash_right">
                                    <img src="../assets/images/dashboard/graduation-cap.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Total Courses</h5>
                                    <h2>{{instructorDashboard.courses}}</h2>
                                    <!-- <span class="crdbg_3">New 5</span> -->
                                </div>
                                <div class="card_dash_right">
                                    <img src="../assets/images/dashboard/online-course.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Total Purchased</h5>
                                    <h2>{{instructorDashboard.saleN}}</h2>
                                    <!-- <span class="crdbg_4">New 245</span> -->
                                </div>
                                <div class="card_dash_right">
                                    <img src="../assets/images/dashboard/knowledge.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" v-if="isInstructor||isAdmin">
                            <div class="card_dash1">
                                <div class="card_dash_left1">
                                    <i class="uil uil-book-alt"></i>
                                    <h1>Jump Into Course Creation</h1>
                                </div>
                                <router-link to="/Dnewcourse">
                                <div class="card_dash_right1">
                                    <button class="create_btn_dash" type="button">Create Your Course</button>
                                </div>
                                </router-link>
                            </div>
                        </div>					
                    </div>
                    
                    <div class="row" v-if="myAdminStatus!='null'" style="margin-top: 75px">
                        <div class="col-lg-12">	
                            <h2 class="st_title"><i class="uil uil-apps"></i> Institution Admin Dashboard</h2>
                            <strong><span class="statusTag orange" v-if="myAdminStatus=='pending'">Status: {{myAdminStatus}}</span></strong>
                            <strong><span class="statusTag green" v-if="myAdminStatus=='approved'">Status: {{myAdminStatus}}</span></strong>
                            <strong><span class="statusTag red" v-if="myAdminStatus=='denied'">Status: {{myAdminStatus}}</span></strong>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Total Sales</h5>
                                    <h2>{{adminDashboard.saleN}}</h2>
                                    <!-- <span class="crdbg_1">New ₦50</span> -->
                                </div>
                                <div class="card_dash_right">
                                    <img src="../assets/images/dashboard/achievement.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Total lecturers</h5>
                                    <h2>{{adminDashboard.instructors}}</h2>
                                    <!-- <span class="crdbg_2">New 125</span> -->
                                </div>
                                <div class="card_dash_right">
                                    <img src="../assets/images/dashboard/graduation-cap.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Total Courses</h5>
                                    <h2>{{adminDashboard.courses}}</h2>
                                    <!-- <span class="crdbg_3">New 5</span> -->
                                </div>
                                <div class="card_dash_right">
                                    <img src="../assets/images/dashboard/online-course.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Total Students</h5>
                                    <h2>{{adminDashboard.students}}</h2>
                                    <!-- <span class="crdbg_4">New 245</span> -->
                                </div>
                                <div class="card_dash_right">
                                    <img src="../assets/images/dashboard/knowledge.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" v-if="isInstructor||isAdmin">
                            <div class="card_dash1">
                                <div class="card_dash_left1">
                                    <i class="uil uil-book-alt"></i>
                                    <h1>Jump Into Course Creation</h1>
                                </div>
                                <router-link to="/Dnewcourse">
                                <div class="card_dash_right1">
                                    <button class="create_btn_dash" type="button">Create Your Course</button>
                                </div>
                                </router-link>
                            </div>
                        </div>					
                    </div>
                    <div class="row" style="margin-top: 75px">
                        <div class="col-lg-12">	
                            <h2 class="st_title"><i class="uil uil-apps"></i> Student Dashboard</h2>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Total Certificates</h5>
                                    <h2>{{studentDashboard.certificates}}</h2>
                                    <!-- <span class="crdbg_1">New $50</span> -->
                                </div>
                                <div class="card_dash_right">
                                    <img src="../assets/images/dashboard/achievement.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Admissions</h5>
                                    <h2>{{studentDashboard.institutions}}</h2>
                                    <!-- <span class="crdbg_2">New 125</span> -->
                                </div>
                                <div class="card_dash_right">
                                    <img src="../assets/images/dashboard/graduation-cap.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Purchases</h5>
                                    <h2>{{studentDashboard.purchased}}</h2>
                                    <!-- <span class="crdbg_3">New 5</span> -->
                                </div>
                                <div class="card_dash_right">
                                    <img src="../assets/images/dashboard/online-course.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6">
                            <div class="card_dash">
                                <div class="card_dash_left">
                                    <h5>Subscribtions</h5>
                                    <h2>{{studentDashboard.instructors}}</h2>
                                    <!-- <span class="crdbg_4">New 245</span> -->
                                </div>
                                <div class="card_dash_right">
                                    <img src="../assets/images/dashboard/knowledge.svg" alt="">
                                </div>
                            </div>
                        </div>				
                    </div>
                    <br><br><br><br>
                    <!-- <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-6">
                            <div class="section3125 mt-50">
                                <h4 class="item_title">Latest Courses performance</h4>
                                <div class="la5lo1">
                                    <div class="owl-carousel courses_performance owl-theme">
                                        <div class="item">
                                            <div class="fcrse_1">
                                                <a href="#" class="fcrse_img">
                                                    <img src="../assets/images/courses/img-1.jpg" alt="">
                                                    <div class="course-overlay"></div>
                                                </a>
                                                <div class="fcrse_content">
                                                    <div class="vdtodt">
                                                        <span class="vdt14">First 2 days 22 hours</span>
                                                    </div>
                                                    <a href="#" class="crsedt145">Complete Python Bootcamp: Go from zero to hero in Python 3</a>
                                                    <div class="allvperf">
                                                        <div class="crse-perf-left">View</div>
                                                        <div class="crse-perf-right">1.5k</div>
                                                    </div>
                                                    <div class="allvperf">
                                                        <div class="crse-perf-left">Purchased</div>
                                                        <div class="crse-perf-right">150</div>
                                                    </div>
                                                    <div class="allvperf">
                                                        <div class="crse-perf-left">Total Like</div>
                                                        <div class="crse-perf-right">1k</div>
                                                    </div>
                                                    <div class="auth1lnkprce">
                                                        <a href="#" class="cr1fot50">Go to course analytics</a>
                                                        <a href="#" class="cr1fot50">See comments (875)</a>
                                                        <a href="#" class="cr1fot50">See Reviews (105)</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="item">
                                            <div class="fcrse_1">
                                                <a href="#" class="fcrse_img">
                                                    <img src="../assets/images/courses/img-2.jpg" alt="">
                                                    <div class="course-overlay"></div>
                                                </a>
                                                <div class="fcrse_content">
                                                    <div class="vdtodt">
                                                        <span class="vdt14">Second 4 days 9 hours</span>
                                                    </div>
                                                    <a href="#" class="crse14s">The Complete JavaScript Course 2020: Build Real Projects!</a>
                                                    <div class="allvperf">
                                                        <div class="crse-perf-left">View</div>
                                                        <div class="crse-perf-right">175k</div>
                                                    </div>
                                                    <div class="allvperf">
                                                        <div class="crse-perf-left">Purchased</div>
                                                        <div class="crse-perf-right">1k</div>
                                                    </div>
                                                    <div class="allvperf">
                                                        <div class="crse-perf-left">Total Like</div>
                                                        <div class="crse-perf-right">85k</div>
                                                    </div>
                                                    <div class="auth1lnkprce">
                                                        <a href="#" class="cr1fot50">Go to course analytics</a>
                                                        <a href="#" class="cr1fot50">See comments (915)</a>
                                                        <a href="#" class="cr1fot50">See Reviews (255)</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="item">
                                            <div class="fcrse_1">
                                                <a href="#" class="fcrse_img">
                                                    <img src="../assets/images/courses/img-3.jpg" alt="">
                                                    <div class="course-overlay"></div>
                                                </a>
                                                <div class="fcrse_content">
                                                    <div class="vdtodt">
                                                        <span class="vdt14">Third 6 days 11 hours:</span>
                                                    </div>
                                                    <a href="#" class="crse14s">Beginning C++ Programming - From Beginner to Beyond</a>
                                                    <div class="allvperf">
                                                        <div class="crse-perf-left">View</div>
                                                        <div class="crse-perf-right">17k</div>
                                                    </div>
                                                    <div class="allvperf">
                                                        <div class="crse-perf-left">Purchased</div>
                                                        <div class="crse-perf-right">25</div>
                                                    </div>
                                                    <div class="allvperf">
                                                        <div class="crse-perf-left">Total Like</div>
                                                        <div class="crse-perf-right">15k</div>
                                                    </div>
                                                    <div class="auth1lnkprce">
                                                        <a href="#" class="cr1fot50">Go to course analytics</a>
                                                        <a href="#" class="cr1fot50">See comments (155)</a>
                                                        <a href="#" class="cr1fot50">See Reviews (15)</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>									
                                    </div>
                                </div>
                            </div>
                        </div>					
                        <div class="col-xl-4 col-lg-6 col-md-6">
                            <div class="section3125 mt-50">
                                <h4 class="item_title">News</h4>
                                <div class="la5lo1">
                                    <div class="owl-carousel edututs_news owl-theme">
                                        <div class="item">
                                            <div class="fcrse_1">
                                                <a href="#" class="fcrse_img">
                                                    <img src="../assets/images/courses/news-1.jpg" alt="">												
                                                </a>
                                                <div class="fcrse_content">
                                                    <a href="#" class="crsedt145 mt-15">COVID-19 Updates & Resources</a>
                                                    <p class="news_des45">See the latest updates to coronavirus-related content, including changes to monetization, and access new Creator support resources</p>
                                                    <div class="auth1lnkprce">
                                                        <a href="#" class="cr1fot50">Learn More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="item">
                                            <div class="fcrse_1">
                                                <a href="#" class="fcrse_img">
                                                    <img src="../assets/images/courses/news-2.jpg" alt="">												
                                                </a>
                                                <div class="fcrse_content">
                                                    <a href="#" class="crsedt145 mt-15">Watch: Edututs+ interview Mr. Joginder</a>
                                                    <p class="news_des45">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ac eleifend ante. Duis ac pulvinar felis. Sed a nibh ligula. Mauris eget tortor id mauris tristique accumsan.</p>
                                                    <div class="auth1lnkprce">
                                                        <a href="#" class="cr1fot50">Watch Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="item">
                                            <div class="fcrse_1">
                                                <a href="#" class="fcrse_img">
                                                    <img src="../assets/images/courses/news-1.jpg" alt="">												
                                                </a>
                                                <div class="fcrse_content">
                                                    <a href="#" class="crsedt145 mt-15">COVID-19 Updates - April 7</a>
                                                    <p class="news_des45">Ut porttitor mi vel orci cursus, nec elementum neque malesuada. Phasellus imperdiet quam gravida pharetra aliquet. Integer vel ligula eget nisl dignissim hendrerit.</p>
                                                    <div class="auth1lnkprce">
                                                        <a href="#" class="cr1fot50">Learn More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>						
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6">
                            <div class="section3125 mt-50">
                                <h4 class="item_title">Profile Analytics</h4>
                                <div class="la5lo1">	
                                    <div class="fcrse_1">
                                        <div class="fcrse_content">
                                            <h6 class="crsedt8145">Current subscribers</h6>
                                            <h3 class="subcribe_title">856</h3>
                                            <div class="allvperf">
                                                <div class="crse-perf-left">View</div>
                                                <div class="crse-perf-right">17k<span class="analyics_pr"><i class="uil uil-arrow-to-bottom"></i>75%</span></div>
                                            </div>
                                            <div class="allvperf">
                                                <div class="crse-perf-left">Purchased<span class="per_text">(per hour)</span></div>
                                                <div class="crse-perf-right">1<span class="analyics_pr"><i class="uil uil-top-arrow-from-top"></i>100%</span></div>
                                            </div>
                                            <div class="allvperf">
                                                <div class="crse-perf-left">Enroll<span class="per_text">(per hour)</span></div>
                                                <div class="crse-perf-right">50<span class="analyics_pr"><i class="uil uil-top-arrow-from-top"></i>70%</span></div>
                                            </div> 
                                            <div class="auth1lnkprce">
                                                <a href="#" class="cr1fot50">Go to profile analytics</a>
                                            </div>
                                        </div>
                                    </div>	
                                </div>
                            </div>
                            <div class="section3125 mt-50">
                                <h4 class="item_title">Submit Courses</h4>
                                <div class="la5lo1">	
                                    <div class="fcrse_1">
                                        <div class="fcrse_content">
                                            <div class="upcming_card">
                                                <a href="#" class="crsedt145">The Complete JavaScript Course 2020: Build Real Projects!<span class="pndng_145">Pending</span></a>
                                                <p class="submit-course">Submitted<span>1 days ago</span></p>
                                                <a href="#" class="delete_link10">Delete</a>
                                            </div>
                                        </div>
                                    </div>	
                                </div>
                            </div>
                            <div class="section3125 mt-50">
                                <h4 class="item_title">What's new in Edututs+</h4>
                                <div class="la5lo1">	
                                    <div class="fcrse_1">
                                        <div class="fcrse_content">
                                            <a href="#" class="new_links10">Improved performance on Studio Dashboard</a>
                                            <a href="#" class="new_links10">See more Dashboard updates</a>
                                            <a href="#" class="new_links10">See issues-at-glance for Live</a>
                                        </div>
                                    </div>	
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <Dfooter />
        </div>
    </div>

</template>
<script>
import Dheader from '@/components/dashboard/Header.vue'
import Dnav2 from '@/components/dashboard/Nav2.vue'
import Dfooter from '@/components/dashboard/Footer.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Dhome',
    data(){
        return{
            studentDashboard: '',
            instructorDashboard: '',
            adminDashboard: '',
            user_id: '',
            user: {},
            subscribed_months: 1,
            myInfluencer: null,
            outlet: null,
            outletSuccess: false,
            outletLoading: false,
            loading : false
        }
    },
    components: {
        Dheader,
        Dnav2,
        Dfooter
    },
    computed: {
        ...mapGetters(['isInstructor']),
        ...mapGetters(['isAdmin']),
        ...mapGetters(['myInstructorStatus']),
        ...mapGetters(['myAdminStatus','influencer']),
    },   
    methods: {
        ...mapActions(['fetch_Student_Dashboard']),
        ...mapActions(['fetch_Instructor_Dashboard']),
        ...mapActions(['fetch_Admin_Dashboard', 'apply_Influencer']),
        ...mapActions(['maintainOutlet', 'fetch_user', 'fetchOutletCourses','getOutlet','fetchOutletAllBooks']),
        applyToInfluencer(){
            this.loading = true
            this.apply_Influencer({userId: this.user_id})
            .then(res => {
                this.loading = false
                if(res.data.success){
                    this.myInfluencer = "pending"
                }
            })
        },
        generateOutletLink(){
            this.outletLoading = true
            this.maintainOutlet({userId: this.user_id})
            .then(res => {
                this.outletLoading = false
                this.outlet.key = res.data.key
                this.outlet.link = "/outlet/"+this.user_id+"?key="+this.outlet.key
                this.outletSuccess = true
            })
        },
        async copyURL(mytext) {
            try {
            await navigator.clipboard.writeText(mytext);
            alert('Copied');
            } catch($e) {
            alert('Cannot copy');
            }
        },
        renewNow() {
            if (this.paymentDetails == "") {
                alert("please wait for minuite and try again");
                return;
            }
            let object = {
                price: this.outlet.plan.limit * this.outlet.plan.rent_price,
                userId: this.user_id,
                id: this.outlet._id,
                email: this.user.email,
                subscribed_months: this.subscribed_months

                // title: this.course.title, reference, userId, price
                // membershipId: null,
                // couponId: null,
                // userId: this.userId
            };
            localStorage.setItem("fmroutletcart", JSON.stringify(object));
            let str = window.location.href + "";
            const myArr = str.split("/Dhom");
            window.location.href = myArr[0] + "/Doutlet/buy.html";
        }
    },
    mounted(){
        this.myInfluencer = this.influencer
		this.user_id = localStorage.getItem('fmruser');
        if (this.isInstructor || this.isAdmin) {
            this.getOutlet(this.user_id)
            .then(res => {
                console.log("res.data")
                this.outlet = res.data
                this.outlet.link = "/outlet/"+this.user_id+"?key="+this.outlet.key
            })
        }

        this.fetch_user(this.user_id)
        .then((res) => {
            this.user = res.data.user;
        })
        .catch((err) => {
            console.log(err);
        });

        this.fetch_Student_Dashboard(this.user_id)
        .then(res => {
            this.studentDashboard = res.data
        })
        this.fetch_Student_Dashboard(this.user_id)
        .then(res => {
            this.studentDashboard = res.data
        })
        if (this.isInstructor) {
            this.fetch_Instructor_Dashboard(this.user_id)
            .then(res => {
                this.instructorDashboard = res.data
                this.myInfluencer = this.instructorDashboard.influencer
            })  
        }
        if (this.isAdmin) {
            this.fetch_Admin_Dashboard(this.user_id)
            .then(res => {
                this.adminDashboard = res.data
            })
        }

    }
}
</script>
<style scoped>
    @import url("../assets/css/style.css");
    /* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
    @import url("../assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/vertical-responsive-menu.min.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/instructor-responsive.css");
    @import url("../assets/css/responsive.css");
    @import url("../assets/css/night-mode.css");
    @import url("../assets/vendor/fontawesome-free/css/all.min.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("../assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("../assets/vendor/semantic/semantic.min.css");

    .wrapper{
        padding-bottom: 50px;
    }

    .statusTag {
        padding: 5px;
        border-radius: 5px;
        color: white;
    } 
    .orange {
        background-color: orange;
    }
    .green{
        background-color: green;
    }
    .red{
        background-color: red;
    }
    .trans{
        background-color: none;
        border: 2px solid grey;
        color: grey;
        cursor: pointer;
    }

    .margin{
        margin-right: 5px;
    }
</style>
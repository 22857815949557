<template>
    <div class="student_reviews">
        <div class="row">
            <div class="col-lg-5">
                <div class="reviews_left">
                    <h3>Student Feedback</h3>
                    <div class="total_rating">
                        <div class="_rate001">{{avrating}}</div>														
                        <div class="rating-box">
                            <span class="rating-star" :class="avrating>0 ? 'full-star' : 'empty-star'"></span>
                            <span class="rating-star" :class="avrating>1&&avrating<2 ? 'half-star' : 'empty-star'" v-if="avrating>1&&avrating<2"></span>
                            <span class="rating-star" :class="avrating>1&&avrating>=2 ? 'full-star' : 'empty-star'" v-if="avrating>1&&avrating>2"></span>

                            <span class="rating-star" :class="avrating>2&&avrating<3 ? 'half-star' : 'empty-star'" v-if="avrating>1&&avrating<3"></span>
                            <span class="rating-star" :class="avrating>2&&avrating>=3 ? 'full-star' : 'empty-star'" v-if="avrating>1&&avrating>=3"></span>

                            <span class="rating-star" :class="avrating>3&&avrating<4 ? 'half-star' : 'empty-star'" v-if="avrating>1&&avrating<4"></span>
                            <span class="rating-star" :class="avrating>3&&avrating>=4 ? 'full-star' : 'empty-star'" v-if="avrating>1&&avrating>=4"></span>

                            <span class="rating-star" :class="avrating>4&&avrating<5 ? 'half-star' : 'empty-star'" v-if="avrating>1&&avrating<5"></span>
                            <span class="rating-star" :class="avrating>4&&avrating==5 ? 'full-star' : 'empty-star'" v-if="avrating>1&&avrating==5"></span>
                        


                            
                        </div>
                        <div class="_rate002">Rating</div>	
                    </div>
                    <div class="_rate003">
                        <div class="_rate004">
                            <div class="progress progress1">
                                <div class="progress-bar " :style="'width: '+five+'%; background-color: red'"></div>
                            </div>
                            <div class="rating-box">
                                <span class="rating-star full-star"></span>
                                <span class="rating-star full-star"></span>
                                <span class="rating-star full-star"></span>
                                <span class="rating-star full-star"></span>
                                <span class="rating-star full-star"></span>
                            </div>
                            <div class="_rate002">{{five}}%</div>
                        </div>
                        <div class="_rate004">
                            <div class="progress progress1">
                                <div class="progress-bar " :style="'width: '+four+'%; background-color: red'"></div>
                            </div>
                            <div class="rating-box">
                                <span class="rating-star full-star"></span>
                                <span class="rating-star full-star"></span>
                                <span class="rating-star full-star"></span>
                                <span class="rating-star full-star"></span>
                                <span class="rating-star empty-star"></span>
                            </div>
                            <div class="_rate002">{{four}}%</div>
                        </div>
                        <div class="_rate004">
                            <div class="progress progress1">
                                <div class="progress-bar " :style="'width: '+three+'%; background-color: red'"></div>
                            </div>
                            <div class="rating-box">
                                <span class="rating-star full-star"></span>
                                <span class="rating-star full-star"></span>
                                <span class="rating-star full-star"></span>
                                <span class="rating-star empty-star"></span>
                                <span class="rating-star empty-star"></span>
                            </div>
                            <div class="_rate002">{{three}}%</div>
                        </div>
                        <div class="_rate004">
                            <div class="progress progress1">
                                <div class="progress-bar " :style="'width: '+two+'%; background-color: red'"></div>
                            </div>
                            <div class="rating-box">
                                <span class="rating-star full-star"></span>
                                <span class="rating-star full-star"></span>
                                <span class="rating-star empty-star"></span>
                                <span class="rating-star empty-star"></span>
                                <span class="rating-star empty-star"></span>
                            </div>
                            <div class="_rate002">{{two}}%</div>
                        </div>
                        <div class="_rate004">
                            <div class="progress progress1">
                                <div class="progress-bar " :style="'width: '+one+'%; background-color: red'"></div>
                            </div>
                            <div class="rating-box">
                                <span class="rating-star full-star"></span>
                                <span class="rating-star empty-star"></span>
                                <span class="rating-star empty-star"></span>
                                <span class="rating-star empty-star"></span>
                                <span class="rating-star empty-star"></span>
                            </div>
                            <div class="_rate002">{{one}}%</div>
                        </div>
                    </div>
                    <div class="_rate003">
                        <ul>
                            <li>
                                <a class="lkcm152"><i class="uil uil-thumbs-up"></i><span>{{allLikes.like}}</span></a>
                            </li>
                        </ul>	
                    </div>
                </div>	
                									
            </div>
            <div class="col-lg-7" style="background-color: transparent">
                <div class="review_right">
                    <div class="review_right_heading">
                        <h3>Reviews</h3>
                        <!-- <div class="review_search">
                            <input class="rv_srch" type="text" placeholder="Search reviews...">
                            <button class="rvsrch_btn"><i class='uil uil-search'></i></button>
                        </div> -->
                    </div>
                </div>
                <div class="tab-pane fade show active" style="background-color: transparent" id="instructor-signup-tab" role="tabpanel" aria-labelledby="instructor-tab" v-if="allowContribution">
                    <p>What do you think?</p>
                    <form>
                        <div class="ui search focus mt-15">
                            <div class="help-block">How many stars does this institution deserve</div>																
                            <div class="ui form swdh30">
                                <div class="field">
                                    <span class="rating-star" :class="star>0 ? 'full-star' : 'empty-star'" @click="setStar(1)"></span>
                                    <span class="rating-star" :class="star>1 ? 'full-star' : 'empty-star'" @click="setStar(2)"></span>
                                    <span class="rating-star" :class="star>2 ? 'full-star' : 'empty-star'" @click="setStar(3)"></span>
                                    <span class="rating-star" :class="star>3 ? 'full-star' : 'empty-star'" @click="setStar(4)"></span>
                                    <span class="rating-star" :class="star>4 ? 'full-star' : 'empty-star'" @click="setStar(5)"></span>
                                </div>
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="ui search focus mt-15">
                            <div class="help-block">Do you like this institution</div>																
                            <div class="ui form swdh30">
                                <div class="field">
                                    <ul>
                                        <li class="liking" @click.prevent="setLike(1)">
                                            <button class="likeButton" :class=" like=='1' ? 'liked' : 'b'"><i class="uil uil-thumbs-up"></i></button>
                                        </li>
                                        <li class="liking" @click.prevent="setLike(2)">
                                            <button class="likeButton" :class=" like=='2' ? 'liked' : 'b'"><i class="uil uil-thumbs-down"></i></button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <br>
                        <div class="ui search focus mt-15">																
                            <div class="ui form swdh30">
                                <div class="field">
                                    <textarea rows="3" name="description" id="id_about" placeholder="Write a little description of your experience in this institution" v-model="reviewMessage"></textarea>
                                </div>
                            </div>
                            <div class="help-block">Your review should have at least 100 characters.</div>
                        </div>
                        <!-- <input type="file" v-model="cv" /> -->
                        <br>
                        <br>
                        <Errors v-if="!error">
                            <ul>
                                <li v-for="error in error" :key="error">{{error}}</li>	
                            </ul>			
                        </Errors>
                        <br>
                        <Success v-if="success">
                            <ul>
                                <li>Action Successful</li>
                                <li>Thanks for your Review, we will try our best to make new institution better</li>	
                            </ul>
                            <p></p>
                        </Success>
                        <Loading v-if="Loading" />
                        <!-- <div class="mt-3">Selected file: {{ cv ? cv.name : '' }}</div>							 -->
                        <button class="login-btn" type="submit" @click.prevent="submitReview()" v-if="!Loading" >Submit Review</button>
                        <br><br>
                    </form>
                </div>
                <div class="review_all120">
                    <div class="review_item" v-for="v in reviews" :key="v">
                        <div class="review_usr_dt">
                            <!-- <img src="./assets/images/left-imgs/img-2.jpg" alt=""> -->
                            <!-- <ProfileImage :id="v.user[0]._id" /> -->
                            <div style="width: 50px; height: 50px; border-radius: 50%">
                               <ProfileImage :id="v.user[0]._id" :name="v.user[0].imageFile" /> 
                            </div>
                            <div class="rv1458">
                                <h4 class="tutor_name1">{{v.user[0].firstName}} {{v.user[0].lastName}}</h4>
                                <span class="time_145">{{v.createdAt.split('T')[0]}}</span>
                            </div>
                        </div>
                        <div class="rating-box mt-20">
                            <span class="rating-star" :class="v.star>0 ? 'full-star' : 'empty-star'"></span>
                            <span class="rating-star" :class="v.star>1 ? 'full-star' : 'empty-star'"></span>
                            <span class="rating-star" :class="v.star>2 ? 'full-star' : 'empty-star'"></span>
                            <span class="rating-star" :class="v.star>3 ? 'full-star' : 'empty-star'"></span>
                            <span class="rating-star" :class="v.star>4 ? 'full-star' : 'empty-star'"></span>
                        </div>
                        <p class="rvds10">{{v.reviewMessage}}</p>
                        <!-- <div class="rpt100">
                            <span>Was this review helpful?</span>
                            <div class="radio--group-inline-container">
                                <div class="radio-item">
                                    <input id="radio-3" name="radio1" type="radio">
                                    <label for="radio-3" class="radio-label">Yes</label>
                                </div>
                                <div class="radio-item">
                                    <input id="radio-4" name="radio1" type="radio">
                                    <label  for="radio-4" class="radio-label">No</label>
                                </div>
                            </div>
                            <a href="#" class="report145">Report</a>
                        </div> -->
                    </div>
                </div>
                <br><br><br>
                <center>
                    <button class="live_link" type="button" v-if="!Loading" @click="fetchMore('reviews')">Load More</button>	
                </center>
                <br><br><br>	
            </div>
        </div>
    </div>										
</template>
<script>
import ProfileImage from "@/components/ProfileImage";
import Loading from '@/components/Loading.vue'
import Success from '@/components/Success.vue'
import Errors from '@/components/Errors.vue'
import { mapActions } from "vuex";
export default {
    name: 'Rating',
	props: {
        id: String,
        type: String,
        canContribute: Boolean,
    },
    components: {
        ProfileImage,
        Loading,
        Errors,
        Success,
    },
    data(){
		return{
            alreadyReviewed: true,
            reviews: '',
            stars: '',
            starsNumber: '',
            avrating: '',
            allLikes: '',
            one: '',
            two: '',
            three: '',
            four: '',
            five: '',
            allLikesNumber: '',
            star: '',
            like: '',
            reviewMessage: '',
            error: '',
            userId: localStorage.getItem('fmruser'),
            success: false,
            Loading: false,
            allowContribution: false,
            skip: 0
        }
    },
    methods: {
        ...mapActions(['checkIf_UserAlreadyReviewedAndFetchAll','submit_Review']),
        checkStar(star){
            let total = 0;
            let answer = 0;
            if (this.stars.length == 0) {
                answer = 0;
            }else{
                for (let i = 0; i < this.stars.length; i++) {
                    const element = this.stars[i];
                    if (element.star == star) {
                        total++;
                    }
                }
                
                answer = (total/this.stars.length)*100;
            }
            return answer.toFixed(1);
        },
        checkAveageStar(){
            let total = 0;
            let answer = 0;
            if (this.stars.length == 0) {
                answer = 0;
            }else{
                for (let i = 0; i < this.stars.length; i++) {
                    const element = this.stars[i];
                    total = element.star + total
                }
                answer = total/this.stars.length;
            }
            return answer.toFixed(1);
        },
        countlikes(){
            let total = { like : 0, dislike : 0};
            for (let i = 0; i < this.stars.length; i++) {
                const element = this.stars[i];
                if (element.like == 1) {
                    total.like++
                }else{
                    total.dislike++
                }
            }
            return total;
        },
        fetchingReviews(type){
            let reviewObject= { 
				user : this.userId, 
                id : this.id,
                type : type,
                skip: this.skip
            }
			this.checkIf_UserAlreadyReviewedAndFetchAll(reviewObject)
			.then(res => {
				this.alreadyReviewed = res.data.alreadyReviewed
				this.reviews = res.data.Reviews
                this.stars = res.data.stars
                this.starsNumber = this.stars.length
                this.one = this.checkStar(1)
				this.two = this.checkStar(2)
				this.three = this.checkStar(3)
				this.four = this.checkStar(4)
				this.five = this.checkStar(5)
                this.avrating = this.checkAveageStar()
                this.allLikes = this.countlikes()
                this.allLikesNumber = this.allLikes.like
                if (this.avrating==0) {
                    this.useDummyRatings()
                }
                if (this.canContribute&&!this.alreadyReviewed) { 
                    this.allowContribution = true
                }
			})
        },
        useDummyRatings(){
            var splitted = this.id.split("");
            var totalRatings = 1
            var totalLikes = 1
            for (let i = 0; i < splitted.length; i++) {
                const element = splitted[i];
                if (this.checkCharacter(element)) {
                    totalLikes++
                    if (i<=9) {
                        totalRatings++
                    }
                }
                
            }
            this.avrating = ((60 + (totalRatings*3))/20).toFixed(1)
            this.starsNumber = totalLikes*6
            this.allLikesNumber = ((this.starsNumber/2)+(this.starsNumber/4)+(this.starsNumber/8)).toFixed(0)
            if (this.avrating>=4.0) {
              this.allLikesNumber = ((this.starsNumber/2)+(this.starsNumber/4)+(this.starsNumber/8)+(this.starsNumber/16)).toFixed(0)  
            }
            this.allLikes.like = this.allLikesNumber
            this.setStarLevels(this.avrating)
        },
        checkCharacter(character){
            var stringflag = false
            var value = /^\d+$/.test(character)
            if (!value){
                stringflag = true
            }
            if (stringflag) {
                return false
            }else{
                return true
            }
        },
        setStarLevels(avr){
            var one,two,three,four,five
            one = 2
            two = 8
            if (avr>=4) {
                three = 20
                four = 40
                five = 30
            }else{
                three = 25
                four = 45
                five = 20
            }
            this.one = ((one/100) * this.allLikesNumber).toFixed(2)
			this.two = ((two/100) * this.allLikesNumber).toFixed(2)
			this.three = ((three/100) * this.allLikesNumber).toFixed(2)
			this.four = ((four/100) * this.allLikesNumber).toFixed(2)
			this.five = ((five/100) * this.allLikesNumber).toFixed(2)
        },
        submitReview(){
			if (this.validate()) {
                this.Loading = true
				let object ={
					user : this.userId, 
					id : this.id,
					star: this.star,
					like: this.like,
					reviewMessage: this.reviewMessage,
					type: this.type
                }
				this.submit_Review(object)
				.then(res => {
					this.reviews = res.data.Reviews
					this.stars = res.data.stars
					this.alreadyReviewed = res.data.alreadyReviewed
					this.one = this.checkStar(1)
					this.two = this.checkStar(2)
					this.three = this.checkStar(3)
					this.four = this.checkStar(4)
					this.five = this.checkStar(5)
					this.avrating = this.checkAveageStar()
					this.allLikes = this.countlikes()
                    //this.success = true
                    this.Loading = false
                    this.allowContribution = false
                })
			}
        },
        validate(){
            let error = [];
            let message = '';
			let flag = true;
            if (this.star == '') {
                message = 'Please tell us the star level of this institution';
                error.push(message)
                flag = false
            }
            if (this.like == '') {
                message = 'Please tell us if you like this institution';
                error.push(message)
                flag = false
            }
            if (this.reviewMessage == '') {
                message = 'Please insert your review message';
                error.push(message)
                flag = false
            }
            if (flag == true) {
                return true
            }else{
                this.error = error
                return false
            }
		},
        setStar(level){
			this.star = level
		},
		setLike(value){
			this.like = value
        },
        fetchMore(){
            this.Loading = true
			this.skip = this.skip + 10
			let reviewObject= { 
				user : this.userId, 
                id : this.id,
                type : this.type,
                skip: this.skip
            }
			this.checkIf_UserAlreadyReviewedAndFetchAll(reviewObject)
            .then(res => {
                for (let i = 0; i < res.data.Reviews.length; i++) {
                    const element = res.data.Reviews[i];
                    this.reviews.push(element)
                }
                this.Loading = false
            })
		},
    },
    mounted(){
        this.fetchingReviews(this.type)
    }
}
</script>
<style >
 .rating{
     background: rgba(243, 240, 237, 0.4);
     padding: 10px;
     border-radius: 3px;
 }
 .liked{
		background-color: yellowgreen;
		border: 2px solid yellowgreen;
	}

	.likeButton{
		width: 50px;
		height: 50px;
		border-radius: 10px;
		padding: 10px;
	}

	.liking{
		width: 60px;
		height: 60px;
		display: inline-block;
		margin: 5px;
		padding-top: 10px;
	}
	
</style>
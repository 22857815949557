<template>
  <div>
    <Header />

    <div class="content-wrapper">
      <div class="container-fluid">
        <!-- Breadcrumbs-->
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Dashboard</a>
          </li>
          <li class="breadcrumb-item active">Your Post</li>
        </ol>
            <div class="box_general" v-if="post!=null">
                <div class="header_box version_2" v-if="course!=''">
                    <h2><i class="fa fa-bookmark"></i>{{post.title}} </h2>
                    <p class="approve" @click.prevent="publisher(true)" v-if="!Loading&&!post.published">Publish</p>
                    <p class="delete" @click.prevent="publisher(false)" v-if="!Loading&&post.published">Un-publish</p>
                    <p class="delete" @click.prevent="deletePost()" v-if="!Loading">Delete</p>
                    <a :href="'/blogpost/'+post._id" class="preview"  v-if="!Loading">Preview</a>
                </div>
                <div class="header_box">
                    <h2 class="d-inline-block">Your Posts </h2>
                </div>
                <div class="bigspinner" v-if="Loading">
                    <div class="smallspiner"></div>
                </div>
                <div class="box_general">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                            <!-- <form > -->
                            <div style="margin-bottom: 40px">
                                <div class="form-group">
                                <label for="">Title</label>
                                <input
                                    v-model="post.title"
                                    type="text"
                                    class="form-control"
                                    name="title"
                                    placeholder="Title"
                                />
                                </div>
                                <div class="form-group">
                                <label> Title Image </label>
                                <div class="input-group">
                                    <span class="input-group-btn">
                                    <span class="btn btn-file">
                                        <span
                                        class="btn text-light"
                                        v-if="titleImage == ''"
                                        style="background: #ef6421"
                                        >
                                        Select
                                        </span>

                                        <input type="file" ref="thumbnail" @change="handleFileUpload" />
                                        <br />
                                        <figure class="titleImage">
                                            <img class="titleImage" v-if="post.imageFile" v-bind:src="publicFiles+post.imageFile" id="thumbnailPreview" alt="">
                                            <img class="titleImage" v-else v-bind:src="'https://image.pngaaa.com/768/791768-middle.png'" id="thumbnailPreview" alt="">
                                        </figure>
                                    </span>
                                    </span>
                                </div>
                                </div>
                                <div class="form-group">

                                <Errors v-if="errors.length>0">
                                    <ul>
                                    <li v-for="error in errors" :key="error">{{error}}</li>	
                                    </ul>
                                </Errors>
                                <br>
                                <Success v-if="Success">
                                <ul>
                                    <li>Action Successful</li>
                                </ul>
                                <p></p>
                                </Success>
                                <button
                                    class="btn my-4"
                                    style="color: #fff; background:#ef6421"
                                    @click="updateBlogPost"
                                    v-if="!Loading&&!Success"
                                >
                                    Save title changes
                                </button>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div> 
                </div>

                <div class="box_general">
                    <div class="list_general" v-for="c in post.contents" :key="c">
                        <ul >
                            <li>
                                <img v-if="c.file" style="width: 80%;" v-bind:src="publicFiles+c.file" alt="">
                                <!-- <p style="margin-top: 10px" >{{c.text}}</p> -->
                                <p style="margin-top: 10px" v-html="formatText(c.text)"></p>
                                <ul class="buttons">
                                <li @click.prevent="deleteContent(c._id)" v-if="!Loading"><a href="#" class="btn_1 red delete"><i class="fa fa-fw fa-times-circle-o"></i> Delete </a></li>
                                </ul>
                            </li>
                            
                        </ul>
                    </div>
                </div>

                <div class="box_general">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                            <!-- <form > -->
                                <div style="margin-bottom: 40px" v-if="post.contents.length<11">
                                    
                                    <div class="form-group">
                                        <label for="">Block Content</label>
                                        <textarea
                                            v-model="text"
                                            type="text"
                                            class="form-control"
                                            name="title"
                                            placeholder="block content"
                                        ></textarea>
                                    </div>
                                    <div class="form-group">
                                        <button
                                            class="btn my-4"
                                            style="color: #fff; background:#ef6421"
                                            @click="addBlockImage = true"
                                            v-if="!addBlockImage"
                                        >
                                            <i class="fa fa-picture-o" aria-hidden="true"></i>
                                            + Add image to this block
                                        </button>
                                        <div class="input-group" v-if="addBlockImage">
                                            <span class="input-group-btn">
                                                <span class="btn btn-file">
                                                    <span
                                                    class="btn text-light"
                                                    v-if="titleImage == ''"
                                                    style="background: #ef6421"
                                                    >
                                                        Select
                                                    </span>
                                                    <input type="file" ref="thumbnailContent" @change="handleFileContentUpload" />
                                                    <br />
                                                    <figure class="titleImage">
                                                        <img class="titleImage" v-bind:src="'https://image.pngaaa.com/768/791768-middle.png'" id="thumbnailContentPreview" alt="">
                                                    </figure>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <Errors v-if="errorsContent.length>0">
                                            <ul>
                                            <li v-for="error in errors" :key="error">{{error}}</li>	
                                            </ul>
                                        </Errors>
                                        <br>
                                        <Success v-if="Success">
                                            <ul>
                                                <li>Action Successful</li>
                                                <li>Go ahead and add post contents</li>	
                                                <button  class="btn my-4" type="button" style="background-color: #006400" >Click here to add post contents</button>
                                            </ul>
                                        </Success>
                                        <button
                                            class="btn my-4"
                                            style="color: #fff; background:#ef6421"
                                            @click="createBlogPostContent"
                                            v-if="!LoadingContent"
                                        >
                                            Save Post Block
                                        </button>
                                        <button
                                            class="btn my-4"
                                            style="color: #fff; background: grey"
                                            v-if="LoadingContent"
                                        >
                                            please wait ...
                                        </button>
                                    </div>
                                </div>
                                <div style="margin-bottom: 40px" v-else>
                                    <div class="form-group">
                                        <Errors>
                                            <ul>
                                                <li>A blog post can not have more than 10 blocks</li>	
                                            </ul>
                                        </Errors>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>

            <!-- <component :is="currentTab"></component> -->
            
            <!--  -->
            </div>
            <div class="box_general" v-else>
                <Errors>
                    <ul>
                        <li>This Post doesn't exist or it has been deleted</li>	
                    </ul>
                </Errors>
            </div>
        </div>
      <!-- /container-fluid-->
    </div>
    <!-- /.container-wrapper-->
    <Footer />
    <!-- Scroll to Top Button-->
    <a class="scroll-to-top rounded" href="#page-top">
      <i class="fa fa-angle-up"></i>
    </a>
    <!-- Logout Modal-->
    <Modal />
  </div>
</template>
<script>
import Errors from '@/components/Errors.vue'
import Success from '@/components/Success.vue'
import Header from "@/components/superAdmin/Header.vue";
import Modal from "@/components/superAdmin/Modal.vue";
import Footer from "@/components/superAdmin/Footer.vue";
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
            postId: "",
            post: {contents: []},
            skip: 0,
            title: "",
            text: "",
            type: "unverified",
            Loading: false,
            Success: false,
            LoadingContent: false,
            SuccessContent: false,
            isFilePresent: false,
            currentTab: "AddBlogPost",
            thumbnail: '',
            thumbnailContent: '',
            addBlockImage: false,
            errors: [],
            errorsContent: [],
        };
    },
    components: {
        Header,
        Modal,
        Errors,
        Success,
        Footer,
    },
    computed: {
        ...mapGetters(['publicFiles'])
    },
    methods: {
        ...mapActions(['editBlogPost__Admin','deleteBlogPost__Admin','fetchBlogPost__Admin','publishBlogPost__Admin']),
        ...mapActions(['createBlogPostContent__Admin','editBlogPostContent__Admin','deleteBlogPostContent__Admin']),
        publisher(status){
            this.Loading = true
            let publishObject = {
                status: status,
                blogPostId: this.postId
            }
            let word = "publish"
            if (!status) {
                word = "un-publish"
            }
            if (confirm("Are you sure you want to "+word+" this post")) {
               this.publishBlogPost__Admin(publishObject)
                .then(res => {
                    if (res.data.success){
                        this.post.published = status
                        this.Loading = false
                    }
                }) 
            }
            
        },
        deletePost(){
            this.Loading = true
            if (confirm("Are you sure you want to delete this post, it can not be recovered after delete")) {
               this.deleteBlogPost__Admin(this.postId)
                .then(res => {
                    if (res.data.success){
                        this.post = null
                        this.Loading = false
                    }
                }) 
            }
        },
        deleteContent(content_id){
            this.Loading = true
            let publishObject = {
                id: this.postId,
                content_id: content_id
            }
            if (confirm("Are you sure you want to delete this block")) {
               this.deleteBlogPostContent__Admin(publishObject)
                .then(res => {
                    if (res.data.success){
                        this.post = res.data.blogPost
                        this.Loading = false
                    }
                }) 
            }
            
        },
        createBlogPostContent(){
            this.SuccessContent = false
            this.LoadingContent = true
            this.errors = []
            let formData = new FormData(); 
            formData.append('blogPostId', this.postId);
            formData.append('text', this.text);
            formData.append('file', this.thumbnailContent);
            formData.append('isFilePresent', this.isFilePresentContent);
            this.createBlogPostContent__Admin(formData)
                .then(res => {
                    this.LoadingContent = false
                    console.log(res.data.blogPost)
                    this.post = res.data.blogPost
                    this.SuccessContent = true
                    this.text = ""
                    this.thumbnailContent = ""
                    this.isFilePresentContent = false;
                    this.addBlockImage = false
                })
                .catch(err => {
                    this.errorsContent.push("Something went wrong")
                    console.log(err)
                })
        },
        updateBlogPost(){
            this.Success = false
            this.Loading = true
            this.errors = []
            let formData = new FormData(); 
            formData.append('adminId', localStorage.getItem('fmrsuperadmin'));
            formData.append('blogPostId', this.postId);
            formData.append('title', this.post.title);
            formData.append('file', this.thumbnail);
            formData.append('isFilePresent', this.isFilePresent);
            this.editBlogPost__Admin(formData)
                .then(res => {
                    this.Loading = false
                    this.post = res.data.blogPost
                    this.Success = true
                    this.isFilePresent = false
                })
                .catch(err => {
                    this.errors.push("Something went wrong")
                    console.log(err)
                })
        },
        handleFileContentUpload(e){
            if (this.validateFile(e.target.files[0])) {
                this.thumbnailContent = e.target.files[0];
                this.isFilePresentContent = true
                const file = e.target.files[0];
                const previewImage = document.getElementById('thumbnailContentPreview');
                if (file) {
                    const reader = new FileReader();
                    reader.addEventListener("load", function () {
                        previewImage.setAttribute('src', this.result);
                    });
                    reader.readAsDataURL(file);
                }
            }
        },
        handleFileUpload(e){
            if (this.validateFileContent(e.target.files[0])) {
                this.thumbnail = e.target.files[0];
                this.isFilePresent = true
                const file = e.target.files[0];
                const previewImage = document.getElementById('thumbnailPreview');
                if (file) {
                    const reader = new FileReader();
                    reader.addEventListener("load", function () {
                        previewImage.setAttribute('src', this.result);
                    });
                    reader.readAsDataURL(file);
                }
                //document.getElementById('thumbnaildisplay').innerHTML = this.thumbnail
            }
        },
        validateFile(file){
            let flag = true;
            let message = '';
            let error =[];
            const allowedExtensions =  ['jpg','png','jpeg','JPG','PNG','JPEG'];
            if (!allowedExtensions.includes(file.name.split(".").pop())) {
                message = 'Please insert a valid image file';
                error.push(message)
                flag = false
            }
            if (file.size > 1000000) {
                message = 'Please insert smaller size image less than 1mb and not '+file.size;
                error.push(message)
                flag = false
            }
            if (flag == true) {
                console.log('yes')
                this.errors = []
                return true
            }else{
                console.log('no')
                this.errors = error
                return false
            }
        },
        validateFileContent(file){
            let flag = true;
            let message = '';
            let error =[];
            const allowedExtensions =  ['jpg','png','jpeg','JPG','PNG','JPEG'];
            if (!allowedExtensions.includes(file.name.split(".").pop())) {
                message = 'Please insert a valid image file';
                error.push(message)
                flag = false
            }
            if (file.size > 1000000) {
                message = 'Please insert smaller size image less than 1mb and not '+file.size;
                error.push(message)
                flag = false
            }
            if (flag == true) {
                console.log('yes')
                this.errors = []
                return true
            }else{
                console.log('no')
                this.errors = error
                return false
            }
        },
        handleFile(e){
            this.file = e.target.files[0]
            this.isFilePresent = true
        },
        formatText(text) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(text, 'text/html');
            return doc.body.innerHTML;
        },
    },
    mounted() {
        this.postId = this.$route.params.id
        this.fetchBlogPost__Admin(this.postId)
        .then(res => {
            this.post = res.data.blogPost
            console.log(this.post)
        })
    },
};
</script>
<style scoped>
@import url("./assets/css/admin.css");
@import url("./assets/css/custom.css");
@import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
@import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
@import url("./assets/vendor/bootstrap/css/bootstrap.min.css");

.tabButton {
  color: white;
  background-color: grey;
  border-radius: 10px;
  padding: 5px;
  display: inline;
  margin-left: 10px;
  cursor: pointer;
}

.approve{
    color: white;
    background-color: green;
    border-radius: 15px;
    padding: 10px;
    display: inline;
    margin-left: 10px;
    cursor: pointer;
}

.delete{
    color: white;
    background-color: red;
    border-radius: 15px;
    padding: 10px;
    display: inline;
    margin-left: 10px;
    cursor: pointer;
}

.preview{
    color: white;
    background-color: grey;
    border-radius: 15px;
    padding: 10px;
    display: inline;
    margin-left: 10px;
    cursor: pointer;
}

.active {
  background-color: red;
}

.titleImage{
  width: 100%;
  height: 200px;
}

.bigspinner {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border-top: 5px solid grey;
  display: inline-block;
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.saymessage {
  display: inline-block;
  margin-left: 10px;
}
.smallspiner {
  height: 30px;
  width: 30px;
  margin: 5px;
  border-radius: 50%;
  border-top: 5px solid grey;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

input[readonly] {
  background-color: white !important;
  cursor: text !important;
}

/* this is only due to codepen display don't use this outside of codepen */
.container {
  padding-top: 20px;
}
</style>
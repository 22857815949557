<template>
    <div>
        <Header />

        <div class="content-wrapper">
            <div class="container-fluid">
                <!-- Breadcrumbs-->
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                    <a href="#">Dashboard</a>
                    </li>
                    <li class="breadcrumb-item active">Tables</li>
                </ol>
                    <!-- Example DataTables Card-->
                <div class="card mb-3">
                    <div class="card-header">
                    <i class="fa fa-table"></i> Data Table Example
                    </div>
                    <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                            <th>Donor</th>
                            <th>Total Students</th>
                            <th>Amount</th>
                            <th>Reference</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Controls</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                            <th>Donor</th>
                            <th>Total Students</th>
                            <th>Amount</th>
                            <th>Reference</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Controls</th>
                            </tr>
                        </tfoot>
                        <tbody v-if="donations" >
                            <tr v-for="d in donations" :key="d">
                                <td>{{d.user[0].firstName}} {{d.user[0].lastName}}</td>
                                <td>{{d.totalStudents}}</td>
                                <td>{{d.price.$numberDecimal}}</td>
                                <td>{{d.reference}}</td>
                                <td>{{d.user[0].email}}</td>
                                <td>{{d.user[0].phone}}</td>
                                <td>
                                    <router-link :to="'/SAdonation/'+d._id">
                                    View
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                    <!-- <div class="card-footer small text-muted">Updated yesterday at 11:59 PM</div> -->
                </div>
                <!-- /tables-->
                <p><a href="#0" class="btn_1 medium" @click.prevent="fetchMore()" v-if="!Loading">Fetch More donations</a></p>
                <p><a href="#0" class="btn_1 medium" v-if="Loading">Please Wait ...</a></p>
            </div>
            <!-- /container-fluid-->
        </div>
        <!-- /.container-wrapper-->
        <Footer />
        <!-- Scroll to Top Button-->
        <a class="scroll-to-top rounded" href="#page-top">
        <i class="fa fa-angle-up"></i>
        </a>
        <!-- Logout Modal-->
        <Modal />
        
    </div>
</template>
<script>
import Header from '@/components/superAdmin/Header.vue'
import Modal from '@/components/superAdmin/Modal.vue'
import Footer from '@/components/superAdmin/Footer.vue'
import { mapActions } from "vuex";
export default {
    data(){
        return{
            skip: 0,
            donations: '',
            Loading: false
        }
    },
    components: {
        Header,
        Modal,
        Footer
    },
    methods: {
        ...mapActions(['fetchDonations__Admin']),
        fetchMore(){
            this.skip = this.skip + 10
            this.Loading = true
            this.fetchDonations__Admin(this.skip)
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    this.donations.push(element)
                }
                this.Loading = false
            })
        }
    },
    mounted(){
        this.fetchDonations__Admin(this.skip)
        .then(res => {
            this.donations = res.data
        })
    }
}
</script>
<style scoped>
	@import url("./assets/css/admin.css");
    @import url("./assets/css/custom.css");
    @import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
    @import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    .verifiedButton{
        color: white;
        background-color: grey;
        border-radius: 10px;
        padding: 5px;
        display: inline;
        margin-left: 10px;
        cursor: pointer;
    }

    .active{
        background-color: red;
    }
</style>
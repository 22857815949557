<template>
    <div class="sign_in_up_bg">
		<div class="container">
			<div class="row justify-content-lg-center justify-content-md-center">
				<div class="col-lg-12">
					<div class="main_logo25" id="logo">
						<a href="/"><img src="../../assets/faslearnBlack.png" height="70" width="250" alt=""></a>
						<a href="/"><img class="logo-inverse" src="../../assets/faslearnWhite.png" height="70" width="250"  alt=""></a>
					</div>
				</div>
			
				<div class="col-lg-6 col-md-8"> 
					<div class="sign_form">
                        <h3 v-if="credentialsCheck">Checking your credentials...</h3> 
						<div class="tab-content" id="myTabContent" v-if="!credentialsCheck">
							<div style="background-color: transparent" class="tab-pane fade show active" id="instructor-signup-tab" role="tabpanel" aria-labelledby="instructor-tab" v-if="canProceed">
								<h3 v-if="canProceed">Become an Instructor!</h3>
                                <p v-else>Sorry but you do not have all we require to be an Instructor</p>
								<form v-if="canProceed">
                                    <div class="ui search focus">
                                        <div class="help-block"> Areas you are good at, (differentiate with a comma "  ,  "  )</div>
                                        <div class="ui left icon input swdh11 swdh19">
                                            <input class="prompt srch_explore" type="text" v-model="expertise" name="expertise" maxlength="64" placeholder="Expertise">															
                                        </div>
                                    </div>
                                    <br>
                                    <div class="ui search focus">
                                        <div class="help-block">Since when have you been on your field professionally</div>
                                        <div class="ui left icon input swdh11 swdh19">
                                            <input class="prompt srch_explore" type="date" v-model="experience" name="experience" maxlength="64" placeholder=" Years of Experience">															
                                        </div>
                                    </div>
                                    <br>
                                    <div class="help-block">What Category do you fall under</div>
									<select class="ui hj145 dropdown cntry152 prompt srch_explore" v-model="category" @change="setSubCat" required>
										<option value="">Select Category</option>
										<option :value="c._id" v-for="c in categories" :key="c">{{c.name}}</option>
									</select>
                                    <div class="help-block" v-if="subCategories!=''">What Subcategory do you fall under</div>
									<select class="ui hj145 dropdown cntry152 prompt srch_explore" v-if="subCategories!=''" v-model="subCategory" required>
										<option value="">Select Category</option>
										<option :value="c._id" v-for="c in subCategories" :key="c">{{c.name}}</option>
									</select>

                                    <center><hr style="width: 80%"></center>
                                    <h4>Bank details</h4>
                                    <div class="help-block">Bank Name</div>
									<select class="ui hj145 dropdown cntry152 prompt srch_explore" v-model="bankCode" @change="setBankName" required>
										<option value="">Select Bank</option>
										<option :value="p.code" v-for="p in payStackBanks" :key="p">{{p.name}}</option>
									</select>
                                    <div class="ui left icon input swdh11 swdh19" style="display: none">
                                        <input class="prompt srch_explore" type="text" v-model="bankName" name="bankName"  >															
                                    </div>
                                    <br>
                                    <div class="ui search focus">
                                        <div class="help-block">Account Number</div>
                                        <div class="ui left icon input swdh11 swdh19">
                                            <input class="prompt srch_explore" @change="validateAccountNumber()" v-model="bankAccountNumber" name="bankAccountNumber" maxlength="64" placeholder="Enter your Account Number">															
                                        </div>
                                    </div>
                                    <br>
                                    <div class="ui search focus">
                                        <div class="help-block">Account Name</div>
                                        <div class="ui left icon input swdh11 swdh19">
                                            <input class="prompt srch_explore" type="text" v-model="bankBusinessName" name="bankBusinessName" maxlength="64" placeholder="Enter your Account Name">															
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <Errors v-if="errors">
                                        <ul>
                                            <li v-for="error in errors" :key="error">{{error}}</li>	
                                        </ul>
                                    </Errors>
                                    <br>
                                    <Success v-if="success">
                                        <ul>
                                            <li>Action Successful</li>
                                            <li>An admin in Faslearn will look into your credentials and approve if it meets our standards</li>
                                        </ul>
                                        <a href="/Dhome">
                                            <button class="login-btn" type="button" style="background-color: darkgreen">Go to instructor Workspace {{isInstructorApproved}}</button>
                                        </a>
                                    </Success>
                                    <Loading v-if="loading" />
									<button class="login-btn" type="submit" @click.prevent="registerteacher()" v-if="!isInstructor&&!loading&&!isLocalInstructor&&myInstructorStatus=='null'">Submit Instructor Details Now</button>
                                    <button class="login-btn" type="button" v-if="(isInstructor)&&!loading">You are already an Instructor</button>
                                    <button class="login-btn" type="button" v-if="(myInstructorStatus=='pending'||isLocalInstructor)&&!loading">You have Applied to be an instructor Already</button>
                                
                                </form>
                                <Errors v-if="!canProceed">
                                    <p style="margin: 0px">The credentials you need for this are</p>
                                    <ul><li v-for="e in errors" :key="e">>>{{e}}</li></ul>
                                    <p style="margin: 0px">Click on back to profile, then edit profile, then upload the credentials needed</p>
                                </Errors>
                                <router-link :to="'/Dprofile/'+userId">
                                <button class="login-btn" type="button" v-if="!canProceed">Back to profile</button>
                                </router-link>
							</div>
                            <div class="tab-pane fade show active" id="instructor-signup-tab" role="tabpanel" aria-labelledby="instructor-tab" v-else>
                                <div class="student_reviews" style="background-color: transparent" v-if="empty!=''">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h3 style="color: red">Notice</h3>
                                            <p>To become an instructor, we will need some credentials from you, <br>NB: Institutions will be allowed to see this credentials if you apply to them</p>
                                            <div class="cmmnt_1526" v-if="!empty.image">
                                                <h3>Profile Picture</h3>
                                                <p v-if="!empty.image">Add an image that displays your face properly, you maybe mistaken for a fraud account if you don't have this</p> 
                                                <div class="cmnt_group">
                                                    <div class="review_usr_dt">
                                                        <img id="previewMyImage" src="./assets/images/hd_dp.jpg" alt="">									
                                                    </div>
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="inputGroupFile06" ref="file" v-on:change="imageChanged">
                                                        <label class="custom-file-label" for="inputGroupFile06">{{image.name}}</label>
                                                    </div>
                                                </div>
                                                <center><p style="color: red; display: block">{{error.image}}</p></center>
                                                <button class="cmnt-btn" type="button" v-if="!empty.image&&!loading" :disabled="image==''" @click="changeUserDetails('image')">Add</button>
                                                <button class="cmnt-btn" type="button"  v-if="loading" >Please Wait ...</button>
                                            </div>
                                            <div class="cmmnt_1526" v-if="!empty.bio">
                                                <h3>Bio</h3>
                                                <p v-if="!empty.bio">People will understand and take you more seriouly when you tell them about you</p>
                                                <div class="cmnt_group">
                                                    <textarea class="_cmnt001" placeholder="Tell us all we need to know about you" v-model="bio"></textarea>
                                                </div>
                                                <button class="cmnt-btn" type="button" v-if="!empty.bio&&!loading" :disabled="bio==''" @click="changeUserDetails('bio')">Add</button>
                                                <button class="cmnt-btn" type="button"  v-if="loading" >Please Wait ...</button>
                                            </div>
                                            <div class="cmmnt_1526" v-if="!empty.Olevel">
                                                <h3>Olevel Certificate</h3>
                                                <p v-if="!empty.Olevel">Please insert an Ordinary level Certificate (example: WAEC, NECO, GCE)</p>
                                                <div class="input-group">
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="inputGroupFile06" ref="file" v-on:change="handleFileOlevel">
                                                        <label class="custom-file-label" for="inputGroupFile06">{{Olevel.name}}</label>
                                                    </div>
                                                </div>
                                                <center><p style="color: red; display: block">{{error.Olevel}}</p></center>
                                                <button class="cmnt-btn" type="button" v-if="!empty.Olevel&&!loading" :disabled="Olevel==''" @click="changeUserDetails('Olevel')">Add</button>
                                                <button class="cmnt-btn" type="button"  v-if="loading" >Please Wait ...</button>
                                            </div>
                                            <div class="cmmnt_1526" v-if="!empty.cv">
                                                <h3>Cv</h3>
                                                <p v-if="!empty.cv">Your curriculum vitae (CV) will tell us more of your skills, please insert a CV </p>
                                                <div class="input-group">
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="inputGroupFile06" ref="file" v-on:change="handleFilecv">
                                                        <label class="custom-file-label" for="inputGroupFile06">{{cv.name}}</label>
                                                    </div>
                                                </div>
                                                <center><p style="color: red; display: block">{{error.cv}}</p></center>
                                                <button class="cmnt-btn" type="button"  v-if="loading" >Please Wait ...</button>
                                                <button class="cmnt-btn" type="button" v-if="!empty.cv&&!loading" :disabled="cv==''" @click="changeUserDetails('cv')">Add</button>
                                            </div>
                                            <div class="cmmnt_1526" v-if="!empty.signature">
                                                <h3>Signature</h3>
                                                <p v-if="!empty.signature">Your signature validates your partnership with faslearn and will be used for certificates on your courses, sign on a little piece of white paper, make sure the signature covers 90% of this paper, take a picture of it, and upload here</p>
                                                <div class="input-group">
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="inputGroupFile06" ref="file" v-on:change="handleFilesignature">
                                                        <label class="custom-file-label" for="inputGroupFile06">{{signature.name}}</label>
                                                    </div>
                                                </div>
                                                <center><p style="color: red; display: block">{{error.signature}}</p></center>
                                                <button class="cmnt-btn" type="button"  v-if="loading" >Please Wait ...</button>
                                                <button class="cmnt-btn" type="button" v-if="!empty.signature&&!loading" :disabled="signature==''" @click="changeUserDetails('signature')">Add</button>
                                            </div>
                                            <div class="cmmnt_1526" v-if="!empty.Bsc">
                                                <h3>Bachelor degree</h3>
                                                <p v-if="!empty.Bsc">To back up your skills , you need to insert a Bachelor degree certificate</p>
                                                <div class="input-group">
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="inputGroupFile06" ref="file" v-on:change="handleFileBsc">
                                                        <label class="custom-file-label" for="inputGroupFile06">{{Bsc.name}}</label>
                                                    </div>
                                                </div>
                                                <center><p style="color: red; display: block">{{error.Bsc}}</p></center>
                                                <button class="cmnt-btn" type="button"  v-if="loading" >Please Wait ...</button>
                                                <button class="cmnt-btn" type="button" v-if="!empty.Bsc&&!loading" :disabled="Bsc==''" @click="changeUserDetails('Bsc')">Add</button>
                                            </div>
                                            <div class="cmmnt_1526" v-if="!empty.trainingLicence">
                                                <h3>Training Licence</h3>
                                                <p v-if="!empty.trainingLicence">Finally, you need to insert a Training Licence</p>
                                                <div class="input-group">
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="inputGroupFile06" ref="file" v-on:change="handleFiletrainingLicence">
                                                        <label class="custom-file-label" for="inputGroupFile06">{{trainingLicence.name}}</label>
                                                    </div>
                                                </div>
                                                <center><p style="color: red; display: block">{{error.trainingLicence}}</p></center>
                                                <button class="cmnt-btn" type="button"  v-if="loading" >Please Wait ...</button>
                                                <button class="cmnt-btn" type="button" v-if="!empty.trainingLicence&&!loading" :disabled="trainingLicence==''" @click="changeUserDetails('trainingLicence')">Add</button>
                                            </div>
                                            <Loading v-if="Loading" />
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
						<!-- <p class="mb-0 mt-30">Already have an account? <a href="sign_in.html">Log In</a></p> -->
					</div>
					<div class="sign_footer"><img src="../../assets/faslearnBlack.png" height="70" width="250"  alt="">© 2020 <strong>Faslearn</strong>. All Rights Reserved.</div>
				</div>				
			</div>				
		</div>				
	</div>
</template>
<script>
import Errors from '@/components/Errors.vue'
import Loading from '@/components/Loading.vue'
import Success from '@/components/Success.vue'
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'RegisterTeacher',
    data(){
        return{
            categories: '',
            subCategories : '',
            userId : '',
            category: '',
            subCategory: '', 
            instructorType: '',
            expertise: '',
            experience: '',
            bankCode: '',
            bankName: '',
            bankBusinessName: '',
            bankAccountNumber: '',
            errors: '',
            success: false,
            canProceed: false,
            loading: false,
            isLocalInstructor: false,
            empty: '',
            error: {},
            bio: '',
            trainingLicence: '',
            incorporationCertificate: '',
            Olevel: '', 
            cv: '', 
            signature: '',
            image: '', 
            Bsc: '',
            itemModal: '',
            payStackBanks: '',
            credentialsCheck: false
        }
    },
    components: {
        Errors,
        Success,
        Loading
	},
    computed: {
        ...mapGetters(['error']),
        ...mapGetters(['isInstructor']),
        ...mapGetters(['myInstructorStatus']),
        ...mapGetters(['credentials'])
	},
    methods: {
        ...mapActions(['registerInstructor']),
        ...mapActions(['check_user_instructor','fetch_empty_user_detail']),
        ...mapActions(['fetch_categories']),
        ...mapActions(['manageUser_Details']),
        ...mapActions(['fetchPayStackBanks']),
        registerteacher(){
            if (this.validateData()) {
                this.errors= ''
                this.loading = true
                //document.getElementById('myLoader').style.display = 'block'
                let teacher = {
                    user_id : this.userId,
                    category : this.category,
                    subCategory : this.subCategory,
                    experience : this.experience,
                    expertise: this.expertise,
                    bankCode: this.bankCode,
                    bankName: this.bankName,
                    bankAccountNumber: this.bankAccountNumber,
                    bankBusinessName: this.bankBusinessName
                }
                this.registerInstructor(teacher)
                .then(res => {
                    if (res.data.isInstructor) {
                       this.success = true,
                       this.isLocalInstructor = true
                       this.loading = false
                       //document.getElementById('myLoader').style.display = 'none'
                       this.check_user_instructor(this.userId)
                    }
                    
                })
            }
            
        },
        changeUserDetails(type){
            this.loading= true;
            let flag = true;
            let formData = new FormData(); 
            formData.append('userId', this.userId);
            formData.append('type', type);
            switch (type) {
                case 'trainingLicence':
                    formData.append('file', this.trainingLicence);
                    break;
                case 'incorporationCertificate':
                    formData.append('file', this.incorporationCertificate);
                    break;
                case 'Bsc':
                    formData.append('file', this.Bsc);
                    break;
                case 'Olevel':
                    formData.append('file', this.Olevel);
                    break;
                case 'cv':
                    formData.append('file', this.cv);
                    break;
                case 'signature':
                    formData.append('file', this.signature);
                    break;
                case 'bio':
                    formData.append('file', this.bio);
                    break;
                case 'image':
                    formData.append('file', this.image);
                    break;
                default:
                    alert('invalid type');
                    break;
            }
            if (flag) {
                this.manageUser_Details(formData)
                .then(res => {
                    this.empty = res.data.empty;
                    this.loading= false;
                    if (this.checkCredentials()) {
                        this.canProceed = true
                    }
                    this.loading= false;
                })
                .catch(err => {
                    console.log(err)
                })
            }
        },
        setSubCat(){
            this.subCategory = ''
            for (let i = 0; i < this.categories.length; i++) {
                const element = this.categories[i];
                if (element._id == this.category) {
                    this.subCategories = element.subCategory
                }
                
            }
        },
        setBankName(){
            this.bankName = ''
            for (let i = 0; i < this.payStackBanks.length; i++) {
                const element = this.payStackBanks[i];
                if (element.code == this.bankCode) {
                    this.bankName = element.name
                }
                
            }
        },
        checkCredentials(){
			let file = this.empty;
			let flag = true;
            let error = []
			if(!file.Olevel){ 
				flag=false;
				error.push('Olevel certificate')
            }
            if(!file.cv){
				flag=false;
				error.push('CV')
            }
            if(!file.signature){
				flag=false;
				error.push('signature')
            }
            if(!file.Bsc){
				flag=false;
				error.push('Bachelor Degree')
			}
            if(!file.trainingLicence){
				flag=false;
				error.push('trainingLicence certificate')
			}
			if(!file.image){
				flag=false;
				error.push('Profile picture')
			}
			if(!file.bio){
				flag=false;
				error.push('About you(BIO)')
			}
			if (flag == true) {
                this.errors = '';
				return true
			}else{
                this.errors = error;
				return false
			}
        },
        validateData(){
			let flag = true;
			let error = []
			if(this.category == ''){
				flag=false;
				error.push('Please choose your category')
            }
            if(this.subCategory == ''){
				flag=false;
				error.push('Please choose your subCategory')
            }
            if(this.experience == ''){
				flag=false;
				error.push('Please insert your experience')
            }
            if(this.expertise == ''){
				flag=false;
				error.push('Please insert your expertise')
            }
            if(this.bankName == ''){
				flag=false;
				error.push('Please choose your Bank Name')
            }
            if(this.bankCode == ''){
				flag=false;
				error.push('Please choose your Bank Name')
            }
            if(this.bankAccountNumber == ''){
				flag=false;
				error.push('Please insert your Account Number')
            }
            if(this.bankBusinessName == ''){
				flag=false;
				error.push('Please insert your Account Name')
            }
			if (flag == true) {
                this.errors = [];
				return true
			}else{
                this.errors = error;
				return false
			}
        },
        imageChanged(e) {
            if (this.validateFile(e.target.files[0], 'image')) {
                this.image = e.target.files[0]
                const file = this.image;
                const previewImage = document.getElementById('previewMyImage');
                if (file) {
                    const reader = new FileReader();
                    reader.addEventListener("load", function () {
                        previewImage.setAttribute('src', this.result);
                    });
                    reader.readAsDataURL(file);
                } 
            }
        },
        handleFileOlevel(e){
            if (this.validateFile(e.target.files[0], 'Olevel')) {
                this.Olevel = e.target.files[0]
            }
        },
        handleFilecv(e){
            if (this.validateFile(e.target.files[0], 'cv')) {
                this.cv = e.target.files[0]
            }
        },
        handleFilesignature(e){
            if (this.validateFile(e.target.files[0], 'signature')) {
                this.signature = e.target.files[0]
            }
        },
        handleFileBsc(e){
            if (this.validateFile(e.target.files[0], 'Bsc')) {
                this.Bsc = e.target.files[0]
            }
        },
        handleFiletrainingLicence(e){
            if (this.validateFile(e.target.files[0], 'trainingLicence')) {
                this.trainingLicence = e.target.files[0]
            }
        },
        handleFileincorporationCertificate(e){
            if (this.validateFile(e.target.files[0], 'incorporationCertificate')) {
                this.incorporationCertificate = e.target.files[0]
            }
        },
        validateAccountNumber(){
            var splitted = this.bankAccountNumber.split("");
            var stringflag = false
            var value
            for (let i = 0; i < splitted.length; i++) {
                value = /^\d+$/.test(splitted[i])
                if (!value){
                    stringflag = true
                }
            }
            if (stringflag) {
                alert('your account number should be completely numbers')
                this.bankAccountNumber = ''
                return false
            }else{
                return true
            }
        },
        validateFile(file, type){
            let flag = true;
            let message = '';
            const allowedExtensions =  ['jpg','png','jpeg','JPG','PNG','JPEG'];
            const allowedExtensionsL =  ['jpg','png','jpeg','JPG','PNG','JPEG','pdf','PDF']
            switch (type) {
                case 'image':
                    if (!allowedExtensions.includes(file.name.split(".").pop())) {
                        message = 'Please insert a valid image file for Profile Picture';
                        this.error.image = message;
                        flag = false
                    }
                    
                    if (file.size > 1000000) {
                        message = 'Please insert smaller size Profile Picture less than 1mb';
                        this.error.image = message;
                        flag = false
                    }
                    
                    break;
                case 'trainingLicence':
                    if (!allowedExtensionsL.includes(file.name.split(".").pop())) {
                        message = 'Please insert a valid PDF file for trainingLicence';
                        this.error.trainingLicence = message;
                        flag = false
                    }
                    
                    if (file.size > 1000000) {
                        message = 'Please insert smaller size trainingLicence less than 1mb';
                        this.error.trainingLicence = message;
                        flag = false
                    }
                    break;
                case 'incorporationCertificate':
                    if (!allowedExtensionsL.includes(file.name.split(".").pop())) {
                        message = 'Please insert a valid PDF file for incorporationCertificate';
                        this.error.incorporationCertificate = message;
                        flag = false
                    }
                    
                    if (file.size > 1000000) {
                        message = 'Please insert smaller size incorporationCertificate less than 1mb';
                        this.error.incorporationCertificate = message;
                        flag = false
                    }
                    break;
                case 'Olevel':
                    if (!allowedExtensionsL.includes(file.name.split(".").pop())) {
                        message = 'Please insert a valid PDF file for Olevel';
                        this.error.Olevel = message;
                        flag = false
                    }
                    
                    if (file.size > 1000000) {
                        message = 'Please insert smaller size Olevel less than 1mb';
                        this.error.Olevel = message;
                        flag = false
                    }
                    break;
                case 'cv':
                    if (!allowedExtensionsL.includes(file.name.split(".").pop())) {
                        message = 'Please insert a valid PDF file for cv';
                        this.error.cv = message;
                        flag = false
                    }
                    
                    if (file.size > 1000000) {
                        message = 'Please insert smaller size cv less than 1mb';
                        this.error.cv = message;
                        flag = false
                    }
                    break;
                case 'signature':
                    if (!allowedExtensions.includes(file.name.split(".").pop())) {
                        message = 'Please insert a valid image file for signature';
                        this.error.signature = message;
                        flag = false
                    }
                    
                    if (file.size > 1000000) {
                        message = 'Please insert smaller size signature file less than 1mb';
                        this.error.signature = message;
                        flag = false
                    }
                    break;
                case 'Bsc':
                    if (!allowedExtensionsL.includes(file.name.split(".").pop())) {
                        message = 'Please insert a valid PDF file for Bsc';
                        this.error.Bsc = message;
                        flag = false
                    }
                    
                    if (file.size > 1000000) {
                        message = 'Please insert smaller size Bsc less than 1mb';
                        this.error.Bsc = message;
                        flag = false
                    }
                    
                    break;
            
                default:
                    alert('nothing')
                    break;
            }
            if (flag == true) {
                this.error= {}
                return true
            }else{
                return false
            }
        },
    }, 
    mounted(){
        this.empty = this.credentials
        this.userId = localStorage.getItem('fmruser');
        this.fetch_empty_user_detail(this.userId)
        .then(res => {
            this.empty = res.data
            if (this.checkCredentials()) {
                this.canProceed = true
            }
            this.credentialsCheck = false
        })
        
        this.fetch_categories()
        .then(res => {
            this.categories = res.data
        })
        this.fetchPayStackBanks()
        .then(res => {
            this.payStackBanks = res.data.data
        })
        .catch(err => {
            console.log(err)
        })
    }
}
</script>
<style scoped>
    @import url("./assets/css/style.css");
    /* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
    @import url("./assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/vertical-responsive-menu.min.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/instructor-responsive.css");
    @import url("./assets/css/responsive.css");
    @import url("./assets/css/night-mode.css");
    @import url("./assets/vendor/fontawesome-free/css/all.min.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("./assets/vendor/semantic/semantic.min.css");

    select{
        width: 100%;
        border-radius: 10px;
    }

</style>
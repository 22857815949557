<template>
    <div>

        <Dheader />

        <Dnav2 />
        
        <div class="wrapper">
            <div class="sa4d25">
                <div class="container-fluid">			
                    <div class="row">
                        <div class="col-lg-12">	
                            <h2 class="st_title"><i class="uil uil-book-alt"></i>Memberships</h2>
                        </div>			
                        <div class="col-md-12">
                            <div class="card_dash1">
                                <div class="card_dash_left1"> 
                                    <i class="uil uil-book-alt"></i>
                                    <h1>Jump Into Membership Creation</h1>
                                </div>
                                <router-link to="/DregisterMembership">
                                <div class="card_dash_right1">
                                    <button class="create_btn_dash" type="button">Create Your Membership</button>
                                </div>
                                </router-link>
                            </div>
                        </div>					
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="my_courses_tabs">
                                <ul class="nav nav-pills my_crse_nav" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="pills-my-courses-tab" data-toggle="pill" href="#pills-my-courses" role="tab" aria-controls="pills-my-courses" aria-selected="true"><i class="uil uil-users-alt"></i>My Memberships</a>
                                    </li>
                                    <li class="nav-item" >
                                        <a class="nav-link" id="pills-my-purchases-tab" data-toggle="pill" href="#pills-my-purchases" role="tab" aria-controls="pills-my-purchases" aria-selected="false"><i class="uil uil-user-square"></i>Other Memberships</a>
                                    </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" style="background-color: transparent" id="pills-my-courses" role="tabpanel">
                                        <div class="table-responsive mt-30" style="background-color: transparent">
                                            <table class="table ucp-table">
                                                <thead class="thead-s">
                                                    <tr>
                                                        <th class="text-center" scope="col">Item No.</th>
                                                        <th>Title</th>
                                                        <th class="text-center" scope="col">Max</th>
                                                        <th class="text-center" scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="m in myMemberships" :key="m">
                                                        <td class="text-center">{{1+(myMemberships.indexOf(m))}}</td>
                                                        <td>{{m.title}}</td>
                                                        <td class="text-center">{{m.max}}</td>
                                                        <td class="text-center">
                                                            <router-link :to="'/Dmembership/'+m._id">
                                                                <i class="uil uil-eye"></i>
                                                            </router-link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br><br><br>
                                    </div>
                                    <div class="tab-pane fade" style="background-color: transparent" id="pills-my-purchases" role="tabpanel">
                                        <div class="table-responsive mt-30" style="background-color: transparent">
                                            <table class="table ucp-table">
                                                <thead class="thead-s">
                                                    <tr>
                                                        <th class="text-center" scope="col">SN</th>
                                                        <th class="cell-ta" scope="col">Title</th>
                                                        <th class="text-center" scope="col">Max</th>
                                                        <th class="text-center" scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="o in otherMemberships" :key="o">
                                                        <td class="text-center">{{1+(otherMemberships.indexOf(o))}}</td>
                                                        <td class="cell-ta">{{o.membershipId[0].title}}</td>
                                                        <td class="text-center">{{o.membershipId[0].max}}</td>
                                                        <td class="text-center">
                                                            <router-link :to="'/Dmembership/'+o.membershipId[0]._id">
                                                                <i class="uil uil-eye"></i>
                                                            </router-link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br><br><br>							
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dfooter />
        </div>
    </div>

</template>
<script>
import { mapActions } from "vuex";
import Dheader from '@/components/dashboard/Header.vue'
import Dnav2 from '@/components/dashboard/Nav2.vue'
import Dfooter from '@/components/dashboard/Footer.vue'
// import Loading from "@/components/Loading.vue"
// import Success from "@/components/Success.vue"
export default {
    name: 'Dhome',
    data(){
        return{
            userId: '',
            myMemberships: [],
            otherMemberships: []
        }
    },
    components: {
        Dheader,
        Dnav2,
        Dfooter,
        // Success,
        // Loading
    }, 
    computed: {
    }, 
    methods: {
        ...mapActions(['fetch_FounderMembership','fetch_user','fetch_UserMembership'])
    }, 
    mounted(){
        this.userId = localStorage.getItem('fmruser');
    
		this.fetch_UserMembership(this.userId)
        .then(res => {
			this.otherMemberships = res.data.members.membership
		})
		this.fetch_FounderMembership(this.userId)
		.then(res => {
			this.myMemberships = res.data.members
		})
    }
}
</script>
<style>
    @import url("../assets/css/style.css");
    /* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
    @import url("../assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/vertical-responsive-menu.min.css");
    @import url("../assets/css/instructor-dashboard.css");
    @import url("../assets/css/instructor-responsive.css");
    @import url("../assets/css/responsive.css");
    @import url("../assets/css/night-mode.css");
    @import url("../assets/vendor/fontawesome-free/css/all.min.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("../assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("../assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("../assets/vendor/semantic/semantic.min.css");

    .modal-body{
        max-height: 600px;
        overflow-y: scroll;
    }
    
</style>
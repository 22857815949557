<template>
    <div class="vdtodt">
        <!-- <span ><i class="uil uil-eye"></i>{{seen}}<Processing v-if="processing" /> views</span> -->
        <span ><i class="uil uil-calendar-alt"></i>{{created.split('T')[0]}}<Processing v-if="processing" /></span>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import Processing from "@/components/Processing"
//import TimeFormat from "@/components/TimeFormat";
export default {
    data(){
        return{
            created: '',
            seen: '',
            processing: true
        }
    },
    components: {
        Processing
    },
    props: {
        id: String,
        type: String
    },
    methods: {
        ...mapActions(['checkTotalSee'])
    },
    mounted(){
        this.checkTotalSee(this.id)
        .then(res => {
            this.created = res.data.timeCreated
            this.seen = res.data.totalPurchased
            this.processing = false
        })
    }
}
</script>
<style >

</style>
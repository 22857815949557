<template>
    <div>
    <!-- Header Start -->
        <Dheader @getCurrency="changeCurrency" @selectedCurrency="selectedCur"/>
	<!-- Header End -->
		<Dnav1 />
	<!-- Body Start -->
	<div class="wrapper">		
		<div class="_215cd2">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">						
						<div class="course_tabs">
							<nav>
								<div class="nav nav-tabs tab_crse  justify-content-center">
									<a class="nav-item nav-link" v-bind:class="{ active: about }" @click.prevent="switchtap('about')">About</a>
									<!-- <a class="nav-item nav-link" v-bind:class="{ active: blog }" @click.prevent="switchtap('blog')">Blog</a> -->
									<a class="nav-item nav-link" v-bind:class="{ active: general }" @click.prevent="switchtap('general')">Company</a>
									<a class="nav-item nav-link" v-bind:class="{ active: lecturers }" @click.prevent="switchtap('lecturers')">Lecturers</a>
									<a class="nav-item nav-link" v-bind:class="{ active: courses }" @click.prevent="switchtap('courses')">Courses</a>
									<a class="nav-item nav-link" v-bind:class="{ active: review }" @click.prevent="switchtap('review')">Review</a>
									<a class="nav-item nav-link" v-bind:class="{ active: memberships }" @click.prevent="switchtap('memberships')">Memberships</a>
									<a class="nav-item nav-link" v-bind:class="{ active: edit }" @click.prevent="switchtap('edit')" v-if="isFounder">Edit</a>
								</div>
							</nav>						
						</div>
						<div class="title129 mt-35 mb-35">	
							<h2>{{institution.institutionName}}</h2>
							<!-- <p>{{institution}}</p> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="_215zd5" v-if="general" id="General">
			<div class="container">
				<div class="row">
					<div class="col-md-6">
						<div class="title484">
							<h2>Our Bio</h2>
							<img class="line-title" src="./assets/images/line.svg" alt="">
							<p>{{institution.bio}}</p >				
						</div>
					</div>
					<div class="col-md-6">
						<div class="story125" v-if="institution.institutionLogoFile">
							<img :src="publicFiles+institution.institutionLogoFile" style="width: 250px; height: 250px; border-radius: 50%;" alt="">
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12">
						<div class="title589 text-center">
							<h2>Join Us</h2>
							<p>On our Institution, you have access to:</p>
							<img class="line-title" src="./assets/images/line.svg" alt="">
						</div>
					</div>
				</div>
				<br><br>
				<router-link :to="'/Dadmission/'+institutionId">
				<div class="row" style="cursor: pointer" v-if="!alreadyApplied">
					<div class="col-lg-12">
						<div class="title589 text-center">
							<h2><i class="uil uil-award"></i></h2>
							<h4>Become A Student in Our Online Institution</h4>
						</div>
					</div>
				</div>
				<br><br>
				</router-link>
				<router-link :to="'/Demployment/'+institutionId">
				<div class="row" style="cursor: pointer" v-if="isInstructor&&!alreadyAppliedInstructor">
					<div class="col-lg-12">
						<div class="title589 text-center">
							<h2><i class="uil uil-users-alt"></i></h2>
							<h4>Become A Lecturer in Our Online Institution</h4>
						</div>
					</div>
				</div>
				</router-link>
			</div>
		</div>
		<div class="_215zd5" v-if="courses" id="course">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="title589 text-center">
							<h2>Our Courses</h2>
							<img class="line-title" src="./assets/images/line.svg" alt="">
						</div>
						<div class="course_tabs">
							<nav>
								<div class="nav nav-tabs tab_crse  justify-content-center">
									<a class="nav-item nav-link" v-bind:class="{ active: focus =='institution' }" @click.prevent="switchFocus('institution')">Institution Coures</a>
									<a class="nav-item nav-link" v-bind:class="{ active: focus =='affiliated' }" @click.prevent="switchFocus('affiliated')">Affiliated Coures</a>
								</div>
								<br><br>
							</nav>						
						</div>
					</div>
				</div>
				<div class="row" v-if="focus=='institution'">
					<CourseEntityTiny v-for="c in theCourses" :key="c" 
						:id="c._id" 
						:title="c.title"
						:category="c.category[0].name"
						:price="c.price.$numberDecimal"
						:firstName="c.user[0].firstName"
						:lastName="c.user[0].lastName"
						:userId="c.user[0]._id"
						:file="c.thumbnailFile"
						:discount="c.discountPercent"
						:rate = rate
                      :selectedCurrency="selectedCurrency"
					/>	
				 		
									
				</div>
				<div class="row" v-else>
					<CourseEntityTiny v-for="c in AllAffiliatedCourses" :key="c" 
						:id="c._id" 
						:title="c.title"
						:category="c.category[0].name"
						:price="c.price.$numberDecimal"
						:firstName="c.user[0].firstName"
						:lastName="c.user[0].lastName"
						:userId="c.user[0]._id"
						:file="c.thumbnailFile"
						:discount="c.discountPercent"
						:rate = rate
                      :selectedCurrency="selectedCurrency"
					/>	

				</div>	
				<br><br><br>
				<center>
					<button class="live_link" type="button" v-if="!Loading&&focus=='institution'" @click="fetchMore('own')">Load More</button>
					<button class="live_link" type="button" v-if="!Loading&&focus=='affiliated'" @click="fetchMore('affiliate')">Load More</button>	
				</center>
				<br><br><br>
			</div>
		</div>
		<div class="_215zd5" v-if="blog" id="blog">
			<div class="container">
				<div class="row">
					<div class="col-lg-3 col-md-5">
						<div class="fcrse_3 mt-50">
							<ul class="blogleft12">
								<li>
									<div class="explore_search blg152">
										<div class="ui search focus">
											<div class="ui left icon input swdh11 swdh15">
												<input class="prompt srch_explore" type="text" placeholder="Search">
												<i class="uil uil-search-alt icon icon2"></i>
											</div>
										</div>
									</div>
								</li>
								<li>
									<a href="#collapse1" class="category-topics cate-right collapsed" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapse1">Labels</a>
									<div class="collapse" id="collapse1" style="">
										<ul class="category-card">
											<li>
												<a href="#" class="category-item1 active">All</a>																											
												<a href="#" class="category-item1">Students</a>																												
												<a href="#" class="category-item1">Instructors</a>																												
												<a href="#" class="category-item1">Ideas & Opinions</a>																												
												<a href="#" class="category-item1">Edututs+ News</a>																												
												<a href="#" class="category-item1">Social Innovation</a>
											</li>											
										</ul>
									</div>
									<a href="#collapse2" class="category-topics cate-right collapsed" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapse2">Archive</a>
									<div class="collapse" id="collapse2" style="">
										<ul class="category-card">
											<li>
												<a href="#" class="category-item1">January 2020</a>																											
												<a href="#" class="category-item1">February 2020</a>																											
												<a href="#" class="category-item1">March 2020</a>																											
												<a href="#" class="category-item1">April 2020</a>																																																																																											
											</li>																												
										</ul>
									</div>
								</li>
								<li>
									<div class="socl148">
										<button class="twiter158" data-href="#" onclick="sharingPopup(this);" id="twitter-share"><i class="uil uil-twitter ic45"></i>Follow</button>
										<button class="facebook158" data-href="#" onclick="sharingPopup(this);" id="facebook-share"><i class="uil uil-facebook ic45"></i>Follow</button>
									</div>
								</li>
								<li>
									<div class="help_link">
										<p>Learn More</p>
										<a href="#">Cursus Help Center</a>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-9 col-md-7">
						<div class="blogbg_1 mt-50">
							<a href="blog_single_view.html" class="hf_img">
								<img src="assets/images/blog/img-1.jpg" alt="">
								<div class="course-overlay"></div>
							</a>
							<div class="hs_content">
								<div class="vdtodt">
									<span class="vdt14">109k views</span>
									<span class="vdt14">March 10, 2020</span>
								</div>
								<a href="blog_single_view.html" class="crse14s title900">Blog Title Here</a>
								<p class="blog_des">Donec eget arcu vel mauris lacinia vestibulum id eu elit. Nam metus odio, iaculis eu nunc et, interdum mollis arcu. Pellentesque viverra faucibus diam. In sit amet laoreet dolor, vitae fringilla quam interdum mollis arcu.</p>
								<a href="blog_single_view.html" class="view-blog-link">Read More<i class="uil uil-arrow-right"></i></a>
							</div>
						</div>
						<div class="blogbg_1 mt-30">
							<a href="blog_single_view.html" class="hf_img">
								<img src="assets/images/blog/img-2.jpg" alt="">
								<div class="course-overlay"></div>
							</a>
							<div class="hs_content">
								<div class="vdtodt">
									<span class="vdt14">109k views</span>
									<span class="vdt14">March 10, 2020</span>
								</div>
								<a href="blog_single_view.html" class="crse14s title900">Blog Title Here</a>
								<p class="blog_des">Donec eget arcu vel mauris lacinia vestibulum id eu elit. Nam metus odio, iaculis eu nunc et, interdum mollis arcu. Pellentesque viverra faucibus diam. In sit amet laoreet dolor interdum mollis arcu interdum mollis arcu.</p>
								<a href="blog_single_view.html" class="view-blog-link">Read More<i class="uil uil-arrow-right"></i></a>
							</div>
						</div>
						<div class="blogbg_1 mt-30">
							<a href="blog_single_view.html" class="hf_img">
								<img src="assets/images/blog/img-3.jpg" alt="">
								<div class="course-overlay"></div>
							</a>
							<div class="hs_content">
								<div class="vdtodt">
									<span class="vdt14">109k views</span>
									<span class="vdt14">March 10, 2020</span>
								</div>
								<a href="blog_single_view.html" class="crse14s title900">Blog Title Here</a>
								<p class="blog_des">Donec eget arcu vel mauris lacinia vestibulum id eu elit. Nam metus odio, iaculis eu nunc et, interdum mollis arcu. Pellentesque viverra faucibus diam. In sit amet laoreet dolor interdum mollis arcu.</p>
								<a href="blog_single_view.html" class="view-blog-link">Read More<i class="uil uil-arrow-right"></i></a>
							</div>
						</div>
						<div class="blogbg_1 mt-30">
							<a href="blog_single_view.html" class="hf_img">
								<img src="assets/images/blog/img-4.jpg" alt="">
								<div class="course-overlay"></div>
							</a>
							<div class="hs_content">
								<div class="vdtodt">
									<span class="vdt14">109k views</span>
									<span class="vdt14">March 10, 2020</span>
								</div>
								<a href="blog_single_view.html" class="crse14s title900">Blog Title Here</a>
								<p class="blog_des">Donec eget arcu vel mauris lacinia vestibulum id eu elit. Nam metus odio, iaculis eu nunc et, interdum mollis arcu. Pellentesque viverra faucibus diam. In sit amet laoreet dolor interdum mollis arcu.</p>
								<a href="blog_single_view.html" class="view-blog-link">Read More<i class="uil uil-arrow-right"></i></a>
							</div>
						</div>
						<div class="main-p-pagination">
							<nav aria-label="Page navigation example">
								<ul class="pagination">
									<li class="page-item disabled">
										<a class="page-link" href="#" aria-label="Previous">
											PREV
										</a>
									</li>
									<li class="page-item"><a class="page-link active" href="#">1</a></li>
									<li class="page-item"><a class="page-link" href="#">2</a></li>
									<li class="page-item"><a class="page-link" href="#">...</a></li>
									<li class="page-item"><a class="page-link" href="#">24</a></li>
									<li class="page-item">
										<a class="page-link" href="#" aria-label="Next">
											NEXT
										</a>
									</li>
								</ul>
							</nav>
						</div>			
					</div>					
				</div>
			</div>
		</div>
		<div class="_215zd5" v-if="lecturers" id="lecturers">
			<div class="container">
				<div class="row">
					<div class="col-xl-3 col-lg-4 col-md-6" v-for="t in AllLecturers" :key="t">
						<router-link :to="'/Dprofile/'+t.user[0]._id">
						<div class="fcrse_1 mt-30">
							<div class="tutor_img">
								<a ><ProfileImage :id="t.user[0]._id" :name="t.user[0].imageFile" /></a>												
							</div>
							<div class="tutor_content_dt">
								<div class="tutor150">
									<a class="tutor_name">{{t.user[0].firstName}} {{t.user[0].lastName}}</a>
									<div class="mef78" title="Verify">
										<i class="uil uil-check-circle"></i>
									</div>
								</div>
								<div class="tutor_cate">{{t.expertise}}</div>
								<Ratings :id="t.user[0]._id" :type="'instructor'" />
								<!-- <ul class="tutor_social_links">
									<li><a href="#" class="fb"><i class="fab fa-facebook-f"></i></a></li>
									<li><a href="#" class="tw"><i class="fab fa-twitter"></i></a></li>
									<li><a href="#" class="ln"><i class="fab fa-linkedin-in"></i></a></li>
									<li><a href="#" class="yu"><i class="fab fa-youtube"></i></a></li>
								</ul> -->
								<div class="tut1250">
									<span class="vdt15">{{t.category[0].name}}</span>
									<!-- <span class="vdt15">15 Courses</span> -->
								</div>
							</div>
						</div>
						</router-link>										
					</div>					
				</div>
			</div>
		</div>
		<div class="_215zd5" id="about" v-if="about">
			<div class="_215td5">
				<div class="container">
					<div class="_215td5">
						<div class="container">
							<div class="row">
								<div class="col-lg-12">
									<div class="title589 text-center">
										<h2>About Us</h2>
										<img class="line-title" src="./assets/images/line.svg" alt="">
									</div>
								</div>
								<div class="col-lg-3  col-sm-6">
									<div class="feature125">
										<i class="uil uil-mobile-android-alt"></i>
										<h4>Join Faslearn since</h4>
										<p>{{institution.createdAt}}</p>
									</div>
								</div>
								<div class="col-lg-3  col-sm-6">
									<div class="feature125">
										<i class="uil uil-users-alt"></i>
										<h4>Academic & Technical Support</h4>
										<p>{{institution.email}}</p>
									</div>
								</div>
								<div class="col-lg-3  col-sm-6">
									<div class="feature125">
										<i class="uil uil-award"></i>
										<h4>Sharable Certificates</h4>
										<p>{{institution.institutionType}}</p>
									</div>
								</div>
								<div class="col-lg-3  col-sm-6">
									<div class="feature125">
										<i class="uil uil-globe"></i>
										<h4>Our Address</h4>
										<p>{{institution.address}}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-12">
							<div class="title589 text-center">
								<h2>Our Global Reach</h2>
								<img class="line-title" src="./assets/images/line.svg" alt="">
							</div>
						</div>
						<div class="col-lg-2 col-md-4 col-sm-6">
							<div class="p__metric">
								{{InstitutionDashboard.instructors}}
								<span>Instructors</span>
							</div>
						</div>
						<div class="col-lg-2 col-md-4 col-sm-6">
							<div class="p__metric">
								{{InstitutionDashboard.courses}}
								<span>Courses</span>
							</div>
						</div>
						<div class="col-lg-2 col-md-4 col-sm-6">
							<div class="p__metric">
								{{InstitutionDashboard.saleN}}
								<span>Course enrollments</span>
							</div>
						</div>
						<div class="col-lg-2 col-md-4 col-sm-6">
							<div class="p__metric">
								{{InstitutionDashboard.students}}
								<span>Students</span>
							</div>
						</div>
						<div class="col-lg-2 col-md-4 col-sm-6">
							<div class="p__metric">
								{{InstitutionDashboard.AffiliateCourse}}
								<span>Affiliated courses</span>
							</div>
						</div>
						<!-- <div class="col-lg-2 col-md-4 col-sm-6">
							<div class="p__metric">
								{{stars.length}}
								<span>Reviews</span>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<div class="_215zd5" id="about" v-if="review">
			<div style="width: 90%; margin-left: 5%" v-if="institutionId!=''">
				<RatingsDetails :id="institutionId" :type="'institution'" :canContribute="true" v-if="isStudent" />
				<RatingsDetails :id="institutionId" :type="'institution'" :canContribute="false" v-else />
			</div>
		</div>
		<div class="_215zd5" v-if="edit">
			<center>
				<div class="col-lg-6 col-md-8">
					<div class="cmmnt_1526">
						<h3>Institution Logo</h3>
						<div class="cmnt_group">
							<div class="review_usr_dt">
								<img id="previewMyLogo" :src="publicFiles+institution.institutionLogoFile" alt="" >										
							</div>
							<div class="custom-file">
								<input type="file" class="custom-file-input" id="inputGroupFile06" ref="file" v-on:change="LogoChanged">
								<label class="custom-file-label" for="inputGroupFile06"><span v-if="Logo!=''">{{Logo.name.substring(0,15)}}<span v-if="Logo.name.toString().length>15">...</span></span></label>
							</div>
						</div>
						<center><p style="color: red; display: block">{{LogoError}}</p></center>
						<button class="cmnt-btn" type="button" v-if="LogoError==''&&!LogoLoading&&!LogoSuccess" :disabled="Logo==''" @click.prevent="saveNewImage()">Save new Logo</button>
						<button class="cmnt-btn" type="button" v-if="LogoError==''&&LogoLoading" :disabled="Logo==''">Please wait...</button>
						<button class="cmnt-btn" style="background-color: green" type="button" v-if="LogoError==''&&!LogoLoading&&LogoSuccess" :disabled="Logo==''">Logo Saved</button>
					</div>
					<div class="sign_form">
						<div class="tab-content" id="myTabContent">
							
							<div class="tab-pane fade show active" style="background-color: transparent" id="instructor-signup-tab" role="tabpanel" aria-labelledby="instructor-tab">
								
								<form style="margin-top: -70px">
									<div class="ui search focus">
										<div class="ui left icon input swdh11 swdh19">
											<input class="prompt srch_explore" type="text" v-model="institutionName" name="expertise" maxlength="64" placeholder="Institution Name">															
										</div>
									</div> 
									<br>
									<div class="ui search focus">
										<div class="ui left icon input swdh11 swdh19">
											<input class="prompt srch_explore" type="text" v-model="handle" name="expertise" maxlength="64" placeholder="Institution Handle">															
										</div>
									</div> 
									<br>
									<div class="ui search focus">
										<div class="ui left icon input swdh11 swdh19">
											<input class="prompt srch_explore" type="text" v-model="address" name="address" maxlength="204" placeholder="Enter Full Address">															
										</div>
									</div>
									<br>
									<div class="ui search focus">
										<div class="ui left icon input swdh11 swdh19">
											<input class="prompt srch_explore" type="email" v-model="email" name="email" maxlength="204" placeholder="Enter Institution Email">															
										</div>
									</div>
									<br>
									<div class="ui search focus">
										<div class="ui left icon input swdh11 swdh19">
											<input class="prompt srch_explore" type="number" v-model="mobile" name="mobile" maxlength="204" placeholder="Enter Mobile Contact Number">															
										</div>
									</div>
									<br>
									<div class="ui search focus mt-15">																
										<div class="ui form swdh30">
											<div class="field">
												<textarea rows="3" name="description" id="id_about" placeholder="Write a little description about this Institution..." v-model="bio"></textarea>
											</div>
										</div>
										<div class="help-block">Your biography should have at least 100 characters.</div>
									</div>
									<center><hr style="width: 80%"></center>
                                    <h4>Bank details</h4>
                                    <div class="help-block">Bank Name</div>
									<select class="ui hj145 dropdown cntry152 prompt srch_explore" style="width: 100%" v-model="bankCode" @change="setBankName" required>
										<option value="">Select your Bank</option>
										<option :value="p.code" v-for="p in payStackBanks" :key="p">{{p.name}}</option>
									</select>
                                    <div class="ui left icon input swdh11 swdh19" style="display: none">
                                        <input class="prompt srch_explore" type="text" @change="setBankDetailsChange()" v-model="bankName" name="bankName"  >															
                                    </div>
                                    <br>
                                    <div class="ui search focus">
                                        <div class="help-block">Account Number</div>
                                        <div class="ui left icon input swdh11 swdh19">
                                            <input class="prompt srch_explore" v-model="bankAccountNumber" @change="setBankDetailsChange()" name="bankAccountNumber" maxlength="64" placeholder="Enter your Account Number">															
                                        </div>
                                    </div>
                                    <br>
                                    <div class="ui search focus">
                                        <div class="help-block">Account Name</div>
                                        <div class="ui left icon input swdh11 swdh19">
                                            <input class="prompt srch_explore" type="text" v-model="bankBusinessName" @change="setBankDetailsChange()" name="bankBusinessName" maxlength="64" placeholder="Enter your Account Name">															
                                        </div>
                                    </div>
                                    
									<!-- <input type="file" v-model="cv" /> -->
									<br>
									<Errors v-if="error!=''">
										<ul>
											<li v-for="error in error" :key="error">{{error}}</li>	
										</ul>	
									</Errors>
									<br>
									
									<Loading v-if="Loading" />
									<Success v-if="Success" >
										<ul>
											<li>Action Successful</li>	
										</ul>
										<p></p>
									</Success>
									<!-- <div class="mt-3">Selected file: {{ cv ? cv.name : '' }}</div>							 -->
									<button class="login-btn" type="button" @click.prevent="saveChangesInfo" v-if="!Loading">Save Changes</button>
								</form>
							</div>
							<br>
						</div>
						<!-- <p class="mb-0 mt-30">Already have an account? <a href="sign_in.html">Log In</a></p> -->
					</div>
				</div>
			</center>
		</div>
		<div class="_215zd5" v-if="memberships" id="course">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="title589 text-center">
							<h2>Our Memberships</h2>
							<img class="line-title" src="./assets/images/line.svg" alt="">
						</div>
					</div>
				</div>
				<div class="branches_all">
					<div class="row">
						<div class="table-responsive mt-30" style="background-color: transparent">
							<table class="table ucp-table">
								<thead class="thead-s">
									<tr>
										<th class="text-center" scope="col">Item No.</th>
										<th>Title</th>
										<th class="text-center" scope="col">Max</th>
										<th class="text-center" scope="col">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="m in myMemberships" :key="m">
										<td class="text-center">{{1+(myMemberships.indexOf(m))}}</td>
										<td>{{m.title}}</td>
										<td class="text-center">{{m.max}}</td>
										<td class="text-center">
											<router-link :to="'/Dmembership/'+m._id">
												<i class="uil uil-eye"></i>
											</router-link>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<br><br><br>				
					</div>	
					<br><br><br>				
				</div>
			</div>
		</div>
		
		<Dfooter />
	</div>
	<!-- Body End -->
    </div>
</template>
<script>
import CourseEntityTiny from "@/components/dashboard/CourseEntityTiny";
import { mapActions, mapGetters } from 'vuex'
import Dheader from '@/components/dashboard/Header.vue'
import Dnav1 from '@/components/dashboard/Nav1.vue'
import Dfooter from '@/components/dashboard/Footer.vue'
import Loading from '@/components/Loading.vue'
import Success from '@/components/Success.vue'
import ProfileImage from "@/components/ProfileImage";
import Ratings from '@/components/site/Ratings.vue'
import Errors from '@/components/Errors.vue'
import RatingsDetails from '@/components/dashboard/RatingsDetails.vue'
export default {
	name: 'Dhome',
	data() {
		return{
			institutionId: '',
			institutionHandle: '',
			institutionAdmin: '',
			institution: '',
			general: true,
			courses: false,
			lecturers: false,
			about: false,
			blog: false,
			review: false,
			edit: false,
			memberships: false,
			theCourses: '',
			user_id: '',
			alreadyApplied: true,
			isStudent: true,
			alreadyAppliedInstructor: true,
			error: '',
			Loading: false,
			isFounder: false,
			institutionName: '',
			handle: '',
			bio: '',
			address: '',
			email: '',
			mobile: '',
			Success: false,
			InstitutionDashboard: '',
			AllLecturers: '',
			focus: 'institution',
			AllAffiliatedCourses: '',
			bankCode: '',
			bankName: '',
			bankBusinessName: '',
			bankAccountNumber: '',
			skip: 0,
			payStackBanks: '',
			bankDetailsChanged: false,
			paystackId: '',
			Logo: '',
			LogoLoading: false,
			LogoSuccess: false,
			LogoError: '',
			myMemberships: [],
			rate: null,
      selectedCurrency:''
		}
	},
    components: {
		Dheader,
		Dnav1,
		Dfooter,
		Loading,
		Errors,
		ProfileImage,
		Ratings,
		CourseEntityTiny,
		Success,
		RatingsDetails
	},
	computed: {
		...mapGetters(['backEnd','publicFiles']),
		...mapGetters(['isInstructor'])
	},
	methods: {
		...mapActions(['fetch_institude_details','fetchSingleInstitutionByHandle','checkInstitutionByHandle']),
		...mapActions(['fetch_teacher_courses']),
		...mapActions(['check_IsUserInstitutionStudent']),
		...mapActions(['check_IsLecturerAppllied']),
		...mapActions(['submit_Review']),
		// ...mapActions(['checkIf_UserAlreadyReviewedAndFetchAll']),
		...mapActions(['update_institution_Info','institution_Logo_Update']),
		...mapActions(['fetch_InstitutionDashboard']),
		...mapActions(['fetch_AllInstitutionLecturers']),
		...mapActions(['fetch_AllInstitutionAffiliatedCourses']),
		...mapActions(['fetchPayStackBanks','update_PayStackSubAccountDetails','fetch_FounderMembership']),
		switchFocus(to){
			this.skip = 0
			this.focus = to
		},
		switchtap(tap){
			this.skip = 0
			switch (tap) {
				case 'general':
					this.general = true;
					this.about = false;
					this.courses = false;
					this.lecturers = false;
					this.blog = false;
					this.review = false;
					this.edit = false;
					this.memberships = false;
					break;
				case 'about':
					this.general = false;
					this.about = true;
					this.courses = false;
					this.lecturers = false;
					this.blog = false;
					this.review = false;
					this.edit = false;
					this.memberships = false;
					break;
				case 'courses':
					this.general = false;
					this.about = false;
					this.courses = true;
					this.lecturers = false;
					this.blog = false;
					this.review = false;
					this.edit = false;
					this.memberships = false;
					break;
				case 'lecturers':
					this.general = false;
					this.about = false;
					this.courses = false;
					this.lecturers = true;
					this.blog = false;
					this.review = false;
					this.edit = false;
					this.memberships = false;
					break;
				case 'blog':
					this.general = false;
					this.about = false;
					this.courses = false;
					this.lecturers = false;
					this.blog = true;
					this.review = false;
					this.edit = false;
					this.memberships = false;
					break;
				case 'review':
					this.general = false;
					this.about = false;
					this.courses = false;
					this.lecturers = false;
					this.blog = false;
					this.review = true;
					this.edit = false;
					this.memberships = false;
					break;
				case 'edit':
					this.general = false;
					this.about = false;
					this.courses = false;
					this.lecturers = false;
					this.blog = false;
					this.review = false;
					this.edit = true;
					this.memberships = false;
					break;
				case 'memberships':
					this.general = false;
					this.about = false;
					this.courses = false;
					this.lecturers = false;
					this.blog = false;
					this.review = false;
					this.edit = false;
					this.memberships = true;
					break;
				default:
					break;
			}
		},
		validateHandle(handle){
            let errors = []
            let specailChars = ['@', '!', '#', '$', '%', '^', '&', '*', '(', ')', '"', '=', "'", '|', '\\', '/', '?', '>', '<', ',', '~', '`', '+', '-', ':', ';', '.']
            for (let i = 0; i < specailChars.length; i++) {
                const element = specailChars[i];
                if(handle.indexOf(element)!=-1){
                    const message = "handle can not have characters like "+element
                    errors.push(message)
                }
            }
            if(handle.indexOf(" ")!=-1){
                const message = "handle can not have space inbetween"
                errors.push(message)
            }
            if(handle.length>50){
                const message = "handle can not be more than 50 characters"
                errors.push(message)
            }

            return errors
        },
		async validateInfo(){
            let error = [];
            let message = '';
            let flag = true;
            if (this.institutionName == '') {
                message = 'Please insert Institution Name';
                error.push(message)
                flag = false
            }
			if (this.handle == '') {
                message = 'Please insert Institution Handle';
                error.push(message)
                flag = false
            }else{
				error = error.concat(this.validateHandle(this.handle))
			}
            if (this.address == '') {
                message = 'Please insert address';
                error.push(message)
                flag = false
            }
            if (this.email == '') {
                message = 'Please insert email';
                error.push(message)
                flag = false
            }
            if (this.mobile == '') {
                message = 'Please insert mobile';
                error.push(message)
                flag = false
			}
			if (this.bankCode == '') {
                message = 'Please insert bankCode';
                error.push(message)
                flag = false
			}
			if (this.bankBusinessName == '') {
                message = 'Please insert bankBusinessName';
                error.push(message)
                flag = false
			}
			if (this.bankAccountNumber == '') {
                message = 'Please insert bankAccountNumber';
                error.push(message)
                flag = false
			}
			if (this.bankName == '') {
                message = 'Please insert bankName';
                error.push(message)
                flag = false
			}
			if (!this.validateAccountNumber()) {
				message = 'bank_AccountNumber must be all numbers';
                error.push(message)
                flag = false
            }

            if (flag == true) {
				this.error = ''
                return true
            }else{
                this.error = error
                return false
            }
		},
		async saveChangesInfo(){
			if (this.validateInfo()) {//testhandle
				this.Loading = true
				const institutionCheck = await this.checkInstitutionByHandle(this.handle)
				if(institutionCheck.data!=null){
					if (institutionCheck.data._id!=this.institutionId) {
						this.error = ['Another institution already has this handle']
						this.Loading = false
						return
					}
				} 
				let info = {
					institutionId : this.institutionId,
					institutionName: this.institutionName,
					handle: this.handle,
					bio: this.bio,
					address: this.address,
					email: this.email,
					mobile: this.mobile,
					bankCode: this.bankCode,
					bankName: this.bankName,
					bankBusinessName: this.bankBusinessName,
					bankAccountNumber: this.bankAccountNumber
				}
				this.update_institution_Info(info)
				.then(res => {
					if (res.data.success) {
						this.institution.institutionName= this.institutionName
						this.institution.bio= this.bio
						this.institution.address= this.address
						this.institution.email= this.email
						this.institution.mobile= this.mobile
						if (this.bankDetailsChanged) {
							let paystackObject = {
								business_name: this.bankBusinessName, 
								bank_code: this.bankCode, 
								account_number: this.bankAccountNumber, 
								paystackId: this.paystackId
							}
							this.update_PayStackSubAccountDetails(paystackObject)
							.then(res => {
								console.log(res.data)
									this.Loading = false
									this.Success = true
							})
							.catch(err => {
								alert('something went wrong, make changes and save again')
								console.log(err)
									this.Loading = false
							})
						}else{
							this.Loading = false
							this.Success = true
						}

					}else{
						console.log(res)
					}
				})
				
			}
		},
		saveNewImage(){
			this.LogoLoading = true
			let formData = new FormData();
            formData.append('institutionId', this.institutionId);
			formData.append('institutionLogo', this.Logo);
			this.institution_Logo_Update(formData)
			.then(res => {
				this.institution.institutionLogoFile = res.data.institution.institutionLogoFile
				this.LogoLoading = false
				this.LogoSuccess= true
			})
		},
		fetchMore(type){
            this.Loading = true
			this.skip = this.skip + 10
			switch (type) {
				case 'own':
					this.fetch_teacher_courses({userId: this.institutionAdmin, skip: this.skip})
					.then(res => {
						for (let i = 0; i < res.data.length; i++) {
							const element = res.data[i];
							this.theCourses.push(element)
						}
						this.Loading = false
					})		
					break;
				case 'affiliate':
					this.fetch_AllInstitutionAffiliatedCourses({institutionId: this.institutionId, skip: this.skip})
					.then(res => {
						for (let i = 0; i < res.data.length; i++) {
							const element = res.data[i];
							this.AllAffiliatedCourses.push(element)
						}
						this.Loading = false
					})
								
					break;
				default:
					break;
			}
            // this.fetch_all_institutions(this.skip)
            // .then(res => {
            //     for (let i = 0; i < res.data.length; i++) {
            //         const element = res.data[i];
            //         this.allInstitutions.push(element)
            //     }
            //     this.Loading = false
            // })
		},
		setBankDetailsChange(){
            if (this.paystackId=='false'||this.paystackId==false||this.paystackId==''||this.paystackId==0) {
				this.bankDetailsChanged = false
            }else{  
                this.bankDetailsChanged = true
			}
        },
		setBankName(){
            this.bankName = ''
            for (let i = 0; i < this.payStackBanks.length; i++) {
                const element = this.payStackBanks[i];
                if (element.code == this.bankCode) {
                    this.bankName = element.name
                }
                
			}
			this.setBankDetailsChange()
		},
		LogoChanged(e) {
            if (this.validateFile(e.target.files[0], 'Logo')) {
                this.Logo = e.target.files[0]
                const file = this.Logo;
                const previewLogo = document.getElementById('previewMyLogo');
                if (file) {
                    const reader = new FileReader();
                    reader.addEventListener("load", function () {
                        previewLogo.setAttribute('src', this.result);
                    });
                    reader.readAsDataURL(file);
                } 
            }
        },
		validateFile(file, type){
            let flag = true;
            let message = '';
            const allowedExtensions =  ['jpg','png','jpeg','JPG','PNG','JPEG'];
            //const allowedExtensionsL =  ['jpg','png','jpeg','JPG','PNG','JPEG','pdf','PDF']
            switch (type) {
                case 'Logo':
                    if (!allowedExtensions.includes(file.name.split(".").pop())) {
                        message = 'Please insert a valid image file for Logo';
                        this.LogoError = message;
                        flag = false
                    }
                    
                    if (file.size > 1000000) {
                        message = 'Please insert smaller size Logo less than 1mb';
                        this.LogoError = message;
                        flag = false
                    }
                    
                    break;
               
                default:
                    alert('nothing')
                    break;
            }
            if (flag == true) {
                this.LogoError = ''
                return true
            }else{
                return false
            }
        },
		validateAccountNumber(){
            var splitted = this.bankAccountNumber.split("");
            var stringflag = false
            var value
            for (let i = 0; i < splitted.length; i++) {
                value = /^\d+$/.test(splitted[i])
                if (!value){
                    stringflag = true
                }
            }
            if (stringflag) {
                return false
            }else{
                return true
            }
        },
		  changeCurrency(rate) {
      this.rate = rate;
    },
    selectedCur(cur){
this.selectedCurrency = cur
    }
	},   
    mounted(){
		this.user_id = localStorage.getItem('fmruser')
		this.institutionHandle = this.$route.params.handle
		this.fetchSingleInstitutionByHandle(this.institutionHandle)
		.then(res => {
			this.institutionId = res.data._id
			this.institution = res.data
			this.institutionAdmin = this.institution.user[0]
			this.institutionName = res.data.institutionName
			this.handle = res.data.handle
			this.bio = res.data.bio
			this.address = res.data.address
			this.email = res.data.email
			this.mobile = res.data.mobile
			this.bankBusinessName = res.data.business_name
			this.bankCode = res.data.bank_code
			this.bankName = res.data.bank_name
			this.bankAccountNumber = res.data.account_number
			this.paystackId = res.data.paystackId 
			if (this.institution.user[0]==this.user_id) {
				this.isFounder = true
			}
			this.fetch_teacher_courses({userId: this.institutionAdmin, skip: this.skip})
			.then(res => {
				this.theCourses = res.data
			})
			this.fetch_FounderMembership(this.institutionAdmin)
			.then(res => {
				this.myMemberships = res.data.members
			})

			let object = {
				institutionId : this.institutionId,
				userId : this.user_id
			}
			this.check_IsUserInstitutionStudent(object)
			.then(res => {
				this.alreadyApplied = res.data.isApplied
				this.isStudent = res.data.isStudent
				//console.log('this is the '+this.isStudent)
			})
			this.check_IsLecturerAppllied(object)
			.then(res => {
				this.alreadyAppliedInstructor = res.data.isLecture
			})
			
			this.fetch_InstitutionDashboard(this.institutionId)
			.then(res => {
				this.InstitutionDashboard = res.data
			})

			this.fetch_AllInstitutionLecturers(this.institutionId)
			.then(res => {
				this.AllLecturers = res.data
			})

			this.fetch_AllInstitutionAffiliatedCourses({institutionId: this.institutionId, skip: this.skip})
			.then(res => {
				this.AllAffiliatedCourses = res.data
			})

			this.fetchPayStackBanks()
			.then(res => {
				this.payStackBanks = res.data.data
			})
			.catch(err => {
				console.log(err)
			})
		})

		

		
    }
}
</script>
<style scoped>
	@import url("./assets/css/style.css");
    /* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
    @import url("./assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/vertical-responsive-menu.min.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/instructor-responsive.css");
    @import url("./assets/css/responsive.css");
    @import url("./assets/css/night-mode.css");
    @import url("./assets/vendor/fontawesome-free/css/all.min.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("./assets/vendor/semantic/semantic.min.css");

    .nav-link{
		cursor: pointer;
		border-radius: 50%;
	}

	.liked{
		background-color: yellowgreen;
		border: 2px solid yellowgreen;
	}

	.likeButton{
		width: 50px;
		height: 50px;
		border-radius: 10px;
		padding: 10px;
	}

	.liking{
		width: 60px;
		height: 60px;
		display: inline-block;
		margin: 5px;
		padding-top: 10px;
	}
	

</style>
<template> 
    <p class="cr1fot">By 
        <router-link :to="'/Dprofile/'+id" v-if="uni==null">
            <a ><i v-if="!processing">{{firstName+" "+lastName.substring(0,30)}}<span v-if="firstName+' '+lastName.toString().length>30">...</span></i><Processing v-if="processing" /></a>
        </router-link>
        <router-link :to="'/Dinstitude/'+uni._id" v-else>
            <a ><i v-if="!processing">{{uni.institutionName.substring(0,30)}}<span v-if="uni.institutionName.toString().length>30">...</span></i> <Processing v-if="processing" /></a>
        </router-link>
    </p>
</template>
<script>
import { mapActions } from 'vuex'
import Processing from "@/components/Processing"
export default {
	name: 'Owner',
	data(){
		return{
            uni: null,
            processing: true
		}
	},
    props: {
        id: String,
        firstName: String, 
        lastName: String
    },
    components: {
        Processing
    },
	methods: {
		...mapActions(['fetch_CourseOwnerUniversity'])
	},
	mounted(){
        this.fetch_CourseOwnerUniversity(this.id)
        .then(res => {
            if (res.data.length!=0) {
                this.uni = res.data[0]
            }
            this.processing = false
        })
	}
}
</script>
<style >

</style>
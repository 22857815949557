<template>
    <div class="crse_timer" v-if="type=='dashboard'">
        <i v-if="!processing">{{~~hours}}</i><Processing v-if="processing" /> hours <i v-if="!processing">{{~~mins}}</i><Processing v-if="processing" /> minutes
    </div>
</template>
<script>
import Processing from "@/components/Processing"
import { mapActions } from 'vuex'
export default {
    data(){
        return{
            total: 0,
            hours: 0,
            mins: 0,
            processing: true
        }
    },
    props: {
        id: String,
        type: String
    },
    components:{
        Processing
    },
    methods: {
        ...mapActions(['checkTotalTimeCourse__public'])
    },
    mounted(){
        this.checkTotalTimeCourse__public(this.id)
        .then(res => {
            this.total = res.data
            this.hours = this.total/60
            this.mins = this.total%60
            this.processing = false
        })
    }
}
</script>
<style >

</style>
<template>
    <div class="crse_reviews">
        <i class='uil uil-star'></i>{{avrating}} <Processing v-if="processing" />
        <i class='uil uil-thumbs-up'></i>{{allLikesNumber}} <Processing v-if="processing" />
    </div>										
</template>
<script>
import Processing from "@/components/Processing"
import { mapActions } from "vuex";
export default {
    name: 'Rating',
	props: {
        id: String,
        type: String,
        like: Boolean
    },
    components:{
        Processing
    },
    data(){
		return{
            alreadyReviewed: true,
            reviews: '',
            stars: '',
            avrating: '',
            allLikes: '',
            processing: true,
            allLikesNumber: '',
            starsNumber: ''
        }
    },
    methods: {
		...mapActions(['fetchReviewedPublic']),
        checkAveageStar(){
            let total = 0;
            let answer = 0;
            if (this.stars.length == 0) {
                answer = 0;
            }else{
                for (let i = 0; i < this.stars.length; i++) {
                    const element = this.stars[i];
                    total = element.star + total
                }
                answer = total/this.stars.length;
            }
            return answer.toFixed(1);
        },
        countlikes(){
            let total = { like : 0, dislike : 0};
            for (let i = 0; i < this.stars.length; i++) {
                const element = this.stars[i];
                if (element.like == 1) {
                    total.like++
                }else{
                    total.dislike++
                }
            }
            return total;
        },
        fetchingReviews(type){
            let reviewObject= { 
				id : this.id,
				type : type
			}
			this.fetchReviewedPublic(reviewObject)
			.then(res => {
				this.alreadyReviewed = res.data.alreadyReviewed
				this.reviews = res.data.Reviews
				this.stars = res.data.stars
                this.avrating = this.checkAveageStar()
                this.allLikes = this.countlikes()
                if (this.avrating==0) {
                    this.useDummyRatings()
                }
                this.allLikesNumber = this.allLikes.like
                this.processing = false
			})
        },
        useDummyRatings(){
            var splitted = this.id.split("");
            var totalRatings = 1
            var totalLikes = 1
            for (let i = 0; i < splitted.length; i++) {
                const element = splitted[i];
                if (this.checkCharacter(element)) {
                    totalLikes++
                    if (i<=9) {
                        totalRatings++
                    }
                }
                
            }
            this.avrating = ((60 + (totalRatings*3))/20).toFixed(1)
            this.starsNumber = totalLikes*6
            this.allLikesNumber = ((this.starsNumber/2)+(this.starsNumber/4)).toFixed(0)
            if (this.avrating>=4.0) {
              this.allLikesNumber = ((this.starsNumber/2)+(this.starsNumber/4)+(this.starsNumber/8)).toFixed(0)  
            }
        },
        checkCharacter(character){
            var stringflag = false
            var value = /^\d+$/.test(character)
            if (!value){
                stringflag = true
            }
            if (stringflag) {
                return false
            }else{
                return true
            }
        },
    },
    mounted(){
        this.fetchingReviews(this.type)
    }
}
</script>
<style >

</style>
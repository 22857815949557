<template>
    <div class="sign_in_up_bg">
		<div class="container">
			<div class="row justify-content-lg-center justify-content-md-center">
				<div class="col-lg-12">
					<div class="main_logo25" id="logo">
						<a href="/"><img src="../../assets/faslearnBlack.png" height="70" width="250" alt=""></a>
						<a href="/"><img class="logo-inverse" src="../../assets/faslearnWhite.png" height="70" width="250"  alt=""></a>
					</div>
				</div>
			
				<div class="col-lg-6 col-md-8"> 
					<div class="sign_form">
						<div class="tab-content" id="myTabContent">
							<div class="tab-pane fade show active" style="background-color: transparent" id="instructor-signup-tab" role="tabpanel" aria-labelledby="instructor-tab">
								<h3>Register a Membership</h3>
                                <p style="margin-bottom: 5px; cursor: pointer">A Membership is a means of buying courses for a group of students with discount prices and monitoring the progress of all the members. <br> The more the members, the more the discount</p>
								<p @click="toggleDiscounts()" style="margin: 0px; cursor: pointer; color: green">See discount info</p>
                                <ul v-if="seeDiscounts">
                                    <li v-for="d in discounts" :key="d"><span class="_5f7g11">For members {{d.entity}} we offer {{d.percent}}% discount per course</span></li>
                                </ul>
                                <form >
                                    <div class="ui search focus">
                                        <div class="help-block"> Membership Title</div>
                                        <div class="ui left icon input swdh11 swdh19">
                                            <input class="prompt srch_explore" type="text" v-model="title" name="title" maxlength="60" placeholder="Title">															
                                        </div>
                                    </div>
                                    <br>
                                    <div class="ui search focus">
                                        <div class="help-block"> Maximum Members</div>
                                        <div class="ui left icon input swdh11 swdh19">
                                            <input class="prompt srch_explore" type="number" v-model="max" name="max" maxlength="60" placeholder="maximum">															
                                        </div>
                                    </div>
                                    <br>
                                    <div class="ui search focus mt-15">	
										<div class="help-block">Membership description</div>															
										<div class="ui form swdh30">
											<div class="field">
												<textarea rows="3" name="description" id="id_about" placeholder="Write a little description about this Membership..." v-model="description"></textarea>
											</div>
										</div>
									</div>
                                    <br>
                                    <Errors v-if="errors.length!=0">
                                        <ul>
                                            <li v-for="error in errors" :key="error">{{error}}</li>	
                                        </ul>
                                    </Errors>
                                    <br>
                                    <Success v-if="success">
                                        <ul>
                                            <li>Action Successful</li>
                                            <li>Click button bellow to enter the Membership page and start adding members</li>
                                        </ul>
                                        <a :href="'/Dmembership/'+membership._id">
                                            <button class="login-btn" type="button" style="background-color: darkgreen">Go to membership Workspace </button>
                                        </a>
                                    </Success>
                                    <Loading v-if="loading" />
									<button class="login-btn" type="submit" @click.prevent="saveMembership()" v-if="!loading">Submit</button>
                                
                                </form>
							</div>
						</div>
						<!-- <p class="mb-0 mt-30">Already have an account? <a href="sign_in.html">Log In</a></p> -->
					</div>
					<div class="sign_footer"><img src="../../assets/faslearnBlack.png" height="70" width="250"  alt="">© 2020 <strong>Faslearn</strong>. All Rights Reserved.</div>
				</div>				
			</div>				
		</div>				
	</div>
</template>
<script>
import Errors from '@/components/Errors.vue'
import Loading from '@/components/Loading.vue'
import Success from '@/components/Success.vue'
import { mapActions } from 'vuex'
export default {
    name: 'RegisterTeacher',
    data(){
        return{
            userId : localStorage.getItem('fmruser'),
            membership: '',
            title: '', 
            description: '', 
            max: '',
            success: false,
            loading: false,
            empty: '',
            errors: [],
            seeDiscounts: false,
            discounts: []
        }
    },
    components: {
        Errors,
        Success,
        Loading
	},
    computed: {
	},
    methods: {
        ...mapActions(['save_Membership','fetch_Discounts']),
        saveMembership(){
            if (this.validate()) {
                this.loading = true
                let object = {
                    user: this.userId, 
                    title: this.title, 
                    description: this.description, 
                    max: this.max
                }
                this.save_Membership(object)
                .then(res => {
                    this.membership = res.data.membership
                    this.loading = false
                    this.success = true
                })
            } 
        },
        toggleDiscounts(){
            this.seeDiscounts = !this.seeDiscounts
        },
        validate(){
            let flag = true;
            let error = []
            this.errors = []
            if(this.title == ''){
				flag=false;
				error.push('Please choose your title')
            }
            if(this.description == ''){
				flag=false;
				error.push('Please choose your description')
            }
            if(this.max == ''){
				flag=false;
				error.push('Please choose your max')
            }
            if (flag == true) {
                this.errors = []
                return true
            }else{
                this.errors = error
                return false
            }
        },
    }, 
    mounted(){
        this.fetch_Discounts("membership")
        .then(res => {
            this.discounts = res.data.discount
        })
    }
}
</script>
<style scoped>
    @import url("./assets/css/style.css");
    @import url("./assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/vertical-responsive-menu.min.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/instructor-responsive.css");
    @import url("./assets/css/responsive.css");
    @import url("./assets/css/night-mode.css");
    @import url("./assets/vendor/fontawesome-free/css/all.min.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("./assets/vendor/semantic/semantic.min.css");

    select{
        width: 100%;
        border-radius: 10px;
    }

</style>
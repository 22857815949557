<template>
    <div>
        <Header />

        <div class="content-wrapper">
            <div class="container-fluid">
            <!-- Breadcrumbs-->
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                <a href="#">Dashboard</a>
                </li>
                <li class="breadcrumb-item active">Your messages</li>
            </ol>
            <div class="header_box">
                <h2 class="d-inline-block">Your Messages</h2>
                <div class="card-header">
                <p class="verifiedButton" :class="{active : type=='resolved'}"  @click="switchType('resolved')">Resolved</p>
                <p class="verifiedButton" :class="{active : type=='unresolved'}" @click="switchType('unresolved')">Unresolved</p>
                </div>
            </div>
            <div class="bigspinner" v-if="Loading">
                <div class="smallspiner">
                </div>
            </div>
                <div class="box_general">
                    <div class="header_box">
                        <h2 class="d-inline-block">Your messages</h2>
                        <!-- <div class="filter">
                            <select name="orderby" class="selectbox">
                                <option value="Any status">Any status</option>
                                <option value="Approved">Started</option>
                                <option value="Pending">Pending</option>
                                <option value="Cancelled">Cancelled</option>
                            </select>
                        </div> -->
                    </div>
                    <div class="list_general">
                        <ul v-if="messages!=''">
                            <li v-for="f in messages" :key="f">
                                <figure><img src="img/course_1.jpg" alt=""></figure>
                                <!-- <h4>Course title <i class="pending">Pending</i></h4> -->
                                <ul class="course_list">
                                    <li><strong>User</strong> {{f.name}} </li>
                                    <li><strong>Email</strong> {{f.email}}</li>
                                    <li><strong>Phone</strong> {{f.mobile}}</li>
                                </ul>
                                <h6>Message Content</h6> 
                                <p> {{f.content}}.. </p>
                                <!-- .substring(0, 60) -->
                                <ul class="buttons">
                                    <li><a href="#0" class="btn_1 approve" :class="{gray : !f.resolved}"><i class="fa fa-fw fa-check-circle-o"></i>Resolved</a></li>
                                    <li><a href="#0" class="btn_1 approve" :class="{gray : !f.seen}"><i class="fa fa-fw fa-check-circle-o"></i>Seen</a></li>
                                    <router-link :to="'/SAMessage/'+f._id">
                                    <li><a href="#0" class="btn_1 gray approve"><i class="fa fa-fw fa-check-circle-o"></i> View</a></li>
                                    </router-link>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- /box_general-->
                <!-- <nav aria-label="...">
                    <ul class="pagination pagination-sm add_bottom_30">
                        <li class="page-item disabled">
                            <a class="page-link" href="#" tabindex="-1">Previous</a>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item">
                            <a class="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav> -->
                <!-- /pagination-->
                <p><a href="#0" class="btn_1 medium" @click.prevent="fetchMore()" v-if="!Loading">Fetch More messages</a></p>
                <p><a href="#0" class="btn_1 medium" v-if="Loading">Please Wait ...</a></p>
            </div>
            <!-- /container-fluid-->
            </div>
            <!-- /.container-wrapper-->
            <Footer />
            <!-- Scroll to Top Button-->
            <a class="scroll-to-top rounded" href="#page-top">
            <i class="fa fa-angle-up"></i>
            </a>
            <!-- Logout Modal-->
            <Modal />
        
    </div>
</template>
<script>
import Header from '@/components/superAdmin/Header.vue'
import Modal from '@/components/superAdmin/Modal.vue'
import Footer from '@/components/superAdmin/Footer.vue'
import { mapActions } from 'vuex'
export default {
    data(){
        return{
            messages: '',
            skip: 0,
            type: "unresolved",
            Loading: false
        }
    },
    components: {
        Header,
        Modal,
        Footer
    },
    methods: {
        ...mapActions(['fetchAllPublicMessages__Admin']),
        fetchMore(){
            this.skip = this.skip + 10
            this.Loading = true
            this.fetchAllPublicMessages__Admin({type: this.type, skip:this.skip})
            .then(res => {
                for (let i = 0; i < res.data.messages.length; i++) {
                    const element = res.data.messages[i];
                    this.messages.push(element)
                }
                this.Loading = false
            })
        },
        switchType(type){
            this.skip = 0
            this.type = type
            this.Loading = true
            this.fetchAllPublicMessages__Admin({type: this.type, skip:this.skip})
            .then(res => {
                this.messages = res.data.messages
                this.Loading = false
            })
        }
    },
    mounted(){
        this.fetchAllPublicMessages__Admin({type: this.type, skip:this.skip})
        .then(res => {
            this.messages = res.data.messages
            console.log(this.messages)
        })
    }
}
</script>
<style >
	@import url("./assets/css/admin.css");
    @import url("./assets/css/custom.css");
    @import url("./assets/vendor/font-awesome/css/font-awesome.min.css");
    @import url("./assets/vendor/datatables/dataTables.bootstrap4.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    .verifiedButton{
        color: white;
        background-color: grey;
        border-radius: 10px;
        padding: 5px;
        display: inline;
        margin-left: 10px;
        cursor: pointer;
    }

    .active{
        background-color: red;
    }

          .bigspinner{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border-top: 5px solid grey;
        display: inline-block;
        animation-name: spin;
        animation-duration: 3000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear; 
    }
    .saymessage{
        display: inline-block;
        margin-left: 10px;
    }
    .smallspiner{
        height: 30px;
        width: 30px;
        margin: 5px;
        border-radius: 50%;
        border-top: 5px solid grey;
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes spin{
        from {
        transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }
</style>